import { combineReducers } from 'redux';
import { AppReducer } from './AppReducer';
import { Login } from './Login';
import cookiesReducer from './cookiesReducer'

export default combineReducers({
  Login,
  AppReducer,
  cookiesReducer
});
