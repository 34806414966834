import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import StatusBar from '../statusBar';
import getPlatformType from '../../helpers/helpers';
import DrawerMenu from '../../screens/drawerMenu/DrawerMenu';
import { Container } from './Drawer.style';
import StatusBarMobile from '../statusBarMobile';
import Footer from '../footerComponent/customFooter';
import { Dimensions } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import StatusBarTab from '../statusTabBar/StatusBarTab';
import FooterLanding from '../footerComponentLanding/customFooter';

const WIN_HEIGHT = Dimensions.get('window').height;
var layoutType = getPlatformType();

const drawerHoc = ({ Component, title, showBackButton,navigation }) => {
  const WrapperComponent = (props) => {
    const navigation = useNavigation();
    const userToken = useSelector((state) => state?.AppReducer?.token);

    return (
      <>
        <ScrollView style={{ flexGrow: 1, height: 'auto' }}>
          {layoutType === 'web' ? <StatusBar title={title} backbutton={showBackButton} navigation={navigation} /> : null}
          {layoutType === 'tab' ? <StatusBarTab title={title} backbutton={showBackButton} /> : null}

          {layoutType === 'phone' ? <StatusBar /> : null}

          {layoutType === 'phone' || !userToken? null : <DrawerMenu />}
          {layoutType === 'phone' ? <StatusBarMobile backbutton={showBackButton} /> : null}
          <Container style={{ marginBottom: layoutType == 'web'? 40 : 0,paddingTop:layoutType == 'tab'? 50:0 }}>
            <Component {...props} />
          </Container>
        </ScrollView>
        {/* <Footer {...props} navigation={navigation} /> */}
        
         <FooterLanding navigation={navigation}/>
      </>
    );
  };
  WrapperComponent.router = Component.router;
  WrapperComponent.navigationOptions = Component.navigationOptions;
  return WrapperComponent;
};

export default drawerHoc;
