import styled from 'styled-components/native';

import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let Container ,LeftSection, RightSection ,FormBox;
if (layoutType == 'web') {
  Container = styled.View`
    flex-direction: row;
  `;
  LeftSection = styled.View`
    width: 49%;
    // align-items: flex-start;
  `;
  RightSection = styled.View`
    width: 44%;
    align-items: flex-end;
    margin-left:5%;
  `;
} else if (layoutType == 'phone') {
    Container = styled.View`
    flex-direction: coloumn;
  `;
  FormBox = styled.View`
    width: 60%;
    margin-right: 10%;
  `;
  LeftSection = styled.View`
    width: 100%;
  `;
  RightSection = styled.View`
    width: 100%;
    margin-top: 5%;
  `;

} else {
    Container = styled.View`
    flex-direction: row;
  `;
  FormBox = styled.View`
    width: 60%;
    margin-right: 10%;
  `;
  LeftSection = styled.View`
    width: 50%;
  `;
  RightSection = styled.View`
    width: 44%;
    align-items: flex-end;
    margin-left: 5%;
  `;
}

export { Container, LeftSection , RightSection , FormBox};
