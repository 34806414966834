import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { View, FlatList, TouchableOpacity } from 'react-native';

import { Row } from '../commonStyles';
import Urls from '../../services/Urls';
import Box from '../../components/box';
import Text from '../../components/text';
import ajax from '../../helpers/ajaxHelper';
import SortList from '../../components/sortList';
import drawerHoc from '../../components/drawerHOC';
import Button from '../../components/button/Button';
import getPlatformType from '../../helpers/helpers';
import { navigate } from '../../navigation/RootNavigation';
import SearchCardComponent from '../../components/searchCard';
import { HeaderView, Section, WebText } from '../payment/index.style';
import { MaterialIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';

const layoutType = getPlatformType();
const sortData = [
  { name: 'Title', value: 'title' },
  { name: 'Date', value: 'date' },
];
const Reminder = () => {
  const [reminders, setReminders] = useState([]);
  const [filteredReminders, setFilteredReminders] = useState([]);
  const [sort, setSort] = useState('Date');
  const focus = useIsFocused();
  const navigation = useNavigation();

  useEffect(() => {
    ajax
      .get(`${Urls.ADD_REMAINDERS}?filterReminders=true`)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload) {
            setReminders(data?.payload);
            setFilteredReminders(data?.payload);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  const sortReminders = (name, value) => {
    setSort(name);
    const sortedObjs = _.sortBy(reminders, value);
    setFilteredReminders(sortedObjs);
  };

  const _ReminderItem = (item) => {
    return (
      <TouchableOpacity
        onPress={() => navigate('EditReminder', { id: item.id, type: item.type })}
        style={{
          justifyContent: 'space-between',
          marginBottom: 10,
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          borderBottomWidth: 1,
          borderColor: '#dcdcdc',
          paddingBottom: 10,
        }}
      >
        <View style={{ flexDirection: 'row', width:  layoutType == 'phone' ? '100%' : '50%', justifyContent: 'space-between' }}>
          <Text fontSize={15} fontWeight={'500'} style={{ width:'55%' }}>
           
            {layoutType == 'phone' ? item.title.substring(0,15):item?.title}
          </Text>
          <Text fontSize={15} fontWeight={'500'} color={Colors.light.purple} style={{ width: '45%' }}>
            {item?.added_by == 'auto' ? 'Auto' : 'Manual'}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', width:  layoutType == 'phone' ? '100%' : '47%', justifyContent: 'space-between' }}>
          <View style={{ width: layoutType == 'phone' ? '40%' : '40%' }}>
            <WebText>Property</WebText>

            <Text fontSize={14} fontWeight={'400'}>
              {item?.property?.name}
            </Text>
          </View>
          <View style={{ width: layoutType == 'phone' ? '40%' : '25%' }}>
            <WebText>Date</WebText>
            <Text fontSize={14} fontWeight={'400'} color = {moment(item.date).isBefore(moment().format())? '#FF0000': '#000000'}>
              {moment(item?.date).format('DD/MM/YYYY')}
            </Text>
          </View>
          <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />
        </View>
      </TouchableOpacity>
    );
  };

  const filter = (list, search) => {
    if (search) {
      search = search.toLowerCase();
      const response = list.filter((reminder) => {
        return (
          reminder.title.toLowerCase().includes(search) ||
          reminder.property.name.toLowerCase().includes(search) ||
          reminder.property.postcode.toLowerCase().includes(search)
        );
      });
      return response;
    }
    return list;
  };

  const filterReminders = (search) => {
    setFilteredReminders(filter(reminders, search));
  };

  const EmptyListMessage = ({ item }) => {
    return (
      // Flat List Item
      <Text>
        No Reminder Found
      </Text>
    );
  };


  return (
    <>
      <Row
        style={{
          justifyContent: 'space-between',
          alignItems: layoutType == 'phone' ? 'flex-end' : 'center',
          zIndex: 999,
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          marginBottom: layoutType == 'phone' ? 10 : 0,
        }}
      >
        <SearchCardComponent 
      placehorderText={'Search Reminders'}
        onChangeText={(e) => filterReminders(e)} leftText={'My Reminders'} />
        <SortList data={sortData} renderTitle={sort} setSort={(name, value) => sortReminders(name, value)}  width={200} />
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Button childText={'Add New Reminder'} onPress={() => navigation.push('AddReminder', { type: 'other' })} width={170} />
      </Row>
      <Section>
        <Box>
          <FlatList
            data={filteredReminders}
            style={{ flexGrow: 0 }}
            ListHeaderComponent={() => (
              <HeaderView>
                <View style={{ width: '25%', marginBottom: 10, alignItems: 'flex-start' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Title
                  </Text>
                </View>

                <View style={{ width: '23%', marginBottom: 10, alignItems: 'flex-start' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Type
                  </Text>
                </View>

                <View style={{ width: '25%', marginBottom: 10, alignItems: 'flex-start' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Property
                  </Text>
                </View>

                <View style={{ width: '15%', marginBottom: 10 }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    Date
                  </Text>
                </View>

                <View style={{ width: '3%', marginBottom: 10 }}></View>
              </HeaderView>
            )}
            renderItem={({ item }) => _ReminderItem(item)}
            ListEmptyComponent={EmptyListMessage}
          />
        </Box>
      </Section>
      <Row style={{ marginTop: 10 }}>
        <Button childText={'Add New Reminder'} onPress={() => navigation.push('AddReminder', { type: 'other' })} width={170} />
      </Row>
    </>
  );
};

export default drawerHoc({ Component: Reminder });
