import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';

export const Status = styled.div`
  font-size: 12px;
  font-weight: bold;
  border: 1px solid ${(props) => (props.status === 'verified' ? variables.green : variables.orangeButton)};
  color: ${(props) => (props.status === 'verified' ? '#fff' : variables.purple2)};
  background: ${(props) => (props.status === 'verified' ? variables.green : 'transparent')};

  display: flex;
  border-radius: 18px;
  width: 156px;
  height: 36px;
  font-weight: 400;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
