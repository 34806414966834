import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
// import { tenantActions } from '_actions';
import { Heading, SubHeading } from './style';
import variables from '../atoms/variables.json';
import RentalWarrentyPopup from './rentalWarrentyPopup';
import { LineSeperator } from '../atoms/backComponent/styles';

const RentalWarrenty = (props) => {
  useEffect(() => {}, []);

  const dispatch = useDispatch();
  const warranty = props.warranty;
  const flowId = props.flowId;
  const [modalData, setModalData] = useState('');
  const [checkedValue, setCheckedValue] = useState('');
  const [showSuccessModal, setSuccessModal] = React.useState({ status: false, type: null });
  const buyRentalWarrantySuccessData = useSelector((state) => state.tenant?.buyRentalWarrantySuccess);

  console.log('warrantywarrantywarrantywarranty', warranty);
  useEffect(() => {
    console.log(warranty);
    if (warranty && warranty?.baught_or_commission) {
      let checkvalue = warranty?.baught_or_commission == 'commission' ? 'Earn-Commission' : 'Buy-Yourself';
      setCheckedValue(checkvalue);
    }
  }, [warranty]);

  const handleChange = (event) => {
    setCheckedValue(event);
  };

  const buyWarranty = (data) => {
    // dispatch(tenantActions.buyRentalWarranty(flowId, data.type != 'buy', data));
  };

  useEffect(() => {
    if (buyRentalWarrantySuccessData) {
      setSuccessModal(false);
    }
  }, [buyRentalWarrantySuccessData]);

  const initialRentalWarranty = () => {
    return (
      <div>
        <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              marginBottom: 20,
              flexDirection: 'column',
            }}
          >
            <SubHeading>
              OpenBrix has teamed up with FCC Paragon to bring marketing leading rental warranty, often refered to as
              rent gurantee product and services to you.
            </SubHeading>
            <SubHeading>
              Once you have completed your tenant referencing, rental warranty option will appear below.
            </SubHeading>
            <SubHeading>
              However if no options are displayed, you may call FCC paragon directly on the number below.
            </SubHeading>
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 20,
                }}
              >
                <SubHeading>For Information contact:</SubHeading>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: 20,
                }}
              >
                <SubHeading style={{ color: '#6D08C0' }}>02392 315105</SubHeading>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 20,
                }}
              >
                <SubHeading>Quote REF:</SubHeading>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: 20,
                }}
              >
                <SubHeading style={{ color: '#6D08C0' }}>OBX HS</SubHeading>
              </div>
            </div>
          </div>
        </CardBox>
      </div>
    );
  };

  const checkWarrantyAvailable = () => {
    // dispatch(tenantActions.checkWarrantyAvailable(flowId));
  };

  const warrantyAvailable = () => {
    return (
      <div>
        {warranty?.baught_or_commission == null ? (
          <div>
            <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%' }}>
              <div
                style={{
                  display: 'flex',
                  marginTop: 10,
                  marginBottom: 20,
                  flexDirection: 'column',
                }}
              >
                <SubHeading>This Tenancy has qualified for Rental Warranty..</SubHeading>
                <SubHeading>
                  OpenBrix has Teamed up with FCC Paragon to provide the best in class rental warranty.
                </SubHeading>
                <SubHeading>You have two options.</SubHeading>
                <span>
                  <SubHeading>Buy yourself</SubHeading>
                  <SubHeading>
                    You can buy your warranty directly from FCC via your openbrix account at a very competitive price.
                  </SubHeading>
                </span>
                <span>
                  <SubHeading>Earn Commission</SubHeading>
                  <SubHeading>
                    Earn commission by simple asking FCC Paragon to contact your landlord and sell the rental warranty
                    for you. ALL sucessful sales on rental warranty will mean you get risidual commission.
                  </SubHeading>
                </span>
              </div>
            </CardBox>

            <SubHeading>Which option would you like to review?</SubHeading>
            <div style={{ paddingLeft: 30, display: 'flex', flexDirection: 'column' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedValue === 'Buy-Yourself' ? true : false}
                    style={{ color: variables.heliotrope }}
                    onChange={() => handleChange('Buy-Yourself')}
                    name="Buy-Yourself"
                    size={'small'}
                  />
                }
                label="Buy Yourself"
                style={{ color: '#000', fontSize: 8 }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedValue === 'Earn-Commission' ? true : false}
                    style={{ color: variables.heliotrope }}
                    onChange={() => handleChange('Earn-Commission')}
                    name="Earn-Commission"
                    size={'small'}
                  />
                }
                label="Earn Commission"
                style={{ color: '#000', fontSize: 8 }}
              />
            </div>
          </div>
        ) : null}

        {checkedValue === 'Buy-Yourself' && (
          <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%' }}>
            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20, flexDirection: 'column' }}>
              <SubHeading>Buy Yourself</SubHeading>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <SubHeading>Warranty Available:</SubHeading>
                <SubHeading>{warranty?.name}</SubHeading>
                <Button
                  type="custom"
                  title={'Warranty Price:  ' + '  £' + warranty?.price.toFixed(2) + ''}
                  color={'black'}
                  style={{
                    backgroundColor: variables.lightgrey,
                    alignSelf: 'left',
                    marginBottom: 15,
                    height: 76,
                    width: 148,
                  }}
                />
              </div>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: '4%' }}>
                {warranty?.status == 'warranty_available' ? (
                  <Button
                    type="custom"
                    onClick={() => {
                      setSuccessModal({ status: true, type: 'buy' });
                    }}
                    title={'Buy Now'}
                    color={'white'}
                    style={{ backgroundColor: variables.navyblue, alignSelf: 'left', marginBottom: 15 }}
                  />
                ) : null}

                {warranty?.status == 'pending' ? (
                  <Button
                    type="custom"
                    title={'Pending'}
                    color={'white'}
                    disabled={true}
                    style={{ backgroundColor: variables.yellow, alignSelf: 'left', marginBottom: 15 }}
                  />
                ) : null}
              </div>
              {warranty?.terms_url ? (
                <div style={{ display: 'flex', width: '100%', marginTop: '4%', justifyContent: 'space-between' }}>
                  <CardBox
                    padding={'7px'}
                    style={{
                      width: '50%',
                      backgroundColor: variables.lightGreen,
                      border: 'solid 1px ' + variables.darkgreen,
                      borderRadius: 20,
                    }}
                    hasShadow={true}
                    hasBorderRadius={true}
                  >
                    <div style={{ justifyContent: 'space-between', display: 'flex', flex: 1, alignItems: 'center' }}>
                      <div style={{ fontSize: '14px' }}>Rental Warranty Terms</div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(warranty?.terms_url, '_blank');
                          }}
                        >
                          <div style={{ fontSize: '14px', fontWeight: 500, color: variables.purple1 }}>View</div>
                        </span>
                      </div>
                    </div>
                  </CardBox>
                </div>
              ) : null}
              {warranty?.waranty_schedule ? (
                <div style={{ display: 'flex', width: '100%', marginTop: '4%', justifyContent: 'space-between' }}>
                  <CardBox
                    padding={'7px'}
                    style={{
                      width: '50%',
                      backgroundColor: variables.lightpink,
                      border: 'solid 1px ' + variables.darkpink,
                      borderRadius: 20,
                    }}
                    hasShadow={true}
                    hasBorderRadius={true}
                  >
                    <div style={{ justifyContent: 'space-between', display: 'flex', flex: 1, alignItems: 'center' }}>
                      <div style={{ fontSize: '14px' }}>Rental Warranty Schedule</div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            window.open(warranty?.waranty_schedule, '_blank');
                          }}
                        >
                          <div style={{ fontSize: '14px', fontWeight: 500, color: variables.purple1 }}>View</div>
                        </span>
                      </div>
                    </div>
                  </CardBox>
                </div>
              ) : null}
            </div>
          </CardBox>
        )}
        {checkedValue === 'Earn-Commission' && (
          <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%' }}>
            <div style={{ display: 'flex', marginTop: 10, marginBottom: 20, flexDirection: 'column' }}>
              <SubHeading>Earn Commission</SubHeading>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '4%' }}>
                  <SubHeading>
                    If you choose this option, OpenBrix will Share your details and your landlords details to FCC
                    Paragon.
                  </SubHeading>
                  <SubHeading>
                    If you have any question regarding this you can contact FCC directly on the following Number.
                  </SubHeading>
                </div>
                <Button
                  type="custom"
                  title={'Commission £' + warranty.commission.toFixed(2)}
                  color={'black'}
                  style={{
                    backgroundColor: variables.lightgrey,
                    alignSelf: 'left',
                    marginBottom: 15,
                    height: 76,
                    width: 148,
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '2%',
                }}
              >
                <div style={{ display: 'flex', flex: 1 }}>
                  <SubHeading>For Information contact: </SubHeading>
                  <SubHeading style={{ color: '#6D08C0', marginLeft: '2%' }}>02392 315105</SubHeading>
                </div>
                {warranty.status == 'warranty_available' ? (
                  <Button
                    type="custom"
                    onClick={() => {
                      setSuccessModal({ status: true, type: 'earn' });
                    }}
                    title={'Earn Commission'}
                    color={'white'}
                    style={{
                      backgroundColor: modalData?.type === 'earn' ? variables.darkgrey : '#90C418',
                      alignSelf: 'left',
                      marginBottom: 15,
                    }}
                  />
                ) : null}
              </div>

              {warranty.status == 'pending' || warranty.status == 'sold' ? (
                <div style={{ display: 'flex', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flex: 0.5,
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingRight: 20,
                    }}
                  >
                    <SubHeading>Sales Status:</SubHeading>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flex: 0.5,
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      paddingLeft: 20,
                    }}
                  >
                    <div
                      style={{
                        height: 48,
                        width: 146,
                        borderRadius: 6,
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        backgroundColor: warranty?.status == 'pending' ? variables.yellow : '#00B401',
                      }}
                    >
                      {warranty.status == 'pending' ? <span>Pending</span> : <span>Warranty Sold</span>}
                    </div>
                  </div>
                </div>
              ) : null}
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                  }}
                >
                  <SubHeading>For Information contact:</SubHeading>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 20,
                  }}
                >
                  <SubHeading style={{ color: '#6D08C0' }}>02392 315105</SubHeading>
                </div>
              </div>
              <div style={{ display: 'flex', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    paddingRight: 20,
                  }}
                >
                  <SubHeading>Quote REF:</SubHeading>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 0.5,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: 20,
                  }}
                >
                  <SubHeading style={{ color: '#6D08C0' }}>OBX EARN</SubHeading>
                </div>
              </div>
            </div>
          </CardBox>
        )}
      </div>
    );
  };

  const warrantyNotAvailable = () => {
    return (
      <div>
        <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%' }}>
          <div
            style={{
              display: 'flex',
              marginTop: 10,
              marginBottom: 20,
              flexDirection: 'column',
            }}
          >
            <SubHeading>This Tenancy does not qualify for rental warranty</SubHeading>
            <SubHeading>
              If you wish to have get more information on this please contact FCC on the details below.
            </SubHeading>
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 20,
                }}
              >
                <SubHeading>For Information contact:</SubHeading>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: 20,
                }}
              >
                <SubHeading style={{ color: '#6D08C0' }}>02392 315105</SubHeading>
              </div>
            </div>
            <div style={{ display: 'flex', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 20,
                }}
              >
                <SubHeading>Quote REF:</SubHeading>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingLeft: 20,
                }}
              >
                <SubHeading style={{ color: '#6D08C0' }}>OBX NW</SubHeading>
              </div>
            </div>
          </div>
        </CardBox>
      </div>
    );
  };

  const checkApiRequired = () => {
    return (
      <CardBox hasShadow={true} hasBorderRadius={true} style={{ marginBottom: '5%' }}>
        <div
          style={{
            display: 'flex',
            marginTop: 10,
            marginBottom: 20,
            flexDirection: 'column',
          }}
        >
          <SubHeading>This tenancy MAY be qualify for a warranty.</SubHeading>
          <SubHeading>Would you like us to check to see?</SubHeading>

          <div style={{ display: 'flex', width: '100%' }}>
            {warranty.status == 'check_requested' ? (
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: 20,
                }}
              >
                <Button
                  style={{
                    backgroundColor: variables.yellow,
                    color: 'black',
                    borderRadius: 5,
                  }}
                  type="solid"
                  noClick="noClick"
                  title={'Pending'}
                />
              </div>
            ) : null}
            {warranty.status == 'check_api_required' ? (
              <div
                style={{
                  display: 'flex',
                  flex: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 20,
                }}
              >
                <Button
                  type="custom"
                  onClick={() => {
                    checkWarrantyAvailable();
                  }}
                  title={'check'}
                  color={'white'}
                  style={{ backgroundColor: variables.navyblue, alignSelf: 'left', marginBottom: 15 }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </CardBox>
    );
  };

  return (
    <>
      <LineSeperator />

      <Heading style={{ marginTop: 20 }}>Rental Warranty</Heading>
      {warranty == null ? initialRentalWarranty() : null}

      {warranty &&
      (warranty.status == 'warranty_available' ||
        warranty.status == 'pending' ||
        warranty.status == 'warranty_schedule' ||
        warranty.status == 'sold')
        ? warrantyAvailable()
        : null}

      {warranty && warranty.status == 'warranty_not_available' ? warrantyNotAvailable() : null}

      {warranty && (warranty.status == 'check_api_required' || warranty.status == 'check_requested')
        ? checkApiRequired()
        : null}

      <RentalWarrentyPopup
        visible={showSuccessModal?.status}
        data={warranty}
        // visible={true}
        // data={{price:100,commission:30}}
        type={showSuccessModal.type}
        heading={showSuccessModal.type == 'buy' ? 'Buy Warranty' : 'Earn Commission'}
        subHeading={[
          `Thank you for choosing to buy your rental warranty.`,
          `Below are all the details reagrding the warranty you are about to buy.`,
          'Information Sharing',
          'Please note we will be sending the following information to FCC Paragon,',
        ]}
        onCancelPress={() => setSuccessModal(false)}
        onOkPress={(data) => {
          buyWarranty(data);
        }}
      />
    </>
  );
};

export default RentalWarrenty;
