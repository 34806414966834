import styled from 'styled-components/native';
import { Text } from 'react-native';
import Colors from '../../constants/Colors';

export const Container = styled.View``;

export const SortBox = styled.View`
  background: transparent;
  border-color: ${Colors.light.lightPurple};
  border-width: 2;
  border-radius: 20;
  padding-top: 5;
  padding-bottom: 5;
  padding-left: 10;
  padding-right: 10;
  height: auto;
`;
export const TopView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextWrapper = styled(Text)`
  margin-right: 10;
`;
export const RenderTitle = styled(Text)`
  margin-right: 10;
  color: ${Colors.light.lightPurple};
`;
