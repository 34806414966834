import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';

export const BackBox = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5%;
  justify-content: space-between;
`;
