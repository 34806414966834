import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { Button } from '../button';
import Modal from '@material-ui/core/Modal';
import { ModalHeader, ModalBody } from './style';

const SaveandContinueModal = (props) => {
  const { visible, heading, subHeading, onOkPress, onCancelPress, showNote, modalState, showMsg, hideSwitch } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [state, setState] = React.useState({
    send_to_tenant: false,
  });
  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  useEffect(() => {
    setState({ ...modalState });
  }, [modalState]);

  return (
    <Modal
      open={showModal}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 600,
          backgroundColor: '#f4f4f4',
          boxShadow: 'none',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          outline: 'none',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          position: 'relative',
          color: '#000',
          maxHeight: '100vh',
          overflowX: 'auto',
        }}
      >
        <span
          style={{
            position: 'absolute',
            right: 25,
            fontWeight: 'bold',
            color: '#6D08C0',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onCancelPress) {
              onCancelPress();
            }
          }}
        >
          x
        </span>
        <ModalBody>
          <ModalHeader>{heading}</ModalHeader>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {subHeading.map((item, index) => (
              <div key={index} style={{ fontSize: 18, marginBottom: 20, fontWeight: '400' }}>
                {item}
              </div>
            ))}

            {hideSwitch ? null : (
              <div
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  marginTop: 20,
                  marginBottom: 20,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    color: state?.send_to_tenant ? '#dadada' : '#FC4850',
                    fontWeight: '900',
                    fontSize: 20,
                    marginRight: 20,
                  }}
                >
                  Dont Send
                </div>

                <Switch
                  checked={state?.send_to_tenant}
                  onChange={() => setState({ ...state, send_to_tenant: !state?.send_to_tenant })}
                  color="primary"
                  size="normal"
                />

                <div
                  style={{
                    color: state?.send_to_tenant ? '#90C418' : '#dadada',
                    fontWeight: '900',
                    fontSize: 20,
                    marginLeft: 20,
                  }}
                >
                  Send
                </div>
              </div>
            )}
            {showNote && (
              <div>
                <div>PLEASE NOTE:</div>
                <div style={{ marginTop: 10 }}>
                  The email may end up in your tenant(s) junk mail. Please communicate with them to ensure they locate
                  the email and carry out the relevant actions.
                </div>
              </div>
            )}
            {state.send_to_tenant && (
              <>
                <div style={{ width: '100%' }}>Once you click this, you cannot undo this change.</div>
              </>
            )}
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'space-between',
                marginTop: 20,
              }}
            >
              {hideSwitch ? null : (
                <Button
                  onClick={() => {
                    if (onCancelPress) {
                      onCancelPress();
                    }
                  }}
                  style={{ borderColor: '#FC4850', color: '#FC4850' }}
                  title={'Cancel'}
                  type="stripe"
                />
              )}
              <Button
                onClick={() => {
                  if (onOkPress) {
                    onOkPress(state);
                  }
                }}
                style={{ borderColor: '#90C418', color: '#90C418' }}
                title={'OK'}
                type="stripe"
              />
            </div>
            {showMsg && (
              <div>
                <div style={{ marginTop: 15, justifyContent: 'center', alignItems: 'center' }}>{showMsg}</div>
              </div>
            )}
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default SaveandContinueModal;
