import React, { useState } from 'react';
import { View, FlatList } from 'react-native';
import Box from '../box';
import Text from '../text';
import { MaterialIcons } from '@expo/vector-icons';

import {
  RowWrapper,
} from './TooltipsAndNews.style';
const TooltipsAndNews = ({ navigation }) => {
  const NewsItems = () => (
    <RowWrapper align="center">
      <View style={{ marginRight: 20, marginBottom: 20 }}>
        <Text fontSize={15} bold>
          Industry insight title description
        </Text>
        <Text fontSize={14} numberOfLine={1}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit
        </Text>
      </View>
      <MaterialIcons name="keyboard-arrow-right" size={24} color="#6612B6" />
    </RowWrapper>
  );
  const TooltipsItem = (name) => (
    <Box style={{ width: 113 }}>
      <View style={{ marginBottom: 20, alignItems: 'center' }}>
        <MaterialIcons name={name} size={42} color="#6612B6" />
      </View>
      <Text fontSize={14} bold align="center">
        Lorem ipsum dolor
      </Text>
    </Box>
  );
  return (
    <>
      <RowWrapper justifyContent={"space-between"}>
          <RowWrapper  >
            <Text fontSize={20} marginTop={20} marginLeft={20} marginBottom={10} bold>
              New & Updates
            </Text>
            <Text fontSize={14} marginTop={20} marginBottom={10} bold color="#6612B6">
              View all
            </Text>
          </RowWrapper>
          <RowWrapper justifyContent="space-between">
            <Text fontSize={20} marginTop={20} marginLeft={30} marginBottom={10} 
            color="#6612B6"
            bold>
              Tool tips
            </Text>
            <Text fontSize={14} marginTop={20} marginBottom={10} bold color="#6612B6">
              View all
            </Text>
          </RowWrapper>
        </RowWrapper>

        <RowWrapper>
          <Box>
            {NewsItems()}
            {NewsItems()}
          </Box>
          <RowWrapper width="40%">
            {TooltipsItem('watch-later')}
            {TooltipsItem('file-copy')}
            {TooltipsItem('vpn-key')}
          </RowWrapper>
        </RowWrapper>
    </>
  );
};

export default TooltipsAndNews;
