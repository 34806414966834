import React, { useEffect, useState } from 'react';
import { ListItem } from '../atoms/listitem';
import { View } from 'react-native';
import Checkbox from '@material-ui/core/Checkbox';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import { useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import variables from '../atoms/variables.json';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import ToolTip from '../atoms/ToolTip';
// import HomeSetup from './../../../assets/svgIcons/OpenFlo/DepositProtect.svg';
import { navigate } from '../../../../navigation/RootNavigation';

// import logo from '../../../assets/img/hometelecom.png';
import { useDispatch, useSelector } from 'react-redux';

import { CardWrapper, TlyfeSelector, LineSeperator, SubHeading, ButtonRowContainer } from './style';
// import { tenantActions, alertActions } from '_actions';

import { UploadCard } from '../atoms/uploadCard';
import BackComponent from '../atoms/backComponent';
import ToggleComplete from '../atoms/toggleComplete';
import BottomButton from '../atoms/bottomButton';
import { DocumentWithStatusCard } from './../atoms/documentWithStatusCard';
import PropertyCard from '../atoms/PropertyCard';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';

const DepositProtectHomeSetup = (props) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const [showSuccessModal, setSuccessModal] = React.useState(false);
  const depositProtectData = useSelector((state) => state.tenant?.depositProtectData);
  const rentCollData = useSelector((state) => state.tenant?.depositProtectData?.rentCollect);
  const depositDate = useSelector((state) => state);

  const [flowId, setFlowId] = React.useState(null);
  const [despositSchemeDoc, setDespositSchemeDoc] = React.useState([]);
  const modalGlobalObject = useSelector((state) => state?.tenant?.depositProtectData);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });

  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);
  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
      // dispatch(tenantActions.getProtectData(props?.match?.params?.id));
    }
  }, [props?.match?.params?.id]);

  useEffect(() => {
    if (depositProtectData && depositProtectData?.deposit_certificate?.length) {
      setDespositSchemeDoc(depositProtectData?.deposit_certificate);
    }
  }, [depositProtectData?.deposit_certificate]);

  const addDoc = (data) => {
    let teampArr = [
      ...despositSchemeDoc,
      {
        document_url: data[0],
      },
    ];
    setDespositSchemeDoc(teampArr);
    _onSaveData(true, true);
  };

  const removeDoc = (docIndex) => {
    let teampArr = [...despositSchemeDoc];
    teampArr.splice(docIndex, 1);
    setDespositSchemeDoc(teampArr);
  };

  const _onSaveData = (blockNavigation, showAlert = false, data = null) => {
    let payload = {
      deposit_certificate: despositSchemeDoc,
      ...data,
    };
    // dispatch(tenantActions.saveProtectData(payload, props?.match?.params?.id, blockNavigation, showAlert));
  };

  return (
    <Container style={{ fontWeight: 400 }}>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Deposit Protect & Home Set Up'}
          listIcon={<ListAltTwoToneIcon style={{ fontSize: '25px', color: variables.purple1 }} />}
          // icon={HomeSetup}
          showPropertyCard
          description={`Protect the Deposit and arrange all the utility and home set-up connections for your Tenants. `}
        />

        <BackComponent
          id={flowId}
          isComplete={depositProtectData?.flow?.property_status === 1 ? true : false}
          flowtype={'property_status'}
        />

        <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SubHeading>Deposit Protect</SubHeading>
          </div>
        </TlyfeSelector>
        <CardBox backColor={variables.powderBlue} style={{ width: '100%' }}>
          <SubHeading color={variables.scooter}>Deposit & upfront rental payment</SubHeading>
          <SubHeading color={variables.scooter}>
            You must protect the tenant(s) deposit by using a government-approved tenancy deposit scheme (TDP).
            <br />
            <br />
            You must do this within 30 calendar days of receiving the deposit.
          </SubHeading>
        </CardBox>
        <LineSeperator />
        <TlyfeSelector
          style={{
            marginTop: 0,
            justifyContent: 'space-between',
            position: 'relative',
          }}
        >
          <ToolTip
            style={{ top: '0%', right: '-40%' }}
            heading={'Tool tip title'}
            ContentData={[
              'Failing to protect the deposit can result in',
              '- Not being able to gain possession',
              '- Potential fine of 3x the deposit',
              ' - And the original deposit is returned to the tenant',
            ]}
          />
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SubHeading>Deposit Protect</SubHeading>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: variables.heliotrope }}
                    name="isComplete"
                  />
                }
                label="Mark as complete"
                style={{ color: "#000", fontSize: 10 }}
              />
            </FormControl>
          </div> */}
        </TlyfeSelector>
        <CardBox hasBorderRadius hasShadow style={{ marginTop: '5px', width: '100%' }}>
          <DocumentWithStatusCard
            flowID={flowId}
            hideIcon
            docValue={despositSchemeDoc}
            hideUpload={despositSchemeDoc.length > 0 ? true : false}
            onClick={(data) => addDoc(data)}
            onRemove={(docIndex) => removeDoc(docIndex)}
            name={'Deposit Scheme'}
            isSeen={despositSchemeDoc ? despositSchemeDoc[0]?.is_seen : null}
            showFileIcon
            showButton
            renderCustomBody={() => {
              if (
                despositSchemeDoc &&
                despositSchemeDoc.length &&
                despositSchemeDoc[despositSchemeDoc.length - 1]?.document_url
              ) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  ></div>
                );
              } else {
                return null;
              }
            }}
            subName={`Please upload your deposit protection certificate.`}
          />
        </CardBox>
        {depositProtectData?.can_upload_cert && (
          <Button
            style={{ marginTop: 35, alignSelf: 'flex-end', color: 'black' }}
            onClick={() => {
              _onSaveData(true, true);
            }}
            title={'Save & Send Document'}
            type="custom"
            color={variables.puregreen}
          />
        )}

        <LineSeperator />
        <View style={{ position: 'relative' }}>
          <ToolTip
            style={{ top: '20%', right: '-40%' }}
            heading={'Tool tip title'}
            ContentData={[
              'Charged at £10.00 per property, per month.',
              'NB – this has to be collected from the Lead Tenant. Ie. not split or shared.',
            ]}
          />
        </View>
        <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SubHeading>Rent Collection</SubHeading>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: variables.heliotrope }}
                    name="isComplete"
                  />
                }
                label="Mark as complete"
                style={{ color: "#000", fontSize: 10 }}
              />
            </FormControl>
          </div> */}
        </TlyfeSelector>
        <CardBox hasBorderRadius hasShadow style={{ marginTop: '5px', width: '100%' }}>
          <UploadCard
            noUpload
            rightButtonComponent={() => {
              return (
                <Button
                  type="solid"
                  title="Buy Rent Collection"
                  onClick={() => {
                    navigate('RentCollectionSignUp');

                    if (rentCollData) {
                      navigate('RentCollectionSignUp');
                      // dispatch(alertActions.success("you've already signed up for rent collection."));
                    } else {
                      navigate('RentCollectionSignUp');

                      // history.push('/dashboard/RentCollectionSignUp');
                    }
                  }}
                  color={variables.navyblue}
                />
              );
            }}
            titleComponent={() => {
              return (
                <SubHeading style={{ fontSize: '13px' }}>
                  <strong>Rent Collection</strong> <br /> <br />
                  Would you like to arrange rent collection for this tenancy through our partner, FCC Paragon
                </SubHeading>
              );
            }}
          />
        </CardBox>
        <LineSeperator />
        <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <SubHeading>Utility Setup</SubHeading>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: variables.heliotrope }}
                    name="isComplete"
                  />
                }
                label="Mark as complete"
                style={{ color: "#000", fontSize: 10 }}
              />
            </FormControl>
          </div> */}
        </TlyfeSelector>
        <CardBox hasBorderRadius hasShadow style={{ marginTop: '5px', width: '100%' }}>
          <UploadCard
            flexWidth={1}
            noUpload
            //   rightButtonComponent={()=>{
            //       return(
            //           <Button type="custom" title="Sign up" color={variables.lead_green}/>
            //       )
            //   }}
            titleComponent={() => {
              return (
                <div style={{ alignItems: 'center' }}>
                  <div style={{ flex: 1 }}>
                    <SubHeading style={{ fontSize: '13px' }}>
                      <strong>Utility Setup</strong>
                      <br />
                      {/* <img style={{ marginLeft: '75%' }} height={140} alt="Logo" src={logo} /> */}
                      <br /> <br />
                      <b>
                        {' '}
                        • Openbrix has partnered with Hometelecom to assist your tenant move and set up Utilities and
                        broadband.
                        <br />
                        <br />
                        • Hometelecom are tenant specialist with 23 years experience in telecoms covering 98% of the UK.
                        <br />
                        <br />
                        • They offer specialist broadband services with unique contract lengths, from 6 months,12 months
                        and 18 month contracts to match the length of the tenancy.
                        <br />
                        <br />
                        • UK based telephone customer services and online chat support in different languages
                        <br />
                        <br />
                        • 4.5 stars on trust pilot <br />
                        <br />
                        • UK green energy provider
                        <br />
                        <br />
                        • Great commission and customer services.
                        <br />
                        <br />
                      </b>
                    </SubHeading>
                    <SubHeading>
                      If you have a different move in partner that you would like to use, please let us know.
                    </SubHeading>
                  </div>
                </div>
              );
            }}
          />
        </CardBox>
        <BottomButton
          onContinue={() => _onSaveData(null, null, modalState)}
          showSendbutton={false}
          showContinueModal={false}
        />
      </CardWrapper>
      <SaveandContinueModal
        modalState={modalState}
        visible={showSuccessModal}
        showNote
        heading={'Deposit Certificate'}
        subHeading={[
          `Do you with to send the "deposit protection certificate" to your tenant(s)?`,
          `Remember,  if you don't toggle to "send", your tenant(s) will not receive the "deposit protection certificate".`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        onOkPress={(data) => {
          _onSaveData(null, null, data);
        }}
      />
    </Container>
  );
};

export default drawerHoc({
  Component: DepositProtectHomeSetup,
  showBackButton: true,
});
