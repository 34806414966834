import React, { useState } from 'react';
import { Container, GradientView, TextWrapper } from './GradientText.style';
import PropTypes from 'prop-types';
import Text from '../text';
import { View } from 'react-native';
const GradientText = (props) => {
  const { text,fontSize } = props;
  return (
    <GradientView colors={['#6612B6', '#4258DB']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 0 }} >
     <View style={{backgroundColor:'tranparent'}}>
     <Text fontWeight={'600'}  lineHeight={22} fontSize={fontSize?fontSize:16}>{text}</Text> 
     </View>
    </GradientView>
  );
};

GradientText.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.number,
};

GradientText.defaultProps = {
  text: "Hello",
  fontSize:20
};

export default GradientText;
