import React, { useState, useEffect } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { useIsFocused } from '@react-navigation/native';
import { View, TouchableOpacity } from 'react-native';

import Urls from '../../services/Urls';
import Colors from '../../constants/Colors';
import ajax from '../../helpers/ajaxHelper';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import getPlatformType from '../../helpers/helpers';
import {
  Container,
  ContainerScrollView,
  MainText,
  ProfileName,
  ProfileBox,
  ProfileEmail,
  ProfileContact,
  WrapperBox,
} from './index.style';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Text from '../../components/text';
import { Row } from '../commonStyles';
import Box from '../../components/box';
import SwitchButton from '../../components/switch';
import { navigate } from '../../navigation/RootNavigation';

var layoutType = getPlatformType();

const MyAccount = ({ navigation }) => {
  const [user, setUser] = useState({});
  const focused = useIsFocused();
  useEffect(() => {
    ajax
      .get(Urls.PROFILE)
      .then(({ data }) => {
        if (data.status) {
          setUser(data?.data.userDetails);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focused]);

  return (
    <>
      <WrapperBox>
        <ProfileBox>
          <View style={{ flexDirection: 'row',flex:1 }}>
            <FontAwesome name="user" size={44} color={Colors.light.purple} />
            <View style={{ marginLeft: 15,flex:1 }}>
              <Text fontWeight={'600'} fontSize={16} numberOfLines={1}>
                {user.first_name} {user.last_name}
              </Text>
              <Text fontSize={14} marginTop={5} numberOfLines={1}>
                {user.contact_number}
              </Text>
              <Text>{user.email}</Text>
            </View>
          </View>
          <TouchableOpacity onPress={() => navigate('AccountEdit')}>
            <Text color={Colors.light.lightPurple} bold>
              Edit
            </Text>
          </TouchableOpacity>
        </ProfileBox>
      </WrapperBox>
      {/* <Text fontSize={18} fontWeight={'600'} marginBottom={20} marginTop={20}>
        Notification preferences
      </Text>
      <Box>
        {[1, 2, 3, 4, 5].map((e) => (
          <View style={{ borderBottomWidth: 1, borderColor: '#ddd', padding: 10 }}>
            <Row style={{ justifyContent: 'space-between' }}>
              <Text fontSize={15}>Notification Description</Text>
              <SwitchButton onSwitchChange={(e) => console.log(e)} />
            </Row>
          </View>
        ))}
      </Box> */}
    </>
  );
};

export default drawerHoc({
  Component: MyAccount,
  showBackButton: true,
});
