import React, { useState } from 'react';
import { ScrollView, Text, TextInput, View } from 'react-native';
// import Hyperlink from "react-native-hyperlink";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { AboutUs, getProfile } from "../../redux/actions/auth";
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { EmailValidation, NameValidation, SubjectValidation } from '../../helpers/validation';
import { Row } from '../commonStyles';
import { ContactHead, ContactTitle, Container, FormContainer, MainContent } from './styles';
const layoutType = getPlatformType();

const AboutUs = ({ navigation }) => {
  const [state, setState] = useState({
    name: '',
    nameErrorMsg: '',
    email: '',
    emailErrorMsg: '',
    topic: '',
    message: '',
    profile: null,
    first_name: '',
    last_name: '',
    pre_email: '',
    topicErrorMsg: '',
    messageErrorMsg: '',
  });
  const validate = () => {};

  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <View style={{ flex: 1 }}>
          <MainContent >
            <ContactTitle>About Us</ContactTitle>

            <Container
              style={{ backgroundColor: 'white', padding: 20, minHeight: 500, borderTopRightRadius: 10 }} >
                         <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Text style={{ fontWeight: 'bold', fontSize: 50, marginTop: 20 }}>
        Hubita
    </Text>
</View>
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <Text style={{ fontWeight: 'bold', fontSize: 30, marginTop: 20 }}>
        Your Property. Managed
    </Text>
</View>
<View style={{ flexDirection: layoutType==='phone'?'column' :'row' }}>
<View style ={layoutType == 'phone' ?{}:{flex:0.49}}>
<Text style={{ fontWeight: 'bold', fontSize: 24, marginTop: 20 }}>
        Who are we
    </Text>
    <Text style={{ fontSize: 14, marginTop: 20 }}>
        
At <a href="http://www.hubita.co.uk" target="_blank" rel="noopener noreferrer">
      hubita.co.uk 
    </a>, we pride ourselves on being a leading property management platform dedicated to helping private landlords manage all their property needs.
    </Text>
    <Text style={{ fontSize: 14, marginTop: 20 }}>
        
Our team of dedicated professionals strive to ensure that our platform provides our landlords with the most upto date information on current legislations in order to manage their properties compliantly.
            </Text>
</View>
<View style ={layoutType == 'phone' ?{}:{flex:0.49,  marginLeft: 10 }}>
<Text style={{ fontWeight: 'bold', fontSize: 24, marginTop: 20 }}>
        What do we do
    </Text>
    <Text style={{ fontSize: 14, marginTop: 20 }}>
    To private landlords, Hubita, is a one stop, online property management tool, that aggregates all transactions around property management into a simple, easy and free to use hub.
            </Text>
            <Text style={{ fontSize: 14, marginTop: 20 }}>

We provide our landlords complete peace of mind that their property is always compliant and securely managed.
            </Text>
            <Text style={{ fontSize: 14, marginTop: 20 }}>

            We invite you to explore Hubita.co.uk and discover the features that will help you manage your property easily and compliantly. Whether you are a single property landlord or a portfolio landlord we have something for everyone.
            </Text>
</View>
</View>
          
<Text style={{ fontWeight: 'bold', fontSize: 24, marginTop: 20 }}>Our Mission</Text>

{[`At Hubita.co.uk, our mission is to be the onestop shop for landlords to manage all their property needs, making sure they have everything the need to manage their property, when they need it. We are passionate about helping our private sector landlords and are dedicated to providing the very best services to our valued customers.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 10}}>{e}</Text>)}
<View style={{ flexDirection: layoutType==='phone'?'column' :'row', marginTop: 20 }}>
<View style ={layoutType == 'phone' ?{}:{flex:0.49}}>
<Text style={{ fontWeight: 'bold', marginTop: 20 , fontSize: 24  }}>Why Choose Hubita.co.uk?</Text>

  <Text style={{ fontWeight: 'bold'  , marginTop: 20}}>{'\u2022'}Quality:</Text> <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400' , marginTop: 5 }}>We prioritize quality in everything we do.This is why we are very selective with our suppliers.</Text>
 
  <Text style={{ fontWeight: 'bold', marginTop: 20 }}>{'\u2022'}Innovation:</Text> <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5   }}>In a rapidly evolving proptech sector, we stay ahead of the curve by embracing innovation. Our commitment to staying at the forefront of frontier technologies from AI, to automated process, to ensure that our customers have access to the latest and most advanced system to manage their property needs.</Text>
  {'\n'} 
  <Text style={{ fontWeight: 'bold' ,marginTop:20 }}>{'\u2022'}Landlord-Centric Approach:</Text> <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5   }}>Our customers are at the heart of what we do. We built this product with over 13 years of collaboration directly with our landlords. We also ensure every supplier we partner with has a similar passion to help our landlords. As a team, we go above and beyond to understand their needs and provide personalized solutions that exceed expectations.
</Text>
  </View>
  <View style ={layoutType == 'phone' ?{}:{flex:0.49 , marginLeft: 10 }}>
  <Text style={{ fontWeight: 'bold', marginTop: 20 , fontSize: 24  }}>What Sets Us Apart</Text>
<View>

    <Text style={{ fontWeight: 'bold' , marginTop: 20}}>{'\u2022'}Expert Team:</Text>  <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5 }}>Our team comprises seasoned professionals with a wealth of experience in the letting industry. Their expertise is the driving force behind our success.</Text>
 

    <Text style={{ fontWeight: 'bold' , marginTop: 20}}>{'\u2022'}Simplicity:</Text>  <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5 }}>Hubita’s core proposition is its ease of use. We work closely with our landlords to make sure that using our website is the easiest software system they have encountered. It is intuitive, simple and very powerful!</Text>


    <Text style={{ fontWeight: 'bold' , marginTop: 20}}>{'\u2022'}Functionality:</Text>   <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5 }}>Hubita may be a free software but it packs a punch in terms of functionality. We have built a product for our landlords that take away all the stress and pain points in managing their property.</Text>
 

    <Text style={{ fontWeight: 'bold', marginTop: 20 }}>{'\u2022'}Compliant:</Text>  <Text style={{ fontSize: 14, marginLeft: 15, fontWeight: '400', marginTop: 5 }}>Using Hubita gives landlords complete peace of mind that their property is always compliant and securely managed.Ensuring they are always kept up to date with the latest legislation and compliance requirements.
  </Text>
</View>

  </View>

  </View>
  <Text style={{ fontWeight: 'bold', fontSize: 24, marginTop: 20 }}>Get in Touch</Text>
  <Text style={{  marginTop: 20 , color: '#6D08C0', textDecorationLine: 'underline'  }}><a href="mailto:info@hubita.co.uk">info@hubita.co.uk</a></Text>
  <Text >5 Indescon Square, South Quay, Canary Wharf, London, England, E14 9DQ</Text>

            </Container>
          </MainContent>
        </View>
      </ScrollView>
    </View>
  );
};
export default drawerHoc({
  Component: AboutUs,
  showBackButton: true,
});
