import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Container,HeaderWrapper,ColoumnWrapper,ListItem,ListSection;
if(layoutType=="web"){
Container = styled.View`
width:100%;
padding-top:25px;
padding-horizontal:20px;
margin-vertical:15px;
padding-bottom:10px;
border-radius: ${(props) => props.borderRadius || 20}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
`;
ListItem=styled.TouchableOpacity`
flex-direction:row;
justify-content:flex-start;
`;
ListSection=styled.View`
flex-direction:row;
justify-content:flex-start;
align-items:center;
width:${props=>props?.width}
`;
HeaderWrapper=styled.View`
flex-direction:row;
margin-bottom:10px;
`;
ColoumnWrapper=styled.View`
width:30%;
`;

} 
else if(layoutType=="phone"){
    Container = styled.View`
    width:100%;
    padding-top:10px;
    padding-horizontal:20px;
    margin-vertical:10px;
    border-radius: ${(props) => props.borderRadius || 20}px;
    background-color: ${(props) => props.backgroundColor || 'white'};
    box-shadow: 0 6px 4px rgba(50,99,244,0.05);
    `;
    ListItem=styled.TouchableOpacity`
    flex-direction:row;
    justify-content:flex-start;
    flex-wrap:wrap;
    `;
    ListSection=styled.View`
    flex-direction:row;
    justify-content:flex-start;
    align-items:center;
    width:${props=>props?.width}
    margin-vertical:10px;

    `;
    HeaderWrapper=styled.View`
    flex-direction:row;
    margin-bottom:10px;
    `;
    ColoumnWrapper=styled.View`
    width:30%;
    `;
}
else {
Container = styled.View`
width:100%;
padding-top:25px;
padding-bottom:10px;
padding-horizontal:20px;
margin-vertical:10px;
border-radius: ${(props) => props.borderRadius || 20}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
`;
ListItem=styled.TouchableOpacity`
flex-direction:row;
justify-content:flex-start;
`;
ListSection=styled.View`
flex-direction:row;
justify-content:flex-start;
align-items:center;
width:${props=>props?.width}
`;
HeaderWrapper=styled.View`
flex-direction:row;
margin-bottom:10px;
`;
ColoumnWrapper=styled.View`
width:30%;
`;
}
export {Container,HeaderWrapper,ColoumnWrapper,ListItem,ListSection};



