import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Container, Row, TextInputWrapper;
const HorizontalLine = styled.View`
  height: 100%;
  width: 1px;
  background-color: #d8d8d8;
  margin-right: 16px;
`;
if (layoutType == 'web') {
  Container = styled.View`
  flex-grow: 2;
  margin-right: 10px;
  max-width: 70%;
    height: 56px;
    align-items: center;
    padding-horizontal: 16px;
    margin-vertical: 10px;
    border-radius: ${(props) => props.borderRadius || 20}px;
    background-color: ${(props) => props.backgroundColor || 'white'};
    box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
    flex-direction: row;
    justify-content: space-between;
  `;
  TextInputWrapper = styled(TextInput)`
    width: 100%;
    height: ${(props) => (props?.height ? props.height + 'px' : '30px')};
    padding: 10px;
    font-size: 16px;
    border-color: white;
  `;
  Row = styled.TouchableOpacity`
    width: ${(props) => props.width || '40%'};
    flex-direction: row;
    align-items: center;
    border-left-width: ${(props) => (props.width ? '0px' : '1px')};
    border-color: #d8d8d8;
    padding-left: ${(props) => (props.width ? '0px' : '15px')};
  `;
} else if (layoutType == 'phone') {
  Container = styled.View`
    width: 100%;
    height: 56px;
    align-items: center;
    padding-horizontal: 15px;
    background-color: pink;
    margin-vertical: 10px;
    border-radius: ${(props) => props.borderRadius || 20}px;
    background-color: ${(props) => props.backgroundColor || 'white'};
    box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
    flex-direction: row;
    justify-content: space-between;
  `;
  TextInputWrapper = styled(TextInput)`
    width: 100%;
    height: ${(props) => (props?.height ? props.height + 'px' : '25px')};
    padding: 10px;
    font-size: 18px;
    border-color: white;
    font-size: 16px;
  `;
  Row = styled.TouchableOpacity`
    width: ${(props) => props.width || '40%'};
    flex-direction: row;
    align-items: center;
    border-left-width: ${(props) => (props.width ? '0px' : '1px')};
    border-color: #d8d8d8;
    padding-left: ${(props) => (props.width ? '0px' : '15px')};
  `;
} else {
  Container = styled.View`
    max-width: 70%;
    height: 56px;
    align-items: center;
    padding-horizontal: 16px;
    margin-vertical: 10px;
    border-radius: ${(props) => props.borderRadius || 20}px;
    background-color: ${(props) => props.backgroundColor || 'white'};
    box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
    flex-direction: row;
    justify-content: space-between;
  `;
  TextInputWrapper = styled(TextInput)`
    width: 100%;
    height: ${(props) => (props?.height ? props.height + 'px' : '30px')};
    padding: 10px;
    font-size: 18px;
    border-color: white;
  `;
  Row = styled.TouchableOpacity`
    width: ${(props) => props.width || '40%'};
    flex-direction: row;
    align-items: center;
    border-left-width: ${(props) => (props.width ? '0px' : '1px')};
    border-color: #d8d8d8;
    padding-left: ${(props) => (props.width ? '0px' : '15px')};
  `;
}
export { Container, Row, HorizontalLine, TextInputWrapper };
