import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { format, addYears } from 'date-fns';
import { StyleSheet } from 'react-native';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { Octicons } from '@expo/vector-icons';
import moment from 'moment';
import Text from '../text';

LocaleConfig.locales['en'] = {
  formatAccessibilityLabel: "dddd d 'of' MMMM 'of' yyyy",
  monthNames: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ],
  monthNamesShort: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
};

LocaleConfig.defaultLocale = 'en';

const INITIAL_DATE = '2020-02-02';

const CalenderComponent = (props) => {
  const { markedDates, theme, minDate, maxDate, disabledDates, onMonthChange, markers } = props;
  const [selected, setSelected] = useState(INITIAL_DATE);
  const onDayPress = (day) => {
    setSelected(day.dateString);
  };
  return (
    <>
      <Calendar
        style={styles.calendar}
        renderArrow={(direction) => {
          if (direction == 'left') return <Octicons name="chevron-left" size={24} color="black" />;
          if (direction == 'right') return <Octicons name="chevron-right" size={24} color="black" />;
        }}
        markingType={'custom'}
        onMonthChange={(month) => {
          onMonthChange(month);
          console.log('month changed', month);
        }}
        enableSwipeMonths={true}
        current={moment().format('YYYY-MM-DD')}
        markedDates={markers}
      />
    </>
  );
};

const styles = StyleSheet.create({
  calendar: {
    marginBottom: 10,
  },
  switchContainer: {
    flexDirection: 'row',
    margin: 10,
    alignItems: 'center',
  },
  switchText: {
    margin: 10,
    fontSize: 16,
  },
  text: {
    textAlign: 'center',
    padding: 10,
    backgroundColor: 'lightgrey',
    fontSize: 16,
  },
  disabledText: {
    color: 'grey',
  },
  defaultText: {
    color: 'purple',
  },
  customCalendar: {
    height: 250,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey',
  },
  customDay: {
    textAlign: 'center',
  },
  customHeader: {
    backgroundColor: '#FCC',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginHorizontal: -4,
    padding: 8,
  },
});

CalenderComponent.propTypes = {
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  selectedDate: PropTypes.string,
  disabledDates: PropTypes.arrayOf(PropTypes.string),
  onSetDatePress: PropTypes.func,
  onClosePress: PropTypes.func.isRequired,
  accessibilityLabel: PropTypes.string,
};
CalenderComponent.defaultProps = {
  minDate: format(new Date(), 'yyyy-MM-dd'),
  maxDate: format(addYears(new Date(), 40), 'yyyy-MM-dd'),
  selectedDate: null,
  disabledDates: [],
  onSetDatePress: () => {},
  accessibilityLabel: 'datePicker',
};
export default CalenderComponent;
