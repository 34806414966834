import React from 'react';
import { Picker } from 'react-native-web';
import Text from '../text';

export default class DropDownPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : 0,
      valueList: this.props.items ? this.props.items : [],
      editable: !this.props.disabled ?? true,
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.defaultValue ? this.props.defaultValue : 0,
      valueList: this.props.items ? this.props.items : [],
      editable: !this.props.disabled ?? true,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.defaultValue !== this.state.value &&
        this.props.value &&
        typeof this.props.defaultValue !== 'undefined') ||
      this.state.valueList !== this.props.items
    ) {
      this.setState({
        value: this.props.defaultValue ?? 0,
        valueList: this.props.items,
        editable: !this.props.disabled ?? true,
      });
    }
  }

  updatePickerValue = (value) => {
    this.setState(
      {
        value: value.value,
      },
      () => {
        this.props.onChangeItem(value);
      }
    );
  };

  static getDerivedStateFromProps(props, state) {
    if (props.hasOwnProperty('defaultValue')) {
      if (!props.defaultValue && state?.value?.length) {
        state.value = '';
      } else {
        state.value = props.defaultValue;
      }
    }
    return null;
  }

  render() {
    let picker;
    const { noBorder = false, disabled, title } = this.props;
    let { value, valueList, editable } = this.state;
    console.log(' title disableddisabled', title, disabled, editable);
    picker = (
      <>
        {title && (
          <Text fontWeight={'600'} lineHeight={22} fontSize={16} marginBottom={10}>
            {title}
          </Text>
        )}
        <Picker
          // enabled={this.state.editable}
          enabled={!disabled}
          selectedValue={value}
          style={{
            height: 40,
            borderWidth: noBorder ? 0 : 2,
            borderColor: disabled ? '#EBEBEB' : 'black',
            borderWidth: 1,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            marginBottom: 10,
            // fontSize: 14,
          }}
          className={'price-filter-box'}
          onValueChange={(value) => this.updatePickerValue({ value: value })}
        >
          <Picker.Item
            key={99}
            enabled={false}
            label={'Select here'}
            value={''}
            style={{
              color: 'red',
              padding: 5,
            }}
          />
          {valueList.map((item, index) => (
            <Picker.Item
              key={index}
              label={item.label}
              value={item.value}
              style={{
                color: 'red',
                padding: 5,
              }}
            />
          ))}
        </Picker>
        {this.props?.error ? (
          <Text style={{ position: 'absolute', right: 0 }} fontSize={10} color={'red'} align={'right'}>
            *Required
          </Text>
        ) : null}
      </>
    );

    return picker;
  }
}
