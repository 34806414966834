import { Dimensions } from "react-native";
const { width } = Dimensions.get("window");

function getPlatformType() {
  if (width <= 767) {
    return "phone";
  } else if (width >= 768 && width <= 1224) {
    return "tab";
  } else if (width >= 1224) {
    return "web";
  }
}
export default getPlatformType;
export const validateNumericWithSpaces = (inputValue) => {
  // Use a regular expression to match numeric values and spaces
  const regex = /^[0-9\s]*$/;
  // Test the input against the regex
  if (regex.test(inputValue)) {
    return inputValue;
  } else {
  // If the input contains invalid characters, remove them
    const sanitizedValue = inputValue.replace(/[^0-9\s]/g, '');
    return sanitizedValue;
  }

};