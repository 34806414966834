import React, { useState } from 'react';
import { FontAwesome } from '@expo/vector-icons';
import { Container } from './index.style';
import PropTypes from 'prop-types';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import Box from '../box';
const ProfileCard = (props) => {
  const { leftIcon, title } = props;

  return (
    <Box style={{ marginVertical: 10, paddingVertical: 15, paddingHorizontal: 10 }}>
      <Container>
        <FontAwesome name="user" size={20} color={Colors.light.lightPurple} />
        <Text fontSize={14} marginLeft={10} fontWeight={500} color="black">
          {title}
        </Text>
      </Container>
    </Box>
  );
};
ProfileCard.propTypes = {
  leftIcon: PropTypes.string,
  title: PropTypes.string,
};

ProfileCard.defaultProps = {
  leftIcon: 'location-pin',
  title: 'Title',
};

export default ProfileCard;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
