import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';

const layoutType = getPlatformType();
let Container;




if (layoutType == 'phone') {
    
  Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px 56px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom:200px
  `;
} else {
  Container = styled.View`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px 56px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 1px 3px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-bottom:200px
  `;
}

export {
  Container,

};
