import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import DialogButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ToolTip from '../atoms/ToolTip';
import { Button } from '../atoms/button';
import BackComponent from '../atoms/backComponent';

import { CardBox } from './../atoms/card';
import { ListItem } from '../atoms/listitem';
import { ListCard } from '../atoms/listCard';
import variables from '../atoms/variables.json';

import HomeSetup from './../../../../assets/svgIcons/OpenFlo/DepositProtect.svg';
import Movein from './../../../../assets/svgIcons/OpenFlo/MoveIn.svg';
import Aggrement from './../../../../assets/svgIcons/OpenFlo/Agreement.svg';
import ID_ICON from './../../../../assets/svgIcons/OpenFlo/ID&Reference.svg';
import HOLDING_ICON from './../../../../assets/svgIcons/OpenFlo/HoldingDeposit.svg';
import RENTAL_ICON from './../../../../assets/svgIcons/OpenFlo/RentalOffer.svg';
import DOC_ICON from './../../../../assets/svgIcons/OpenFlo/Documentation.svg';
import TEANANT_ICON from './../../../../assets/svgIcons/OpenFlo/Tenant.svg';

import { CardWrapper, SubHeading, TlyfeButtonWrapper, TouchableArea } from './style';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import { navigate } from '../../../../navigation/RootNavigation';

const TeanantOnboarding = (props) => {
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state?.tenant);
  const [flowId, setFlowId] = React.useState(null);
  const [flowIdInfo, setFlowIdInfo] = React.useState(2);

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [showWarningDialog, setShowWarningDialog] = React.useState(false);

  const closeDialog = () => {
    setShowConfirmDialog(false);
  };

  const closeWarningDialog = () => {
    setShowWarningDialog(false);
  };

  const addFlowToProperty = () => {
    // dispatch(tenantActions.addFlowToProperty(globalState?.flowIdInfo?.openflow_id));
    closeDialog();
  };

  const openDialog = () => {
    let allCompleted = ListArray.every(function (item) {
      return flowIdInfo[item.buttonStatus] == 1;
    });
    if (allCompleted) {
      setShowConfirmDialog(true);
    } else {
      setShowWarningDialog(true);
    }
  };

  useEffect(() => {
    if (props?.match?.params) {
      if (props?.match?.params?.id && props?.match?.params?.id != 'new') {
        // dispatch(tenantActions.getFlowInfo(props?.match?.params?.id));
      } else {
        // dispatch(tenantActions.cleanGetFlowInfo());
      }
    }
  }, []);

  useEffect(() => {
    if (globalState?.flowIdInfo?.openflow_id) {
      // setFlowId(globalState?.flowIdInfo?.openflow_id);
      // setFlowIdInfo(globalState?.flowIdInfo);
    }
  }, [globalState]);

  const ListArray = [
    {
      title: 'Tenant',
      buttonStatus: 'tenancy_status',
      routeKey: 'TenantDetails',
      desc: `Begin the tenant onboarding process by "pairing" your Applicant Tenant(s) to your property.`,
      icon: TEANANT_ICON,
    },
    {
      title: 'Landlord & Property Documentation',
      buttonStatus: 'doc_status',
      routeKey: 'PropertyDocumentation',
      desc: 'Please ensure all details are correct so that all documentation, certificates and compliance requirements are correct.',
      icon: DOC_ICON,
    },
    {
      title: 'Rental Offer',
      buttonStatus: 'rental_offer_status',
      routeKey: 'RentalOffers',
      desc: 'The following information contains the details of the offer agreed on the proposed property, subject to contract. ',
      icon: RENTAL_ICON,
    },
    {
      title: 'Holding Deposit',
      buttonStatus: 'holding_deposit_status',
      routeKey: 'HoldingDeposit',
      desc: 'Paying a Holding Deposit indicates the Applicant Tenant(s) intention to accept the terms of the rental offer, subject to contract. ',
      icon: HOLDING_ICON,
    },
    {
      title: 'Tenant ID & Reference ',
      buttonStatus: 'verification_status',
      routeKey: 'IdAndReference',
      desc: "Verify the Applicant Tenant(s) ID, fulfil 'Right to Rent' obligations and start the Reference procedure.",
      icon: ID_ICON,
    },
    {
      title: 'Tenancy Agreement',
      buttonStatus: 'landlord_status',
      routeKey: 'TenancyAgreement',
      desc: 'Digitally create, check or amend your tenancy Agreement and send to all parties.',
      icon: Aggrement,
    },
    {
      title: 'Move in',
      buttonStatus: 'move_in_status',
      routeKey: 'MoveIn',
      desc: `A breakdown of all "Moving in" Monies, plus the prescribed information and requirements of the Deposit. `,
      icon: Movein,
    },

    {
      title: 'Deposit Protect & Home Setup',
      buttonStatus: 'property_status',
      routeKey: 'DepositProtectHomeSetup',
      desc: 'Protect the Deposit and arrange all the utility and home set-up connections for your Tenants. ',
      icon: HomeSetup,
    },
  ];
  const getLeadTenant = () => {
    let tenant = flowIdInfo.tenants;
    var leadTenant = tenant.filter(function (e) {
      return e.is_lead == true;
    });
    return leadTenant[0]?.app_user?.user;
  };
  //       this.props.history.push("/dashboard/partnership");
  return (
    <>
      <div>
        <Dialog
          open={showConfirmDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Move to Property? </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to complete this onboard?
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              If you select "YES", the flow will be moved to the "completed onboards" section
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={addFlowToProperty} color="primary" autoFocus>
              Yes
            </DialogButton>
            <DialogButton onClick={closeDialog} color="primary">
              No
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={showWarningDialog}
          onClose={closeWarningDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Incomplete flow </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please complete all flow steps first to add it to the property
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={closeWarningDialog} color="primary">
              Ok
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>

      {flowIdInfo ? (
        <CardWrapper>
          {flowIdInfo?.openflow_id && (
            <ListCard
              Id={flowId}
              showPropertyCard
              key={flowIdInfo.openflow_id}
              name={flowIdInfo?.tenants[0] ? getLeadTenant()?.first_name + ' ' + getLeadTenant()?.last_name : null}
              // name={
              //   flowIdInfo?.tenants[0]?.app_user?.user?.first_name
              //     ? flowIdInfo?.tenants[0]?.app_user?.user?.first_name +
              //       flowIdInfo?.tenants[0]?.app_user?.user?.last_name
              //     : null
              // }
              contact={flowIdInfo?.tenants ? getLeadTenant()?.contact_number : null}
              email={flowIdInfo?.tenants ? getLeadTenant()?.email : null}
              percentage={flowIdInfo?.percentage_completion ? (flowIdInfo?.percentage_completion).toFixed(1) : 0}
              propertyName={flowIdInfo?.property?.postcode}
              style={{ marginBottom: '5%', position: 'relative' }}
              leftComponent={() => {
                return <p></p>;
              }}
            />
          )}

          <CardBox style={{ position: 'relative', width: '100%' }} backColor={variables.powderBlue}>
            <SubHeading color={variables.scooter}>
              OpenFlow is the tenant onboarding system that interacts with your tenant via thier own tlyfe app.
            </SubHeading>
            <SubHeading color={variables.scooter}>
              You can go through the process steps, in any order you wish. The system will guide you through in a
              compliant way.
            </SubHeading>
          </CardBox>

          <BackComponent
            hideMark
            hideLine
            handleClick={
              () => {}
              // props.history.push(`/dashboard/TenantLandingPage`)
            }
          />
          <View style={{ position: 'relative' }}>
            <ToolTip
              style={{ bottom: '90%', right: '-40%' }}
              heading={'Tenant Tips'}
              ContentData={[
                'Tip 1 - Ensure you have the correct email address for the tenant, everything else the tenant can complete.',
                'Tip 2 -  Ask the applicants to Check to see if all the details  are entered correctly on the Tlyfe app. ',
              ]}
            />
            {ListArray.map((data, index) => (
              <TouchableArea
                key={index}
                onClick={() => {
                  if (data?.routeKey) {
                    navigate(data?.routeKey);
                  }
                }}
              >
                <ListItem
                  type={
                    flowIdInfo && flowIdInfo[data?.buttonStatus] == 0
                      ? 'incomplete'
                      : flowIdInfo[data?.buttonStatus] == 1
                      ? 'complete'
                      : 'in progress'
                  }
                  title={data.title}
                  showArrowIcon
                  showButton
                  key={index}
                  marginTop={'5%'}
                  description={data.desc}
                  icon={data.icon}
                />
              </TouchableArea>
            ))}
          </View>

          <TlyfeButtonWrapper style={{ marginTop: '5%', position: 'relative' }}>
            <ToolTip
              style={{ bottom: '90%', right: '-40%' }}
              heading={'Tool Tips'}
              ContentData={['Home Setup - This is where the OpenFlo will earn your agency referral commissions.']}
            />
            <Button
              title={'Save'}
              onClick={() => {
                openDialog();
              }}
              type="stripe"
            />
          </TlyfeButtonWrapper>
        </CardWrapper>
      ) : null}
    </>
  );
};
export default drawerHoc({
  Component: TeanantOnboarding,
  showBackButton: true,
});
