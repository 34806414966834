import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import { Button } from '../../atoms/button';
import Modal from '@material-ui/core/Modal';
import { ModalHeader, ModalBody } from './style';
import { Heading, SubHeading, TlyfeButtonWrapper } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select } from '@material-ui/core';
import { TextInput } from '../../atoms/textinput';
// import StringConstatnt from '../../../../utils/constants';

const RentalWarrentyPopup = (props) => {
  const { visible, heading, subHeading, onOkPress, onCancelPress, showNote, type, data } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [landloards, setLandloards] = React.useState(false);

  const [state, setState] = React.useState({
    type: props.type,
    title: '',
    name: '',
    first_name: '',
    last_name: '',
    correspondence_address: '',
    post_code: '',
    email: '',
    number: '',
    showForm: false,
  });

  const agentInfo = useSelector((state) => state.users?.user_profile);
  const landlordsInfo = useSelector((state) => state?.tenant?.idendityData?.flow?.open_flow_landlords);

  const handleChange = (e, name) => {
    setState({
      ...state,
      [e.target.name]: e.target.value ? e.target.value : name,
    });
  };
  useEffect(() => {
    setState({
      ...state,
      type,
    });
  }, [type]);
  useEffect(() => {
    if (data) {
      setState({
        warranty: data?.name,
        commission: data?.commission,
        price: data?.price,
        ...state,
      });
    }
  }, [data]);
  useEffect(() => {
    if (landlordsInfo?.length) {
      let leadLandloard = landlordsInfo[0];
      setState({
        ...state,
        title: leadLandloard?.title,
        name: leadLandloard?.name,
        first_name: leadLandloard?.first_name,
        last_name: leadLandloard?.last_name,
        correspondece_address: leadLandloard?.correspondece_address,
        post_code: leadLandloard?.post_code,
        email: leadLandloard?.email,
        number: leadLandloard?.number,
        showForm: false,
      });
    }
  }, [landlordsInfo]);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);
  console.log('SaveandContinueModalSaveandContinueModaltestatestatestate', state);
  return (
    <Modal
      open={showModal}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 600,
          backgroundColor: '#f4f4f4',
          boxShadow: 'none',
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          outline: 'none',
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          position: 'relative',
          color: '#000',
          maxHeight: '100vh',
          overflowX: 'auto',
        }}
      >
        <span
          style={{
            position: 'absolute',
            right: 25,
            fontWeight: 'bold',
            color: '#6D08C0',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (onCancelPress) {
              onCancelPress();
            }
          }}
        >
          x
        </span>
        <ModalBody>
          <ModalHeader>{heading}</ModalHeader>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {subHeading.map((item, index) => (
              <div key={index} style={{ fontSize: 18, marginBottom: 20, fontWeight: '400' }}>
                {item}
              </div>
            ))}
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '4%',
                marginBottom: '4%',
              }}
            >
              <span style={{ fontSize: 20, fontWeight: '600', marginBottom: 20 }}>Landlord info</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p style={{ fontSize: 14, width: '100%' }}>Title</p>
                  <Select
                    value={state?.title}
                    style={{
                      width: '100%',
                      height: '40px',
                    }}
                    onChange={handleChange}
                    variant="outlined"
                    name="title"
                    placeholder="Title"
                    disabled={state?.showForm ? false : true}
                  >
                    {/* {StringConstatnt.OPENFLOW_TITLES.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })} */}
                  </Select>
                </div>
                <TextInput
                  title={'First Name'}
                  isfullwidth={true}
                  type="text"
                  wrapperStyle={{ width: '24%', marginBottom: 15 }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  name="first_name"
                  value={state.first_name}
                  disabled={state?.showForm ? false : true}
                />
                <TextInput
                  title={'Last Name'}
                  isfullwidth={true}
                  type="text"
                  wrapperStyle={{ width: '24%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="last_name"
                  value={state.last_name}
                  disabled={state?.showForm ? false : true}
                />
                <TextInput
                  title={'Correspondence Address'}
                  isfullwidth={true}
                  type="text"
                  value={state?.correspondece_address}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="correspondece_address"
                  disabled={state?.showForm ? false : true}
                />
                <TextInput
                  title={'Post Code'}
                  isfullwidth={true}
                  type="text"
                  value={state.post_code}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="post_code"
                  disabled={state?.showForm ? false : true}
                />
                <TextInput
                  title={'Email Address'}
                  isfullwidth={true}
                  type="email"
                  value={state.email?.toLowerCase()}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="email"
                  disabled={state?.showForm ? false : true}
                />
                <TextInput
                  title={'Contact Number'}
                  isfullwidth={true}
                  type="tel"
                  value={state.number}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="number"
                  disabled={state?.showForm ? false : true}
                />
              </div>
              <TlyfeButtonWrapper>
                <Button
                  onClick={() => {
                    setState({ ...state, showForm: !state.showForm });
                  }}
                  title={state?.showForm ? 'Save' : 'Edit'}
                  type="stripe"
                  style={{ borderColor: '#90C418', marginTop: 25 }}
                />
              </TlyfeButtonWrapper>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '4%',
                marginBottom: '4%',
              }}
            >
              <span style={{ fontSize: 20, fontWeight: '600', marginBottom: 20 }}>Agent info</span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div style={{ width: '100%' }}>
                  <p style={{ fontSize: 14, width: '100%' }}>Title</p>
                  <Select
                    disabled={true}
                    value={agentInfo?.title}
                    style={{
                      width: '100%',
                      height: '40px',
                    }}
                    variant="outlined"
                    name="title"
                    placeholder="Title"
                  >
                    {/* {StringConstatnt.OPENFLOW_TITLES.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })} */}
                  </Select>
                </div>
                <TextInput
                  title={'First Name'}
                  isfullwidth={true}
                  type="text"
                  disabled={true}
                  wrapperStyle={{ width: '24%', marginBottom: 15 }}
                  name="first_name"
                  value={agentInfo?.first_name}
                />
                <TextInput
                  title={'Last Name'}
                  isfullwidth={true}
                  type="text"
                  wrapperStyle={{ width: '24%', marginBottom: 15 }}
                  disabled={true}
                  name="last_name"
                  value={agentInfo?.last_name}
                />
                <TextInput
                  title={'Correspondence Address'}
                  isfullwidth={true}
                  type="text"
                  value={agentInfo?.agent?.branch?.branch_address}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  disabled={true}
                  name="correspondece_address"
                />
                <TextInput
                  title={'Post Code'}
                  isfullwidth={true}
                  type="text"
                  value={agentInfo?.agent?.branch?.postcode}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  disabled={true}
                  name="post_code"
                />
                <TextInput
                  title={'Email Address'}
                  isfullwidth={true}
                  type="email"
                  value={agentInfo?.email}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  disabled={true}
                  name="email"
                />
                <TextInput
                  title={'Contact Number'}
                  isfullwidth={true}
                  type="tel"
                  value={agentInfo?.contact_number}
                  wrapperStyle={{ width: '49%', marginBottom: 15 }}
                  disabled={true}
                  name="number"
                />
              </div>
            </div>
            {type == 'buy' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '4%',
                    marginBottom: '4%',
                    alignItems: 'end',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Heading>Warranty:</Heading>
                  <TextInput
                    type={'row'}
                    wrapperstyle={{
                      width: '49%',
                    }}
                    containerstyles={{ width: '100%' }}
                    value={state?.warranty}
                    name="warranty"
                    onChange={handleChange}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '4%',
                    marginBottom: '4%',
                    alignItems: 'end',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Heading>Total Cost:</Heading>
                  <TextInput
                    type={'row'}
                    wrapperstyle={{
                      width: '49%',
                    }}
                    containerstyles={{ width: '100%' }}
                    value={'£ ' + state?.price}
                    onChange={handleChange}
                    name="price"
                  />
                </div>
              </>
            )}

            {type == 'earn' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '4%',
                    marginBottom: '4%',
                    alignItems: 'end',
                    justifyContent: 'space-evenly',
                  }}
                >
                  <Heading>Commission:</Heading>
                  <TextInput
                    type={'row'}
                    wrapperstyle={{
                      width: '49%',
                    }}
                    containerstyles={{ width: '100%' }}
                    value={'£ ' + data?.commission}
                    onChange={handleChange}
                    name="commission"
                  />
                </div>
              </>
            )}
            <div style={{ marginTop: 20, fontSize: 18, fontWeight: '400' }}>
              Are you happy with the costs and Ok to proced?
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-evenly',
                alignItems: 'space-between',
                marginTop: 20,
              }}
            >
              <Button
                onClick={() => {
                  if (onCancelPress) {
                    onCancelPress();
                  }
                }}
                style={{ borderColor: '#FC4850', color: '#FC4850' }}
                title={'No'}
                type="stripe"
              />
              <Button
                onClick={() => {
                  if (onOkPress) {
                    onOkPress(state);
                  }
                }}
                style={{ borderColor: '#90C418', color: '#90C418' }}
                title={'Yes'}
                type="stripe"
              />
            </div>

            <div style={{ marginTop: 20 }}>PLEASE NOTE:</div>
            <div style={{ marginTop: 10 }}>
              By clicking yes you agree to OpenBrix invoicing you at the end of every month for this reference
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default RentalWarrentyPopup;
