import React, { useEffect } from 'react';
import drawerHoc from '../../../../../components/drawerHOC/DrawerHOC';

import Text from "./../../../../../components/text/TextComponent"
import { Ionicons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TextInput } from '../../atoms/textinput';
import { View, Image, TouchableOpacity, TextInput as RNTextInput } from 'react-native';
import Checkbox from '@material-ui/core/Checkbox';
import variables from '../../atoms/variables.json';
import Button from "./../../../../../components/button/Button";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js/pure';
import success from "./../../../../../assets/images/success.svg";
import failed from "./../../../../../assets/images/failed.png";
import { EmailValidation, NameValidation, MobileValidation } from "../../../../../helpers/validation";
import getPlatformType, { validateNumericWithSpaces } from "../../../../../helpers/helpers";
import { AntDesign } from '@expo/vector-icons';
import CheckoutForm from './checkoutForm';

import { useFocusEffect, useRoute } from '@react-navigation/native';
import { showErrorToast } from '../../helpers';
import Urls from '../../../../../services/Urls';
import ajax from '../../../../../helpers/ajaxHelper';
import logo from '../../../../../assets/images/safe2logo.png';

const layoutType = getPlatformType();

var curr = new Date();
curr.setDate(curr.getDate() + 10);
var mindate = curr.toISOString().substring(0, 10);

let initialValues = {
    gas_type: 'Natural',
    key_pick_up: '',
    key_drop_off: '',
    Furnishing: '',
};
const STRIPEKEY = 'pk_live_51MlCEgFCJVtBrQ2WrFpZjfEbieiBD6GaxyoY5mQsOe7Sxfkj6qHvaIMxEshrwCvNnjqV2IJ2SoE0xs8ip6DVlRnc00l6X5Hl3u';
const CLIENT_SECRET = 'pi_3NVYvXFCJVtBrQ2W1UYLpyPV_secret_xprxVLO1APYRA2SZ9lhhDNQE2';

const CommanBuyCertificate = ({ navigation }) => {

    const [pageInfo, updatePageInfo] = React.useState('');
    const [pageType, updatePageType] = React.useState(null);
    const [fieldsValue, setFieldValues] = React.useState(initialValues);
    const [checkForError, setCheckForError] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const stripePromise = loadStripe(STRIPEKEY);
    const route = useRoute();
    const [bed, setBed] = React.useState(1);
    const [totalCost, setTotalCost] = React.useState(0);
    const [minValue, setMinValue] = React.useState(1);
    const [ispoa, setIsPoa] = React.useState(false);
    const [clientSecret, setClientSecret] = React.useState('');
    // const [notifyDateError, setNotifyDateError] = React.useState(false);
    // const [confirmDateError, setConfirmDateError] = React.useState(false);

    useEffect(() => {
        updatePageType(route.params.pageType);
        setMinValue(1)
        setFieldValues(initialValues)
    }, [route?.params?.pageType, useFocusEffect])

    useEffect(() => {
        if (pageType === 'gas_certificate') {
            updatePageInfo('Gas Safety Certificate (CP12)')
            setTotalCost(65)
        }
        else if (pageType === 'epc_certificate') {
            updatePageInfo('Energy Performance Certificate (EPC)')
            setTotalCost(65)
        }
        else {
            updatePageInfo('Electrical Installation Condition Report (EICR)')
            setTotalCost(130)
        }
    }, [pageType])

    useEffect(() => {
        if (pageType === 'gas_certificate') {
            if (minValue == 1) {
                if (fieldsValue.gas_type === "LPG") {
                    setTotalCost(85)
                } else {
                    setTotalCost(65)
                }
            }
            if (minValue == 2) {
                if (fieldsValue.gas_type === "LPG") {
                    setTotalCost(85)
                } else {
                    setTotalCost(65)
                }
            }
            if (minValue == 3) {
                if (fieldsValue.gas_type === "LPG") {
                    setTotalCost(105)
                } else {
                    setTotalCost(85)
                }
            }
            if (minValue == 4) {
                if (fieldsValue.gas_type === "LPG") {
                    setTotalCost(115)
                } else {
                    setTotalCost(95)
                }
            }
            if (minValue == 5) {
                if (fieldsValue.gas_type === "LPG") {
                    setTotalCost(145)
                } else {
                    setTotalCost(125)
                }
            }
            if (minValue > 6) {
                setTotalCost("N/A")
            }
        }
        else if (pageType === 'epc_certificate') {
            if (minValue > 0 && minValue < 5) {
                setTotalCost(65)
            } else if (minValue > 4 && minValue < 7) {
                setTotalCost(75)
            }
            if (minValue > 6) {
                setTotalCost("N/A")
            }
        }
        else {
            if (minValue == 1) {
                setTotalCost(130)
            }
            if (minValue == 2) {
                setTotalCost(140)
            }
            if (minValue == 3) {
                setTotalCost(150)
            }
            if (minValue == 4) {
                setTotalCost(160)
            }
            if (minValue == 5) {
                setTotalCost(170)
            }
            if (minValue > 5) {
                setTotalCost("N/A")
            }
        }

    }, [minValue, fieldsValue.gas_type])


    const _onNext = () => {
        const requestBody = fieldsValue
        requestBody.certificate_name = pageType
        requestBody.property_id = route?.params?.property_id
        requestBody.confirmDateCheckbox = confirmDateCheckbox
        requestBody.notifyDateCheckbox = notifyDateCheckbox
        requestBody.price = totalCost
        requestBody.totalPrice = totalCost + totalCost * 0.20
        if (pageType === 'gas_certificate') {
            requestBody.number_of_appliances = minValue
        }
        else {
            requestBody.number_of_bedrooms = minValue
        }

        console.log("requestBodyyyyyyyyyyyyy", requestBody)
        ajax
            .post(Urls.CERTIFICATE_PAYMENTS, requestBody)
            .then(({ data }) => {
                if (data.status) {
                    console.log('data: 166============', data, data?.data?.payload);
                    setClientSecret(data?.data?.payload?.client_secret)
                }
                setPage(2)
            })
            .catch((error) => {
                console.log('error', error);
            });
    }

    const checkValues = () => {

        if (fieldsValue?.agent_email && fieldsValue?.name && fieldsValue?.contact && fieldsValue?.agent_contact && fieldsValue?.conduct_date && fieldsValue?.Furnishing && fieldsValue?.key_pick_up && fieldsValue?.key_drop_off) {
            setCheckForError(false)
            let EmailValidate = EmailValidation(fieldsValue?.agent_email);
            let FSValidate = NameValidation(fieldsValue?.name);
            let ContactValidate = MobileValidation(fieldsValue?.contact);
            let AgentValidate = MobileValidation(fieldsValue?.agent_contact);
            if (

                EmailValidate.status &&
                FSValidate.status &&
                ContactValidate.status &&
                AgentValidate.status
            ) {
                _onNext();

            }
        } else {
            setCheckForError(true)
            showErrorToast("Please complete the mandatory fields!")
            // setConfirmDateError(confirmDateCheckbox? '' :'Please accept above condition.');
            // setNotifyDateError(notifyDateCheckbox? '' :'Please accept above condition.');
        }
    }
    const renderHeader = () => {
        return (
            <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row', width: "100%", marginTop: 20 }}>
                <View style={{ flex: 0.5 }}>
                    <Text fontSize={34} fontWeight={'500'} color={'#6D08C0'}>
                        {pageInfo}
                    </Text>
                    <View style={{ flexDirection: 'row' ,alignItems: 'center' }}>
                    <Text fontSize={28} fontWeight={'500'} color={'#6D08C0'}  >
                        Powered By{' '}
                    </Text>
                    <Image
                        style={{ width: 87, height: 87,  }}
                        source={logo}
                        resizeMode="contain"
                    />
                    </View>
                </View>
                
                <View style={{ flex: 0.5, justifyContent: "center", alignItems: "center", marginTop: 20 }}>
                    <View style={{
                        flexDirection: "row", width: "90%", borderTopRightRadius: 10, borderBottomLeftRadius: 10, backgroundColor: "white",
                        boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)"
                    }}>
                        <View style={{ flex: 0.4 }}>
                            <Image
                                style={{ width: "100%", height: 67, borderBottomLeftRadius: 10 }}
                                source={{ uri: "https://picsum.photos/seed/picsum/200/300" }} />
                        </View>
                        <View style={{ flex: 0.6, alignItems: "center", justifyContent: "center" }}>
                            <Text fontSize={13} fontWeight={'500'} color={'#6D08C0'}>
                                E14,obt,london
                            </Text>
                            {/* <Text fontSize={13} fontWeight={'500'} color={'#B0B0B0'}>
                                Gas Safety Certificate
                                (CP12)
                            </Text> */}
                        </View>
                    </View>
                </View>
            </View>
        )
    }
    const handleChange = (e, name) => {
        if (e.target.name === "agent_contact" || e.target.name === "contact") {
            setFieldValues({ ...fieldsValue, [e.target.name]: validateNumericWithSpaces(e.target.value) });
        }
        else {
            setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });

        }
    };
    const [confirmDateCheckbox, setConfirmDateCheckbox] = React.useState(false);
    const [notifyDateCheckbox, setNotifyDateCheckbox] = React.useState(false);
    console.log("fieldsValuefieldsValue", fieldsValue)
    const checkForErrorFunct = (feildName, type) => {
        if (checkForError) {
            if (feildName) {
                if (type == "email") {
                    console.log("EmailValidation(feildName)", EmailValidation(feildName))
                    if (EmailValidation(feildName).status) {
                        return null
                    } else {
                        return "Email is invalid!"
                    }
                } else {
                    return null
                }
            } else {
                return "Field is mandatory!"
            }
        } else {
            return null
        }

    }

    const addAndSubtractValue = (value, type) => {
        if (type == "ADD") {
            setMinValue(minValue + 1)
        } else {
            setMinValue(minValue - 1)
        }
    }
    const renderContent = () => {
        return (
            <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row', width: "100%", marginTop: 40, justifyContent: "space-between" }}>

                <View style={[{
                    padding: 20, backgroundColor: "white", borderTopRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: "white",
                    boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)"
                }, layoutType == 'phone' ?{}:{flex:0.49}]}>
                    <View>
                        <View style={{ flexDirection: "row", alignItems: "center" }}>
                            <Text
                                fontSize={18} fontWeight={'500'} color={'black'}>
                                Cost:
                            </Text>
                            <View style={{
                                flexDirection: "row", width: 126, height: 40,
                                borderRadius: 8,
                                borderColor: "#6d08c0",
                                borderWidth: 1,
                                marginLeft: 30,
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Text
                                    fontSize={18} fontWeight={'500'} color={'black'}>
                                    {totalCost == "N/A" ? null : "£ "}{totalCost}
                                </Text>

                            </View>
                            <Text style={{ marginLeft: '10px' }}
                                fontSize={18} fontWeight={'500'} color={'black'}>
                                +VAT
                            </Text>
                        </View>
                    </View>
                    {/* {totalCost === "N/A"?     <View style={{
                                // flexDirection: "row", width: 126, height: 40,
                                borderRadius: 8,
                                borderColor: "#000",
                                borderWidth: 2,
                                margin: 10,
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                    <Text>
            
            This purchase is currently not available.
            Please contact sales@hubita.co.uk for more info.
        </Text>
        </View>:null} */}
                    {pageType === "gas_certificate" ?
                        <>
                            <Text
                                marginTop={20}
                                fontSize={18} fontWeight={'500'} color={'black'}>
                                Number of appliances:
                            </Text>
                            <Text
                                fontSize={14} fontWeight={'400'} color={'black'}>
                                eg boiler, boiler and cooker, boiler, hob and fire
                            </Text>
                        </> :
                        <Text
                            marginTop={20}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Number of bed rooms:
                        </Text>
                    }

                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: 20 }}>

                        <TouchableOpacity
                            disabled={minValue == 1 ? true : false}
                            onPress={() => {
                                addAndSubtractValue(1, "SUB")
                            }}
                            style={{ height: 35, width: 35, borderRadius: 25, backgroundColor: minValue == 1 ? "#dadada" : "#6D08C0", justifyContent: "center", alignItems: "center" }}>
                            <Entypo name="minus" size={20} color="white" />
                        </TouchableOpacity>

                        <View style={{
                            flexDirection: "row", width: 90, height: 40,
                            borderRadius: 8,
                            borderColor: "#6d08c0",
                            borderWidth: 1,
                            justifyContent: "center",
                            alignItems: "center",
                            marginHorizontal: 20
                        }}>
                            <Text
                                fontSize={18} fontWeight={'600'} color={'black'}>
                                {minValue}
                            </Text>

                        </View>
                        <TouchableOpacity
                            onPress={() => {
                                addAndSubtractValue(1, "ADD")

                            }}
                            style={{ height: 35, width: 35, borderRadius: 25, backgroundColor: "#6D08C0", justifyContent: "center", alignItems: "center" }}>
                            <Ionicons name="add" size={20} color="white" />
                        </TouchableOpacity>

                    </View>
                    {pageType === "gas_certificate" ? <>
                        <View style={{ width: '70%', marginTop: 20, marginBottom: 10 }}>
                            <Text
                                fontSize={18} fontWeight={'500'} color={'black'}>
                                Gas Type
                            </Text>
                        </View>
                        <Select
                            value={fieldsValue?.gas_type}
                            style={{
                                width: '100%',
                                height: '40px',
                                borderWidth: 0,
                                backgroundColor: "white"
                            }}
                            onChange={handleChange}
                            variant="outlined"
                            name="gas_type"
                            placeholder="Select Gas Type"
                        >
                            <MenuItem value={'Natural'}>Natural</MenuItem>
                            <MenuItem value={'LPG'}>LPG + £20</MenuItem>
                        </Select>
                        {checkForErrorFunct(fieldsValue?.gas_type) ?
                            <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
                                {/* This field is required! */}
                                {checkForErrorFunct(fieldsValue?.gas_type)}
                            </p> : null
                        }
                    </> : null}
                    <View style={{ width: '70%', marginTop: 20, marginBottom: 10 }}>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Conduct Date:
                        </Text>
                    </View>
                    <TextInput
                        title={
                            <>
                                {/* <strong>Conduct Date:</strong> */}
                            </>
                        }
                        type={'date'}
                        wrapperstyle={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: 10,
                            marginBottom: 20,
                        }}
                        inputTextStyle={{ marginBottom: 0 }}
                        containerstyles={{ width: '100%' }}
                        value={fieldsValue?.conduct_date}
                        name="conduct_date"
                        onChange={handleChange}
                        inputProps={{
                            min: mindate
                        }}
                        error={checkForErrorFunct(fieldsValue?.conduct_date)}
                    />


                    <Text
                        marginTop={20}
                        marginBottom={18}
                        fontSize={18} fontWeight={'500'} color={'black'}>
                        Access Details
                    </Text>
                    <>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Name:
                        </Text>
                        <TextInput
                            title={""}
                            type={'column'}
                            wrapperstyle={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: 10,
                            }}
                            inputTextStyle={{ marginBottom: 0 }}
                            containerstyles={{ width: '100%' }}
                            value={fieldsValue?.name}
                            error={checkForErrorFunct(fieldsValue?.name)}
                            name="name"
                            onChange={handleChange}
                        />
                    </>
                    <>
                        <Text
                            marginTop={10}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Contact No:
                        </Text>
                        <TextInput
                            title={""}
                            type={'column'}
                            wrapperstyle={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: 10,
                            }}
                            inputTextStyle={{ marginBottom: 0 }}
                            containerstyles={{ width: '100%' }}
                            value={fieldsValue?.contact}
                            name="contact"
                            onChange={handleChange}
                            error={checkForErrorFunct(fieldsValue?.contact)}
                        />
                    </>
                    <View style={{ width: '70%', marginTop: 10, marginBottom: 10 }}>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Furnishing
                        </Text>
                    </View>
                    <Select
                        value={fieldsValue?.Furnishing}
                        style={{
                            width: '100%',
                            height: '40px',
                            borderWidth: 0,
                            backgroundColor: "white"
                        }}
                        onChange={handleChange}
                        variant="outlined"
                        name="Furnishing"
                        placeholder="Furnishing"
                    >
                        <MenuItem value={'Fully Furnished'}>Furnished</MenuItem>
                        <MenuItem value={'Part Furnished'}>Part Furnished</MenuItem>
                        <MenuItem value={'Unfurnished'}>UnFurnished</MenuItem>
                    </Select>
                    {checkForErrorFunct(fieldsValue?.Furnishing) ?
                        <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
                            {/* This field is required! */}
                            {checkForErrorFunct(fieldsValue?.Furnishing)}
                        </p> : null
                    }
                </View>
                <View style={[{
                 padding: 20, backgroundColor: "white", borderTopRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: "white",
                    boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                    marginTop: layoutType == 'phone' ? 20:0 
                }, layoutType == 'phone' ?{}:{flex:0.49}]}>
                    <Text
                        fontSize={18} fontWeight={'500'} color={'black'}>
                        Key Pick Up Information
                    </Text>
                    <Text
                        fontSize={14} fontWeight={'400'} color={'black'}>

                        Where are the keys to be picked up?
                    </Text>
                    <View style={{ width: '70%', marginTop: 20, marginBottom: 10 }}>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Key Pick Up
                        </Text>
                    </View>
                    <Select
                        value={fieldsValue?.key_pick_up}
                        style={{
                            width: '100%',
                            height: '40px',
                            borderWidth: 0,
                            backgroundColor: "white"
                        }}
                        onChange={handleChange}
                        variant="outlined"
                        name="key_pick_up"
                        placeholder="key_pick_up"
                    >
                        <MenuItem value={'Occupied - Knock/Ring Door'}>Occupied - Knock/Ring Door</MenuItem>
                        <MenuItem value={'Collect from Agent'}>Collect from Agent</MenuItem>
                        <MenuItem value={'Collect from Neighbour'}>Collect from Neighbour</MenuItem>
                        <MenuItem value={'Collect from Lockbox'}>Collect from Lockbox</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                    {checkForErrorFunct(fieldsValue?.key_pick_up) ?
                        <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
                            {/* This field is required! */}
                            {checkForErrorFunct(fieldsValue?.key_pick_up)}
                        </p> : null
                    }
                    <Text
                        marginTop={10}
                        fontSize={18} fontWeight={'500'} color={'black'}>
                        Notes:
                    </Text>
                    <View
                        style={{
                            width: '100%',
                            height: 100,
                            borderColor: '#B2B2B2',
                            borderWidth: 1,
                            borderRadius: 5,
                            position: 'relative',
                            marginTop: 12,
                        }}
                    >
                        <RNTextInput
                            style={{
                                height: 100,
                                width: '100%',
                                padding: 10,
                                borderColor: '#B2B2B2',
                            }}
                            multiline={true}
                            maxLength={500}
                            placeholder={'Enter pick up details'}
                            onChangeText={(val) => handleChange({ target: { name: "key_pick_up_address", value: val } })}
                            id={'key_pick_up_address'}
                            value={fieldsValue?.key_pick_up_address}
                        />
                         
                    </View>
                    {fieldsValue?.key_pick_up_address?
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {500-fieldsValue?.key_pick_up_address.length} character left
                      </Text>:null}
                    <View style={{ width: '70%', marginTop: 20, marginBottom: 10 }}>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Key Drop Off
                        </Text>
                    </View>
                    <Select
                        value={fieldsValue?.key_drop_off}
                        style={{
                            width: '100%',
                            height: '40px',
                            borderWidth: 0,
                            backgroundColor: "white"
                        }}
                        onChange={handleChange}
                        variant="outlined"
                        name="key_drop_off"
                        placeholder="Select Gas Type"
                    >
                        <MenuItem value={'Not Required'}>Not Required</MenuItem>
                        <MenuItem value={'Return to Agent'}>Return to Agent</MenuItem>
                        <MenuItem value={'Post through letterbox'}>Post through letterbox</MenuItem>
                        <MenuItem value={'Return to Neighbour'}>Return to Neighbour</MenuItem>
                        <MenuItem value={'Return to Lockbox'}>Return to Lockbox</MenuItem>
                        <MenuItem value={'Retain until further instruction'}>Retain until further instruction</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                    </Select>
                    {checkForErrorFunct(fieldsValue?.key_drop_off) ?
                        <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
                            {/* This field is required! */}
                            {checkForErrorFunct(fieldsValue?.key_drop_off)}
                        </p> : null
                    }
                    <Text
                        marginTop={10}
                        fontSize={18} fontWeight={'500'} color={'black'}>
                        Notes:
                    </Text>
                    <View
                        style={{
                            width: '100%',
                            height: 100,
                            borderColor: '#B2B2B2',
                            borderWidth: 1,
                            borderRadius: 5,
                            position: 'relative',
                            marginTop: 12,
                        }}
                    >
                    <RNTextInput
                        style={{
                            height: 100,
                            width: '100%',
                            padding: 10,
                            borderColor: '#B2B2B2',
                        }}
                        multiline={true}
                        maxLength={500}
                        placeholder={'Enter drop off address'}
                        onChangeText={(val) => handleChange({ target: { name: "key_drop_off_address", value: val } })}
                        id={'key_drop_off_address'}
                        value={fieldsValue?.key_drop_off_address}
                    />
                     </View>
                     {fieldsValue?.key_drop_off_address?
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {500-fieldsValue?.key_drop_off_address.length} character left
                      </Text>:null}
                    <View style={{ width: '70%', marginTop: 20, marginBottom: 10 }}>
                        <Text
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Landlord Details
                        </Text>
                    </View>
                    <>
                        <Text
                            marginTop={10}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Contact No.:
                        </Text>
                        <TextInput
                            title={""}
                            type={'column'}
                            wrapperstyle={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: 10,
                            }}
                            inputTextStyle={{ marginBottom: 0 }}
                            containerstyles={{ width: '100%' }}
                            value={fieldsValue?.agent_contact}
                            name="agent_contact"
                            onChange={handleChange}
                            error={checkForErrorFunct(fieldsValue?.agent_contact)}
                        />
                    </>
                    <>
                        <Text
                            marginTop={10}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Email:
                        </Text>
                        <TextInput
                            title={""}
                            type={'column'}
                            wrapperstyle={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                marginTop: 10,
                            }}
                            inputTextStyle={{ marginBottom: 0 }}
                            containerstyles={{ width: '100%' }}
                            value={fieldsValue?.agent_email}
                            name="agent_email"
                            onChange={handleChange}
                            error={checkForErrorFunct(fieldsValue?.agent_email, "email")}
                        />
                    </>
                    <Text
                        marginTop={10}
                        fontSize={18} fontWeight={'500'} color={'black'}>
                        Notes:
                    </Text>
                    <View
                        style={{
                            width: '100%',
                            height: 100,
                            borderColor: '#B2B2B2',
                            borderWidth: 1,
                            borderRadius: 5,
                            position: 'relative',
                            marginTop: 12,
                        }}
                    >
                              <RNTextInput
                        style={{
                            height: 100,
                            width: '100%',
                            padding: 10,
                            borderColor: '#B2B2B2',
                        }}
                        multiline={true}
                        maxLength={500}
                        placeholder={'Enter notes'}
                        onChangeText={(val) => handleChange({ target: { name: "notes", value: val } })}
                        id={'notes'}
                        value={fieldsValue?.notes}
                    />
                          </View>
                          {fieldsValue?.notes?
                       <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 5,
                        }}
                      >
                        {500-fieldsValue?.notes.length} character left
                      </Text>:null}
                    <View style={{ display: 'flex', marginTop: '30px', flexDirection: "row" }}>
                        <Checkbox
                            style={{ width: '30%' }}
                            name="confirm_date"
                            style={{ color: variables.heliotrope }}
                            checked={confirmDateCheckbox}
                            onChange={() => setConfirmDateCheckbox(!confirmDateCheckbox)}
                        />
                        <Text
                            marginTop={10}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Confirm Date and visit with tenants
                        </Text>


                    </View>

                    {/* {checkForErrorFunct(confirmDateCheckbox) ?
                        <p style={{ marginTop: '5px', color: 'red', textAlign: 'left', marginTop: 10, fontFamily: 'lato' }}>
                            Please accept above condition
                            {checkForErrorFunct(confirmDateCheckbox)}
                        </p> : null
                    } */}
                    {/* <If condition={confirmDateError}>
                               <p style={{ marginTop: '5px', color: 'red', textAlign: 'left', marginTop: 10, fontFamily: 'lato' }}>
                            {confirmDateError}
                        </p> 
              </If> */}

                    <View style={{ display: 'flex', flexDirection: "row" }}>
                        <Checkbox
                            style={{ width: '30%' }}
                            name="notify_date"
                            style={{ color: variables.heliotrope }}
                            checked={notifyDateCheckbox}
                            onChange={() => setNotifyDateCheckbox(!notifyDateCheckbox)}
                        />
                        <Text
                            marginTop={10}
                            fontSize={18} fontWeight={'500'} color={'black'}>
                            Notify Date and visit with tenants
                        </Text>


                    </View>

                    {/* <If condition={notifyDateError}>
                               <p style={{ marginTop: '5px', color: 'red', textAlign: 'left', marginTop: 10, fontFamily: 'lato' }}>
                          {notifyDateError}
                        </p> 
              </If> */}


                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                        <Button
                            color={'#FC4850'}
                            type="stripe"
                            noClick="noClick"
                            childText={"Cancel"}
                            style={{ flex: 0.4 }}
                            onPress={() => navigation.goBack()}
                        />
                        {totalCost === "N/A" ? null :
                            <Button
                                color={'#90C418'}
                                type="stripe"
                                noClick="noClick"
                                childText={"Next"}
                                style={{ flex: 0.4 }}
                                onPress={() => checkValues()}

                            />}
                    </View>
                    {totalCost === "N/A" ? <View style={{
                        // flexDirection: "row", width: 126, height: 40,
                        borderRadius: 8,
                        borderColor: "#000",
                        borderWidth: 3,
                        // marginLeft: 30,
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 5
                    }}>
                        <Text fontSize={14} fontWeight={'600'} >
                            This purchase is currently not available.
                            Please contact <a href="mailto:sales@hubita.co.uk" style={{ color: 'blue', textDecoration: 'underline' }}>sales@hubita.co.uk</a> for more info.
                        </Text>

                    </View> : null}
                </View>
            </View>
        )
    }

    const renderStripe = () => {
        return (
            <View style={{
                width: layoutType == 'phone' ? "100%" : "70%", marginTop: 40,
                padding: 20, backgroundColor: "white", borderTopRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: "white",
                boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                alignSelf: "center"
            }}>
                <Text
                    marginBottom={30}
                    fontSize={26} fontWeight={'700'} color={'#1B8A8C'}>
                    Checkout:
                </Text>
                <View style={{
                    borderRadius: 8,
                    borderColor: "#1B8A8C",
                    borderWidth: 1,
                    marginBottom: 30,
                    boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                    padding: 30
                }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 20 }}>
                        <Text

                            fontSize={18} fontWeight={'500'} color={'black'}>
                            {pageInfo}
                        </Text>
                        <Text

                            fontSize={18} fontWeight={'700'} color={'#1B8A8C'}>
                            £{totalCost} <Text

                                fontSize={18} fontWeight={'500'} color={'#1B8A8C'}>
                                + VAT
                            </Text>
                        </Text>

                    </View>
                    <Text
                        marginTop={40}
                        fontSize={24} fontWeight={'700'} color={'#1B8A8C'}>
                        Total Cost:  £{totalCost + totalCost * 0.20}
                    </Text>
                </View>
                <Elements
                    stripe={stripePromise}
                    options={{
                        clientSecret: clientSecret,
                    }}
                >
                    <CheckoutForm
                        onSuccessToken={(success) => {

                            if (success) {
                                setPage(3);
                            } else {
                                setPage(4);
                            }
                        }}
                    />
                </Elements>
            </View>
        )
    }

    const renderSuccess = () => {
        return (
            <View style={{
                width: "60%", marginTop: 40,
                padding: 20, backgroundColor: "white", borderTopRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: "white",
                boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                alignSelf: "center"
            }}>
                <img src={success} style={{ height: 100, marginTop: 20 }} />

                <Text
                    marginBottom={30}
                    marginTop={40}
                    style={{ alignSelf: "center" }}
                    fontSize={26} fontWeight={'700'} color={'black'}>
                    Payment Successfull!
                </Text>

                <View style={{ backgroundColor: "#1B8A8C", width: 100, height: 2, alignSelf: "center", marginBottom: 20 }} />
                <TouchableOpacity
                    onPress={() => navigation.goBack()}
                    style={{
                        marginTop: 10,
                        paddingHorizontal: 20,
                        backgroundColor: "white", borderColor: "#536DFE",
                        borderWidth: 1,
                        boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                        alignSelf: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 10,
                        paddingVertical: 10,
                        flexDirection: "row"
                    }}
                >
                    <Text
                        fontSize={20} fontWeight={'700'} color={'#536DFE'}>
                        Continue
                    </Text>
                    <AntDesign
                        style={{ marginLeft: 20 }}
                        name="arrowright" size={24} color="#536DFE" />
                </TouchableOpacity>
                <Text
                    marginTop={30}
                    style={{ alignSelf: "center" }}
                    fontSize={20} fontWeight={'400'} color={'black'}>
                    Thank you for your payment. Please click continue to start your order.
                </Text>
                <Text
                    marginBottom={30}
                    marginTop={20}
                    style={{ alignSelf: "center" }}
                    fontSize={20} fontWeight={'400'} color={'black'}>
                    You will receive confirmation of this payment via email.
                </Text>
            </View>
        )
    }

    const renderFailure = () => {
        return (
            <View style={{
                width: "60%", marginTop: 40,
                padding: 20, backgroundColor: "white", borderTopRightRadius: 18, borderBottomLeftRadius: 18, backgroundColor: "white",
                boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                alignSelf: "center"
            }}>
                <img src={failed} style={{ height: 70, marginTop: 20, width: 70, alignSelf: "center" }} />

                <Text
                    marginBottom={30}
                    marginTop={40}
                    style={{ alignSelf: "center" }}
                    fontSize={26} fontWeight={'700'} color={'black'}>
                    Payment Failed!
                </Text>

                <View style={{ backgroundColor: "red", width: 100, height: 2, alignSelf: "center", marginBottom: 20 }} />
                <TouchableOpacity
                    onPress={() => setPage(2)}
                    style={{
                        marginTop: 10,
                        paddingHorizontal: 20,
                        backgroundColor: "white", borderColor: "#536DFE",
                        borderWidth: 1,
                        boxShadow: "0px 2px 11px 1px rgba(0,0,0,0.09)",
                        alignSelf: "center",
                        justifyContent: "space-between",
                        alignItems: "center",
                        borderRadius: 10,
                        paddingVertical: 10,
                        flexDirection: "row"
                    }}
                >
                    <Text
                        fontSize={20} fontWeight={'700'} color={'#536DFE'}>
                        Back
                    </Text>
                    <AntDesign
                        style={{ marginLeft: 20 }}
                        name="arrowright" size={24} color="#536DFE" />
                </TouchableOpacity>


            </View>
        )
    }
    return (
        <View style={{ flex: 1 }}>
            {renderHeader()}
            {page == 1 ? <>
         
            <Text fontSize={18} fontWeight={'600'} color={'black'} marginTop={"40"}>
                In order to faciliate the purchase of your {pageType === 'gas_certificate' ? 'Gas Certification' : pageType === 'epc_certificate' ? 'Energy Certification' : 'Electrical Certificate'}, please enter the details below
            </Text> </>: null}
            {page == 1 ? renderContent() : null}
            {page == 2 ? renderStripe() : null}
            {page == 3 ? renderSuccess() : null}
            {page == 4 ? renderFailure() : null}


        </View>
    );
};

export default drawerHoc({
    Component: CommanBuyCertificate,
    showBackButton: true,
    // navigation: navigation
});
