import React from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  Dimensions,
  StyleSheet,
  Platform,
  Linking,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  FooterWrapper,
  Container,
  FooterLogo,
  FooterLinks1,
  FooterLinks2,
  SocialLinks,
  TextContent,
  SocialImg,
  SocialImgBox,
  ObxWhiteImg,
} from './footerStyle';
import * as Device from 'expo-device';
import { Link } from '@react-navigation/native';
import SocialIcons from './socialIcons';
import getPlatformType from '../../helpers/helpers';
let deviceType = '';
const layoutType = getPlatformType();
const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get('window');

export default class FooterLanding extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    deviceType = await Device.getDeviceTypeAsync();
  };

  _redirectSocial = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };


  render() {
    const { navigation, isTLYF, paintedWidth,isTds } = this.props;

    return (
      <LinearGradient
      colors={['#6612B6', '#4258DB']}
      end={{ x: 1, y: 0 }}
      start={{ x: 1, y: 1 }}
        style={{
          height: '50px',
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
          <Link
            to={isTLYF ? '/home' : '/home'}
            style={{ width: layoutType == 'phone' ? 100 : 130 }}
          >
            <Text style={{ fontSize: layoutType == 'phone' ? 25 : 30, fontWeight: 'bold', color: 'white' }}>
                  HUBITA
                </Text>
          </Link>
          {layoutType=="phone"? null : (
           <SocialIcons
           iconSize={"10px"}
           />
           )}
        </View>
        <View style={{ flexDirection: 'row', marginRight: 20 }}>
          <TextContent onPress={() => navigation.navigate('AboutUs')}>
            {isTLYF ? 'About Us' : 'About Us'}
          </TextContent>

          <TextContent onPress={() => navigation.navigate('ContactUs')}>
            Contact Us
          </TextContent>
          <TextContent onPress={() => navigation.navigate('LegalBits')}>
            Privacy Policy
          </TextContent>

          {layoutType=="phone" ? null:<React.Fragment>
            <TextContent onPress={() => navigation.navigate('MediaHub')}>
              Media Hub
            </TextContent>

            <TextContent onPress={() => navigation.navigate('LegalBits')}>
              T &amp; C's
            </TextContent>
            <TextContent onPress={() =>navigation.navigate('Charity')}>Charity</TextContent>
          </React.Fragment>}
        </View>
      </LinearGradient>
    );
  }
}
