import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Container, DashedContainer } from './Box.style';

const Box = ({ children, borderRadius, dashed, backgroundColor, borderColor, borderWidth, noShadow, ...restProps }) => (
  <Choose>
    <When condition={dashed}>
      <DashedContainer borderWidth={borderWidth} borderColor={borderColor} {...restProps}>
        {children}
      </DashedContainer>
    </When>
    <Otherwise>
      <Container noShadow={noShadow} borderRadius={borderRadius} backgroundColor={backgroundColor} {...restProps}>
        {children}
      </Container>
    </Otherwise>
  </Choose>
);

`{
    EXAMPLE

<Box>
<Text style={styles.title}>Tab One</Text>
</Box>

<Box dashed>
<Text style={styles.title}>Tab One</Text>
<Text style={styles.title}>Tab One</Text>
<Text style={styles.title}>Tab One</Text>
<Text style={styles.title}>Tab One</Text>
</Box>}`;

export default Box;
