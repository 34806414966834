import { LinearGradient } from 'expo-linear-gradient';
import { Dimensions, Text } from 'react-native';
import styled from 'styled-components/native';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
const WIN_HEIGHT = Dimensions.get('window').height;
const layoutType = getPlatformType();
let Container,
  LeftSection,
  RightSection,
  FormContainer,
  TouchableWrapper,
  ImageWrapper,
  ImageLogoWrapper,
  TopSection,
  TermsLinks,
  Main;
if (layoutType == 'web') {
  Main = styled.ScrollView`
    height: ${(props) => (props.height ? WIN_HEIGHT - props.height : WIN_HEIGHT)};
  `;
  Container = styled.View`
    flex-direction: row;
    width: 100%;
    height: 100%;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 60%;
    margin-right: 10%;
  `;
  LeftSection = styled.View`
    width: 50%;
    height: auto;
    padding-vertical: 50px;
    background-color: white;
    justify-content: center;
    align-items: flex-end;
  `;
  RightSection = styled.View`
    width: 50%;
    background-color: rgb(242, 245, 250);
    justify-content: center;
    align-items: flex-start;
  `;
  ImageWrapper = styled.Image`
    width: 100%;
    height: 100%;
    // margin-left: 5%;
    // margin-top: 5%;
    // resize-mode: contain;
  `;
  ImageLogoWrapper = styled.Image`
    height: 31px;
    width: 137px;
    margin-top: 5%;
    resize-mode: contain;
  `;
  TopSection = styled.View`
    position: absolute;
    top: 5%;
    flex-direction: row;
    right: 30%;
    align-items: center;
    z-index: 10000;
  `;
  TermsLinks = styled.Text`
  color: ${Colors.light.purple};
  font-weight: bold;
`;
} else if (layoutType == 'phone') {
  Main = styled.ScrollView`
    height: ${(props) => (props.height ? WIN_HEIGHT - props.height : WIN_HEIGHT)};
  `;
  Container = styled.View``;
  LeftSection = styled.View`
    width: 100%;
    justify-content: center;
    background-color: white;
    padding-vertical: 30px;
    align-items: center;
  `;
  RightSection = styled.View`
    background-color: rgb(242, 245, 250);
    justify-content: center;
    align-items: center;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 80%;
  `;
  ImageWrapper = styled.Image`
    width: 100%;
    height: 60%;
    resize-mode: contain;
  `;
  ImageLogoWrapper = styled.Image`
    height: 63px;
    width: 87px;
    resize-mode: contain;
  `;
  TopSection = styled.View`
    // position: absolute;
    // top: 5%;
    // flex-direction: row;
    // right: 15%;
    // align-items: center;
    flex-direction: row;
    align-items: center;
    margin-top: 56px;
  `;
  TermsLinks= styled.Text`
  color: ${Colors.light.purple};
  font-weight: bold;
`;
} else {
  Main = styled.View`
    flex: 1;
  `;
  Container = styled.View`
    flex-direction: row;
    width: 100%;
    height: 100%;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 70%;
  `;
  LeftSection = styled.View`
    width: 50%;
    height: auto;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding-vertical: 50px;
  `;
  RightSection = styled.View`
    width: 50%;
    background-color: rgb(242, 245, 250);
    justify-content: center;
    align-items: center;
  `;
  ImageWrapper = styled.Image`
    width: 100%;
    height: 100%;
    resize-mode: cover;
  `;
  ImageLogoWrapper = styled.Image`
    height: 31px;
    width: 137px;
    resize-mode: contain;
  `;
  TopSection = styled.View`
    position: absolute;
    top: 5%;
    flex-direction: row;
    right: 10%;
    align-items: center;
    z-index: 10000;
  `;
  TermsLinks = styled.Text`
    color: ${Colors.light.purple};
    font-weight: bold;
  `;
}
export {
  ImageLogoWrapper,
  Container,
  LeftSection,
  RightSection,
  FormContainer,
  TouchableWrapper,
  ImageWrapper,
  TopSection,
  Main,
  TermsLinks
};
