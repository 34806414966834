import React from "react";
import styled from "styled-components/native";
import getPlatformType from "../../helpers/helpers";

const layoutType = getPlatformType();
let Container,
  DualView,
  SectionText,
  TextDataBox,
  CenterBlock,
  CenterBlockBox,
  SectionHeadText,
  DualViewTextBox,
  CharityLastBlock,
  VideoBox,
  TextDataBoxLeft;

Container = styled.View`
  margin: 0 auto;
`;
DualView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

DualViewTextBox = styled.View`
  width: 35%;
  padding: 30px 0px;
`;
SectionText = styled.Text`
  color: #000000;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: 20px;
`;
SectionHeadText = styled.Text`
  color: #6D08C0;
  font-size: 28px;
  letter-spacing: 0;
  line-height: 32px;
  margin-top: 20px;
`;
TextDataBoxLeft = styled.View`
  width: 50%;
  padding: 0px 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
  background-color: #ffffff;
`;
TextDataBox = styled.View`
  width: 50%;
  padding: 0px 20px 20px;
`;
CenterBlock = styled.View`
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
`;
CenterBlockBox = styled.View`
  padding: 50px 0px 60px;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: 0 auto;
`;
CharityLastBlock = styled.View`
  padding: 30px 0px 40px;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
  background-color: #ffffff;
  margin-top: 50px;
`;

VideoBox = styled.View`
  height: 460px;
  width: 815px;
  margin-top: 30px;
  margin-bottom: 20px;
`;
if (layoutType == "phone") {
  Container = styled.View`
    margin: 0 auto;
  `;
  DualView = styled.View`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  `;

  DualViewTextBox = styled.View`
    width: 100%;
    padding: 30px 0px;
    align-items: center;
  `;
  SectionText = styled.Text`
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 20px;
    text-align: center;
  `;
  SectionHeadText = styled.Text`
    color: #6D08C0;
    font-size: 28px;
    letter-spacing: 0;
    line-height: 32px;
    margin-top: 20px;
    text-align: center;
  `;
  TextDataBoxLeft = styled.View`
    width: 100%;
    padding: 0px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
    background-color: #ffffff;
  `;
  TextDataBox = styled.View`
    width: 100%;
    padding: 0px 20px 20px;
    margin-top: 30px;
  `;
  CenterBlock = styled.View`
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
    padding: 20px;
  `;
  CenterBlockBox = styled.View`
    padding: 50px 0px 60px;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  `;
  CharityLastBlock = styled.View`
    padding: 30px 0px 40px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 6.68px;
    background-color: #ffffff;
    margin-top: 50px;
  `;
  VideoBox = styled.View`
    height: 460px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 20px;
  `;
} else if (layoutType == "web") {
} else {
  Container = styled.View`
    margin: 0 auto;
  `;
}

export {
  Container,
  DualView,
  SectionText,
  TextDataBox,
  CenterBlock,
  CenterBlockBox,
  SectionHeadText,
  DualViewTextBox,
  CharityLastBlock,
  TextDataBoxLeft,
  VideoBox,
};
