import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, Image } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';

import { Entypo } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { AntDesign } from '@expo/vector-icons';
import { FontAwesome } from '@expo/vector-icons';

import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import getPlatformType from '../../helpers/helpers';
import getInitials from '../../helpers/getInitials';
import { GradientWrapper, NameText, SelectedItem, ItemText, CornerView } from './style';
import { saveUserProfile } from '../../redux/action/authActions';
import { useDispatch } from 'react-redux';

var layoutType = getPlatformType();

const DrawerMenu = (props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const [selectedKey, setSelectedKey] = useState(route?.name);

  const [user, setUser] = useState({});
  const focused = useIsFocused();
  const dispatch = useDispatch();
  useEffect(() => {
    ajax
      .get(Urls.PROFILE)
      .then(({ data }) => {
        if (data.status) {
          setUser(data?.data.userDetails);
          dispatch(saveUserProfile(data?.data.userDetails));
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focused]);

  const closeDrawer = () => {
    if (props?.setDrawer) {
      props?.setDrawer(false);
    }
  };
  const checkIfSelected = (key) => {
    if (key === selectedKey) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setSelectedKey(route?.name);
  }, [route, selectedKey]);
const getname=(name)=>{
  let returnName = name
  if(name){
    returnName=  name.length>10?name.substring(0,10):name
  }
  return returnName
}
  return (
    <>
      <GradientWrapper colors={['#6612B6', '#4258DB']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 1 }}>
        {/* <CornerView/> */}
        <NameText>
          {layoutType === 'tab' ? getInitials(`${user.first_name} ${user.last_name}`) : getname(user.first_name)}
        </NameText>
        <TouchableOpacity
          onPress={() => {
            setSelectedKey('home');
            navigation.push('home');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('home')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('home')
                  ? require('../../assets/icons/Home.png')
                  : require('../../assets/icons/home_white.png')
              }
            />
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('home')}>Home</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setSelectedKey('Properties');
            navigation.push('Properties');
            closeDrawer();
          }}
        >
          <SelectedItem selected={selectedKey == 'Properties' ? true : false}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                selectedKey == 'Properties'
                  ? require('../../assets/icons/Properties.png')
                  : require('../../assets/icons/Properties_White.png')
              }
            />
            {/* <AntDesign name="home" size={25} color={selectedKey == 'Properties' ? true : false ? '#6612B6' : 'white'} /> */}
            {layoutType === 'tab' ? null : (
              <ItemText selected={selectedKey == 'Properties' ? true : false}>Properties</ItemText>
            )}
          </SelectedItem>
        </TouchableOpacity>
        {/* <TouchableOpacity
          onPress={() => {
            navigation.push('tenants');
            setSelectedKey('tenants');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('tenants')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('tenants')
                  ? require('../../assets/icons/Tenants.png')
                  : require('../../assets/icons/Tenants_White.png')
              }
            />
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('tenants')}>Tenants</ItemText>}
          </SelectedItem>
        </TouchableOpacity> */}
        {/* <FontAwesome name="user" size={25} color={checkIfSelected('tenants') ? '#6612B6' : 'white'} /> */}

        <TouchableOpacity
          onPress={() => {
            navigation.push('Payments');
            setSelectedKey('Payments');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('Payments')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('Payments')
                  ? require('../../assets/icons/Payments.png')
                  : require('../../assets/icons/Payments_White.png')
              }
            />
            {/* <FontAwesome name="user" size={25} color={checkIfSelected('Payments') ? '#6612B6' : 'white'} /> */}
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('Payments')}>Payments</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.push('Reminder');
            setSelectedKey('Reminder');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('Reminder')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('Reminder')
                  ? require('../../assets/icons/Reminder.png')
                  : require('../../assets/icons/reminder_White.png')
              }
            />
            {/* <FontAwesome name="bell" size={25} color={checkIfSelected('Reminder') ? '#6612B6' : 'white'} /> */}
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('Reminder')}>Reminder</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.push('MyDocs');
            setSelectedKey('MyDocs');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('MyDocs')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('MyDocs')
                  ? require('../../assets/icons/My Docs purple.png')
                  : require('../../assets/icons/My Docs White.png')
              }
            />
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('MyDocs')}>My Docs</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.push('Setting');
            setSelectedKey('Settings');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('Setting')}>
            {/* <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('Setting')
                  ? require('../../assets/icons/Home.png')
                  : require('../../assets/icons/home_white.png')
              }
            /> */}
            <Ionicons name="settings" size={25} color={checkIfSelected('Setting') ? '#6612B6' : 'white'} />
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('Setting')}>Settings</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            navigation.push('MediaHub');
            setSelectedKey('MediaHub');
            closeDrawer();
          }}
        >
          <SelectedItem selected={checkIfSelected('MediaHub')}>
            <Image
              style={{ height: 25, width: 25 }}
              resizeMode="contain"
              source={
                checkIfSelected('MediaHub')
                  ? require('../../assets/icons/MediaHub.png')
                  : require('../../assets/icons/MediaHub_White.png')
              }
            />
            {/* <Ionicons name="MediaHub" size={25} color={checkIfSelected('MediaHub') ? '#6612B6' : 'white'} /> */}
            {layoutType === 'tab' ? null : <ItemText selected={checkIfSelected('MediaHub')}>Media Hub</ItemText>}
          </SelectedItem>
        </TouchableOpacity>
      </GradientWrapper>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e7e7e7',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 25,
    height: 1,
    width: '80%',
  },
  tinyLogo: {
    width: 100,
    height: 100,
  },
});

export default DrawerMenu;
