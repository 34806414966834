import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, TextInputWrapper, Row, RowCol, Section, Left;
let HorizontalLine = styled.View`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-vertical: 30px;
`;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 65%;
    margin-left: 320px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View`
    flex-direction: row;
  `;
  Left = styled.View`
    width: 70%;
    margin-top: 10px;
    margin-right: 25px;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '100%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View``;
  Left = styled.View`
    width: 100%;
    margin-top: 10px;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 85%;
    margin-left: 90px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View`
    flex-direction: row;
  `;
  Left = styled.View`
    width: 70%;
    margin-top: 10px;
    margin-right: 15px;
  `;
}
export { ContainerScrollView, Container, HorizontalLine, Row, TextInputWrapper, RowCol, Section, Left };
