import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { MediaWrapper, TitleText, MediaVideoBox, MediaVideoDesc ,MediaBox } from './styles';

const layoutType = getPlatformType();
import YoutubePlayer from '../../components/youtubePlayer/youtubePlayer';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { useIsFocused } from '@react-navigation/native';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';

const MediaVideoHub = (props) => {
  const [posts, setPost] = useState([]);

  useEffect(() => {
    ajax
      .get(Urls.GET_POST + 'VIDEO')
      .then(({ data }) => {
        if (data.status) {
          console.log('ASDASDAS', data);

          if (data?.payload) {
            if (data?.payload && data?.payload?.length) {
              setPost(data?.payload);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  return (
    <>
      <View style={{display:'flex',flexDirection:"row",flexWrap:"wrap",justifyContent: layoutType=='web'?'flex-start':'center',width:'100%',alignItems:'center' }}>
        
          {posts != undefined &&
            posts.map((data, index) => {
              return (
                <MediaBox 
                  key={`mbox${index}`}
                  // style={{
                  //   shadowOffset: {
                  //     width: 0,
                  //     height: 2,
                  //   },
                  //   shadowOpacity: 0.09,
                  //   shadowRadius: 6.68,
                  //   elevation: 11,
                  // }}
                >
                 
                   
                      {/* <Video  
                                            source={{
                                                uri: data.video_id
                                            }}
                                            progressUpdateIntervalMillis={500}
                                            positionMillis={0}
                                            shouldPlay={false}
                                            rate={1.0}
                                            shouldCorrectPitch={false}
                                            volume={1.0}
                                            isMuted={false}
                                            isLooping={false}
                                            resizeMode={Video.RESIZE_MODE_COVER}
                                            useNativeControls={true}
                                            style={{
                                                flex: 1,
                                                backgroundColor: "black",
                                                justifyContent: "center",
                                            }}
                                        /> */}
                                        <View style = {{width :'100%'}}>
                      <YoutubePlayer url={data.video_id} height={122} width={'100%'}  />
                      </View>
                      <View style={{ paddingHorizontal: 20,paddingTop:20, width: "100%",flex:1 }}>
                <View style={{flex:0.9}}>

                      <TitleText>{data.title}</TitleText>
                      <MediaVideoDesc>{data.desc}</MediaVideoDesc>
                    </View>
       
                 
                  <View style={{ alignSelf: 'flex-end', width: 169,flex:0.1,justifyContent:"flex-end"}}>
                    <Button
                                          onPress={() => {
                                            navigate('MediaVideoDetails', {
                                              data: data.id,
                                            });
                                          }}
                        color={"#00BCD4"}
                        type={'solid'}
                        childText="Read More"
                        width={"100%"}
                    />
                </View>
                </View>
                </MediaBox >
              );
            })}
      
      </View>
    </>
  );
};

// const mapStateToProps = (state) => ({
//   loading: state.mediaReducer.postLoading,
//   posts: idx(state, (_) => _.mediaReducer.videoPost.data),
// });
// const mapDispatchToProps = (dispatch) =>
//   bindActionCreators(
//     {
//       getPosts,
//     },
//     dispatch
//   );
export default MediaVideoHub;
