import React, { useState } from 'react';
import { View,Image, TouchableOpacity } from 'react-native';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import { HeaderContainer, Logo } from './hello.style';
import { navigate } from '../../navigation/RootNavigation';

export const Header=(props)=>{
    const layoutType=getPlatformType();

    return(
        <HeaderContainer>
          <Logo source={require('../../assets/images/Logo/HubitaWeb.png')}/>
          {layoutType=="phone"?<TouchableOpacity onPress={()=>props.setDrawer()}>
          <Image
                source={require('./../../assets/images/menu.png')}
                style={{ height: 16.8, width: 22.4,marginTop:20 }}
                resizeMode={'contain'}
              />
          </TouchableOpacity> :<View style={{flexDirection:'row',width:layoutType=="web"?'12%':'20%',justifyContent:'space-between',alignItems:'center'}}>
          <TouchableOpacity onPress={() => navigate('login')}>
            <Text fontSize={18}>Login</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => navigate('RegisterScreen')}>
            <Text fontSize={18}>Register</Text>
            </TouchableOpacity>
          </View>}
        
        </HeaderContainer>
    )
}