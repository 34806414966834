import { useRoute } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, Image,View, Pressable } from 'react-native';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import getPlatformType from '../../helpers/helpers';
import getInitials from '../../helpers/getInitials';
import { GradientWrapper ,NameText } from './MainDrawer.Style'
import { useDispatch } from 'react-redux';
import Text from '../../components/text';

var layoutType = getPlatformType();

const MainDrawerMenu = (props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const focused = useIsFocused();
  const dispatch = useDispatch();
  const closeDrawer = () => {
    if (props?.setDrawer) {
      props?.setDrawer(false);
    }
  };
  return (
    <>
        {/* <CornerView/> */}
        <GradientWrapper colors={['#6612B6', '#4258DB']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 0.5 }}>
          <View style={{width:'100%',height:100,justifyContent:'center',alignItems:'flex-end',paddingHorizontal:40}}>
         <Pressable onPress={()=>props?.setDrawer(false)}>
         <Image
                source={require('./../../assets/images/menu-white.png')}
                style={{ height: 22, width: 22.4,marginTop:20}}
                resizeMode={'contain'}
              />
         </Pressable>
       
          </View>
        <TouchableOpacity
          onPress={() => {
            navigation.push('login');
            closeDrawer();
          }}
        >
            <NameText> Login </NameText>

        </TouchableOpacity>
        <View style={{width:'100%',height:1,backgroundColor:'white',marginVertical:20,alignSelf:'center'}}/>
        <TouchableOpacity
          onPress={() => {
    
            navigation.push('RegisterScreen');
            closeDrawer();
          }}
        >
    
            <NameText> Register </NameText>
          
        </TouchableOpacity>

        </GradientWrapper>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#e7e7e7',
  },
  title: {
    fontSize: 40,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 25,
    height: 1,
    width: '40%',
  },
  tinyLogo: {
    width: 100,
    height: 100,
  },
});

export default MainDrawerMenu;
