import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { CardBox } from '../../atoms/card';
import { TextInput } from '../../atoms/textinput';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { SubHeading, Heading } from '../../screens/style';
import { TlyfeButtonWrapper } from '../../screens/style';
import { Button } from '../../atoms/button';
import Select from '@material-ui/core/Select';
import variables from '../../atoms/variables.json';
import { useDispatch, useSelector } from 'react-redux';

import MenuItem from '@material-ui/core/MenuItem';
import OpenFlowModal from '../../atoms/modal';
import getPlatformType from '../../../../../helpers/helpers';
// import { tenantActions } from '_actions';
const layoutType = getPlatformType();
export const SpecialCondition = (props) => {
  const [showCCModal, setShowCCModal] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(false);

  const [ccArray, setCcArray] = React.useState([]);
  const [commonCondition, setCommonCondition] = useState([]);
  const [commonConditionText, setCommonConditionText] = useState('');
  const [additionalCondition, setAdditionalCondition] = useState([]);
  const [additionalConditionText, setAdditionalConditionText] = useState('');
  const [clause, setClause] = useState({});
  const [selected, setSelected] = useState('none');
  const dispatch = useDispatch();
  const ccData = useSelector((state) => state.tenant?.ccData);
  const handleChange = () => {};
  useEffect(() => {
    // dispatch(tenantActions.getCommonClauses());
  }, []);
  useEffect(() => {
    setCcArray(ccData);
  }, [ccData]);

  useEffect(() => {
    getText();
  }, [props.commonConditionText]);

  const removeFromArray = (original, remove) => {
    if (original && original.length) {
      return original.filter((value) => !remove.some((item) => item.clause_id === value.clause_id));
    }
    return [];
  };

  const getText = () => {
    if (ccData) {
      let obj = ccData.find((item) => {
        return item.header == props.commonConditionText;
      });
      setClause(obj);
    }
  };
  const _onSave = () => {
    if (isAdd) {
      // props.setAdditionalConditionText(clause?.text);
      props._addAdditionCondition(clause?.text);
      setShowCCModal(false);
      setClause({});
      props.setCommonConditionText('');
    } else {
      props._addCommonCondition(clause);
      setShowCCModal(false);
      props.setCommonConditionText('');
      setClause({});
    }
  };
  const onCommonChange = (e) => {
    props.setCommonConditionText(e.target.value);
  };
  const renderCommonClauses = (item, index) => (
    <div
      style={{
        flexWrap: 'wrap',
      }}
    >
      {isAdd ? (
        <SubHeading>
          You may add additional clauses and edit them as appropriate.
          <br />
          Please add a clause in the empty section
        </SubHeading>
      ) : (
        <SubHeading>
          You may select these additional clauses and edit them as appropriate. <br /> please select a clause in the
          empty section
        </SubHeading>
      )}
      {isAdd ? null : (
        <Select
          value={props.commonConditionText ? props.commonConditionText : selected}
          style={{
            width: layoutType == 'phone' ? '100%' : '60%',
            height: '40px',
            borderRadius: 30,
            marginLeft: layoutType == 'phone' ? 0 : '20%',
            marginTop: 30,
          }}
          onChange={(e) => onCommonChange(e)}
          variant="outlined"
          name="rental_frequency"
          placeholder="duration"
        >
          <MenuItem key="0" disabled value="none">
            Click to Add conditions
          </MenuItem>

          {props?.commonCondition &&
            removeFromArray(ccArray, props.commonCondition).map((item, index) => {
              return (
                <MenuItem key={index} value={item.header}>
                  {item.header}
                </MenuItem>
              );
            })}
        </Select>
      )}
      <TextField
        InputLabelProps={{ shrink: true }}
        multiline
        variant="outlined"
        rows={4}
        placeholder="Click above to Add comman conditions"
        style={{ marginTop: 20, marginBottom: 20, width: '100%' }}
        value={clause?.text}
        onChange={(e) => setClause({ ...clause, text: e.target.value })}
      />
      <Button
        onClick={() => {
          _onSave();
        }}
        title={'Save'}
        type="stripe"
        style={{ width: '60%', marginLeft: '20%' }}
      />
    </div>
  );
  return (
    <div>
      <Heading style={{ color: variables.purple }}>Special Conditions</Heading>
      <CardBox hasShadow={true} hasBorderRadius={true} style={{ width: '100%' }}>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <TextInput
            title={"Break Clause"}
            type={"row"}
            wrapperstyle={{
              marginBottom: 15,
              width: "70%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            value={props?.break_clause}
            onChange={e => props?.setBreakClause(e.target.value)}
            name="break_clause"
          />
        </div> */}
        <SubHeading>Common conditions</SubHeading>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            justifyContent: 'space-between',
            width: layoutType == 'phone' ? '100%' : '70%',
          }}
        >
          {/* <AddCircleOutlineOutlinedIcon
            onClick={() => setShowCCModal(true)}
            style={{
              marginLeft: "10px",
              fontSize: "30px",
              color: variables.purple1
            }}
          /> */}
          <TextInput
            title={'Condition'}
            type={'row'}
            onClick={() => {
              setIsAdd(false);
              setShowCCModal(true);
            }}
            inputProps={{ readOnly: true }}
            wrapperstyle={{
              marginBottom: 15,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            placeholder={' Add common condition'}
            name="commonConditionText"
          />
        </div>

        <div
          style={{
            alignItems: 'center',
            marginTop: '10px',
            alignItems: 'center',
          }}
        >
          {props?.commonCondition &&
            props.commonCondition.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '10px',
                    alignItems: 'center',
                  }}
                >
                  <TextInput
                    type={'row'}
                    title={'Condition ' + (index + 1)}
                    inputProps={{ readOnly: true }}
                    wrapperstyle={{
                      marginBottom: 15,
                      width: '70%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                    value={item.header}
                  />
                  <HighlightOff
                    onClick={() => props._subCommonCondition(item)}
                    style={{
                      marginLeft: '10px',
                      marginBottom: '10px',
                      fontSize: '30px',
                      color: variables.orange,
                    }}
                  />
                </div>
              );
            })}
        </div>
        <SubHeading>Additional conditions</SubHeading>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '10px',
            alignItems: 'center',
            width: layoutType == 'phone' ? '100%' : '70%',
          }}
        >
          <TextInput
            onClick={() => {
              setIsAdd(true);
              setClause({});
              setShowCCModal(true);
            }}
            inputProps={{ readOnly: true }}
            title={'Condition'}
            type={'row'}
            wrapperstyle={{
              marginBottom: 15,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            value={props.additionalConditionText}
            onChange={(e) => {
              //setAdditionalError(false);
            }}
            name="additionalCondtionText"
            placeholder=" Add additional condition"
          />
        </div>

        <div
          style={{
            alignItems: 'center',
            marginTop: '10px',
            alignItems: 'center',
          }}
        >
          {props.additionalCondition.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                  alignItems: 'center',
                }}
              >
                <TextInput
                  type={'row'}
                  title={'Condition ' + (index + 1)}
                  inputProps={{ readOnly: true }}
                  wrapperstyle={{
                    marginBottom: 15,
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  value={item}
                />
                <HighlightOff
                  onClick={() => props._subAdditionCondition(item)}
                  style={{
                    marginLeft: '10px',
                    marginBottom: '10px',
                    fontSize: '30px',
                    color: variables.orange,
                  }}
                />
              </div>
            );
          })}
        </div>
      </CardBox>
      <OpenFlowModal
        showModal={showCCModal}
        setShowModal={setShowCCModal}
        modalBody={renderCommonClauses()}
        showFooter={false}
        modalHeading={isAdd ? 'Additional Condition Clauses' : 'Condition Clauses'}
      />
    </div>
  );
};
