import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import { Image, View } from "react-native";
import Button from "./../../../../../components/button/Button"
import { useIsFocused } from '@react-navigation/native';
import ajax from '../../../../../helpers/ajaxHelper';
import Urls from '../../../../../services/Urls';
import getPlatformType from '../../../../../helpers/helpers';

export const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [user, setUser] = useState({});
  const focused = useIsFocused();
  const layoutType = getPlatformType();
  useEffect(() => {
    ajax
      .get(Urls.PROFILE)
      .then(({ data }) => {
        if (data?.status) {
          setUser(data?.data.userDetails);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focused]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      dispatch({ type: "tenantConstants.MAKE_PAYMENTS" });
      const address =  await elements.getElement('address').getValue();
      console.log({shipping: address.value})
      const response = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
        confirmParams: {
          shipping: address.value
        },
      });
      console.log("responseresponse",response)
      switch (response?.paymentIntent?.status) {
        case 'succeeded':
          props.onSuccessToken(true);
          break;
        case 'processing':
          props.onSuccessToken(true);
          break;
        case 'requires_payment_method':
          props.onSuccessToken(false);
          break;
        default:
          props.onSuccessToken(false);
      }
      dispatch({ type: "tenantConstants.MAKE_PAYMENTSSUCCESS"});
    } catch (error) {
      console.log(error)
      dispatch({ type: "tenantConstants.MAKE_PAYMENTSSUCCESS"});
      props.onSuccessToken(false);
    }
 };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <AddressElement
        options={{
          mode: "billing",
          allowedCountries: ["UK"],
          blockPoBox: true,
          defaultValues: {
            name: user?.first_name ? user?.first_name + " " + user?.last_name
              : "",
            address: {
              country: "GB",
            },
          },
          fields: {
            phone: "always",
          },
          validation: {
            phone: {
              required: "never",
            },
          },
        }}
      />
      <Image
        source={require("./../../../../../assets/images/stripe.png")}
        resizeMode="contain"
        style={{
          height: 26,
          width: 119,
          alignSelf: "flex-end",
          marginTop: 24,
        }}
      />
      <View style={{
        marginTop: 30,
        width: "100%",
justifyContent:"center",
alignItems:"flex-end"
      }}>
          <Button
              color={'#1B8A8C'}
              type="solid"
              childText={"Submit order"}
              style={{width:layoutType == 'phone'?150:200}}
              onPress={handleSubmit}
            />
       
      </View>
    </form>
  )
};

export default CheckoutForm;