import React, { useEffect, useState } from 'react';

import { Heading, FormContainer, SubHeading } from './style';
import { RowContainer } from './WelcomeOpenFlo.style';
import { View } from 'react-native';
import variables from '../atoms/variables.json';
import { Button } from '../atoms/button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { CostTitle } from './style';
import { ModalHeader } from '../atoms/modal/style';
import { TextInput } from '../atoms/textinput';
import { CardBox } from '../atoms/card';
import BackComponent from '../atoms/backComponent';
import { Otp } from '../atoms/otp';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';

const termAndCondition =
  '- I confirm that the information supplied is to the best of my knowledge and belief, true.- I have no objection to this information being verified by fair and lawful means.- I agree that a company check can be carried out on my details to ensure I meet the requirements of the Rent Collect service.- We process and hold all information in accordance with the GDPR 2018 Legislation.- Please note that if the payment date falls on a bank holiday or a weekend it will be made on the next working day.- I confirm that the tenant(s) have a clean and up to date rent record for the last 3 months and that the rent has been paid on the rent due date.- I confirm that all bank details provided are accurate as FCC Paragon does not accept any responsibly for errors or omissions.- Payments made under this service to an incorrect bank account will not be reimbursed.- I believe that the facts stated and the details given in this form are true and that the same can be relied upon by FCC Paragon during the collection and payment of the rent.';

const RentCollectionSignUp = () => {
  const globalState = useSelector((state) => state?.tenant?.rentCollData);
  const flowId = useSelector((state) => state?.tenant?.depositProtectData?.flow?.openflow_id);
  const rentalValue = useSelector(
    (state) => state?.tenant?.depositProtectData?.flow?.is_rental_payment_received_by_landlord
  );

  const agentDetails = useSelector((state) => state?.users?.user_profile?.agent);
  const dispatch = useDispatch();

  const [isAgent, setIsAgent] = React.useState(true);
  const [isLandlord, setIsLandlord] = React.useState(false);
  const [Accounts, setAccounts] = React.useState([]);
  const [selectedAccount, setselectedAccount] = React.useState('');
  const [account_id, setAccount_id] = React.useState('');
  const [sortCode, setsortCode] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [payeeRef, setPayeeRef] = React.useState('');
  const [authorityOfAct, setAuthorityOfAct] = React.useState(true);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [getPaid, setRental] = React.useState(rentalValue == true ? 'Landlord' : 'Agent');

  let landlordModal = {
    first_rental_collection_date: null,
    first_rental_collection_amount: 0,
    subsquent_rental_collection_amount: 0,
    rental_frequency: '',
    number_of_collections: 6,
  };
  const [fieldsValue, setFieldValues] = React.useState(landlordModal);
  const [isValidated, setIsValidated] = React.useState(false);

  useEffect(() => {
    if (flowId) {
      dispatch(tenantActions.getRentalCollectionData(flowId));
    }
  }, [flowId]);

  useEffect(() => {
    if (globalState?.rentalOffer) {
      setFieldValues({
        ...fieldsValue,
        rental_frequency: globalState?.rentalOffer?.rental_frequency,
        subsquent_rental_collection_amount: globalState?.rentalOffer?.rental_amount,
      });
    }
    if (globalState?.agentAccounts && globalState?.agentAccounts.length) {
      setAccounts(globalState?.agentAccounts);
    }

    if (globalState?.leadTenant?.app_user?.user?.address || globalState?.leadTenant?.app_user?.user?.postcode) {
      setFieldValues({
        ...fieldsValue,
        user_address: globalState?.leadTenant?.app_user?.user?.address,
        user_post_code: globalState?.leadTenant?.app_user?.user?.postcode,
      });
    }
  }, [
    globalState?.rentalOffer?.rental_frequency,
    globalState?.rentalOffer?.subsquent_rental_collection_amount,
    globalState?.agentAccounts,
    globalState?.leadTenant?.app_user?.user?.address,
    globalState?.leadTenant?.app_user?.user?.postcode,
  ]);
  useEffect(() => {
    if (globalState?.agentAccounts) {
      if (globalState?.agentAccounts && globalState?.agentAccounts?.length) {
        let filter = globalState?.agentAccounts.filter((data, index) => selectedAccount === data.account_name);
        if (filter?.length) {
          let data = filter[0];
          setAccount_id(data?.account_id);
          setAccountName(data.account_name);
          setsortCode(data.sort_code);
          setPayeeRef(data.payeeRef);
        }
      }
    }
  }, [selectedAccount, globalState?.agentAccounts]);

  const onSave = () => {
    setIsValidated(true);
    if (
      fieldsValue?.first_rental_collection_date &&
      fieldsValue?.first_rental_collection_amount &&
      acceptedTerms &&
      fieldsValue?.confirmation_name &&
      fieldsValue?.confirmation_position
    ) {
      let data = {
        ...fieldsValue,
        isAgent,
        isLandlord,
        agent_account_name: accountName,
        agent_payee_ref: payeeRef,
        agent_sort_code: sortCode,
        agent_account_id: account_id,
        number_of_collections: fieldsValue?.number_of_collections,
      };
      dispatch(tenantActions.saveRentalCollectionData(flowId, data));
    } else {
      dispatch(alertActions.error('Please select all mandatory fields'));
    }
  };
  const handleChange = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };

  const displayAddLandLord = () => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        <TextInput
          title={'Landlord First Name'}
          isfullwidth={true}
          type="text"
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={globalState?.landlord?.first_name || ''}
          disabled={true}
        />
        <TextInput
          title={'Landlord Last Name'}
          isfullwidth={true}
          type="text"
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          onChange={handleChange}
          name="last_name"
          value={globalState?.landlord?.last_name || ''}
          disabled={true}
        />

        <TextInput
          title={'Correspondence Address'}
          isfullwidth={true}
          type="text"
          value={fieldsValue.correspondece_address}
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={globalState?.landlord?.correspondece_address || ''}
          disabled={true}
        />
        <TextInput
          title={'Post Code'}
          isfullwidth={true}
          type="text"
          value={fieldsValue.post_code}
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={globalState?.landlord?.post_code || ''}
          disabled={true}
        />
        <TextInput
          title={'Email Address'}
          isfullwidth={true}
          type="email"
          value={fieldsValue.email}
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={globalState?.landlord?.email || ''}
          disabled={true}
        />
        <TextInput
          title={'Contact Number'}
          isfullwidth={true}
          type="tel"
          value={fieldsValue.number}
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={globalState?.landlord?.number || ''}
          disabled={true}
        />
      </div>
    </>
  );

  const displayCorrespondence = () => (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginBottom: 30,
        }}
      >
        <TextInput
          title={'Correspondence Address'}
          isfullwidth={true}
          type="text"
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          disabled={true}
          value={agentDetails?.branch?.branch_address || ''}
        />
        <TextInput
          title={'Post Code'}
          value={agentDetails?.branch?.postcode || ''}
          disabled={true}
          isfullwidth={true}
          type="text"
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
        />

        <TextInput
          title={'Branch Email Address'}
          isfullwidth={true}
          type="text"
          value={agentDetails?.branch?.email || ''}
          disabled={true}
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
        />
        <TextInput
          title={'Contact Number'}
          isfullwidth={true}
          type="text"
          wrapperstyle={{ width: '49%', marginBottom: 15 }}
          value={agentDetails?.branch?.contact_number || ''}
          disabled={true}
        />
      </div>
    </>
  );
  const agentAccountDetail = () => (
    <>
      <RowContainer style={{ marginTop: 20 }}>
        <FormContainer>
          <TextInput
            value="1234567"
            title={'Account Name'}
            containerstyles={{ width: '100%' }}
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
            error={accountName ? false : isValidated ? true : false}
          />
        </FormContainer>
        <FormContainer>
          <TextInput
            title={'Payee Ref'}
            containerstyles={{ width: '100%' }}
            onChange={(e) => setPayeeRef(e.target.value)}
            value={payeeRef}
            error={payeeRef ? false : isValidated ? true : false}
          />
        </FormContainer>
      </RowContainer>
      <RowContainer style={{ marginTop: 20, marginBottom: 20 }}>
        <FormContainer>
          <Otp
            title={'Sort Code'}
            containerstyles={{ width: '100%' }}
            value={sortCode}
            no={6}
            onChange={(e) => setsortCode(e.target.value)}
            error={sortCode ? false : isValidated ? true : false}
          />
        </FormContainer>
        <FormContainer>
          <Otp
            no={8}
            title={'Account No'}
            containerstyles={{ width: '100%' }}
            value={account_id}
            onChange={(e) => setAccount_id(e.target.value)}
            error={account_id ? false : isValidated ? true : false}
          />
        </FormContainer>
      </RowContainer>
    </>
  );
  const accountDetail = () => (
    <>
      <RowContainer style={{ marginTop: 20 }}>
        <FormContainer>
          <TextInput
            name={'landlord_account_name'}
            title={'Landlord Account Name'}
            containerstyles={{ width: '100%' }}
            onChange={handleChange}
            value={fieldsValue.landlord_account_name || ''}
            error={fieldsValue?.landlord_account_name ? false : isValidated ? true : false}
          />
        </FormContainer>
        <FormContainer>
          <TextInput
            title={'Payee Ref'}
            containerstyles={{ width: '100%' }}
            onChange={handleChange}
            value={fieldsValue.landlord_payee_ref || ''}
            name={'landlord_payee_ref'}
            error={fieldsValue?.landlord_payee_ref ? false : isValidated ? true : false}
          />
        </FormContainer>
      </RowContainer>
      <RowContainer style={{ marginTop: 20, marginBottom: 20 }}>
        <FormContainer>
          <Otp
            title={'Sort Code'}
            no={6}
            containerstyles={{ width: '100%' }}
            value={fieldsValue.landlord_sort_code}
            onChange={handleChange}
            name={'landlord_sort_code'}
            error={fieldsValue?.landlord_sort_code ? false : isValidated ? true : false}
          />
        </FormContainer>
        <FormContainer>
          <Otp
            no={8}
            value="1234567"
            title={'AccountNo'}
            containerstyles={{ width: '100%' }}
            value={fieldsValue.landlord_account_number}
            onChange={handleChange}
            name={'landlord_account_number'}
            error={fieldsValue?.landlord_account_number ? false : isValidated ? true : false}
          />
        </FormContainer>
      </RowContainer>
    </>
  );
  const userAccountDetail = () => (
    <>
      <RowContainer style={{ marginTop: 20 }}>
        <FormContainer>
          <TextInput
            name={'teanant_account_name'}
            title={'Account Name'}
            containerstyles={{ width: '100%' }}
            onChange={handleChange}
            value={fieldsValue.teanant_account_name || ''}
            error={fieldsValue?.teanant_account_name ? false : isValidated ? true : false}
          />
        </FormContainer>
        <FormContainer>
          <TextInput
            title={'Payee Ref'}
            containerstyles={{ width: '100%' }}
            onChange={handleChange}
            value={fieldsValue.teanant_payee_ref || ''}
            name={'teanant_payee_ref'}
            error={fieldsValue?.teanant_payee_ref ? false : isValidated ? true : false}
          />
        </FormContainer>
      </RowContainer>
      <RowContainer style={{ marginTop: 20, marginBottom: 20 }}>
        <FormContainer>
          <Otp
            title={'Sort Code'}
            containerstyles={{ width: '100%' }}
            value={fieldsValue.teanant_sort_code}
            error={fieldsValue?.teanant_sort_code ? false : isValidated ? true : false}
            no={6}
            onChange={handleChange}
            name={'teanant_sort_code'}
          />
        </FormContainer>
        <FormContainer>
          <Otp
            no={8}
            value="1234567"
            title={'Account No'}
            containerstyles={{ width: '100%' }}
            value={fieldsValue.teanant_account_number}
            error={fieldsValue?.teanant_account_number ? false : isValidated ? true : false}
            onChange={handleChange}
            name={'teanant_account_number'}
          />
        </FormContainer>
      </RowContainer>
    </>
  );
  const userDetails = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <TextInput
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={globalState?.leadTenant?.app_user?.user?.first_name || ''}
        disabled={true}
      />
      <TextInput
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={globalState?.leadTenant?.app_user?.user?.last_name || ''}
        disabled={true}
      />
      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={globalState?.leadTenant?.app_user?.user?.email || ''}
        disabled={true}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={globalState?.leadTenant?.app_user?.user?.contact_number || ''}
        disabled={true}
      />
      <TextInput
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={fieldsValue?.user_address}
        name={'user_address'}
        onChange={handleChange}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        onChange={handleChange}
        containerstyles={{ width: '100%' }}
        value={fieldsValue?.user_post_code || ''}
        name={'user_post_code'}
      />
    </div>
  );

  const rentalDetail = () => (
    <>
      <RowContainer>
        <TextInput
          title={
            <>
              <strong>First Rental Collection Date</strong>
            </>
          }
          type={'date'}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          onChange={handleChange}
          name="first_rental_collection_date"
          value={fieldsValue?.first_rental_collection_date || ''}
          error={fieldsValue?.first_rental_collection_date ? false : isValidated ? true : false}
        />
        <TextInput
          title={
            <>
              <strong>First Rental collection amount (£)</strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          onChange
          containerstyles={{ width: '100%' }}
          value={fieldsValue?.first_rental_collection_amount || ''}
          onChange={handleChange}
          name="first_rental_collection_amount"
          error={fieldsValue?.first_rental_collection_amount ? false : isValidated ? true : false}
        />
      </RowContainer>
      <RowContainer style={{ alignItems: 'center' }}>
        <TextInput
          title={
            <>
              <strong>Subsquent collection amount (£) </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          onChange={handleChange}
          name="subsquent_rental_collection_amount"
          value={fieldsValue?.subsquent_rental_collection_amount || ''}
          error={fieldsValue?.subsquent_rental_collection_amount ? false : isValidated ? true : false}
        />
        <div style={{ width: '49%', marginTop: '15px' }}>
          <label>Frequency</label>
          <Select
            value={fieldsValue?.rental_frequency || ''}
            style={{
              width: '100%',
              height: '40px',
              borderWidth: 0,
            }}
            onChange={handleChange}
            variant="outlined"
            name="rental_frequency"
            placeholder="duration"
          >
            <MenuItem value={'WEEKLY'}>Weekly</MenuItem>
            <MenuItem value={'MONTHLY'}>Per Calender Month</MenuItem>
            <MenuItem value={'QUARTERLY'}>Quarterly</MenuItem>
            <MenuItem value={'BIANNUALLY'}>6 Monthly</MenuItem>
            <MenuItem value={'FORTNIGHTLY'}>FORTNIGHTLY</MenuItem>
          </Select>
        </div>
      </RowContainer>
      <Heading style={{ marginTop: 20 }}>Number of Collections</Heading>
      <div style={{ display: 'flex' }}>
        <SubHeading style={{ width: '35%', marginRight: 15 }}>
          How many collections are to be made? The maximum number of collections must be within a 2 year period.
        </SubHeading>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            onClick={() =>
              setFieldValues({
                ...fieldsValue,
                number_of_collections:
                  fieldsValue?.number_of_collections > 0 ? fieldsValue?.number_of_collections - 1 : 0,
              })
            }
            style={{ marginRight: 10, marginTop: 15, cursor: 'pointer' }}
          >
            <p style={{ fontSize: 40, fontWeight: 'bold' }}>-</p>
          </div>
          <TextInput
            title={<>{/* <strong>Number of Collections</strong> */}</>}
            type={'row'}
            wrapperstyle={{
              width: '20%',
            }}
            containerstyles={{ width: '100%', paddingLeft: 15 }}
            onChange={handleChange}
            name="number_of_collections"
            value={fieldsValue?.number_of_collections || ''}
            error={fieldsValue?.number_of_collections ? false : isValidated ? true : false}
          />
          <div
            onClick={() =>
              setFieldValues({ ...fieldsValue, number_of_collections: fieldsValue?.number_of_collections + 1 })
            }
            style={{ marginLeft: 10, marginTop: 15, cursor: 'pointer' }}
          >
            <p style={{ fontSize: 40, fontWeight: 'bold' }}>+</p>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <View style={{ paddingLeft: 30, paddingRight: 30 }}>
      <ModalHeader style={{ fontSize: 24 }}>Rent Collection</ModalHeader>

      <SubHeading>Welcome to Rent Collection provided by one of UK leading property services company.</SubHeading>
      <BackComponent id={flowId} hideMark={true} flowtype={'verification_status'} />
      <div style={{ display: 'flex' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '300px',
            height: '70px',
          }}
        >
          <Heading style={{ marginRight: '40px', marginTop: '26px' }}>Cost ( PCM )</Heading>
          <CostTitle>£ 10 </CostTitle>
        </div>
        <div style={{ marginLeft: '40px', marginTop: '12px', width: '400px' }}>
          <SubHeading>Property Details</SubHeading>

          <TextInput
            title={'Address:'}
            inputProps={{ readOnly: true }}
            value={globalState?.prop?.address_line1 || ''}
            wrapperstyle={{
              display: 'flex',
              width: '80%',
              alignItems: 'center',
              marginTop: '50px',
              justifyContent: 'space-between',
            }}
          />

          <TextInput
            title={'Postcode:'}
            inputProps={{ readOnly: true }}
            value={globalState?.prop?.postcode || ''}
            wrapperstyle={{
              display: 'flex',
              width: '80%',
              alignItems: 'center',
              marginTop: '50px',
              justifyContent: 'space-between',
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: '20px' }}>
        <ModalHeader style={{ fontSize: 24 }}> Rent Details</ModalHeader>
        <ModalHeader style={{ fontSize: 18, fontWeight: 'normal' }}> Who will get paid?</ModalHeader>
        <div style={{ marginStart: '40px' }}>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="is_date_different"
              name="is_date_different"
              value={getPaid}
              onChange={(e) => setRental(e.target.value)}
              name="is_date_different"
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  value={'Agent'}
                  control={<Radio style={{ color: variables.heliotrope }} />}
                  style={{ color: 'black' }}
                  label="Agent"
                />
                <FormControlLabel
                  value={'Landlord'}
                  control={<Radio style={{ color: variables.heliotrope }} />}
                  style={{ color: 'black' }}
                  label="Landlord"
                />
              </div>
            </RadioGroup>
          </FormControl>
          {/* <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAgent}
                  onChange={() => {
                    setIsAgent(!isAgent);
                    setIsLandlord(!isLandlord);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Agent"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLandlord}
                  onChange={() => {
                    setIsAgent(!isAgent);
                    setIsLandlord(!isLandlord);
                  }}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="LandLord"
            />
          </FormGroup> */}
        </div>
      </div>
      <CardBox hasShadow hasBorderRadius style={{ width: '100%', marginBottom: 40 }}>
        {rentalDetail()}
      </CardBox>

      <ModalHeader style={{ fontSize: 24 }}>Rent Payment - Account & Info</ModalHeader>
      {isLandlord ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <CardBox hasShadow hasBorderRadius style={{ width: '100%' }}>
            <Heading>Lead Tenant Details</Heading>
            <SubHeading style={{ color: '#6D08C0' }}>Please note: This is the lead Tenant</SubHeading>
            {displayAddLandLord()}
          </CardBox>
          <CardBox hasShadow hasBorderRadius style={{ width: '95%', marginLeft: '5%' }}>
            <Heading>Account Details</Heading>
            {accountDetail()}
          </CardBox>
        </div>
      ) : null}

      {isAgent || isLandlord ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: 30,
          }}
        >
          <CardBox hasShadow hasBorderRadius style={{ minWidth: '60%', maxWidth: '60%' }}>
            <Heading>Agent Details</Heading>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginTop: 20,
              }}
            >
              <TextInput
                title={'Company Name'}
                isfullwidth={true}
                type="text"
                wrapperstyle={{ width: '49%', marginBottom: 15 }}
                disabled={true}
                value={agentDetails?.branch.company?.name || ''}
                name="Company Name"
              />
              <TextInput
                title={'Branch Name'}
                isfullwidth={true}
                type="text"
                disabled={true}
                value={agentDetails?.branch?.name || ''}
                wrapperstyle={{ width: '49%', marginBottom: 15 }}
                //   onChange={handleChange}
                name="post_code"
              />
            </div>
            <TextInput
              title={'Branch ID'}
              isfullwidth={true}
              type="text"
              wrapperstyle={{ width: '49%', marginBottom: 15 }}
              // onChange={handleChange}
              value={agentDetails?.branch?.name || ''}
              disabled={true}
              value={'data'}
            />
            {displayCorrespondence()}
          </CardBox>
          {isAgent && (
            <div>
              <CardBox hasShadow hasBorderRadius style={{ width: '98%', marginLeft: '20px' }}>
                <Heading>Account Details</Heading>
                {Accounts && Accounts.length ? (
                  <div className="col-lg-12">
                    <div style={{ display: 'flex' }}>
                      <label>Display Account Name</label>
                      <Select
                        value={selectedAccount}
                        style={{
                          width: '100%',
                          height: '40px',
                          borderWidth: 0,
                        }}
                        onChange={(e) => {
                          setselectedAccount(e.target.value);
                        }}
                        variant="outlined"
                        name="selectedAccount"
                        placeholder="duration"
                      >
                        {Accounts.map((data) => {
                          return <MenuItem value={data?.account_name}>{data?.account_name}</MenuItem>;
                        })}
                      </Select>
                    </div>
                  </div>
                ) : null}
                {agentAccountDetail()}
              </CardBox>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ModalHeader
                  style={{
                    marginTop: 30,
                    marginLeft: 20,
                    marginRight: 40,
                    fontSize: 24,
                  }}
                >
                  Authority to Act?
                </ModalHeader>
                <div style={{ display: 'flex', marginTop: 20 }}>
                  <Checkbox
                    checked={authorityOfAct}
                    onChange={(e) => setAuthorityOfAct(!authorityOfAct)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <SubHeading>yes</SubHeading>
                </div>
              </div>

              <SubHeading style={{ marginLeft: 20 }}>
                We the agent warrant that we are appointed by the person named as the landlord for matters concerning
                the property.
              </SubHeading>
              {authorityOfAct ? null : isValidated ? (
                <SubHeading style={{ color: 'red', marginLeft: 20 }}>Please accept above condition!</SubHeading>
              ) : null}
            </div>
          )}
        </div>
      ) : null}

      <ModalHeader style={{ fontSize: 24, marginTop: 40 }}>Rent Collection - Account & Info</ModalHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <CardBox hasShadow hasBorderRadius style={{ minWidth: '60%', maxWidth: '60%' }}>
          <Heading>Tenant Details</Heading>
          <SubHeading style={{ color: 'red' }}>Please note: This is the lead tenant</SubHeading>
          {userDetails()}
        </CardBox>
        <CardBox hasShadow hasBorderRadius style={{ width: '48%', marginLeft: '20px' }}>
          <Heading>Account Details</Heading>
          {userAccountDetail()}
        </CardBox>
      </div>

      <ModalHeader style={{ fontSize: 24, marginTop: 40 }}>Confirmation</ModalHeader>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextInput
          title={'Name'}
          isfullwidth={true}
          type="text"
          value={fieldsValue?.confirmation_name || ''}
          wrapperstyle={{ width: '30%', marginBottom: 15 }}
          onChange={handleChange}
          name="confirmation_name"
          error={fieldsValue?.confirmation_name ? false : isValidated ? true : false}
        />
        <TextInput
          title={'Position'}
          isfullwidth={true}
          type="text"
          value={fieldsValue?.confirmation_position || ''}
          wrapperstyle={{ width: '30%', marginBottom: 15 }}
          onChange={handleChange}
          name="confirmation_position"
          error={fieldsValue?.confirmation_position ? false : isValidated ? true : false}
        />
        <Button
          type="custom"
          title="Submit"
          onClick={() => onSave()}
          style={{ marginRight: 20 }}
          color={variables.orangeButton}
        />
      </div>
      <div style={{ marginTop: 20, display: 'flex', alignItems: 'flex-start' }}>
        <div style={{ width: '85%' }}>
          {termAndCondition.split('-').map((val, i) => (
            <SubHeading style={{ margin: 4 }} key={'sb' + i}>
              {val}
            </SubHeading>
          ))}
          <SubHeading style={{ margin: 4 }}>
            I agree to the{' '}
            <a
              target="_blank"
              href={'https://admin.paragonsecure.com/downloads/pdfdownloads/RentCollectTerms_PRCTC-150222-01.pdf'}
            >
              {' '}
              terms and conditions{' '}
            </a>{' '}
            of FCC Paragon Rent Collect.
          </SubHeading>
        </div>
        <Checkbox
          checked={acceptedTerms}
          onChange={() => setAcceptedTerms(!acceptedTerms)}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <SubHeading>Yes</SubHeading>
      </div>
      <SubHeading style={{ color: 'red' }}>
        {acceptedTerms ? null : isValidated ? 'Please accept all above condition!' : null}
      </SubHeading>
    </View>
  );
};
export default drawerHoc({
  Component: RentCollectionSignUp,
  showBackButton: true,
});
