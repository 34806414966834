import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
var layoutType = getPlatformType();

let GradientWrapper, NameText;
GradientWrapper = styled(LinearGradient)`
position: absolute;
right: 0;
top: 0;
width: 100%;
border-radius: 16px;
padding-left: 20px;
padding-bottom: 42px;
flex-direction:column;
height:220px;
`;

NameText = styled.Text`
font-size: 20px;
color: white;
margin-right:40px;


`;
export {GradientWrapper,NameText};