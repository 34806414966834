import React, { useState } from 'react';
import { View } from 'react-native';
import getPlatformType from '../../helpers/helpers';
import {
  Container,
  ContainerScrollView,
  MainText,
  NoticeText,
  FormBox,
  ContainerRight,
  ContainerLeft,
} from './index.style';
import Button from '../../components/button/Button';
import ListCard from '../../components/listCard';
import ProfileCard from '../../components/profileCard';
import CheckBox from '../../components/checkbox';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Box from '../../components/box/Box';
import DrawerMenu from '../drawerMenu/DrawerMenu';
var layoutType = getPlatformType();

const TenantScreen = ({ navigation }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <ContainerScrollView>
      {layoutType != 'phone' ? <DrawerMenu navigation={navigation} /> : null}
      <Container>
        <ContainerLeft>
          <ListCard
            leftIcon="piggy-bank"
            title="Deposit & Rent"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          />
          <MainText>Deposit & Rent</MainText>
          <Box backgroundColor={'#ade2ea'}>
            <NoticeText>Deposit & upfront rental payment</NoticeText>
            <NoticeText>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </NoticeText>
          </Box>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
            <MainText>Requesting Payment</MainText>
            <CheckBox
              width="170px"
              checked={isChecked}
              label="Mark as Complete"
              onPress={() => setIsChecked(!isChecked)}
            />
          </View>
          <Box>
            <FormBox>
              <View style={{ flex: 1, marginRight: layoutType === 'phone' ? 0 : 25 }}>
                <TextInputComponent title={'Payment Amount (£)'} isAmount />
              </View>
              <Button type={'stripe'} width={170} childText={'Request payment'} style={{ alignSelf: 'flex-end' }} />
            </FormBox>
          </Box>
          <FormBox style={{ padding: 20 }}>
            <If condition={layoutType !== 'phone'}>
              <Button type={'stripe'} width={170} childText={'Back'} />
            </If>
            <Button
              type={'solid'}
              width={170}
              childText={'Add Reminder'}
              style={{ alignSelf: 'flex-end' }}
              onPress={() => navigation.navigate('TenantReminder')}
            />
          </FormBox>
        </ContainerLeft>
        <If condition={layoutType === 'web'}>
          <ContainerRight>
            <ProfileCard title="Bertrand Debeuf" />
          </ContainerRight>
        </If>
      </Container>
    </ContainerScrollView>
  );
};

export default TenantScreen;
