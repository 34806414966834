import styled from 'styled-components/native';
import { Text } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

export const Container = styled.View`
flex:1;
`;
export const TextWrapper = styled(Text)`
  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: black;
`;
export const GradientView = styled(LinearGradient)`
`;
