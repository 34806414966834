import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Container, Row, Left;
if (layoutType == 'web') {
  Container = styled.TouchableOpacity``;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
} else if (layoutType == 'phone') {
  Container = styled.TouchableOpacity``;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
} else {
  Container = styled.TouchableOpacity``;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
}
export { Container, Row, Left };
