import React from 'react';
import styled from 'styled-components';
import { Button } from '../button/index.js';
import { FileCard } from '../fileCard/index.js';
const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const UploadCard = (props) => {
  const [files, setFiles] = React.useState([]);
  const _pushItems = (file) => {
    setFiles([...files, file]);
  };
  const _removeItem = (i) => {
    let _array = files;
    _array.splice(i, 1);
    setFiles(_array);
  };
  return (
    <div>
      <Container>
        <div style={{ flex: props.flexWidth || 0.7 }}>
          {props.titleComponent()}
          <p style={{ marginTop: '5px', fontSize: '13px' }}>{props.description}</p>
        </div>
        <div>
          {props.noUpload ? null : (
            <Button type="stripe" title="Upload" onClick={() => _pushItems({ fileName: 'Document.pdf', url: '' })} />
          )}
        </div>
      </Container>
      <Container style={{ marginTop: '10px' }}>
        <div style={{ flex: 0.7 }}>
          {files.map((item, index) => {
            return <FileCard data={item} onRemove={() => _removeItem(index)} style={{ marginTop: '15px' }} />;
          })}
        </div>
        <div>
          {props.rightButtonComponent ? props.rightButtonComponent() : null}
          {files.length > 0 ? <Button style={{ marginTop: '10px' }} type="stripe" title="Progress" /> : null}
        </div>
      </Container>
    </div>
  );
};

{
  /*
Example.(type=complete/incomplete)
 <ListItem type={"complete"} title={"Tenants Details"} description={"This is a new way of selling property and our UK MLS is tailored for the UK Market, which means you can achieve higher fees from your clients."}/>

*/
}
