import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const Container = styled.View`
  height: 50px;
  position:absolute;
  top:0;
  z-index:100;
  width:100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;




export const BackButtonContainer = styled.TouchableOpacity`
  flex-direction: row;
margin-left: 100px;
  justify-content: space-between;

`;

export const TextWrapper = styled.Text`
  color: #4258db;
  font-size: 20;
  font-weight: bold;
  margin-left:20;
`;
export const BackTextWrapper = styled.Text`
  color: #4258db;
  font-size: 20;
  font-weight: bold;
 
`;
export const LogoutContainer = styled.Text`
  color: black;
  font-size: 20;
  font-weight: bold;
  margin-right: 10;
`;
