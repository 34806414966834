import React from 'react';
import { TouchableOpacity, View, Image, Text, Dimensions, StyleSheet, Platform, Linking } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import {
  FooterWrapper,
  Container,
  FooterLogo,
  FooterLinks1,
  FooterLinks2,
  SocialLinks,
  TextContent,
  SocialImg,
  SocialImgBox,
  ObxWhiteImg,
} from './footerStyle';
import * as Device from 'expo-device';
import { Link } from '@react-navigation/native';
import getPlatformType from '../../helpers/helpers';
import { navigate } from '../../navigation/RootNavigation';

let deviceType = '';
const layoutType = getPlatformType();
const { height: WINDOW_HEIGHT, width: WINDOW_WIDTH } = Dimensions.get('window');
export default class Footer extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = async () => {
    deviceType = await Device.getDeviceTypeAsync();
  };

  _redirectSocial = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          window.open(url);
        } else {
          Linking.openURL(url);
        }
      } else {
      }
    });
  };

  render() {
    const { navigation, isTLYF = true } = this.props;

    return (
      <LinearGradient
        colors={['#6612B6', '#4258DB']}
        end={{ x: 1, y: 0 }}
        start={{ x: 1, y: 1 }}
        style={{
          height: 150,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'row',
        }}
      >
        <Container>
          <FooterWrapper>
            <FooterLogo>
              <Link
                to={isTLYF ? '/home' : '/home'}
                style={{ width: layoutType == 'phone' ? 100 : 130, marginBottom: 20 }}
              >
                {/* <ObxWhiteImg
                  source={ require("../../assets/images/obxWhite.png")}
                  resizeMode="contain"
                /> */}
                <Text style={{ fontSize: layoutType == 'phone' ? 25 : 30, fontWeight: 'bold', color: 'white' }}>
                  HUBITA
                </Text>
              </Link>
              <SocialLinks>
                <SocialImgBox onPress={() => this._redirectSocial('https://uk.linkedin.com/company/hubita')}>
                  <SocialImg source={require('../../assets/images/linkedInNew.png')} resizeMode="contain" />
                </SocialImgBox>
                <SocialImgBox onPress={() => this._redirectSocial('https://www.facebook.com/hubitauk')}>
                  <SocialImg source={require('../../assets/images/facebookBig.png')} resizeMode="contain" />
                </SocialImgBox>
                <SocialImgBox onPress={() => this._redirectSocial('https://twitter.com/HubitaOfficial')}>
                  <SocialImg source={require('../../assets/images/twitterBig.png')} resizeMode="contain" />
                </SocialImgBox>
                <SocialImgBox onPress={() => this._redirectSocial('https://www.facebook.com/hubitauk')}>
                  <SocialImg source={require('../../assets/images/instagramBig.png')} resizeMode="contain" />
                </SocialImgBox>
              </SocialLinks>
            </FooterLogo>

            <FooterLinks1>
              <TextContent
                // onPress={() => navigate(navigation,isTLYF?"About Us": "User Details")}
                onPress={() => {
                  navigation.navigate('AboutUs');
                }}
              >
                {isTLYF ? 'About Us' : 'About Us'}
              </TextContent>

              <TextContent
                // onPress={() => navigateAction(navigation, "Contact Us")}
                onPress={() => {
                  navigation.navigate('ContactUs');
                }}
              >
                Contact Us
              </TextContent>

              {/* <TextContent
                onPress={() => navigateAction(navigation, "Our Services")}
              >
                Agent partnership
              </TextContent> */}
              <TextContent
                // onPress={() => navigateAction(navigation, "MediaHub")}
                onPress={() => {
                  navigation.navigate('MediaHub');
                }}
              >
                Media Hub
              </TextContent>
            </FooterLinks1>

            <FooterLinks2>
              <TextContent
              onPress={() =>navigation.navigate("LegalBits")}
              >
                Privacy Policy
              </TextContent>

              <TextContent
              // onPress={() => navigateAction(navigation, "Legal Bits")}
              onPress={() =>navigation.navigate("LegalBits")}
              >
                T &amp; C's
              </TextContent>

              {/* <TextContent
                onPress={() => navigateAction(navigation, "Our Services")}
              >
                Careers
              </TextContent> */}
              <TextContent
              onPress={() =>navigation.navigate("Charity")}
              >
                Charity
              </TextContent>
            </FooterLinks2>
          </FooterWrapper>
        </Container>
      </LinearGradient>
    );
  }
}
