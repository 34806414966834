import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let MainText;
if (layoutType == 'web') {
  MainText = styled.Text`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
  `;
} else if (layoutType == 'phone') {
  MainText = styled.Text`
    font-size: 14px;
    font-weight: 500;
   
  `;
} else {
  MainText = styled.Text`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
  `;
}
export { MainText };
