import React, { useState } from 'react';
import { TextInput, View } from 'react-native';
import { Container, SolidButton, StripeButton, TextInputWrapper, TextWrapper, TopView } from './statusButton.style';
import PropTypes from 'prop-types';
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';

const StatusButton = (props) => {
  const { childText, fontSize, color, onPress, alignSelf, type, width } = props;
  return (
    <SolidButton
      onPress={() => props?.onPress()}
      width={width}
      onPress={onPress}
      alignSelf={alignSelf}
      color={color}
      {...props}
    >
      <Text color={type == 'solid' ? 'white' : color} fontWeight={600} fontSize={fontSize}>
        {childText}
      </Text>
    </SolidButton>
  );
};

StatusButton.propTypes = {
  childText: PropTypes.string,
  onPress: PropTypes.func,
  color: PropTypes.bool,
  fontSize: PropTypes.string,
  alignSelf: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
};

StatusButton.defaultProps = {
  type: 'solid',
  color: Colors.light.purple,
  fontSize: 12,
};

export default StatusButton;
//Eg.
{
  /* <Button title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
