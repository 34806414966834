import React, { useState, useEffect } from 'react';
import { View, SafeAreaView, Platform, ScrollView, Linking, Text, Image } from 'react-native';
import {
  TitleText,
  MediaLink,
  MediaBigDate,
  DualView,
  DualViewBox,
  SubTitleText,
  ShareBox,
  ShareCount,
  ShareMedia,
  SectionText,
  ImageViewBox,
  DFlex,
  UserInfo,
  WideButtonWrapper,
} from './styles';
import {
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import getPlatformType from '../../helpers/helpers';
import Urls from '../../services/Urls';
import ajax from '../../helpers/ajaxHelper';
import RenderHtml from 'react-native-render-html';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
const layoutType = getPlatformType();
export const MEDIA_ASSETS_BASE_URL = 'https://assets.hubita.co.uk/';
const MediaDetails = (props) => {
  const [post, setPost] = useState([]);
  console.log('selectedPostselectedPost', props);
  useEffect(() => {
    ajax
      .get(Urls.GET_POST_DETAILS + props.selectedPost)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload && data?.payload) {
            setPost(data?.payload);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [props.selectedPost]);
  const returnImage = (post) => {
    const image = post ? post.image : '';
    return `${MEDIA_ASSETS_BASE_URL}${image}`;
  };

  const createMarkup = (data) => {
    const desc = data ? data.description : '';
    return { __html: desc };
  };

  return (
    <View style={{ padding: '2%' }}>
      <DualView>
        <ImageViewBox>
          <Image source={returnImage(post)} resizeMode="contain" style={{ height: '100%', width: '100%' }} />
        </ImageViewBox>
        <DualViewBox style={{ marginTop: '9%' }}>
          <TitleText>{post ? post.title : ''}</TitleText>
          {/* <SubTitleText style={{ marginTop: layoutType == "web" ? 20 : 10 }}>{blogData.subTitle}</SubTitleText> */}
          <MediaLink style={{ marginTop: layoutType == 'web' ? 20 : 10 }}>{post ? post.link : ''}</MediaLink>
          {/* <ShareBox>
            <ShareCount>
              <Text style={{ fontSize: 18, fontWeight: 600 }}>{blogData.shares}</Text>
            </ShareCount>
            <ShareMedia>
              <WideButtonWrapper>
                <WideButton
                  borderRadius={15}
                  childText="Share"
                  whiteButton={true}
                  height={30}
                />
              </WideButtonWrapper>
            </ShareMedia>
          </ShareBox> */}
          <MediaBigDate style={{ marginTop: layoutType == 'web' ? 20 : 10 }}>{post ? post.date : ''}</MediaBigDate>
        </DualViewBox>
      </DualView>
      <DFlex style={{ textAlign: 'justify', flexWrap: 'wrap' }}>
        <RenderHtml contentWidth={'70%'} source={{ html: `${post?.description}` }} />
      </DFlex>

  { layoutType =='web' ? null:
  <View style={{ width: '100%', marginTop: 30, justifyContent: 'center' }}>
        <Text style={{ fontSize: 18, color: 'black', fontWeight: 'bold' }}>Share Media hub</Text>
        <View style={{ marginTop: 10, flexDirection: 'row' }}>
          <FacebookMessengerShareButton
            url={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FacebookMessengerIcon size={32} round />
          </FacebookMessengerShareButton>
          <TwitterShareButton
            url={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <TelegramShareButton
            url={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <WhatsappShareButton
            url={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            separator=":: "
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <LinkedinShareButton
            url={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <EmailShareButton
            url={''}
            subject={'Hubita Media Hub'}
            body={`Hey,\nCheck this out on Hubita Media Hub!\n\n${window.location.href}`}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </View>
      </View>
      }
    </View>
  );
};

export default drawerHoc({
  Component: MediaDetails,
  showBackButton: true,
});
