import React from 'react';
import { View, Text } from 'react-native';
import moment from 'moment';

// import DatePickerComponent from '../datepicker/DatePickerComponent.web';
import { Ionicons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import TextInputComponent from '../textinput/TextInputComponent';
import DatePickerComponent from '../datepicker/DatePickerComponent.web';
import { TouchableOpacity } from 'react-native-gesture-handler';

const CalenderTextSlider = (props) => {
  const [monthAndYear, setMonthAndYear] = React.useState(moment().format('MMM YYYY'));
  const onDateChange = (data) => {
    let obj = {
      month: new Date(data).getMonth() + 1,
      year: new Date(data).getFullYear(),
    };
    if (props?.onDateChange) {
      props.onDateChange(obj.month, obj.year);
    }
    setMonthYear(obj);
  };
  const setMonthYear = (monthAndYear)=> {
      props.setMonthYear(monthAndYear);
  }
  return (
    <TouchableOpacity
      style={{
        flexDirection: 'row',
        paddingHorizontal: 10,
        borderRadius: 30,
        borderWidth: 2,
        alignItems: 'center',
        height: 40,
        borderColor: Colors.light.purple,
      }}
    >
      <Ionicons
        name="chevron-back"
        size={20}
        color={Colors.light.purple}
        onPress={() => {
          setMonthAndYear(moment(monthAndYear).subtract(1, 'months').format('MMM YYYY'));
          onDateChange(moment(monthAndYear).subtract(1, 'months'));
        }}
      />
      {/* <DatePickerComponent
       
        value={monthAndYear}
        onChangeText={(e) => {
          console.log('eeeeeee', e);
          setMonthAndYear(moment(e).format('MMM YYYY'));
          onDateChange(moment(e));
        }}
        title={''}
        isDate
        onlyCalender
        date={''}
        style={{ width: 20, height: 20 }}
        // date={state?.gas_certificate_expiry}
      ></DatePickerComponent> */}
      <DatePickerComponent
        showMonths={true}
        onUpdateInput={(date) => {
          setMonthAndYear(moment(date).format('MMM YYYY'));
          onDateChange(moment(date));
        }}
      />
      <Ionicons name="reorder-three-outline" size={24} color={Colors.light.purple} onPress={() => {}} />
      <Text style={{ color: Colors.light.purple, fontSize: 14, marginHorizontal: 5, fontWeight: '600' }}>
        {monthAndYear}
      </Text>

      <Ionicons
        name="chevron-forward"
        size={20}
        color={Colors.light.purple}
        onPress={() => {
          setMonthAndYear(moment(monthAndYear).add(1, 'months').format('MMM YYYY'));
          onDateChange(moment(monthAndYear).add(1, 'months'));
        }}
      />
    </TouchableOpacity>
  );
};

export default CalenderTextSlider;
