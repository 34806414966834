import styled from 'styled-components/native';

export const StatusBtn = styled.TouchableOpacity`
  border-color: ${(props) => (props.isPrimary ? '#6D08C0' : '#03BBD4')};
  border-radius: 20;
  border-width: 1;
  height: 25;
  justify-content: center;
  align-items: center;
`;
export const CardWrapper = styled.View`
  margin: 15px;
  flex-direction: row;
  min-height: 100px;
`;
export const StatusText = styled.Text`
  color: ${(props) => (props.isPrimary ? '#6D08C0' : '#03BBD4')};
  margin-top: ${(props) => props.marginTop || '0px'};
`;
export const SecondaryText = styled.Text`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => (props.isPrimary ? '#6D08C0' : '#03BBD4')};
  padding: ${(props) => (props.nopadding ? '0px' : '10px')};
`;
export const PrimaryText = styled.Text`
  color: black;
  padding: 0 10px 0 0;
  font-size: 16px;
  font-weight: 500;
`;

export const LineSaperator = styled.View`
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;
`;
export const LeftWrapper = styled.View`
  flex: 0.7;
  margin-right: 5px;
`;
export const LeftWrapper2 = styled.TouchableOpacity`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const RightWrapper = styled.View`
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;
