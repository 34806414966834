import React from 'react';
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  WebView
} from "react-native";
import Hyperlink from 'react-native-hyperlink'
import styled from "styled-components";
import {
  Container,
  SectionWrapper,
  DualView,
  TextBox,
  SectionTitle,
  SectionText,
  ImageBox,
  TeamsSection,
  MemberBoxWrapper,
  MemberBox,
  MemberImage,
  MemberName,
  MemberNameText,
  MemberDetails,
  MemberDetailsText,
  MemberHeader,
  TeamHeader,
  SectionPageTitle,
  SectionTitleTop,
  BulletText,
  Bullet,
  SectionInnerTitle,
  PrivacyGrid,
  PrivacyGridHeader,
  PrivacyGridBody,
  Asterick,
  PrivacyLeftBox,
  PrivacyRightBox,
  CookieTable,
  CookieCells,
  PrivacyCells,
  BulletInlineText,
} from "./styles";
import getPlatformType from "../../helpers/helpers";

const rowText = (text1, text2) => {
  return (
    <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
      <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{text1}</Text>
      <Text style={{ fontSize: 14, marginLeft: 5 }}>{text2}</Text>
    </View>
  )
}

const Privacy = () => {
  const linkStyle = { color: '#2980b9', fontSize: 16 };
  const layoutType = getPlatformType();;

  return (
    <View style={{ flex: 1, padding: 20, width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
    

        <SectionPageTitle>Policy version: 1.0 (20/07/2023)</SectionPageTitle>
  
      <Text style={{ fontSize: 14, marginTop: 20, marginLeft: 5, fontWeight: '400' }}>
        <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
          <a href="http://Hubita.co.uk" target="_blank" rel="noopener noreferrer">
            Hubita.co.uk 
          </a>
        </Text>
        {'(the “Website”) is provided by Hubita Limited (‘we’, ‘our’ or ‘us’). We are the Controller of personal data obtained via our Website (meaning we are the organisation legally responsible for deciding how and for what purposes it is used).'}
      </Text>

        <Text style={{fontSize: 14, marginTop: 20 }}>We take your privacy very seriously. Please read this Privacy Policy carefully as it contains important information on who we are and how and why we collect, store, use and share any information relating to you (your personal data) in connection with your use of our Website. It also explains your rights in relation to your personal data and how to contact us or a relevant regulator in the event you have a complaint.</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>We collect, use and are responsible for certain personal data about you. When we do so we are subject to the UK General Data Protection Regulation (UK GDPR).</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>Our website should not be used by anyone under the age of 18 years.</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>This privacy policy is divided into the following sections:</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>{`       `}How does THE COMPANY protect data?</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>{`       `}For how long does THE COMPANY keep data?</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>{`       `}Purposes for which we will use your personal data</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>{`       `}What data do we process,</Text>
        <Text style={{fontSize: 14, marginTop: 20 }}>{`       `}How do we process your data,</Text>
        {[` What this policy applies to;

 Personal data we collect about you;

 How your personal data is collected;

 How and why we use your personal data;

 Marketing;

 Who we share your personal data with;

 How long your personal data will be kept;

 Cookies and other tracking technologies;

 Your rights;

 Keeping your personal data secure;

 How to complain;

 Changes to this Privacy Policy;

 How to contact us.

Sometimes you can choose if you want to give us your personal data and let us use it. Where that is the case we will tell you and give you the choice before you give the personal data to us.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

        {/* <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>c.{`       `}Legal basis to process your data</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>9.{`       `}Your rights</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>10.{`       `}Links to other websites</Text>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>11.{`       `}Changes to the privacy policy</Text> */}

        <SectionText>
        We collect and use this personal data for the purposes described in the section <Text style={{ fontWeight: 'bold', marginTop: 20  }}>‘How and why we use your personal data’ </Text>below.
        </SectionText>
        <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>How your personal data is collected</Text>
        {[`We collect personal data from you:

 directly, when you enter or send us information, such as when you register with us, contact us (including via email and social media), send us feedback, purchase products or services via our website, post material to our website and complete customer surveys or participate in competitions via our website;

 Indirectly:

– such as your browsing activity while on our website; we will collect information indirectly using the technologies explain in the section on ‘Cookies and other tracking technologies’ below; and

– if you link, connect or login to the Website via a third-party service (i.e. Google or Facebook); in these circumstances, personal information is sent to us as controlled by that service or as authorised by you via your privacy settings with that service.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
        {/* <SectionText>If you have any questions about this
          privacy notice, data protection generally or you wish to exercise your legal
          rights please contact our Data Protection Officer (DPO) using the details set
          out in “Your rights” section below.</SectionText>

        <SectionTitle>1. What information does THE COMPANY collect?</SectionTitle>

        <SectionText>
          We may collect, use, store and/or transfer
          different kinds of personal data about you. What we mean by personal data is
          any information about an individual from which that person can be identified
          (either by itself or when combined with other information). We will limit the
          collection and processing of personal data to what is necessary to achieve one
          or more purpose(s) as identified in this notice. The personal data we collect
          may include:
        </SectionText> */}

<Text style={{ fontWeight: 'bold', marginTop: 20  }}>How your personal data is collected</Text>
{[`We collect personal data from you:

 directly, when you enter or send us information, such as when you register with us, contact us (including via email and social media), send us feedback, purchase products or services via our website, post material to our website and complete customer surveys or participate in competitions via our website;

 Indirectly:

– such as your browsing activity while on our website; we will collect information indirectly using the technologies explain in the section on ‘Cookies and other tracking technologies’ below; and

– if you link, connect or login to the Website via a third-party service (i.e. Google or Facebook); in these circumstances, personal information is sent to us as controlled by that service or as authorised by you via your privacy settings with that service.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

<Text style={{ fontWeight: 'bold', marginTop: 20  }}>How and why we use your personal data</Text>
{[`Under data protection law, we can only use your personal data if we have a proper reason, eg:

 where you have given consent;

 to comply with our legal and regulatory obligations;

 for the performance of a contract with you or to take steps at your request before entering into a contract; or

 for our legitimate interests or those of a third party.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


<Text style={{fontSize: 14, marginTop: 20 }}>A legitimate interest is when we have a business or commercial reason to use your personal data, so long as this is not overridden by your own rights and interests. We will carry out an assessment when relying on legitimate interests, to balance our interests against your own. You can obtain details of this assessment by contacting us (see ‘<Text style={{ fontWeight: 'bold', marginTop: 20  }}>How to contact us’</Text> below).</Text>
<Text style={{fontSize: 14, marginTop: 20 }}>The table below explains what we use your personal data for and why.</Text>


{layoutType != "phone" && (
<CookieTable style={{ borderBottomWidth: 0, marginTop: 20 }}>
                        <PrivacyCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>What we use your personal data for</Text>
                        </PrivacyCells>
                        <PrivacyCells style={{ borderBottomWidth: 0 }}>
                            <Text style={{ fontWeight: "bold" }}>
                            Our reasons
                            </Text>
                        </PrivacyCells>
                        </CookieTable>)}
                       
                       
                        <CookieTable >
                        <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            <Text style={{  }}>Create and manage your account with us</Text>
                        </PrivacyCells>
                        <PrivacyCells >
                      {[`For our legitimate interests, i.e. to be as efficient as we can so we can deliver the best service to you at the best price AND/OR To perform our contract with you or to take steps at your request before entering into a contract`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                        </PrivacyCells>
                        <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0 }}>
                          {[`Providing products AND/OR services to you`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                          </PrivacyCells>
                          <PrivacyCells >
                            {[`To perform our contract with you or to take steps at your request before entering into a contract`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                          </PrivacyCells>
                          <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Conducting checks to identify you and verify your identity or to help prevent and detect fraud against you or us`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                          </PrivacyCells>
                          <PrivacyCells >
                            {[`To comply with our legal and regulatory obligations AND/OR For our legitimate interests, i.e. to minimise fraud that could be damaging for you and/or us`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                          </PrivacyCells>

                          <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                        {[`To enforce legal rights or defend or undertake legal proceedings`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                          </PrivacyCells>
                          <PrivacyCells >
                            {[`Depending on the circumstances: —to comply with our legal and regulatory obligations —in other cases, for our legitimate interests, ie to protect our business, interests and rights`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ borderBottomWidth: 0 ,marginTop: layoutType == 'phone' ? 15 : 0 }}>
                            {[`Customise our website and its content to your particular preferences based on a record of your selected preferences or on your use of our Website`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`Depending on the circumstances:
                             —to comply with your consent as gathered e.g. by the separate cookies tool on our Website —see ‘Cookies and other tracking technologies’ below 
                            —where we are not required to obtain your consent and do not do so, for our legitimate interests, i.e. to be as efficient as we can so we can deliver the best service to you at the best price If you decide to withdraw your consent e.g. by changing your cookie preferences, this may adversely affect your ability to use our Website and our ability to provide products and services to you. Please note that any changes to your cookie preferences will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn.`
                          ].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Retaining and evaluating information on your recent visits to our Website and how you move around different sections of our Website for analytics purposes to understand how people use our Website so that we can make it more intuitive or to check our Website is working as intended.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`Depending on the circumstances: —to comply with your consent as gathered e.g. by the separate cookies tool on our website —see ‘Cookies and other tracking technologies below —where we are not required to obtain your consent and do not do so, for our legitimate interests, i.e. to be as efficient as we can so we can deliver the best service to you at the best price If you decide to withdraw your consent e.g. by changing your cookie preferences, this may adversely affect your ability to use our Website and our ability to provide products and services to you. Please note that any changes to your cookie preferences will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Provide third party social login solutions, enabling you to login using accounts created by you on other services such as Google or Facebook, making account creation or login a one-click process. This enables you to skip the sign-up form, where personal details need to be filled out, importing them directly from the third-party service, with your authorization.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`—to make account creation and login more accessible to our users If you decide to withdraw your consent e.g. by changing your cookie preferences, this may adversely affect your ability to use our Website and our ability to provide products and services to you. Please note that any changes to your cookie preferences will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Provide Payment functionalities, enabling you to purchase products presented on our Website,using the latest technologies and supporting secure payments with different methods (Credit card, Apple Pay, Google Pay)`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`—To provide payment solution for our products If you decide to withdraw your consent e.g. by changing your cookie preferences, this may adversely affect your ability to use our Website and our ability to provide products and services to you. Please note that any changes to your cookie preferences will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdrawn.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Communications with you not related to marketing, including about changes to our terms or policies or changes to our products 
                            AND/OR 
                            services or other important notices`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`Depending on the circumstances: —to comply with our legal and regulatory obligations —in other cases, for our legitimate interests, i.e. to be as efficient as we can so we can deliver the best service to you at the best price`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Protecting the security of systems and data used to provide the services`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`To comply with our legal and regulatory obligations We may also use your personal data to ensure the security of systems and data to a standard that goes beyond our legal obligations, and in those cases our reasons are for our legitimate interests, i.e. to protect systems and data and to prevent and detect criminal activity that could be damaging for you and/or us`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Statistical analysis to help us understand our customer base`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`For our legitimate interests, i.e. to be as efficient as we can so we can deliver the best service to you at the best price`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Updating and enhancing customer records`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`Depending on the circumstances: —to perform our contract with you or to take steps at your request before entering into a contract —to comply with our legal and regulatory obligations —where neither of the above apply, for our legitimate interests, e.g. making sure that we can keep in touch with our customers about existing orders and new products`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ borderBottomWidth: 0 ,marginTop: layoutType == 'phone' ? 15 : 0 }}>
                            {[`Disclosures and other activities necessary to comply with legal and regulatory obligations that apply to our business, e.g. to record and demonstrate evidence of your consents where relevan`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells >
                            {[`To comply with our legal and regulatory obligations`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Marketing our services to existing and former customers`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells>
                            {[`For our legitimate interests, i.e. to promote our business to existing and former customers See ‘Marketing’ below for further information`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{marginTop: layoutType == 'phone' ? 15 : 0 }}>
                            {[`To share your personal data with members of our group and third parties that will or may take control or ownership of some or all of our business (and professional advisors acting on our or their behalf) in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale, initial public offering or in the event of our insolvency In such cases information will be anonymised where possible and only shared where necessary`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>
                            <PrivacyCells style={{ }}>
                            {[`Depending on the circumstances: —to comply with our legal and regulatory obligations —in other cases, for our legitimate interests, ie to protect, realise or grow the value in our business and assets`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                            </PrivacyCells>


                        </CookieTable>


                        <Text style={{ fontWeight: 'bold', marginTop: 20  }}>How and why we use your personal data—sharing</Text>
                        <Text style={{fontSize: 14, marginTop: 20 }}>See ‘<Text style={{ fontWeight: 'bold', marginTop: 20  }}>Who we share your personal data with</Text>’ for further information on the steps we will take to protect your personal data where we need to share it with others.</Text>
                        
                        
                        <Text style={{ fontWeight: 'bold', marginTop: 20  }}>Marketing</Text>
                        <Text style={{  fontSize: 14, marginTop: 20 }}>We will use your personal data to send you updates (by email, text message, telephone or post) about our products and services, including exclusive offers, promotions or new products and services.{'\n'}

We have a legitimate interest in using your personal data for marketing purposes (see above ‘<Text style={{ fontWeight: 'bold', marginTop: 20  }}>How and why we use your personal data</Text>’). This means we do not need your consent to send you marketing information. If we change our marketing approach in the future so that consent is needed, we will ask for this separately and clearly.</Text>

{[`You have the right to opt out of receiving marketing communications at any time by:

 contacting us at info@Hubita.co.uk; and/or

 using the ‘unsubscribe’ link in emails

We may ask you to confirm or update your marketing preferences if you ask us to provide further products AND/OR services in the future, or if there are changes in the law, regulation, or the structure of our business.

We will always treat your personal data with the utmost respect and will never sell OR share it with other organisations for marketing purposes unless your consent has been provided.

For more information on your right to object at any time to your personal data being used for marketing purposes, see ‘Your rights’ below.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


<Text style={{ fontWeight: 'bold', marginTop: 20  }}>Who we share your personal data with</Text>
{[`We routinely share personal data with:

 third parties we use to help deliver our products AND/OR services to you e.g. FCC Paragon, Inventorybase and Lexis Nexis

 other third parties we use to help us run our business, e.g. marketing agencies, providers of website hosting, data services and website analytics.

We only allow those organisations to handle your personal data if we are satisfied they take appropriate measures to protect your personal data. We also impose contractual obligations on them to ensure they can only use your personal data to provide services to us and to you.

We or the third parties mentioned above occasionally also share personal data with:

 our and their external auditors, eg in relation to the audit of our or their accounts, in which case the recipient of the information will be bound by confidentiality obligations

 our and their professional advisors (such as lawyers and other advisors), in which case the recipient of the information will be bound by confidentiality obligations

 law enforcement agencies, courts, tribunals and regulatory bodies to comply with our legal and regulatory obligations

 other parties that have or may acquire control or ownership of our business (and our or their professional advisers) in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale, initial public offering or in the event of our insolvency—usually, information will be anonymised but this may not always be possible. The recipient of any of your personal data will be bound by confidentiality obligations`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Who we share your personal data with—further information</Text>
<Text style={{  fontSize: 14, marginTop: 20 }}>If you would like more information about who we share our data with and why, please contact us (see ‘<Text style={{ fontWeight: 'bold' }}>How to contact us</Text>’ below).</Text>
<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How long your personal data will be kept</Text>
{[`We will not keep your personal data for longer than we need it for the purpose for which it is used.

Different retention periods apply for different types of personal data.

If you stop using your account, we will delete or anonymise your account data after seven years.

Following the end of the of the relevant retention period, we will delete or anonymise your personal data.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Transferring your personal data out of the UK</Text>
<Text style={{  fontSize: 14, marginTop: 20 }}>In order to provide support for our IT systems, it is necessary for us to transfer your personal data to colleagues based in Portugal. In doing so, we will comply with applicable UK laws designed to ensure the privacy of your personal data. Both the UK Government and EU Commission have decided that adequate safeguards are in place for personal data in such circumstances (per Articles 45 of the UK and EU GDPR’s).{'\n'}

Any changes to the destinations to which we send personal data or in the transfer mechanisms we rely on to transfer personal data internationally will be notified to you in accordance with the section on ‘Changes to this privacy policy’ below.{'\n'}

If you would like further information about data transferred outside the UK please contact us OR our Data Protection Officer (see ‘How to contact us’ below).</Text>

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Cookies and other tracking technologies</Text>

{[`A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you use our Website. We use cookies and local storage on our Website. These help us recognise you and your device and store some information about your preferences or past actions.

For further information on cookies and local storage when we will request your consent before placing them and how to disable them, please see our Cookie Policy.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Your rights</Text>
<Text style={{  fontSize: 14, marginTop: 20 }}>You generally have the following rights, which you can usually exercise free of charge:</Text>
 
<CookieTable style={{ marginTop: 20 }} >
                    <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Access to a copy of your personal data`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells>
                            {[`The right to be provided with a copy of your personal data`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Correction (also known as rectification)`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`The right to require us to correct any mistakes in your personal data`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Erasure (also known as the right to be forgotten)`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`The right to require us to delete your personal data—in certain situations`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Restriction of use`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`The right to require us to restrict use of your personal data in certain circumstances, eg if you contest the accuracy of the data`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Data portability`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`The right to receive the personal data you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party—in certain situations`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`To object to use`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells>
                            {[`The right to object:
                            —at any time to your personal data being used for direct marketing (including profiling)
                            —in certain other situations to our continued use of your personal data, eg where we use your personal data for our legitimate interests unless there are compelling legitimate grounds for the processing to continue or the processing is required for the establishment, exercise or defence of legal claims`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}}>
                            {[`Not to be subject to decisions without human involvement`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you We do not make any such decisions based on data collected by our website`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells style={{ marginTop: layoutType == 'phone' ? 15 : 0}} >
                            {[`The right to withdraw consents`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                     <PrivacyCells >
                            {[`If you have provided us with a consent to use your personal data you have a right to withdraw that consent easily at any time You may withdraw consents by changing your cookie preferences or contacting us directly (please see How to contact us below) Withdrawing a consent will not affect the lawfulness of our use of your personal data in reliance on that consent before it was withdraw`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
                     </PrivacyCells>

                    


</CookieTable>


<Text style={{  fontSize: 14, marginTop: 20 }}>For further information on each of those rights, including the circumstances in which they do and do not apply, please contact us (see ‘<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How to contact us</Text>’ below). You may also find it helpful to refer to the guidance from the UK’s Information Commissioner on your rights under the UK GDPR</Text>
<Text style={{  fontSize: 14, marginTop: 20 }}>f you would like to exercise any of those rights, please email us—see below: ‘<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How to contact us</Text>’. When contacting us please:</Text>
 {[` provide enough information to identify yourself (e.g. your full name, address and tlyfe reference number) and any additional identity information we may reasonably request from you, and

 let us know which right(s) you want to exercise and the information to which your request relates`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
 

 <Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Keeping your personal data secure</Text>
{[`We have appropriate security measures to prevent personal data from being accidentally lost, or used or accessed unlawfully. We limit access to your personal data to those who have a genuine need to access it.

We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


<Text style={{ fontSize: 14, marginTop: 20, marginLeft: 5, fontWeight: '400' }}>
  If you want detailed information from Get Safe Online on how to protect your personal data and other information and your computers and devices against fraud, identity theft, viruses, and many other online problems, please visit{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="http://www.getsafeonline.org" target="_blank" rel="noopener noreferrer">
      www.getsafeonline.org
    </a>
  </Text>
  {'. Get Safe Online is supported by HM Government and leading businesses.'}
</Text>


<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How to complain</Text>
<Text style={{  fontSize: 14, marginTop: 20 }}>Please contact us if you have any queries or concerns about our use of your personal data (see below ‘<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How to contact us</Text>’). We hope we will be able to resolve any issues you may have.</Text>
<Text style={{ fontSize: 14, marginTop: 20, marginLeft: 5, fontWeight: '400' }}>
  You also have the right to lodge a complaint with the Information Commissioner who may be contacted using the details at{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="https://ico.org.uk/make-a-complaint" target="_blank" rel="noopener noreferrer">
      https://ico.org.uk/make-a-complaint
    </a>
  </Text>
  {' or by telephone: 0303 123 1113.'}
</Text>

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Changes to this Privacy Policy</Text>
{[`We keep this privacy policy under regular review. We encourage you to review this page regularly to identify any updates or changes to our privacy policy.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>How to contact us</Text>
<Text style={{ fontSize: 14, marginTop: 20, marginLeft: 5, fontWeight: '400' }}>
  You can contact us by emailing{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="mailto:info@hubita.co.uk">info@hubita.co.uk</a>
  </Text>
  {' if you have any questions about this privacy policy or the information we hold about you, to exercise a right under data protection law, or to make a complaint.'}
</Text>

<Text style={{ fontWeight: 'bold', marginTop: 20 ,fontSize: 14, }}>Do you need extra help?</Text>
<Text style={{ fontSize: 14, marginTop: 20, marginLeft: 5, fontWeight: '400' }}>
  If you would like this policy in another format (for example audio, large print, braille) please contact us by email at{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="mailto:info@hubita.co.uk">info@hubita.co.uk</a>
  </Text>
  {'.'}
</Text>

       {/* <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Basic personal data to identify you such as your
          first name, maiden name, last name, username or similar identifier, marital
          status, title, date of birth;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Your contact information including your email
          address, address and telephone numbers;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Financial information – including bank account
          details, card payment details and transactional information and history;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Products and services provided to you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Online information and online activity based on
          your interaction with us, our websites and applications for example your
          internet protocol (IP) address, your login data, browser type and version, time
          zone setting and location, browser plug-in types, searches, site visits and
          versions, operating system and platform, and other technology on the devices
          you use to access this website;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Images and personal appearance such as copies of
          your passport or driver’s licence or CCTV images;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Profile Data which may include your username and
          password, purchases or orders made by you, your interests, preferences,
          feedback and survey responses; and
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Usage Data including statistical data including
          information about how you use our website, products and services.
        </BulletInlineText> */}
{/* 
        <SectionText>
          Marketing and Communications Data includes
          your preferences in receiving marketing from us and our third parties and your
          communication preferences.
        </SectionText>

        <SectionText>
          It is important that the personal data we
          hold about you is accurate and current. Please keep us informed if your
          personal data changes during your relationship with us.
        </SectionText>


        <SectionTitle>2. How we collect personal data?</SectionTitle>

        <SectionText>
          THE COMPANY collects this information in a
          variety of ways. For example:
        </SectionText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          data is collected through application forms, CVs
          or biographies;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          obtained from your passport or other identity
          documents;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          from forms completed by you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          from correspondence with you;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          or through calls, meetings or other types of
          exchange;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          while providing customer support, consultation,
          live chat, or other interaction;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          through the process of maintaining and upgrading
          our services; through means such as communications protocols and e-mail
          communications;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          through our mobile applications;
        </BulletInlineText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          when information is provided to us from third
          parties authorised under applicable law to initiate transactions or perform
          account-related services on your behalf, such as account information service
          providers or payment initiation service providers.
        </BulletInlineText>

        <SectionTitle>3. Why does THE COMPANY process personal data?</SectionTitle>

        <SectionText>
          THE COMPANY needs to use your personal
          information to perform its contract with you or provide our services to you. To
          conduct a payment or money transfer, or to enter into a foreign exchange
          derivative contract with us and thus enable you to become a client, you must
          provide certain information required by us to execute the transaction and to
          enable us to comply with our legal obligations associated with providing our
          services. Failure to provide some of your personal information may affect our
          ability to provide our services to you. We will inform you when information is
          required in order to use our services.
        </SectionText>

        <SectionText>
          We use the information you provide and that
          we collect from other people for the following legal bases and legitimate
          business purposes:
        </SectionText>


        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Providing our services to you:
          </Text>
          This
          includes using information necessary performing contracts or for taking steps
          to enter into a contract with you, including conducting money transfers,
          payment services, or delivering other products or services.
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Legal and compliance purposes:
          </Text>
          This
          includes using your personal information to comply with legal and regulatory
          duties, such as anti-money laundering and counter-terrorist financing,
          detection, prevention and prosecution of fraud and theft as well as preventing
          illegitimate or prohibited use of our services. This may also include
          establishing, exercising, or defending legal rights and claims, monitoring and reporting
          compliance issues, or responding to legal process. Additionally, this includes
          using your details to validate and authenticate your identity and using third
          parties to help us do this, as well as retaining records containing your
          personal information as required under applicable law.
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          <Text style={{ fontWeight: "bold", marginBottom: 5 }}>
            Legitimate business purposes:
          </Text>
          We
          use your personal information to analyse and improve our products, services,
          operations, the running of our websites and your customer experience. We also
          use your personal information to provide customer service, to conduct other
          administrative tasks necessary to provide our services, to help manage risks
          related to security, fraud and identity, and to protect the security and
          integrity of our technology, systems, and services. We conduct analysis of the
          information we hold to better understand our customers. We may also conduct
          credit checks on you and your business using a licensed credit reference
          agency.
        </BulletInlineText> */}

{/* 
        <SectionTitle>4. How and why we share your personal data</SectionTitle>

        <SectionText>
          The Organisation may from time to time
          share your personal data with the following organisations who are also required
          to keep your information confidential, safe and secure:
        </SectionText>

        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Third parties, commercial partners, agents,
          professionals and subcontractors who provide products, services and
          administrative support to the Company;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Third parties or professionals who may be
          engaged on your behalf;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Where we are required by law and law enforcement
          agencies, judicial bodies, credit reference agencies, fraud prevention
          agencies, governmental entities, tax authorities or regulatory bodies around
          the world;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Where required as part of any proposed sale,
          reorganisation, transfer, financial arrangement, asset disposal or other
          transaction relating to our business and/or business assets;
        </BulletInlineText>
        <BulletInlineText>
          <Bullet>{"\u2022"}</Bullet>
          Anyone else with your permission.
        </BulletInlineText>


        <SectionTitle>5. Transferring personal data overseas</SectionTitle>

        <SectionText>
          From time to time we may share your
          personal data with organisations in other countries including organisations
          within the Hubita network.
        </SectionText>

        <SectionText>
          Additionally, when you send or receive
          money to or from another country, we may send some of your personal information
          to country as required or permitted by law. The privacy law in countries to
          which personal information is transferred may not provide the same level of data protection as is in force within the
          EEA. If this is the case, personal information transferred outside the EEA will
          be protected by EU-approved mechanisms for transferring data internationally,
          including appropriate contractual clauses, as required by law.
        </SectionText>


        <SectionTitle>6. How does THE COMPANY protect data?</SectionTitle>

        <SectionText>
          THE COMPANY takes the security of your data
          seriously. THE COMPANY has internal policies and controls in place to ensure
          that your data is not lost, accidentally destroyed, misused or disclosed, and
          is not accessed except by its employees in the performance of their duties.
        </SectionText>

        <SectionText>
          Where THE COMPANY engages third parties to
          process personal data on its behalf, they do so on the basis of written
          instructions, are under a duty of confidentiality and are obliged to implement
          appropriate technical and organisational measures to ensure the security of data.
        </SectionText>



        <SectionTitle>7. For how long does THE COMPANY keep data?</SectionTitle>

        <SectionText>
          We will not retain your personal data
          longer than is necessary. The retention periods for which THE COMPANY will hold
          your personal data will vary depending on its category including but not
          limited to the nature and type of record, the nature of the activity, the
          product or service and any applicable legal or regulatory requirements. Your
          personal information will be retained in accordance with statutory periods
          contained in regulations applicable to financial transactions, including those
          in anti-money laundering, anti-terrorist financing and other laws applicable to
          us. For the most part, your personal data will be retained for no longer than
          seven years after the end of our relationship, unless there is an overarching
          regulatory reason for holding onto it for longer.
        </SectionText>

        <SectionTitle>8. Purposes for which we will use your
          personal data</SectionTitle>

        <SectionText>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases we rely on
          to do so. We have also identified what our legitimate interests are where
          appropriate.
        </SectionText>

        <SectionText>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your data.
          Please contact us if you need details about the specific legal ground we are
          relying on to process your personal data where more than one ground has been
          set out in the table below.
        </SectionText>

        <SectionTitle>9. Your rights</SectionTitle>

        <SectionText>
          You have the right to know if we are
          processing your personal information, and to ask us for a copy of your
          information free of charge. You have the right to request a structured and
          machine-readable copy of certain information you have provided to us. We may
          charge you a reasonable fee for providing the information, or not act upon your
          request, if the request is manifestly unfounded or excessive. You have the
          right to stop us sending you marketing communications. You have the right to
          ask us to correct information about you that is incomplete, inaccurate or
          out-of-date. You have the right to ask us to erase certain information about
          you, to restrict certain uses of your information, and also to object to
          certain uses of it. You also have the right to lodge a complaint with a
          supervisory authority about our processing of personal information. When we
          receive a request, we may ask for additional information from you to verify
          your identity.
        </SectionText>

        <SectionText>
          To exercise these rights, please contact
          our data protection officer (DPO) at Hubita through the following channels:
        </SectionText>

        <SectionText>
          Data Protection
          Officer - enquiry@Hubita.co.uk
        </SectionText>

        <SectionTitle>10. Links to other websites</SectionTitle>

        <SectionText>
          Within our website we may have links to
          third party websites, plug-ins and applications. Clicking those links may
          enable third parties to share or collect your personal data. Hubita do not
          control such third-party websites and are not responsible for their privacy
          statements or the contents of those websites. We would encourage you to read
          the privacy policy of every website you visit.
        </SectionText>

        <SectionTitle>11. Changes to the privacy notice</SectionTitle>

        <SectionText>
          We keep our privacy notice under regular
          review. Any changes to our privacy notice in the future will be posted on this
          page. We encourage you to review this page regularly to identify any updates or
          changes to our privacy notice.
        </SectionText> */}
    
    </View>
  )
}
export default Privacy;