import { LinearGradient } from 'expo-linear-gradient';
import {Text} from "react-native"
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Container,LeftSection,RightSection,FormContainer,TouchableWrapper,ImageWrapper,ImageLogoWrapper , TopSection,GradientOverlay;
if(layoutType=="web"){
Container = styled.View`
flex:1;
flex-direction:row;
`;
TouchableWrapper=styled.TouchableOpacity`
`;
GradientOverlay = styled(LinearGradient)`
  position: absolute;
  width:100%;
  height:100%;
`;
FormContainer = styled.View`
width:60%;
margin-right:10%;
`;
LeftSection = styled.View`
flex:0.5;
background-color:white;
justify-content:center;
align-items:center;
`;
RightSection = styled.View`
flex:0.5;
background-color:rgb(242,245,250);
justify-content:center;
align-items:center;
`;
ImageWrapper=styled.Image`
width:100%;
height:100%;
`;
ImageLogoWrapper= styled.Image`
height: 31px;
width: 137px;
margin-top: 5%;
resize-mode: contain;
`;
TopSection = styled.View`
position:absolute;
top:5%;
flex-direction:row;
right:15%;
align-items:center;
`;
}
else if(layoutType=="phone"){
Container = styled.View`
flex:1;
`;
GradientOverlay = styled(LinearGradient)`
  position: absolute;
  width:100%;
  height:100%;
`;
LeftSection = styled.View`
flex:0.65;
justify-content:center;
background-color:white;
align-items:center;
`;
RightSection = styled.View`
flex:0.35;
background-color:rgb(242,245,250);
justify-content:center;
align-items:center;
display:none;
`;
TouchableWrapper=styled.TouchableOpacity`
`;
FormContainer = styled.View`
width:80%;
`;
ImageWrapper=styled.Image`
width:100%;
height:60%;
resize-mode:contain;
`;
ImageLogoWrapper= styled.Image`
height: 73px;
width: 97px;
resize-mode: contain;
`;
TopSection = styled.View`
position:absolute;
top:5%;
flex-direction:row;
right:15%;
align-items:center;
`;
}
else {
    Container = styled.View`
    flex:1;
    flex-direction:row;
    `;
    GradientOverlay = styled(LinearGradient)`
  position: absolute;
  width:100%;
  height:100%;
`;
    TouchableWrapper=styled.TouchableOpacity`
    `;
    FormContainer = styled.View`
    width:70%;
    `;
    LeftSection = styled.View`
    flex:0.5;
    background-color:white;
    justify-content:center;
    align-items:center;
    `;
    RightSection = styled.View`
    flex:0.5;
    background-color:rgb(242,245,250);
    justify-content:center;
    align-items:center;
    `;
    ImageWrapper=styled.Image`
    width:100%;
    height:100%;
    resize-mode:cover;
    `;
    ImageLogoWrapper= styled.Image`
    height: 31px;
    width: 137px;
    resize-mode: contain;
`;
    TopSection = styled.View`
    position:absolute;
    top:5%;
    flex-direction:row;
    right:10%;
    align-items:center;
    `;
 }
export {Container,LeftSection,RightSection,FormContainer,TouchableWrapper,ImageWrapper,ImageLogoWrapper, TopSection,GradientOverlay};
