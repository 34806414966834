import React, { useState } from 'react';
import { View, FlatList } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import {
  BorderColor,
  BoxWrapper,
  HeaderWrapper,
  ToolTipsWrapper,
  RowWrapper,
  ParentWrapper,
  ViewFlex,
  RowCardBlock,
} from './News.style';
import { ScrollView } from 'react-native-gesture-handler';
import TooltipsAndNews from '../../components/tooltipsAndNews';

var layoutType = getPlatformType();

const DATA = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    tenants: 'First Item',
    date: '12/12/2021',
    property: 'testing',
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    tenants: 'First Item',
    date: '12/12/2021',
    property: 'testing',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e29d72',
    tenants: 'First Item',
    date: '12/12/2021',
    property: 'testing',
  },
];

const TableRowTitle = ({ row1, row2, row3 }) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'row',
      borderBottomColor: '#c7c4bf',
      borderBottomWidth: 0.5,
      paddingVertical: 10,
    }}
  >
    <View style={{ flex: 1.5, flexDirection: 'row' }}>
      <FontAwesome name="user" size={22} color={'#6612B6'} />
      <Text marginLeft={10} fontSize={14}>
        {row1}
      </Text>
    </View>
    <View style={{ flex: 1.5 }}>
      <Text fontSize={14}>{row2}</Text>
    </View>
    <View style={{ flex: 1.5 }}>
      <Text fontSize={14}>{row3}</Text>
    </View>
    <View style={{ flex: 0.2 }}>
      <MaterialIcons name="keyboard-arrow-right" size={24} color="#6612B6" />
    </View>
  </View>
);

const News = ({ navigation }) => {
  const tableTitle = (row1, row2, row3) => (
    <>
      <ViewFlex flex={1.5}>
        <Text bold>{row1}</Text>
      </ViewFlex>
      <ViewFlex flex={1.5}>
        <Text bold>{row2}</Text>
      </ViewFlex>
      <ViewFlex flex={1.5}>
        <Text bold>{row3}</Text>
      </ViewFlex>
      <ViewFlex flex={0.2} />
    </>
  );

  // const NewsItems = () => (
  //   <RowWrapper align="center">
  //     <View style={{ marginRight: 20, marginBottom: 20 }}>
  //       <Text fontSize={15} bold>
  //         Industry insight title description
  //       </Text>
  //       <Text fontSize={14} numberOfLine={1}>
  //         Lorem ipsum dolor sit amet, consectetur adipiscing elit
  //       </Text>
  //     </View>
  //     <MaterialIcons name="keyboard-arrow-right" size={24} color="#6612B6" />
  //   </RowWrapper>
  // );

  const TooltipsItem = (name) => (
    <Box style={{ width: 113 }}>
      <View style={{ marginBottom: 20, alignItems: 'center' }}>
        <MaterialIcons name={name} size={42} color="#6612B6" />
      </View>
      <Text fontSize={14} bold align="center">
        Lorem ipsum dolor
      </Text>
    </Box>
  );

  const renderItem = ({ item }) => <TableRowTitle row1={item.tenants} row2={item.date} row3={item.property} />;

  return (
    <ScrollView>
      {layoutType === 'tab' ? null : <StatusBar />}
      <DrawerMenu navigation={navigation} />
      <ParentWrapper>
        <BoxWrapper>
          <Text bold fontSize={18}>
            News and industry insite
          </Text>
        </BoxWrapper>

        <BoxWrapper>
          <RowCardBlock>
            <View style={{ flex: 0.1, backgroundColor: 'grey',borderRadius:20,justifyContent:"center" }}>
              <View style={{ height: 60, width: "100%",borderRadius:20 }}>
              <Text bold fontSize={18}></Text>
              </View>
            </View>
            <View style={{ flex: 0.9,marginLeft:10 }}>
              <View style={{ flex:1,flexDirection:"row",justifyContent:"space-between" }}>
                <Text  bold fontSize={18}>Industry insight title 
description</Text>
                <Text bold fontSize={18}> DD/MM/YY</Text>
              </View>
              <View style={{ flex:1,flexDirection:"row",}}>
              <Text  fontSize={18}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
et dolore magna aliqua. Ut enim ad minim veniam</Text>
                
              </View>
            </View>
          </RowCardBlock>
          <View style={{}}>
   
          </View>
        </BoxWrapper>
        <TooltipsAndNews />
        {/* <BoxWrapper>
          <Text bold fontSize={18}>
         News and industry insite
          </Text>
        </BoxWrapper>
        <ToolTipsWrapper noShadow>
          <FontAwesome name="bell-o" size={22} color="#00BED8" />
          <Text color="#00BED8" marginLeft={10} bold>
            configurability for several features, such as auto-correction, auto-capitalization,
          </Text>
        </ToolTipsWrapper>
        <Box>
          <RowWrapper>{tableTitle('Tenant', 'Property', 'Tenants End Date')}</RowWrapper>
          <FlatList data={DATA} keyExtractor={(item) => item.id} renderItem={renderItem} />
        </Box>
       */}
      </ParentWrapper>
 
    </ScrollView>
  );
};

export default News;
