import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';

const ModalHeader = styled.h2`
  font-size: 30px;
  font-weight: 500;
  background: linear-gradient(to right, #365ff2 0%, #7002bc 40%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  font-family: 'lato';
`;
const ModalBody = styled.div`
  border-radius: 5px;
  background: #fff;
  padding: 30px;
  padding-top: 10px;
`;

export { ModalHeader, ModalBody };
