import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
import Text from '../../components/text';
import SearchCardComponent from '../../components/searchCard';

let layoutType = getPlatformType();
let ContainerScrollView, Container, TextInputWrapper, SearchComponent, Row, RowCol, Section, Left, HeaderView, WebText, SearchButtonContainer;
let HorizontalLine = styled.View`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-vertical: 30px;
`;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  SearchComponent = styled(SearchCardComponent)`
  flex-grow: 2;
  margin-right: 20px;
  `;
  WebText = styled(Text)`
    display: none;
  `;
  HeaderView = styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  `;
  Container = styled.View`
    width: 65%;
    margin-left: 320px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View``;
  Left = styled.View`
    width: 70%;
    margin-top: 10px;
    margin-right: 25px;
  `;
  SearchButtonContainer = styled.View`
    display: inline-block;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  SearchComponent = styled(SearchCardComponent)`
  flex-grow: 2;
  margin-right: 10px;
  `;
  WebText = styled(Text)`
    font-size: 13px;
    font-weight: 500px;
    margin-top: 10px;
  `;
  HeaderView = styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    display: none;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '100%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View``;
  Left = styled.View`
    width: 100%;
    margin-top: 10px;
  `;
  SearchButtonContainer = styled.View`
    display: inline-flex;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  SearchComponent = styled(SearchCardComponent)`
  flex-grow: 2;
  margin-right: 20px;
  `;
  WebText = styled(Text)`
    display: none;
  `;
  HeaderView = styled.View`
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
  `;
  Container = styled.View`
    width: 85%;
    margin-left: 90px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  Section = styled.View``;
  Left = styled.View`
    width: 70%;
    margin-top: 10px;
    margin-right: 15px;
  `;
  SearchButtonContainer = styled.View`
    display: inline-block;
  `;
}
export {
  ContainerScrollView,
  Container,
  HorizontalLine,
  Row,
  TextInputWrapper,
  RowCol,
  Section,
  Left,
  HeaderView,
  WebText,
  SearchComponent,
  SearchButtonContainer
};
