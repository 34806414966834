import React from 'react';
import { CardBox } from '../card/index.js';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Heading } from '../../screens/style.js';

const Accordion = (props) => {
  const { title, children } = props;

  const [show, setShow] = React.useState(true);
  return (
    <div style={{ width: '300px' }}>
      <div
        style={{
          margin: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Heading>{title}</Heading>
        <span style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
          {show ? <ExpandMore /> : <ExpandLess />}
        </span>
      </div>
      {show && children && (
        <CardBox padding={'10px'} hasShadow={true} hasBorderRadius={true}>
          {children}
        </CardBox>
      )}
    </div>
  );
};

export default Accordion;
