import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { navigate } from '../../navigation/RootNavigation';
import { Container, HeaderWrapper, ColoumnWrapper, ListItem, ListSection } from '../propertyTable/index.style';

const layoutType = getPlatformType();

const PropertyArchiveTable = (props) => {
  const { colData, data, rowIcon: RowIcon, onArchived, onDelete } = props;

  const _renderItem = (item, index) => {
    return (
      <ListItem
        key={index}
        style={{
          flexDirection: 'row',
          borderBottomWidth: data.length == index + 1 ? 0 : 1,
          paddingVertical: 10,
          borderColor: '#ebebeb',
        }}
      >
        <ListSection style={{ flexDirection: 'row' }} width={layoutType == 'phone' ? '80%' : '30%'}>
          {RowIcon ? RowIcon : <MaterialIcons name="home" color={Colors.light.purple} size={20} />}
          <Text fontSize={15} marginLeft={10}>
            {item.name}
          </Text>
        </ListSection>
        {layoutType == 'phone' ? (
          <ListSection width={'20%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <MaterialCommunityIcons
              name={'archive-arrow-up-outline'}
              color={Colors.light.purple}
              size={20}
              style={{ marginRight: 20 }}
              onPress={() => onArchived(item.id)}
            />
            <MaterialIcons name={'delete'} color={Colors.light.purple} size={20} onPress={() => onDelete(item.id)} />
          </ListSection>
        ) : null}
        <ListSection width={layoutType == 'phone' ? '50%' : '20%'}>
          <Text fontSize={15} marginLeft={0}>
            {item.postcode}
          </Text>
        </ListSection>
        <ListSection width={layoutType == 'phone' ? '50%' : '20%'}>
          <Text fontSize={15} marginLeft={0}>
            {item?.percentage ? Math.round(item?.percentage) + '%' : 0 + '%'}
          </Text>
        </ListSection>
        {layoutType != 'phone' ? (
          <ListSection width={'20%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <MaterialCommunityIcons
              name={'archive-arrow-up-outline'}
              onPress={() => onArchived(item.id)}
              color={Colors.light.purple}
              size={20}
              style={{ marginRight: 70 }}
            />
            <MaterialIcons name={'delete'} color={Colors.light.purple} size={20} onPress={() => onDelete(item.id)} />
          </ListSection>
        ) : null}
      </ListItem>
    );
  };
  const _renderHeader = () => {
    return (
      <HeaderWrapper>
        {colData.map((e) => (
          <View key={e} style={{ width: e === 'Location' ? '20%' : '30%' }}>
            <Text fontWeight={500} fontSize={15}>
              {e}
            </Text>
          </View>
        ))}
      </HeaderWrapper>
    );
  };

  return (
    <Container>
      {layoutType != 'phone' ? _renderHeader() : null}
      {data?.length ? (
        <FlatList
          data={data}
          renderItem={({ item, index }) => _renderItem(item, index)}
          keyExtractor={(item, index) => item.id}
        />
      ) : (
        <Text fontSize={15} style={{ marginTop: '10px', alignSelf: 'center' }}>
          {'No property available'}
        </Text>
      )}
    </Container>
  );
};
PropertyArchiveTable.propTypes = {
  leftText: PropTypes.string,
  onChangeText: PropTypes.func,
  onItemClick: PropTypes.func,
};

PropertyArchiveTable.defaultProps = {};

export default PropertyArchiveTable;
