import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import Box from '../../components/box';
import Text from '../../components/text';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol } from './index.style';
import Button from '../../components/button/Button';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import Sofa from '../../assets/svgIcons/property/sofa.svg';
import doubleBed from '../../assets/svgIcons/property/doubleBed.svg';
import Toilet from '../../assets/svgIcons/property/waterCloset.svg';
import ItemCounter from '../../components/itemCounter';
import DropDownPicker from '../../components/picker/DropDownPicker';
import useUtilsProvider from '../../hooks/useUtilsProvider';
import Urls from '../../services/Urls';
import { DashedContainer } from '../../components/box/Box.style';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import ajax from '../../helpers/ajaxHelper';
import { Ionicons } from '@expo/vector-icons';
import { useRoute } from '@react-navigation/native';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Colors from '../../constants/Colors';
import Knob from '../../components/knob';
import moment from 'moment';
import ListOverView from '../../components/listOverView';
import { MarginTop } from '../tenants/tenantOnboarding/screens/style';
import { useIsFocused } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { showErrorToast } from '../tenants/tenantOnboarding/helpers';

var layoutType = getPlatformType();

export const testingDetectorDate = (testDate) => {
  if (testDate) {
    const addOneYear = moment(testDate).add(1, 'y');
    return addOneYear > moment() ? 'GreenTick' : 'Cross';
  }
};

const getCertificate = (name, cer) => {
  let certificateObj;
  if (cer?.eicr_certificate && cer?.eicr_certificate?.expiry_date) {
    const expiryDate = cer?.eicr_certificate?.expiry_date;
    if (moment(expiryDate) > moment()) {
      if (cer?.eicr_certificate?.files?.length) {
        certificateObj = {
          eicr: 'GreenTick',
        };
      } else {
        certificateObj = {
          eicr: 'OrangeTick',
        };
      }
    } else {
      certificateObj = {
        eicr: 'RedCross',
      };
    }
  } else {
    certificateObj = {
      eicr: 'RedCross',
    };
  }
  
  if (cer?.epc_certificate && cer?.epc_certificate?.expiry_date) {
    const expiryDate = cer?.epc_certificate?.expiry_date;
    if (moment(expiryDate) > moment()) {
      if (cer?.epc_certificate?.files?.length) {
        certificateObj = {
          ...certificateObj,
          epc: 'GreenTick',
        };
      } else {
        certificateObj = {
          ...certificateObj,
          epc: 'OrangeTick',
        };
      }
    } else {
      certificateObj = {
        ...certificateObj,
        epc: 'RedCross',
      };
    }
  } else {
    certificateObj = {
      ...certificateObj,
      epc: 'RedCross',
    };
  }
  if (cer?.gas_certificate && cer?.gas_certificate?.expiry_date) {
    const expiryDate = cer?.gas_certificate?.expiry_date;
    if (moment(expiryDate) > moment()) {
      if (cer?.gas_certificate?.files?.length) {
        certificateObj = {
          ...certificateObj,
          gas: 'GreenTick',
        };
      } else {
        certificateObj = {
          ...certificateObj,
          gas: 'OrangeTick',
        };
      }
    } else {
      certificateObj = {
        ...certificateObj,
        gas: 'RedCross',
      };
    }
  } else {
    certificateObj = {
      ...certificateObj,
      gas: 'RedCross',
    };
  }


  const listItem = [
    { name: name, item_1: certificateObj.gas, item_2: certificateObj.eicr, item_3: certificateObj.epc },
  ];
  console.log("listItem",listItem)
  return listItem;
};

const getLicense = (licenses) => {
  if (licenses?.additional_license?.length > 0) {
    return licenses?.additional_license[0];
  }
  if (licenses?.hmo_license?.length > 0) {
    return licenses?.hmo_license[0];
  }
  if (licenses?.selective_license?.length > 0) {
    return licenses?.selective_license[0];
  }
  return null;
};

const getLicenseAndSmoke = ({
  name,
  licenses,
  is_smoke_detector_available_on_every_floor,
  carbon_monoxide_detector_testing_date,
  smoke_detector_testing_date,
  is_carbon_monoxide_detector_available_in_every_room,
}) => {
  let licenseAndSmoke;
  const license = getLicense(licenses);
  if (license && license?.expiry_date) {
    console.log('data', license?.expiry_date, moment());

    if (moment(license?.expiry_date) > moment()) {
      if (license?.document_url) {
        licenseAndSmoke = {
          licenses: 'GreenTick',
        };
      } else {
        licenseAndSmoke = {
          licenses: 'OrangeTick',
        };
      }
    }
  } else {
    licenseAndSmoke = {
      // licenses: 'Cross', //TODO NA image need to change
    };
  }
  const returnItem = [
    {
      name: name,
      item_1: is_smoke_detector_available_on_every_floor ? testingDetectorDate(smoke_detector_testing_date) : 'Cross',
      item_2: is_carbon_monoxide_detector_available_in_every_room
        ? testingDetectorDate(carbon_monoxide_detector_testing_date)
        : 'Cross',
    },
  ];
  return returnItem;
};

const PropertiesInfoDetails = ({ navigation }) => {
  const isFocused = useIsFocused();
  const route = useRoute();
  const [state, setState] = useState({
    images: [],
    price_worth: '',
    quantity: {},
    finance: {},
    name: '',
    certificates: {},
    licenses: {},
    is_smoke_detector_available_on_every_floor: '',
    is_carbon_monoxide_detector_available_in_every_room: '',
    current_loan_value: '',
    percentage: '',
  });

  const [selectedProviders, setSelectedProviderData] = useState([]);
  const { response } = useUtilsProvider(Urls.GET_PROVIDERS);

  useEffect(() => {
    ajax
      .get(Urls.Properties + '/' + route?.params?.id)
      .then(({ data }) => {
        console.log('data', data);
        if (data.status) {
          setState({
            ...state,
            ...{
              images: data?.payload?.images && data?.payload?.images.length ? data?.payload?.images : [],
              quantity: data?.payload?.quantity,
              description: data?.payload?.description,
              price_worth: data?.payload?.price_worth,
              address_line1: `${data.payload?.address_line1}, ${data.payload?.city}, ${data.payload?.country}`,
              name: data.payload?.name,
              certificates: data.payload?.certificates,
              userCertificates: data.payload?.userCertificates,
              licenses: data.payload?.licenses,
              is_carbon_monoxide_detector_available_in_every_room:
                data.payload?.is_carbon_monoxide_detector_available_in_every_room,
              is_smoke_detector_available_on_every_floor: data.payload?.is_smoke_detector_available_on_every_floor,
              finance: data.payload?.finance || {},
              current_loan_value: data?.payload?.current_loan_value,
              carbon_monoxide_detector_testing_date: data?.payload?.carbon_monoxide_detector_testing_date,
              smoke_detector_testing_date: data?.payload?.smoke_detector_testing_date,
              percentage: data?.payload?.percentage,
              mortgage_discount_rate_ends: data?.payload?.finance?.mortgage_discount_rate_ends,
            },
          });
          if (data?.payload?.providerDetails && data?.payload?.providerDetails?.length) {
            setSelectedProviderData(data?.payload?.providerDetails);
          }
        } else {
          // alert(data.message);
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [isFocused]);
  return (
    <>
      <View style={{ width: layoutType == 'phone' ? '100%' : '70%' }}>
        <ListCard
          leftIcon={'view-list-outline'}
          description={
            'Below is a detailed overview of your property. If you need to edit any items please go back to the property overview page and make the necessary changes to each section.'
          }
          title={'Property Information'}
          showPropertyCard={route.params?.name}
        />
        <Box
          style={{
            flexDirection: layoutType == 'phone' ? 'column' : 'row',
            alignItems: layoutType == 'phone' ? 'flex-start' : 'center',
          }}
        >
          <If condition={layoutType !== 'phone'}>
            <Ionicons name="home-outline" size={80} color={Colors.light.purple} />
          </If>
          <View style={{ marginLeft: 20, flex: 1 }}>
            <Text fontSize={18} bold>
              {state.name}
            </Text>
            <Text fontSize={15} marginTop={20}>
              {state.address_line1}
            </Text>
          </View>
          <View style={{ alignSelf: 'center', marginTop: 20 }}>
            <Knob
              size={90}
              strokeWidth={10}
              progressPercent={state?.percentage ? Math.round(state?.percentage) : 0}
              text={'Complete'}
              textColor={Colors.light.purple}
              pgColor={Colors.light.purple}
            />
          </View>
        </Box>

        <Text bold fontSize={16} marginBottom={20} marginTop={20}>
          Properties Details
        </Text>
        <Box>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
            <View style={{ alignItems: 'center' }}>
              <img src={Sofa} style={{ height: layoutType == 'phone' ? 60 : 80 }} />

              <Text bold fontSize={22}>
                {state.quantity?.Kitchens || 0}
              </Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <img src={Toilet} style={{ height: 80 }} />
              <Text bold fontSize={22}>
                {state.quantity?.Bathrooms || 0}
              </Text>
            </View>
            <View style={{ alignItems: 'center' }}>
              <img src={doubleBed} style={{ height: 80 }} />

              <Text bold fontSize={22}>
                {state.quantity?.Bedrooms || 0}
              </Text>
            </View>
          </View>
        </Box>
      </View>

      <Text fontSize={15} bold marginBottom={20} marginTop={20}>
        Photo
      </Text>

      <Box>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <If condition={state?.images.length > 0}>
            {state?.images.map((data, index) => (
              <View style={{ width: 140, height: 120, marginLeft: 20, borderRadius: 5, marginBottom: 20 }}>
                <Image
                  source={{
                    uri: data.openbrixName,
                  }}
                  style={{ height: '100%', width: '100%', borderRadius: '5%' }}
                />
              </View>
            ))}
            <Else />
            <Text>No Photo Present</Text>
          </If>
        </View>
      </Box>

      <Text fontSize={15} bold marginBottom={20} marginTop={20}>
        Finance
      </Text>
      <Box>
      <TouchableOpacity       onPress={() => {
          navigation.navigate('financeDetails', {
            id: route?.params?.id,
            key: 'finance_details_status',
          });
        }}>
        {layoutType==='phone' && <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' , marginBottom: 20}}>
  <MaterialIcons name="keyboard-arrow-right" size={24} color="rgb(159, 120, 255)" />
</View>
}
</TouchableOpacity>
        <View style={{ width: layoutType == 'phone' ? '100%' : '50%', alignSelf: 'center', marginBottom: 20 }}>
          <TextInputComponent title={'Property Worth'} isAmount onChangeText={(e) => {}} value={state.price_worth} colored={true} />
        </View>
        <View
          style={{
            flexDirection: layoutType == 'phone' ? 'colunm' : 'row',
            justifyContent: 'space-around',
            alignItems:'center',
          }}
        >
          <TextInputComponent
            value={state.finance?.mortgage_outstanding_amount || 0}
            title={'Outstanding Mortgage Amount'}
            onChangeText={(e) => {}}
            colored={true}
            isAmount
          />
          <MarginTop marginTop={20} />
          <TextInputComponent
            value={state.current_loan_value || 'NA'}
            title={'Current Loan to Value %'}
            onChangeText={(e) => {}}
            colored={true}
          />
          <MarginTop marginTop={20} />
          <TouchableOpacity       onPress={() => {
          navigation.navigate('financeDetails', {
            id: route?.params?.id,
            key: 'finance_details_status',
          });
        }}>
    {layoutType!='phone' &&      <MaterialIcons name="keyboard-arrow-right" size={24} color="#rgb(159, 120, 255)" />}
          </TouchableOpacity>
          <MarginTop marginTop={20} />
        </View>
        <View
          style={{
            flexDirection: layoutType == 'phone' ? 'colunm' : 'row',
            justifyContent: 'space-around',
            marginTop: layoutType == 'phone' ? 0 : 20,
          }}
        >
          <TextInputComponent
            value={state.finance?.mortgage_discount_rate || 0}
            title={'Discount Rate %'}
            onChangeText={(e) => {}}
            colored={true}
            disabled={true}
            isNumeric
          />
          <MarginTop marginTop={20} />

          <TextInputComponent
            date={state?.mortgage_discount_rate_ends}
            title={'Discount Rate Ends'}
            onChangeText={(e) => {}}
            dateBackgroundColor= {true}
            isDate
          />
          <MarginTop marginTop={20} />
          <TextInputComponent
            value={state.finance?.mortgage_monthly_amount || 0}
            title={'Mortgage Monthly Amount'}
            onChangeText={(e) => {}}
            colored={true}
            isAmount
          />
        </View>
      </Box>

      <Text fontSize={15} bold marginBottom={20} marginTop={20}>
        Certificate Overview
      </Text>

      <ListOverView
        header1="Property"
        header2="Gas"
        header3="EICR"
        header4="EPC"
        list={getCertificate(state.name, state?.userCertificates)}
        onPress={() => {
          navigation.navigate('Certification', {
            id: route?.params?.id,
            key: 'certificate_details_status',
          });
        }}
      />

      <Text fontSize={15} bold marginBottom={20} marginTop={20}>
        Alarms & Habitation
      </Text> 

      <ListOverView
        list={getLicenseAndSmoke(state)}
        header1="Property"
        header2={`Smoke\nAlarm`}
        header3={`Carbon M\nAlarm`}
        header4={null}
        onPress={() => {
          navigation.navigate('Requirements', {
            id: route?.params?.id,
            key: 'Requirements_screen',
          });
        }}
      />
      <Button
        onPress={() => {
          navigation.goBack();
        }}
        type="stripe"
        childText={'Back'}
        width={150}
        style={{ marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: PropertiesInfoDetails,
  showBackButton: true,
});
