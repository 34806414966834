import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();

export const Container = styled.View`
    flex-direction: row;
`;



