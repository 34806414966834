import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
var layoutType = getPlatformType();

let Container, GradientView, GradientWrapper, NameText, SelectedItem, ItemText, CornerView;
if (layoutType === 'web') {
  Container = styled.View`
    height: 15;
  `;

  GradientView = styled(LinearGradient)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 300;
  `;

  GradientWrapper = styled(LinearGradient)`
    position: absolute;
    left: 142px;
    right: 0;
    top: 0;
    min-height: 634px;
    width: 169px;
    border-radius: 16px;
    padding-left: 20px;
    padding-bottom: 42px;
  `;

  NameText = styled.Text`
    font-size: 20px;
    color: white;
    height: 40px;
    margin-top: 50px;
  `;

  SelectedItem = styled.View`
    width: 100%;
    margin-top: 25px;
    height: 48px;
    background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
  `;
  ItemText = styled.Text`
    font-size: 16px;
    color: ${(props) => (props.selected ? '#6514C7' : 'white')};
    margin-left: 7px;
  `;
  CornerView = styled.View`
    display: none;
  `;
} else if (layoutType === 'tab') {
  GradientView = styled(LinearGradient)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 300;
  `;
  CornerView = styled.View`
    position: absolute;
    left: 60px;
    right: 0;
    top: 0;
    min-height: 100%;
    background-color: #f2f2f2;
    width: 50px;
    padding-left: 20px;
    padding-bottom: 42px;
    border-radius: 20px;
  `;

  GradientWrapper = styled(LinearGradient)`
    position: absolute;
    left: 0px;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100px;
    padding-left: 20px;
    padding-bottom: 42px;
  `;

  NameText = styled.Text`
    font-size: 20px;
    color: white;
    margin-top: 50px;
  `;

  SelectedItem = styled.View`
    width: 100%;
    margin-top: 25px;
    height: 48px;
    background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
  `;
  ItemText = styled.Text`
    font-size: 16px;
    color: ${(props) => (props.selected ? '#6514C7' : 'white')};
    margin-left: 7px;
  `;
} else {
  GradientView = styled(LinearGradient)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 300;
  `;
  CornerView = styled.View`
    display: none;
  `;
  GradientWrapper = styled(LinearGradient)`
    position: absolute;
    left: 0px;
    right: 0;
    top: 0;
    min-height: 100%;
    width: 100%;
    border-radius: 16px;
    padding-left: 20px;
    padding-bottom: 42px;
  `;

  NameText = styled.Text`
    font-size: 20px;
    color: white;
    margin-top: 50px;
  `;

  SelectedItem = styled.View`
    width: 100%;
    margin-top: 25px;
    height: 48px;
    background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;
  `;
  ItemText = styled.Text`
    font-size: 16px;
    color: ${(props) => (props.selected ? '#6514C7' : 'white')};
    margin-left: 7px;
  `;
}

export { Container, GradientView, GradientWrapper, NameText, SelectedItem, ItemText, CornerView };
