import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Main, IconView, DescView;
if (layoutType == 'web') {
  Main = styled.View`
    flex-direction: row;
    width: 100%;
    height: 154px;
    margin-vertical: 20px;
  `;
  IconView = styled.View`
    width: 25%;
    justify-content: center;
    align-items: center;
  `;
  DescView = styled.View`
    flex: 75%;
    justify-content: center;
  `;
} else if (layoutType == 'phone') {
  Main = styled.View`
    flex-direction: row;
    width: 100%;
    height: 179px;
    margin-vertical: 20px;
  `;
  IconView = styled.View`
    width: 25%;
    justify-content: center;
    align-items: center;
    display: none;
  `;
  DescView = styled.View`
    flex: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  `;
} else {
  Main = styled.View`
    flex-direction: row;
    width: 100%;
    height: 154px;
    margin-vertical: 20px;
  `;
  IconView = styled.View`
    width: 25%;
    justify-content: center;
    align-items: center;
  `;
  DescView = styled.View`
    flex: 75%;
    justify-content: center;
  `;
}
export { Main, IconView, DescView };
