import React, { useState } from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { Container, Row, HorizontalLine, TextInputWrapper } from './index.style';
import PropTypes from 'prop-types';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';

const SearchCardComponent = (props) => {
  const { onChangeText, leftText, placehorderText } = props;
  const [searchBox, setSearchBox] = useState(true);

  const closeSearch = () => {
    setSearchBox(true);
    onChangeText();
  };
  return (
    <Container>
      <If condition={searchBox}>
        <>
          <Text fontWeight={'500'} fontSize={layoutType == 'phone' ? 16 : 18}>
            {leftText}
          </Text>
          <Row onPress={() => setSearchBox(false)}>
            <MaterialIcons name={'search'} color={Colors.light.lightPurple} size={20} />
            <Text
              fontWeight={'500'}
              color={Colors.light.lightPurple}
              marginLeft={5}
              fontSize={layoutType == 'phone' ? 14 : 18}
            >
              {placehorderText ? placehorderText : 'Search'}
            </Text>
          </Row>
        </>
      </If>
      <If condition={!searchBox}>
        <>
          <Row width={'95%'}>
            <MaterialIcons name={'search'} color={Colors.light.lightPurple} size={layoutType == 'phone' ? 16 : 20} />
            <TextInputWrapper
              placeholderTextColor={Colors.light.lightPurple}
              placeholder={'Search here'}
              onChangeText={onChangeText}
            />
          </Row>
          <TouchableOpacity style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <MaterialIcons
              onPress={() => closeSearch()}
              name={'close'}
              color={Colors.light.lightPurple}
              size={layoutType == 'phone' ? 16 : 20}
            />
          </TouchableOpacity>
        </>
      </If>
    </Container>
  );
};
SearchCardComponent.propTypes = {
  leftText: PropTypes.string,
  onChangeText: PropTypes.func,
};

SearchCardComponent.defaultProps = {};

export default SearchCardComponent;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
