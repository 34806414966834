import React, { useState } from 'react';
import {Image} from "react-native"
import PropTypes from 'prop-types';
import { Entypo, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';

import Box from '../box';
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { Container, Row, Left } from './index.style';
import StatusButton from '../statusButton/statusButton';

const layoutType = getPlatformType();

const ListCard = (props) => {
  const { leftIcon, title, description, status, statusButton,localUrlImage } = props;
  const getStatus = () => {
    if (status == 0) {
      return { text: 'Not Started', type: 'stripe', color: Colors.light.purple };
    } else if (status == 1) {
      return { text: 'In Progress', type: 'stripe', color: Colors.light.purple };
    } else if (status == 2) {
      return { text: 'Completed', type: 'solid', color: Colors.light.purple };
    } else {
      return { text: 'N/A', type: 'solid', color: 'yellow' };
    }
  };

  return (
    <>
      <Box style={{ marginVertical: 10 }}>
        <Container onPress={() => props?.onPress()}>
          <Row>
            <Left>
              {localUrlImage?
               <Image source={localUrlImage} style={{height:23, width:23}}/>:
               leftIcon?
                <MaterialCommunityIcons name={leftIcon} size={23} color={Colors.light.purple} />:null}
              <Text fontSize={layoutType == 'phone' ? 12 : 16} marginLeft={10} bold color={Colors.light.purple}>
                {title}
              </Text>
              <If condition={statusButton}>
                <MaterialIcons name={'chevron-right'} style={{ marginLeft: 5 }} size={23} color={Colors.light.purple} />
              </If>
            </Left>
            <If condition={statusButton}>
              <Left>
                <StatusButton
                  onPress={() => props?.onPress()}
                  type={getStatus().type}
                  childText={getStatus().text}
                  color={getStatus().color}
                />
              </Left>
            </If>
          </Row>
          <Text fontSize={14} marginTop={10}>
            {description}
          </Text>
        </Container>
      </Box>
      {layoutType == 'web' ? (
        props?.showPropertyCard ? (
          <Box style={{ padding: 0,marginTop:5, maxWidth: 250,alignSelf:'flex-end' }}>
            <Row style={{ paddingVertical: 7, paddingHorizontal: 15 }}>
              <Entypo name="home" size={22} color={Colors.light.lightPurple} />
              <Text fontSize={14} numberOfLines={2} fontWeight={'500'} marginLeft={10}>
                {props?.showPropertyCard}
              </Text>
            </Row>
          </Box>
        ) : null
      ) : null}
    </>
  );
};
ListCard.propTypes = {
  leftIcon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.number,
  statusButton: PropTypes.bool,
  onPress: PropTypes.func,
};

ListCard.defaultProps = {
  leftIcon: 'map-marker',
  onPress: () => {},
  title: 'Title',
  status: 0,
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nod.',
};

export default ListCard;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
