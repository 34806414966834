import LOGIN from '../ActionTypes';

const initialState = {
  userName: '',
  userDetails: '',
};

export function Login(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload,
      };
    case 'USERPROFILE':
      return {
        ...state,
        userDetails: action?.payload,
      };
    default:
      return state;
  }
}
