import React from 'react';
import styled from 'styled-components/native';
import variables from '../variables.json';
import { View } from 'react-native';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Wrapper, HeadingWrapper, SubheadingWrapper } from './style';
import getPlatformType from '../../../../../helpers/helpers';
const ToolTip = (props) => {
  let layoutType = getPlatformType();
  const { heading, ContentData, style } = props;
  return (
    <Wrapper style={layoutType == 'phone' ? {} : { ...style }}>
      <div style={{ flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 0.8 }}>
          <HeadingWrapper>{heading}</HeadingWrapper>
        </div>
        <div style={{ flex: 0.2 }}>
          <ErrorOutlineIcon style={{ color: '#6d08c0' }} />
        </div>
      </div>

      {ContentData && ContentData?.length
        ? ContentData.map((data, index) => <SubheadingWrapper key={index}>{data}</SubheadingWrapper>)
        : null}
    </Wrapper>
  );
};

export default ToolTip;
