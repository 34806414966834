import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../../../../helpers/helpers';
let layoutType = getPlatformType();

let Wrapper, HeadingWrapper, SubheadingWrapper;

if (layoutType == 'web') {
  Wrapper = styled.View`
    border-radius: 8px;
    padding: 16px;
    width: 220px;
    border-radius: 8px;
    background-color: rgba(159, 120, 255, 0.16);
    display: flex;
    flex-direction: column;
    position: absolute;
  `;

  HeadingWrapper = styled.View`
    color: #6d08c0;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    font-family: 'lato';
  `;
  SubheadingWrapper = styled.Text`
    color: #9f78ff;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    margin-top: 10px;
    font-family: 'lato';
  `;
} else if (layoutType == 'phone') {
  Wrapper = styled.View`
    border-radius: 8px;
    padding: 16px;
    border-radius: 8px;
    background-color: rgba(159, 120, 255, 0.16);
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 10px;
    width: '100%';
  `;

  HeadingWrapper = styled.View`
    color: #6d08c0;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    font-family: 'lato';
  `;
  SubheadingWrapper = styled.Text`
    color: #9f78ff;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    margin-top: 10px;
    text-wrap: 'wrap';
    font-family: 'lato';
  `;
} else {
  Wrapper = styled.View`
    border-radius: 8px;
    padding: 16px;
    width: 145px;
    border-radius: 8px;
    background-color: rgba(159, 120, 255, 0.16);
    display: flex;
    flex-direction: column;
    position: absolute;
  `;

  HeadingWrapper = styled.View`
    color: #6d08c0;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    font-family: 'lato';
  `;
  SubheadingWrapper = styled.Text`
    color: #9f78ff;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 18px;
    margin-top: 10px;
    font-family: 'lato';
  `;
}

export { Wrapper, HeadingWrapper, SubheadingWrapper };
