import styled from 'styled-components/native';
import { Text, TextInput, TouchableOpacity } from 'react-native';
import Colors from '../../constants/Colors';


export const Container = styled.View`
`;
export const SolidButton = styled(TouchableOpacity)`
  width:${props=>props?.width?props.width+'px':'92px'};
  height:${props=>props?.height?props.height+'px':'26px'};
  background-color:${props=>props?.type=="solid"?props.color:'transparent'};
  border-color:${props=>props?.type=="stripe"?props.color:'white'};
  border-width:1px;
  border-radius:8px;
  font-color:${props=>props?.type=="stripe"?props.color:'white'};
  justify-content:center;
  align-items:center;
  margin-vertical:10px;
  align-self:${props=>props?.alignSelf?props.alignSelf:'auto'};
`;
export const StripeButton = styled(TouchableOpacity)`
  width:${props=>props?.width?props.width+'px':'86px'};
  height:${props=>props?.height?props.height+'px':'36px'};
  border-color:${props=>props?.color?props.color:Colors.light.purple};
  border-radius:1000px;
  justify-content:center;
  align-items:center;
  border-width:2px;
  margin-vertical:10px;
  align-self:${props=>props?.alignSelf?props.alignSelf:'auto'};
`;

