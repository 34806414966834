import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();

let FooterWrapper;
let Container;
let FooterLogo;
let FooterLinks1;
let FooterLinks2;
let SocialLinks;
let TextContent;
let SocialImg;
let ObxWhiteImg;
let SocialImgBox;
let TextContentMain;
//
// ─── MOBILE SPECIFIC CSS ────────────────────────────────────────────────────────
//

if (layoutType == 'phone') {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
    padding: 0;
  `;

  Container = styled.View`
    height: 150px;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-grow: 6;
  `;

  FooterLinks1 = styled.View`
    flex-grow: 2;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 2;
  `;

  ObxWhiteImg = styled.Image`
    height: 40px;
    width: 100px;
    margin-bottom: 16px;
  `;

  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
    margintop: 6px;
  `;

  SocialImgBox = styled.TouchableOpacity`
    margin-right: 11px;
  `;
  SocialImg = styled.Image`
    height: 17px;
    width: 17px;
  `;

  TextContent = styled.Text`
    color: #ffffff;
    font-size: 8.4px;
    margin-bottom: 10px;
  `;
  TextContentMain = styled.Text`
  color: #000000;
  font-size: 8.4px;
  margin-bottom: 10px;
`;
}
//
// ─── WEB SPECIFIC CSS ───────────────────────────────────────────────────────────
//
else if (layoutType == 'web') {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
  `;

  Container = styled.View`
    height: 150px;
    width: 1130px;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-grow: 8;
  `;

  FooterLinks1 = styled.View`
    flex-grow: 1;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 1;
  `;

  ObxWhiteImg = styled.Image`
    height: 50px;
    width: 130px;
    margin-bottom: 24px;
  `;

  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
  `;

  SocialImg = styled.Image`
    height: 25px;
    width: 25px;
  `;
  SocialImgBox = styled.TouchableOpacity`
    margin-right: 16px;
  `;
  TextContent = styled.Text`
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 10px;
  `;
  TextContentMain = styled.Text`
  color: #000000;
  font-size: 14px;
  margin-bottom: 10px;
`;
}

//
// ─── TABLET SPECIFIC CSS ────────────────────────────────────────────────────────
//
else {
  FooterWrapper = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    height: 100%;
    align-items: center;
  `;

  Container = styled.View`
    height: 150px;
    width: 100%;
    padding-right: 120px;
    padding-left: 120px;
    margin-right: auto;
    margin-left: auto;
  `;

  FooterLogo = styled.View`
    flex-grow: 8;
  `;

  FooterLinks1 = styled.View`
    flex-grow: 1;
  `;

  FooterLinks2 = styled.View`
    flex-grow: 1;
  `;

  ObxWhiteImg = styled.Image`
    height: 40px;
    width: 130px;
    margin-bottom: 24px;
  `;

  SocialLinks = styled.View`
    display: flex;
    flex-direction: row;
  `;

  SocialImg = styled.Image`
    height: 25px;
    width: 24px;
  `;
  SocialImgBox = styled.TouchableOpacity`
    margin-right: 16px;
  `;
  TextContent = styled.Text`
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 10px;
  `;
  TextContentMain = styled.Text`
  color: #000000;
  font-size: 14px;
  margin-bottom: 10px;
`;
}

export {
  FooterWrapper,
  Container,
  FooterLinks1,
  FooterLinks2,
  FooterLogo,
  SocialLinks,
  TextContent,
  SocialImg,
  ObxWhiteImg,
  SocialImgBox,
  TextContentMain,
};
