import React, { useEffect, useState } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import OpenFlowModal from '../modal';
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  FormContainer,
  RowContainer,
} from './../../screens/style';
import Tooltip from '@material-ui/core/Tooltip';
import { CardBox } from './../card';
import variables from '../variables.json';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Button } from '../button';
import { View } from 'react-native';
import { TextInput } from '../textinput';
import Text from '../../../../../components/text';
function ContactCard(props) {
  const {
    name,
    is_lead,
    isLeadEditable,
    row,
    data,
    heading,
    nonRemove,
    onViewClick,
    selection,
    isSelected,
    onSelect,
    selectedEmailForLead,
    tooltip,
    iconColor,
  } = props;
  const [teanantsData, setTeanantData] = React.useState(data);
  const [showModal, setShowModal] = React.useState(false);
  const [showMakeHeadModal, setShowMakeHeadModal] = React.useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = React.useState(false);
  const [editTeanantID, setEditTeanantID] = React.useState('');
  console.log(' teanantsData={ true}', teanantsData);
  const [complete, setComplete] = useState(isSelected);
  const type = heading == 'Landlord Details' ? 'landlord' : heading == 'Guarantor Details' ? 'guarantor' : 'tenant';

  useEffect(() => {
    setComplete(isSelected);
  }, [isSelected]);

  const handleChange = (event) => {
    setComplete(!complete);
    onSelect(!complete);
  };
  const renderRemoveCardBody = () => (
    <View
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Text>Do you wish to remove this ?</Text>
      <TlyfeButtonWrapper style={{marginBottom: '5%'}}>
        <Button
          onClick={() => {
            props.onDelete();
            setShowDeleteCardModal(false);
          }}
          title={'Yes'}
          type="stripe"
          style={{ borderColor: '#90C418', color: '#90C418' }}
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setShowDeleteCardModal(false);
          }}
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>

      <RowContainer>
        <Text>Please Note</Text>
        <Text> Once you remove this you cannot undo this change</Text>
      </RowContainer>
    </View>
  );
  const renderArchiveCardBody = () => (
    <View
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Text bold fontSize={16} marginBottom={3}>Please Note:</Text>
      <Text fontSize={14}>You must click "save and continue" for this change to take affect. Once you make this change you cannot undo it. </Text>
      <TlyfeButtonWrapper style={{marginTop: '5%'}}>
        <Button
          onClick={() => {
            props.onDelete();
            setShowDeleteCardModal(false);
          }}
          title={'Yes'}
          type="stripe"
          style={{ borderColor: '#90C418', color: '#90C418' }}
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setShowDeleteCardModal(false);
          }}
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
     
    </View>
  );
  const renderMakeLeadModalBody = () => (
    <View
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <Text>
        Do you wish to make this {type} as Lead {type}
      </Text>
      <TlyfeButtonWrapper style={{marginTop:20}}>
        <Button
          onClick={() => {
            props.onMakeLead();
            setShowMakeHeadModal(false);
            // setConfirm(true)
          }}
          style={{ borderColor: '#90C418', color: '#90C418' }}
          title={'Yes'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setShowMakeHeadModal(false);
          }}
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
      <RowContainer>
        <Text>Please Note</Text>
        <Text>
          If you make tenent the Lead {type} any other {type} has been assigned as lead {type} would no longer be the
          Lead {type}
        </Text>
      </RowContainer>
    </View>
  );

  const renderModalBody = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {teanantsData?.opbrix_id && (
        <TextInput
          disabled={true}
          title={
            <>
              <strong>tlyfe ID: </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '99%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={teanantsData?.opbrix_id || ''}
        />
      )}
      <TextInput
        disabled={true}
        title={
          <>
            <strong>Title: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '10%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.title || ''}
      />
      <TextInput
    disabled={editTeanantID? false : true}
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '38%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.first_name || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, first_name: event.target.value });
        }}
      />
      <TextInput
    disabled={editTeanantID? false : true}
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.last_name || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, last_name: event.target.value });
        }}
      />
      <TextInput
    disabled={editTeanantID? false : true}
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.email || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, email: event.target.value });
        }}
      />
      <TextInput
    disabled={editTeanantID? false : true}
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.contact_number || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, contact_number: event.target.value.replace(/\D/, '') });
        }}
      />
      <TextInput
               disabled={editTeanantID? false : true}
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.postcode || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, postcode: event.target.value });
        }}
      />
      <TextInput
        disabled={editTeanantID? false : true}
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={teanantsData.address || ''}
        onChange={(event) => {
          setTeanantData({ ...teanantsData, address: event.target.value });
        }}
      />

      <Button
        style={{ marginTop: 20, marginBottom: 10, borderColor: '#90C418' }}
        onClick={() => {
          if (editTeanantID === teanantsData?.tenant_id) {
            props?.onEdit(teanantsData);
            setEditTeanantID(null);
            setShowModal(false)
          } else {
            setEditTeanantID(teanantsData?.tenant_id);
          }
        }}
        title={editTeanantID === teanantsData?.tenant_id ? 'Save tenant' : 'Edit tenant'}
        type="stripe"
      />
    </div>
  );
  return (
    <CardBox
      hasBorderRadius
      hasShadow
      style={{
        padding: 10,
        marginRight: '4%',
        marginBottom: '4%',
        width: 180,
        minHeight: 40,
      }}
    >
      <RowContainer>
        <div
          style={{
            flex: 0.2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Tooltip
            title={` ${tooltip ? tooltip : is_lead ? 'Lead ' + type : 'Not Lead ' + type}`}
            placement="top-start"
          >
            <PersonIcon
              style={{
                height: is_lead ? 34 : 30,
                width: is_lead ? 36 : 30,
                color: iconColor ? iconColor : is_lead ? variables.lead_green : variables.heliotrope,
              }}
            />
          </Tooltip>
          <p
            onClick={() => {
              if (onViewClick) {
                onViewClick();
              } else setShowModal(true);
            }}
            style={{
              color: variables.heliotrope,
              fontSize: '13px',
              cursor: 'pointer',
              fontWeight: 500,
              marginTop: 8,
              fontFamily: 'lato',
            }}
          >
            View
          </p>
          {!is_lead && isLeadEditable && (
            <p
              onClick={() => {
                setShowMakeHeadModal(true);
                selectedEmailForLead(row);
              }}
              style={{
                marginTop: '19%',
                color: variables.lead_green,
                cursor: 'pointer',
                fontFamily: 'lato',
              }}
            >
              Make Lead
            </p>
          )}
        </div>
        <div
          style={{
            marginLeft: 10,
            flex: 0.8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Heading>{name}</Heading>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {nonRemove ? null : (
              <p
                onClick={() => {
                  setShowDeleteCardModal(true);
                }}
                style={{
                  color: variables.tint,
                  fontSize: '13px',
                  cursor: 'pointer',
                  fontWeight: 500,
                  fontFamily: 'lato',
                }}
              >
                Remove
              </p>
            )}
            {selection && (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: variables.heliotrope }}
                    checked={complete}
                    onChange={handleChange}
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<RadioButtonCheckedIcon />}
                    name="checkedH"
                  />
                }
              />
            )}
          </div>
        </div>
      </RowContainer>
      {showModal && (
        <OpenFlowModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalBody={renderModalBody()}
          showFooter={false}
          modalHeading={props?.heading ? props.heading : 'Tenant Details'}
          modalWidth={600}
        />
      )}
      {showMakeHeadModal && (
        <OpenFlowModal
          showModal={showMakeHeadModal}
          setShowModal={setShowMakeHeadModal}
          modalBody={renderMakeLeadModalBody()}
          showFooter={false}
          modalHeading="Make Lead ?"
          modalWidth={600}
        />
      )}
      {showDeleteCardModal && (
        <OpenFlowModal
          showModal={showDeleteCardModal}
          setShowModal={setShowDeleteCardModal}
          modalBody={props.isArchive?renderArchiveCardBody(): renderRemoveCardBody()}
          showFooter={false}
          modalHeading={props.isArchive?"Archive ?":"Remove ?"}
          modalWidth={600}
        />
      )}
    </CardBox>
  );
}
export default ContactCard;
