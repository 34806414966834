import { useState, useEffect } from 'react';
import ajax from '../helpers/ajaxHelper';
import { navigate } from '../navigation/RootNavigation';
import Urls from '../services/Urls';

const useProperties = (addGeneral) => {
  const [response, setResponse] = useState([]);
  const [error, setError] = useState('');

  const fetchData = async () => {
    ajax
      .get(Urls.Properties)
      .then(({ data }) => {
        if (data.status && data?.payload) {
          let temp = [];
          // if (addGeneral) {
          //   temp = [{ label: 'General', value: 'general' }];
          // }
          data?.payload?.forEach((element) => {
            temp = [...temp, { label: element.name, value: element.id }];
          });
          setResponse(temp);
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return response;
};

export default useProperties;
