import React, { useState, useEffect, useCallback } from 'react';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import NewsTool from '../../components/newsTool';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import debounce from 'lodash.debounce';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import ListOverView from '../../components/listOverView';
import Text from '../../components/text';

var colData = ['Property', 'Location', 'Status'];

const Properties = ({}) => {
  let navigation = useNavigation();
  const [properties, setProperties] = useState([]);
  const focused = useIsFocused();

  useEffect(() => {
    ajax
      .get(Urls.Properties)
      .then(({ data }) => {
        if (data.status) {
          setProperties(data?.payload);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focused]);

  const _onSearch = (text) => {
    ajax
      .post(Urls.SearchProperty, { searchText: text })
      .then(({ data }) => {
        if (data.status) {
          setProperties(data?.data);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onChange = debounce((text) => {
    _onSearch(text);
  }, 1000);

  return (
    <>
      <>
        <SearchCardComponent
          placehorderText={'Search Properties'}
          onChangeText={(e) => {
            _onChange(e);
          }}
          leftText={'My Properties'}
        />
        <PropertyTable data={properties.reverse()} colData={colData} />
        <Button
          type={'solid'}
          width={170}
          onPress={() => navigation.push('PropertyAddress')}
          childText={'Add New Property'}
        />
        {/* <NewsTool /> */}

        {/* <Text fontSize={15} bold marginBottom={20} marginTop={20}>
          Certificate Overview
        </Text>

        <ListOverView Header1="Property" Header2="Gas" Header3="EICR" Header4="EPC" />

        <Text fontSize={15} bold marginBottom={20} marginTop={20}>
          License & Tests
        </Text>

        <ListOverView
          Header1="Property"
          Header2={`Property\nLicense`}
          Header3={`Smoke\nAlarm`}
          Header4={`Carbon M\nAlarm`}
        /> */}
      </>
    </>
  );
};
export default drawerHoc({
  Component: Properties,
});
