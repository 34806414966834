import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import CheckBox from '../checkbox';
import { useNavigation, useRoute } from '@react-navigation/native';
import Box from '../box';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import {
  Row,
  Right,
  Left,
  Center,
  BoxContainer,
  ImageContainer,
  CircleIcon,
  InfoSection,
  RemainderSection,
  SectionLine,
} from './pageHeader.style';
import { MaterialIcons } from '@expo/vector-icons';
import { Entypo } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import Knob from '../knob';
import Button from '../button/Button';
import Text from '../text';
import getPlatformType from '../../helpers/helpers';
var layoutType = getPlatformType();

const TenantHeader = (props) => {
  const {
    property_name,
    property_worth,
    id,
    percentage,
    address1,
    city,
    postcode,
    onContinuePress,
    contact_no,
    email,
    houseName,
  } = props;
  const navigation = useNavigation();
  const { navigate } = navigation;

  return (
    <Box style={{ marginTop: 10 }}>
      <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row' }}>
        <BoxContainer>
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <Left>
              <ImageContainer>
                <Entypo name="home" size={55} color={Colors.light.purple} />
              </ImageContainer>
            </Left>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row', alignSelf: 'flex-start' }}>
                <Text
                  fontSize={layoutType == 'phone' ? 16 : 18}
                  marginLeft={layoutType == 'phone' ? 0 : 30}
                  fontWeight={'600'}
                  marginBottom={0}
                >
                  {property_name}{' '}
                </Text>
              </View>
              <View style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                <Text marginTop={10} fontSize={15} marginLeft={layoutType == 'phone' ? 0 : 30}>
                  {contact_no}
                </Text>
                <Text marginTop={10} fontSize={15} marginLeft={layoutType == 'phone' ? 0 : 30}>
                  {email}
                </Text>
              </View>
            </View>
            <TouchableOpacity>
              <Text bold fontSize={14} color={Colors.light.purple}>
                Edit
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: layoutType == 'phone' ? 'row-reverse' : 'row',
              justifyContent: layoutType == 'phone' ? 'space-between' : 'flex-start',
              marginLeft: layoutType == 'phone' ? -60 : 0,
              marginTop: layoutType == 'phone' ? 20 : 40,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                width: 36,
                height: 36,
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 2,
                borderRadius: 18,
                borderColor: Colors.light.purple,
              }}
            >
              <Feather name="mail" size={22} color={Colors.light.purple} />
            </View>
            <Text
              fontSize={13}
              color={Colors.light.lightPurple}
              style={{
                padding: 5,
                borderWidth: 1,
                borderRadius: 5,
                borderColor: Colors.light.lightPurple,
                marginLeft: 60,
              }}
              fontWeight={'600'}
            >
              {houseName}
            </Text>
          </View>
        </BoxContainer>
        <SectionLine />
        <View style={{ flexDirection: 'column', width: layoutType == 'phone' ? '100%' : '45%' }}>
          <View
            style={{
              flexDirection: 'row',
              width: '100%',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
              <Knob
                size={78}
                strokeWidth={7}
                progressPercent={percentage ? percentage : 0}
                text={'Completed'}
                textColor={Colors.light.purple}
                pgColor={Colors.light.purple}
              />
              <Text marginTop={15} bold color={Colors.light.purple} fontSize={14}></Text>
            </View>
            <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
              <View
                style={{
                  width: 60,
                  height: 60,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 2,
                  borderRadius: 35,
                  borderColor: Colors.light.purple,
                }}
              >
                <MaterialIcons name="autorenew" size={30} color={Colors.light.purple} />
              </View>
              <Text marginTop={12} bold color={Colors.light.purple} fontSize={14}>
                Renew
              </Text>
            </View>
            <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30%' }}>
              <View
                style={{
                  width: 60,
                  height: 60,
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 2,
                  borderRadius: 35,
                  borderColor: Colors.light.purple,
                }}
              >
                <Ionicons name="md-exit-outline" size={30} color={Colors.light.purple} />
              </View>
              <Text marginTop={12} bold color={Colors.light.purple} fontSize={14}>
                Offboard
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'column',
              marginTop: 20,
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Button
              onPress={() => {
                onContinuePress ? onContinuePress() : navigate('PropertySection', { id: id });
              }}
              childText={'Continue'}
              type="solid"
              width={100}
            />
          </View>
        </View>
      </View>

      {/* <BoxContainer>
          <Left>
            <ImageContainer>
              <Entypo name="home" size={55} color={Colors.light.purple} />
            </ImageContainer>
          </Left>
          <Center style={{ marginLeft: 5 }}>
            <Row style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
              <Text fontSize={18} fontWeight={'600'}>
                {property_name}
              </Text>
              <Text
                fontSize={13}
                color={Colors.light.lightPurple}
                marginLeft={10}
                style={{ padding: 5, borderWidth: 1, borderRadius: 5, borderColor: Colors.light.lightPurple }}
                fontWeight={'600'}
              >
                £{property_worth}
              </Text>
            </Row>
            <Text marginTop={10} fontSize={15}>
              {address1}
              {city},{postcode}
            </Text>
            <TouchableOpacity onPress={() => navigate('PropertySection', { id: id })}>
              <Text fontWeight={'600'} fontSize={14} color={Colors.light.purple} marginTop={5}>
                More details
              </Text>
            </TouchableOpacity>
          </Center>
          <Right>
            <View
              style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}
            >
            
              <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
                <Knob
                  size={90}
                  strokeWidth={10}
                  progressPercent={percentage ? percentage : 0}
                  text={'Complete'}
                  textColor={Colors.light.purple}
                  pgColor={Colors.light.purple}
                />
              </View>
              <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
                <Button
                  onPress={() => navigate('PropertySection', { id: id })}
                  childText={'Continue'}
                  type="solid"
                  width={100}
                />
              </View>
            </View>
          </Right>
        </BoxContainer> */}
    </Box>
  );
};

export default TenantHeader;
