import React from 'react';
import styled from 'styled-components';
import { Button } from '../button/index.js';
import { CardBox } from '../card/index.js';
import Knob from '../knob/index.js';
import PropertyCard from '../PropertyCard';
import variables from '../variables.json';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogButton from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.type == 'complete' ? variables.purple1 : variables.heliotrope)};
  font-size: 10px;
`;
const Left = styled.div`
  flex: 0.2;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const Center = styled.div`
  flex: 0.55;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  border-right: 1px solid #d8d8d8;
`;
const Right = styled.div`
  flex: 0.25;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ListCard = (props) => {
  const { marginTop, Id, openFlow, DeleteFlow, ArchiveFlow, onunArchiveFlow, isCompleted } = props;

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const closeDialog = () => {
    setShowDeleteDialog(false);
  };

  const openDialog = () => {
    setShowDeleteDialog(true);
  };

  const deleteFlow = () => {
    DeleteFlow(Id);
    closeDialog();
  };

  return (
    <CardBox style={{ marginTop: marginTop ? marginTop : 0 }} hasShadow={true} hasBorderRadius={true} {...props}>
      <div>
        <Dialog
          open={showDeleteDialog}
          onClose={closeDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"> Delete Flow? </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this flow
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <DialogButton onClick={deleteFlow} color="primary" autoFocus>
              Yes
            </DialogButton>
            <DialogButton onClick={closeDialog} color="primary">
              No
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>

      {props.showPropertyCard ? <PropertyCard style={{ right: 0, bottom: -90 }} Id={Id} /> : null}
      <Container>
      {props?.leftComponent?  <Left>{props?.leftComponent()}</Left>:null}
        <Center>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p style={{ fontSize: '15px', fontWeight: 400, marginTop: 5 }}>{props?.name ? props?.name : null}</p>
            <Button
              style={{
                width: 'auto',
                padding: 5,
                borderRadius: 5,
                marginLeft: '20px',
                marginRight: '10px',
                height: 'auto',
              }}
              type={'stripe'}
              title={props.propertyName}
            />
          </div>
          <p style={{ fontSize: '13px' }}>{props.contact}</p>
          <p style={{ fontSize: '13px' }}>{props.email}</p>
          {openFlow && props.DeleteFlow !== null ? (
            <Tooltip title="Delete Flow" onClick={() => openDialog()}>
              <IconButton aria-label="delete">
                <DeleteIcon></DeleteIcon>
              </IconButton>
            </Tooltip>
          ) : null}

          {openFlow && !openFlow.is_archived && props.DeleteFlow != null ? (
            <Tooltip title="Archive Flow" onClick={() => ArchiveFlow(Id)}>
              <IconButton aria-label="delete">
                <ArchiveIcon></ArchiveIcon>
              </IconButton>
            </Tooltip>
          ) : null}
          {openFlow && openFlow.is_archived && props.DeleteFlow != null ? (
            <Tooltip title="Unarchive Flow" onClick={() => onunArchiveFlow(Id)}>
              <IconButton aria-label="delete">
                <UnarchiveIcon></UnarchiveIcon>
              </IconButton>
            </Tooltip>
          ) : null}

          {/*openFlow && props.DeleteFlow == null ? (
            <Tooltip title="Close Flow" onClick={() => {alert("not yet implemented")}}>
              <IconButton aria-label="Close">
                <HighlightOffIcon></HighlightOffIcon>
              </IconButton>
            </Tooltip>
          ) : null*/}
        </Center>
        <Right>
          {isCompleted ? (
            <div style={{ display: 'flex', padding: '4%', flexDirection: 'column' }}>
              <div style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }}>Tenancy Info</div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 100,
                  marginTop: '2%',
                }}
              >
                <div style={{ color: 'black', fontWeight: 'bold', fontSize: 16, marginRight: '14%' }}>Start</div>
                <div style={{ color: '#6D08C0', fontWeight: 'bold', fontSize: 16 }}>{props.started}</div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: 100,
                  marginTop: '2%',
                }}
              >
                <div style={{ color: 'black', fontWeight: 'bold', fontSize: 16, marginRight: '14%' }}>End</div>
                <div style={{ color: '#6D08C0', fontWeight: 'bold', fontSize: 16 }}>{props.ended}</div>
              </div>
            </div>
          ) : (
            <Knob size={85} strokeWidth={8} progressPercent={props.percentage} text={props.percentage + '%'} />
          )}
        </Right>
      </Container>
    </CardBox>
  );
};

{
  /*
Example.
 <ListCard style={{marginTop:'30px'}}
        leftComponent={()=>{return(
           <Button
            type={"start" ? "stripe" : "solid"}
            style={{ height: "36px", width: "116px" }}
            title={"start" ? "Start" : "Continue"}
          />)
        }
        }
        name={"Behron Dryo"} contact={"384394834"} email={"Behner@pocketapp.co.uk"} company={"Accorn House"} percentage={0}/> 
*/
}
