const containerStyle = {
  container: {
    zIndex: '12',
    color: '#333',
    marginBottom: 100,
  },
  boxLayout: {
    minHeight: '80vh',
  },
  boxLayoutWithNav: {
    minHeight: '80vh',
    width: 'calc(100% - 260px)',
    marginLeft: '260px',
    '@media (max-width: 959px)': {
      width: '100%',
      marginLeft: '0px',
    },
  },
};

export default containerStyle;
