import { ACCEPT_GA_COOKIES, SHOW_BANNER_COOKIES, SHOW_BANNER_CUSTOMISE, SAVE_COOKIES_PREFERENCES, SET_CUSTOMISE_COOKIES } from "./../reducers/const";
export function changeCookiesSettings(status) {

  return {
    type: ACCEPT_GA_COOKIES,
    payload: status,
  };
}
export function showCookiesBanner(status) {

  return {
    type: SHOW_BANNER_COOKIES,
    payload: status,
  };
}
export function showCustomiseBanner(status) {

  return {
    type: SHOW_BANNER_CUSTOMISE,
    payload: status,
  };
}
export function hasUserClickedOrAcceptedCookies(status) {

  return {
    type: SAVE_COOKIES_PREFERENCES,
    payload: status,
  };
}
export function customiseCookies(data) {

  return {
    type: SET_CUSTOMISE_COOKIES,
    payload:data,
  };
}