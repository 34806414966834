import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { navigate } from '../../navigation/RootNavigation';
import { Container, HeaderWrapper, ColoumnWrapper, ListItem, ListSection } from './index.style';

const layoutType = getPlatformType();

const PropertyTable = (props) => {
  const { colData, data, rowIcon: RowIcon } = props;
  const _renderItem = (item, index) => {
    return (
      <ListItem
        onPress={() => navigate('PropertyInfo', { id: item?.id })}
        key={index}
        style={{ borderBottomWidth: data.length == index + 1 ? 0 : 1, paddingVertical: 10, borderColor: '#ebebeb' }}
      >
        <ListSection style={{ flexDirection: 'row' }} width={layoutType == 'phone' ? '90%' : '30%'}>
          {RowIcon ? RowIcon : <MaterialIcons name="home" color={Colors.light.purple} size={20} />}

          <Text fontSize={15} marginLeft={10}>
            {item.name.substring(0,20)}
          </Text>
        </ListSection>
        {layoutType == 'phone' ? (
          <ListSection width={'10%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />
          </ListSection>
        ) : null}
        <ListSection width={layoutType == 'phone' ? '50%' : '30%'}>
          <Text fontSize={15} marginLeft={0}>
            {item.postcode}
          </Text>
        </ListSection>
        <ListSection width={layoutType == 'phone' ? '50%' : '30%'}>
          <Text fontSize={15} marginLeft={0}>
            {item?.percentage ? Math.round(item?.percentage) + '%' : 0 + '%'}
          </Text>
        </ListSection>
        {layoutType != 'phone' ? (
          <ListSection width={'10%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />
          </ListSection>
        ) : null}
      </ListItem>
    );
  };
  const _renderHeader = () => {
    return (
      <HeaderWrapper>
        {colData.map((e) => (
          <ColoumnWrapper key={e}>
            <Text fontWeight={500} fontSize={15}>
              {e}
            </Text>
          </ColoumnWrapper>
        ))}
      </HeaderWrapper>
    );
  };

  return (
    <Container>
      {layoutType != 'phone' ? _renderHeader() : null}
      {data?.length ? (
        <FlatList
          data={data}
          renderItem={({ item, index }) => _renderItem(item, index)}
          keyExtractor={(item, index) => item.id}
        />
      ) : (
        <Text fontSize={15} style={{ marginTop: '10px', alignSelf: 'center' }}>
          {'No property available'}
        </Text>
      )}
    </Container>
  );
};
PropertyTable.propTypes = {
  leftText: PropTypes.string,
  onChangeText: PropTypes.func,
  onItemClick: PropTypes.func,
};

PropertyTable.defaultProps = {};

export default PropertyTable;
