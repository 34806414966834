import React, { useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView, TextInputWrapper, Row, HorizontalLine, RowCol } from './index.style';
import CalenderComponent from '../../components/calendar';
import moment from 'moment';
import CalendarList from '../../components/calendarList';
import { HOME } from './../../constants/svg';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import ListCard from '../../components/listCard';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import ItemCounter from '../../components/itemCounter';
import RadioButton from '../../components/radioButton';
import Urls from '../../services/Urls';
import { useRoute } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import MarkAsComplete from '../../components/markAsComplete/MarkAsComplete';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import { showSuccesToast } from '../tenants/tenantOnboarding/helpers';
var layoutType = getPlatformType();

const Requirements = ({ navigation }) => {
  const [state, setState] = useState({
    is_smoke_detector_available_on_every_floor: 'No',
    is_carbon_monoxide_detector_available_in_every_room: '',
    is_cleaned_to_professional_standard: '',
    is_plumbing_checked: '',
    appliances_checked: '',
    carbon_monoxide_detector_testing_date: '',
    smoke_detector_testing_date: '',
  });
  const route = useRoute();
  useEffect(() => {
    if (route?.params?.id) {
      _getRequirements(route?.params?.id);
    }
  }, []);

  const _getRequirements = (id) => {
    ajax
      .get(Urls.Properties + '/' + id)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload) {
            let Apidata = data?.payload;
            console.log('data', data);
            setState({
              ...state,
              ...{
                is_smoke_detector_available_on_every_floor: Apidata?.is_smoke_detector_available_on_every_floor,
                is_carbon_monoxide_detector_available_in_every_room:
                  Apidata?.is_carbon_monoxide_detector_available_in_every_room,
                is_plumbing_checked: Apidata?.is_plumbing_checked,
                is_cleaned_to_professional_standard: Apidata?.is_cleaned_to_professional_standard,
                appliances_checked: Apidata?.appliances_checked,
                smoke_detector_testing_date: data?.payload?.smoke_detector_testing_date
                  ? data?.payload?.smoke_detector_testing_date
                  : null,
                carbon_monoxide_detector_testing_date: data?.payload?.carbon_monoxide_detector_testing_date
                  ? data?.payload?.carbon_monoxide_detector_testing_date
                  : null,
              },
            });
          }
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onSave = () => {
    console.log('smoke_detector_testing_date', state);
    const requestBody = {
      is_smoke_detector_available_on_every_floor: state?.is_smoke_detector_available_on_every_floor,
      is_carbon_monoxide_detector_available_in_every_room: state?.is_carbon_monoxide_detector_available_in_every_room,
      smoke_detector_testing_date: state?.is_smoke_detector_available_on_every_floor? state?.smoke_detector_testing_date  || moment() : null,
      carbon_monoxide_detector_testing_date:  state?.is_carbon_monoxide_detector_available_in_every_room? state?.carbon_monoxide_detector_testing_date  || moment() : null,
      is_cleaned_to_professional_standard: state?.is_cleaned_to_professional_standard,
      is_plumbing_checked: state?.is_plumbing_checked,
      appliances_checked: state?.appliances_checked,
    };
    ajax
      .post(Urls.Requirements + '/' + route?.params?.id, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // showSuccesToast(data?.data);
          navigation.navigate('PropertySection', { id: route?.params?.id });
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };

  console.log('State', state);
  return (
    <>
      <ListCard
        leftIcon={'file-document'}
        description={'Please add the local authority licensing requirements that is required.'}
        title={'Property Requirements'}
        showPropertyCard={route.params?.name}
      />
      {/* <Text fontWeight={600} marginTop={15} marginBottom={10} fontSize={18} marginLeft={10}>
        Property requirements
      </Text> */}
      <MarkAsComplete
        label={'Property requirements'}
        flowID={route?.params?.id}
        flowtype={route?.params?.key}
        isComplete={route?.params?.status === 2 ? true : false}
      />
      <Box style={{ marginTop: 15, marginBottom: 15 }}>
        <RadioButton
          onChange={(e) =>
            _handleChange({ value: e == 'Yes' ? true : false, label: 'is_smoke_detector_available_on_every_floor' })
          }
          options={['Yes', 'No']}
          value={state?.is_smoke_detector_available_on_every_floor == true ? 'Yes' : 'No'}
          title={'Do you have Smoke Detectors on every floor of the property?'}
        />
        <If condition={state?.is_smoke_detector_available_on_every_floor}>
          <Row style={{ marginTop: 20, width: '60%' }}>
            <TextInputComponent
              title={'Enter the date you last tested the detector'}
              onChangeText={(e) => _handleChange({ value: e, label: 'smoke_detector_testing_date' })}
              isDate
              date={state?.smoke_detector_testing_date}
            />
          </Row>
        </If>
      </Box>
      <Box style={{ marginBottom: 15 }}>
        <RadioButton
          onChange={(e) =>
            _handleChange({
              value: e == 'Yes' ? true : false,
              label: 'is_carbon_monoxide_detector_available_in_every_room',
            })
          }
          value={state?.is_carbon_monoxide_detector_available_in_every_room == true ? 'Yes' : 'No'}
          options={['Yes', 'No']}
          title={'Do you have Carbon Monoxide Dectectors in every room with an appliance which burns fossil fuels?'}
        />
        <If condition={state?.is_carbon_monoxide_detector_available_in_every_room}>
          <Row style={{ marginTop: 20, width: '60%' }}>
            <TextInputComponent
              title={'Enter the date you last tested the detector'}
              onChangeText={(e) => _handleChange({ value: e, label: 'carbon_monoxide_detector_testing_date' })}
              isDate
              date={state?.carbon_monoxide_detector_testing_date}
            />
          </Row>
        </If>
      </Box>
      <HorizontalLine />
      <Text fontWeight={600} marginTop={10} marginBottom={10} fontSize={18} marginLeft={10}>
        Habitation requirements
      </Text>
      <Box>
        <RadioButton
          onChange={(e) => {
            console.log('eeee', e);

            _handleChange({ value: e == 'Yes' ? true : false, label: 'is_cleaned_to_professional_standard' });
          }}
          options={['Yes', 'No']}
          value={state?.is_cleaned_to_professional_standard == true ? 'Yes' : 'No'}
          row
          title={'Cleaned to a Professional Standard'}
        />
        <RadioButton
          row
          onChange={(e) => _handleChange({ value: e == 'Yes' ? true : false, label: 'is_plumbing_checked' })}
          value={state?.is_plumbing_checked == true ? 'Yes' : 'No'}
          options={['Yes', 'No']}
          title={'Plumbing checked'}
        />
        <RadioButton
          row
          onChange={(e) => _handleChange({ value: e == 'Yes' ? true : false, label: 'appliances_checked' })}
          value={state?.appliances_checked == true ? 'Yes' : 'No'}
          options={['Yes', 'No']}
          title={'Appliance checked'}
        />
      </Box>
      <Button
        onPress={() => _onSave()}
        childText={'Save and Continue'}
        width={150}
        style={{ alignSelf: 'flex-end', marginVertical: 30 }}
      />
    </>
  );
};

export default drawerHoc({
  Component: Requirements,
  showBackButton: true,
});
