import DoneIcon from '@material-ui/icons/Done';
import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { colorsTheme } from './../../helpers/theme';
const CompleteButton = styled.div`
  display: flex;
  border-radius: 6px;
  width: 92px;
  height: 26px;
  justify-content: center;
  align-items: center;
  background: ${colorsTheme.green};
  color: white;
  font-size: 13px;
  font-family: 'lato';
`;
const InCompleteButton = styled.div`
  display: flex;
  border-radius: 6px;
  width: 92px;
  height: 26px;
  justify-content: center;
  align-items: center;
  color: ${colorsTheme.white};
  border: 1px solid ${colorsTheme.pink};
  background: ${colorsTheme.pink};
  font-size: 13px;
  font-family: 'lato';
`;
const InProgressButton = styled.div`
  display: flex;
  border-radius: 6px;
  width: 92px;
  height: 26px;
  justify-content: center;
  align-items: center;
  color: ${colorsTheme.white};
  border: 1px solid ${colorsTheme.yellow};
  background: ${colorsTheme.yellow};
  font-size: 13px;
  font-family: 'lato';
`;
export const StatusButton = (props) => {
  if (props.type == 'complete') {
    return (
      <CompleteButton {...props}>
        <DoneIcon style={{ fontSize: '20px' }} />
        Complete
      </CompleteButton>
    );
  } else if (props.type == 'incomplete') {
    return <InCompleteButton {...props}>Not Started</InCompleteButton>;
  } else {
    return <InProgressButton {...props}>In Progress</InProgressButton>;
  }
};

{
  /*
Example.(type=complete/incomplete)
<StatusButton type={"incomplete"}/>
*/
}
