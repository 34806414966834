import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Box from '../box';
import moment from 'moment';
import Text from '../text';
import { View } from '../Themed';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Delete from '@material-ui/icons/Delete';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import SaveAlt from '@material-ui/icons/SaveAlt';

import { Row } from './DocsComponent.style';
import Colors from '../../constants/Colors';
import { Platform, StyleSheet, TouchableOpacity } from 'react-native';
import getPlatformType from '../../helpers/helpers';

const layoutType = getPlatformType();
const array = [
  { label: 'Label_1', date: '22/07/22', link: 'www.google.com' },
  { label: 'Label_2', date: '22/07/22', link: 'www.google.com' },
  { label: 'Label_3', date: '22/07/22', link: 'www.google.com' },
  { label: 'Label_7', date: '22/07/22', link: 'www.google.com' },
  { label: 'Label_8', date: '22/07/22', link: 'www.google.com' },
];

const _openURL = (url) => {
  if (Platform.OS == 'web') {
    window.open(url);
  } else {
  }
};

const DocsComponent = ({ title, listOfDoc, deleteFile }) => {
  const [paginationCount, setpaginationCount] = useState({
    limit: 5,
    pageCount: 1,
  });
  const [arrayList, setArrayList] = useState(listOfDoc || array);

  useEffect(() => {
    setArrayList(listOfDoc);
  }, [listOfDoc]);

  const renderButtonsOptions = (data) => (
    <Row
      style={{
        flex: 1,
        justifyContent: layoutType == 'phone' ? 'space-between' : 'center',
        width: '100%',
        marginTop: layoutType == 'phone' ? 20 : 0,
        paddingHorizontal: 20,
      }}
    >
      <TouchableOpacity onPress={() => _openURL(data.document_url)}>
        <RemoveRedEye
          style={{
            height: 24,
            width: 24,
            color: '#9F78FF',
          }}
        />
      </TouchableOpacity>
      <TouchableOpacity style={{ marginHorizontal: 30 }} onPress={() => deleteFile(data.id)}>
        <Delete
          style={{
            height: 24,
            width: 24,
            color: '#9F78FF',
          }}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => _openURL(data.document_url)}>
        <SaveAlt
          style={{
            height: 24,
            width: 24,
            color: '#9F78FF',
          }}
        />
      </TouchableOpacity>
    </Row>
  );

  const renderList = () => {
    let list = arrayList.slice(paginationCount.offset, paginationCount.limit);
    return (
      <>
        {list.map((data, index) => {
          return (
            <Row
              key={index}
              style={{
                borderBottomWidth: 1,
                borderColor: '#EBEBEB',
                paddingVertical: 15,
                flexDirection: layoutType == 'phone' ? 'colum' : 'row',
              }}
            >
              <Row style={{ flex: 1, paddingLeft: 20, width: '100%', paddingEnd: 15 }}>
                <Text color="#5A5A5A"> {data?.document_name||"-"}</Text>
                <Text color="#5A5A5A" marginRight={20}>
                  {moment(data.createdAt).format('DD-MM-YYYY')}
                </Text>
              </Row>
              {renderButtonsOptions(data)}
            </Row>
          );
        })}
      </>
    );
  };
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const showPrevious = () => {
    if (paginationCount.offset > 0) {
      setpaginationCount({
        offset: paginationCount.limit - 10,
        limit: paginationCount.limit - 5,
        pageCount: paginationCount.pageCount - 1,
      });
    }
  };

  const showNext = () => {
    if (arrayList.length > paginationCount.limit) {
      setpaginationCount({
        offset: paginationCount.limit,
        limit: paginationCount.limit + 5,
        pageCount: paginationCount.pageCount + 1,
      });
    }
  };

  return (
    <Box
      style={{
        width: '100%',
        marginTop: 20,
        paddingHorizontal: 0,
        paddingVertical: 10,
      }}
    >
      <Row style={{ paddingHorizontal: 15 }}>
        <Text fontSize={15} color={Colors.light.purple}>
          {title || 'Title'}
        </Text>
        <TouchableOpacity onPress={() => setDropDownOpen(!dropDownOpen)}>
          <IconButton>
            <If condition={dropDownOpen}>
              <ArrowDropUp />
              <Else />
              <ArrowDropDown />
            </If>
          </IconButton>
        </TouchableOpacity>
      </Row>
      <If condition={dropDownOpen}>
        <Row style={{ borderBottomWidth: 1, borderColor: '#EBEBEB', paddingBottom: 10,  }}>
          <Row style={{ flex: 1, paddingLeft: 20 }}>
            <Text color="#000">File Name</Text>
            <Text color="#000" style={{ marginRight: 15 }}>
              Date Uploaded
            </Text>
          </Row>
          <If condition={layoutType !== 'phone'}>
            <View style={{ flex: 1, justifyContent: layoutType == 'phone' ? 'center' : 'space-evenly' }} />
          </If>
        </Row>
        {renderList()}
        <If condition={arrayList.length > 5}>
          <Row style={{ justifyContent: 'flex-end', marginTop: 10 }}>
            <Text marginLeft={20}>
              {paginationCount.pageCount} of {Math.ceil(arrayList.length / 5)}
            </Text>
            <TouchableOpacity onPress={showPrevious}>
              <ArrowLeft />
            </TouchableOpacity>
            <TouchableOpacity style={{ marginLeft: 10, marginRight: 20 }} onPress={showNext}>
              <ArrowRight />
            </TouchableOpacity>
          </Row>
        </If>
      </If>
    </Box>
  );
};

export default DocsComponent;
