import Regex from '../../helpers/regex';
import moment from 'moment';

export const EmailValidation = (text) => {
  let email = text.trim();
     if (email.length == 0) {
    return { status: true };
         }

    if (!Regex.validateEmail(email)) {
      return { msg: 'Please enter a valid email address.', status: false };
    } else {
      return { msg: 'Valid', status: true };
    }
  
};
