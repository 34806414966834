import React, { useEffect, useState } from 'react';
import { BackBox, BackButton, LineSeperator } from './styles';
import ToggleComplete from '../toggleComplete';

const BackComponent = (props) => {
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }}>
        <BackButton style={{ display: 'flex' }}></BackButton>
        <div>
          {props.hideMark ? null : (
            <ToggleComplete isComplete={props.isComplete} flowtype={props.flowtype} flowID={props.id} />
          )}
        </div>
      </div>
      {!props.hideLine ? <LineSeperator /> : null}
    </div>
  );
};
export default BackComponent;
