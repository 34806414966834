import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import OtpInput from 'react-otp-input';
import { SubHeading } from '../../screens/style';

const text = {
  fontSize: 14,
  fontFamily: 'lato',
};

const inputStyle = {
  width: '100%',
  padding: 2,
  borderRadius: '6px',
  borderWidth: 0,
};
const container = {
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
});
export const Otp = (props) => {
  const classes = useStyles();
  return (
    <div
      style={
        ({ display: props.type == 'row' ? 'flex' : 'block', ...container },
        props.wrapperstyle ? { ...props.wrapperstyle } : {})
      }
    >
      <SubHeading style={({ text }, props.inputTextStyle ? { ...props.inputTextStyle } : {})}>{props.title}</SubHeading>
      <div
        style={Object.assign(
          {
            height: '40px',
            width: props.isfullwidth ? '100%' : '50%',
            backgroundColor: 'white',
          },
          props?.containerstyles ? props.containerstyles : {}
        )}
      >
        <OtpInput
          value={props?.value}
          title={'Sort Code'}
          style={inputStyle}
          isInputNum={true}
          numInputs={props.no}
          separator={<span>{'-'}</span>}
          isDisabled={props.disable}
          onChange={(E) => {
            props.onChange({
              target: {
                name: props.name ? props.name : 'input_otp',
                value: E,
              },
            });
          }}
        />
        {props.error ? (
          <p style={{ marginTop: '5px', color: 'red', textAlign: 'right' }}>This field is required</p>
        ) : null}
      </div>
    </div>
  );
};

{
  /*
Example.(type=row/column)
<div style={{width:'50%'}}>
<TextInput placeholder="Here" type="row" title={"Holding text deposite"}/>   
</div>
*/
}
