import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, MainText, NoticeText, FormBox, LabelText, ContainerRight, ContainerLeft;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 70%;
    margin-left: 320px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
  `;
  ContainerLeft = styled.View`
    padding: 10px;
    width: 70%;
  `;
  ContainerRight = styled.View`
    padding: 10px;
    width: 30%;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
  `;
  NoticeText = styled.Text`
    font-size: 14px;
    font-weight: 400;
    color: #27afd0;
    margin-bottom: 5px;
    margin-top: 5px;
  `;
  FormBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  LabelText = styled.Text`
    font-size: 16px;
    font-weight: 600;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
  `;
  NoticeText = styled.Text`
    font-size: 14px;
    font-weight: 400;
    color: #27afd0;
    margin-bottom: 5px;
    margin-top: 5px;
  `;
  FormBox = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  `;
  LabelText = styled.Text`
    font-size: 16px;
    font-weight: 600;
  `;
  ContainerLeft = styled.View`
    padding: 10px;
    width: 100%;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 60%;
    padding: 10px;
    margin-left: 10%;
  `;
  ContainerLeft = styled.View`
    padding: 10px;
    width: 100%;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 15px;
  `;
  NoticeText = styled.Text`
    font-size: 14px;
    font-weight: 400;
    color: #27afd0;
    margin-bottom: 5px;
    margin-top: 5px;
  `;
  FormBox = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  LabelText = styled.Text`
    font-size: 16px;
    font-weight: 600;
  `;
}
export { ContainerScrollView, Container, MainText, NoticeText, FormBox, LabelText, ContainerRight, ContainerLeft };
