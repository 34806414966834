import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styles from './Style';

const useStyles = makeStyles(styles);

export default function Container(props) {
  const classes = useStyles();
  const { children, className, ...rest } = props;

  return (
    <div className={classes.container} {...rest}>
      {children}
    </div>
  );
}
