import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
export const Left = styled.View`
  flex: 0.7;
  justify-content: center;
  align-items: center;
`;
export const Right = styled.View`
  width: 25%;
  justify-content: center;
  align-items: center;
`;
export const Center = styled.View`
  width: 25%;
  justify-content: center;
  align-items: center;
`;
export const Row = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
