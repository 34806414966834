import React, { useState } from 'react';
import {
  Container,
  FormContainer,
  GradientOverlay,
  ImageWrapper,
  LeftSection,
  RightSection,
} from './resetPassword.style';
import Text from '../../components/text';
import { PasswordValidation } from '../../helpers/validation';
import Urls from '../../services/Urls';
import Colors from '../../constants/Colors';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import rightImg from '../../assets/images/resetImage.png';

const ResetPasswordScreen = ({ navigation }) => {
  const [passError, setPassError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');

  const resetAPICall = () => {
    const requestBody = {
      reset_code: 'CJaFDVa',
      new_password: password,
    };
    ajax
      .post(Urls.ResetPassword, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // alert(data.data?.message);
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const resetPasswordValidation = () => {
    let passwordValidate = PasswordValidation(password);
    if (passwordValidate.status) {
      resetAPICall();
    } else {
      setEmailError(passwordValidate?.status ? '' : passwordValidate?.msg);
    }
  };
  return (
    <Container>
      <LeftSection>
        <FormContainer>
          <Text fontSize={25} color={Colors.light.gradientColor1} fontWeight={450}>
            HUBITA
          </Text>
          <Text fontSize={30} marginBottom={30} marginTop={10} color={Colors.light.gradientColor1} fontWeight={450}>
            Forgot Password
          </Text>
          <Text fontSize={15} marginBottom={30} color={Colors.light.text} fontWeight={400}>
            Lorem ipsum dolor sit amet, consectetur {'\n'}adipiscing elit, sed do eiusmod tempor{'\n'}incididunt ut
            labore et dolore magna.
          </Text>
          <TextInputComponent
            error={passError}
            password
            title={'New Password'}
            onChangeText={(e) => setPassword(e)}
            colored
          />
          <TextInputComponent password title={'Confirm Password'} onChangeText={(e) => setConfirmPassword(e)} colored />
          <Button onPress={() => resetPasswordValidation()} childText={'Reset'} alignSelf={'flex-end'} />
        </FormContainer>
      </LeftSection>
      <RightSection>
        <ImageWrapper source={rightImg} />
        <GradientOverlay colors={['#7002BC90', '#3364F590']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 1 }} />
      </RightSection>
    </Container>
  );
};

export default ResetPasswordScreen;
