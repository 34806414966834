import React, { useState, useEffect } from 'react';
// import Container from '../../Container/Container';
import { ListItem } from '../atoms/listitem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import variables from '../atoms/variables.json';
import { TextInput } from '../atoms/textinput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import BottomButton from '../atoms/bottomButton';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

import { DocumentWithStatusCard } from './../atoms/documentWithStatusCard';
import ToolTip from '../atoms/ToolTip';
import Checkbox from '@material-ui/core/Checkbox';
import { GetFilename, getHoldingDepositStatusData } from '../helpers';
// import Movein from './../../../assets/svgIcons/OpenFlo/MoveIn.svg';
import { Otp } from '../atoms/otp';
const layoutType = getPlatformType();
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  RowContainer,
  FormContainer,
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
// import { tenantActions } from '_actions';
import BackComponent from '../atoms/backComponent';
import OpenFlowModal from '../atoms/modal';
import CommanModal from './commanModal';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import { Radio, RadioGroup } from '@material-ui/core';
import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
function MovingIn(props) {
  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const [selectedTds, setSelectedTds] = React.useState();
  const [inventoryDoc, setInventoryDoc] = React.useState([]);
  const [teanats, setTeanats] = React.useState([]);
  const [showModal, setShowModal] = React.useState('');
  const [counterValue, setCounterValue] = React.useState(0);
  const [confirmDateCheckbox, setConfirmDateCheckbox] = React.useState(false);
  const [notifyDateCheckbox, setNotifyDateCheckbox] = React.useState(false);
  const [selectedAccount, setselectedAccount] = React.useState('');
  const [accountButtonText, setAccountButtonText] = React.useState('Add Account');
  const [openAccountModal, setAccountModal] = React.useState(false);
  const [inventoryReq, setInventoryReq] = React.useState(false);
  const [checkinReq, setCheckinReq] = React.useState(false);
  const [account_id, setAccount_id] = React.useState('');
  const [newAccAdded, setNewAccAdded] = React.useState('');
  const [sortCode, setsortCode] = React.useState('');
  const [accountName, setAccountName] = React.useState('');
  const [payeeRef, setPayeeRef] = React.useState('');
  const move_in_data = useSelector((state) => state?.tenant?.moveInData);
  const globalState = useSelector((state) => state?.tenant);
  const [dateDepositReceived, setdateDepositReceived] = React.useState(null);
  const [offerStatus, setOfferStatus] = React.useState(0);
  const [Accounts, setAccounts] = React.useState([]);
  const [showSuccessModal, setSuccessModal] = React.useState(false);

  const modalGlobalObject = useSelector((state) => state?.tenant?.moveInData);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });

  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
      setcollectDeposit(move_in_data?.collectDeposit);
    }
  }, [modalGlobalObject]);
  let initialValues = {
    total_amount: move_in_data ? move_in_data?.total_amount : '',
    holding_deposit_paid: move_in_data?.holdingDeposit?.holding_desposit_amount
      ? move_in_data?.holdingDeposit?.holding_desposit_amount
      : 'N/A',
    initial_rental_payment: move_in_data ? move_in_data?.initial_rental_payment : '',
    rental_deposit: move_in_data ? move_in_data?.rental_amount : 0,
    check_in_date: move_in_data?.monies?.checkin_date
      ? moment(move_in_data.monies?.checkin_date).format('YYYY-MM-DD')
      : null,
    inventory_check_in_date: move_in_data?.monies?.inventory_check_date
      ? moment(move_in_data.monies?.inventory_check_date).format('YYYY-MM-DD')
      : null,
  };
  const [fieldsValue, setFieldValues] = React.useState(initialValues);
  const [tdsArry, setTdsArray] = React.useState([]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
      // dispatch(tenantActions.getMoveIn(props?.match?.params?.id));
    }
  }, [props?.match?.params?.id]);

  useEffect(() => {
    // dispatch(tenantActions.getTdsData());
  }, []);

  useEffect(() => {
    if (globalState?.tdsData?.schemes) {
      setTdsArray(globalState?.tdsData?.schemes);
    }
  }, [globalState?.tdsData?.schemes]);

  useEffect(() => {
    if (globalState?.moveInData?.certicicateRequests) {
      let { inventory, checkin } = globalState?.moveInData?.certicicateRequests;
      if (inventory && inventory.length) {
        setInventoryReq(inventory[0]);
      }

      if (checkin && checkin.length) {
        setCheckinReq(checkin[0]);
      }
    }
  }, [globalState?.moveInData?.certicicateRequests]);

  //set account
  useEffect(() => {
    if (move_in_data?.account) {
      if (move_in_data?.accounts && move_in_data?.accounts?.length) {
        let filter = move_in_data?.accounts.filter((data, index) => selectedAccount === data?.openflow_bank_account_id);
        if (filter?.length) {
          let data = filter[0];
          setAccount_id(data?.account_id);
          setAccountName(data?.account_name);
          setsortCode(data?.sort_code);
        }
      }
    }
  }, [selectedAccount, move_in_data?.account]);

  useEffect(() => {
    if (move_in_data?.accounts && move_in_data?.accounts.length) {
      setAccounts(move_in_data.accounts);
    }
    if (move_in_data?.flow?.movein_payee_ref) {
      setPayeeRef(move_in_data?.flow?.movein_payee_ref);
    }
    if (move_in_data?.account) {
      const selectedAccount = move_in_data.accounts.find((val) => {
        if (val?.openflow_bank_account_id === move_in_data.account?.openflow_bank_account_id) {
          return val;
        }
      });
      setselectedAccount(selectedAccount);
      setAccountButtonText('Update Account');
      setAccountName(selectedAccount?.account_name);
      setAccount_id(selectedAccount?.account_id);
      setsortCode(selectedAccount?.sort_code);
    }
  }, [move_in_data?.accounts, move_in_data?.account]);

  useEffect(() => {
    setFieldValues(initialValues);
    if (move_in_data?.monies?.inventory_documents && move_in_data?.monies?.inventory_documents?.length) {
      setInventoryDoc(move_in_data?.monies.inventory_documents);
    }
    if (move_in_data?.monies?.inventory_documents && move_in_data?.monies?.inventory_documents?.length) {
      setInventoryDoc(move_in_data?.monies.inventory_documents);
    } else {
      setInventoryDoc([]);
    }
    if (move_in_data?.monies?.tds_pdf) {
      setSelectedTds(move_in_data?.monies?.tds_pdf);
    }

    setcollectDeposit(move_in_data?.collectDeposit);

    if (move_in_data?.tenants && move_in_data?.tenants.length) {
      setTeanats(move_in_data?.tenants);
      let ar = move_in_data?.tenants;
      let movein_money_status = 0;
      ar.some(function (item, index) {
        if (item?.movein_money_status > 0) {
          movein_money_status = item?.movein_money_status;
          return true;
        }
      });
      setOfferStatus(movein_money_status);
    }
  }, [
    move_in_data?.total_amount,
    move_in_data?.rental,
    move_in_data?.initial_rental_payment,
    move_in_data?.holding_deposit_paid,
    move_in_data?.flow,
    move_in_data?.monies?.tds,
    move_in_data?.monies?.inventory_documents,
    move_in_data?.collectDeposit,
  ]);

  const getCheckedStatus = () => {
    if (teanats && teanats.length) {
      let isChecked = true;
      for (let i = 0; i < teanats.length; i++) {
        if (teanats[i]?.movein_money_status != 3) {
          isChecked = false;
          break;
        }
      }
      return isChecked;
    }
    return false;
  };
  const accountDetails = () => (
    <div>
      <>
        <RowContainer>
          <FormContainer>
            <TextInput
              title={'Account Name'}
              value={accountName}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </FormContainer>
        </RowContainer>
        <RowContainer>
          <FormContainer>
            <Otp
              title={'Sort Code'}
              no={6}
              value={sortCode}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setsortCode(e.target.value)}
            />
          </FormContainer>
          <FormContainer>
            <Otp
              value={account_id}
              title={'AccountNo'}
              no={8}
              containerStyles={{ width: '100%' }}
              onChange={(e) => setAccount_id(e.target.value)}
            />
          </FormContainer>
        </RowContainer>
      </>
      <RowContainer
        style={{
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: 10,
        }}
      >
        <Button
          onClick={() => {
            _onMovinData(false, false);
            setAccountButtonText('Update Account');
          }}
          style={{
            marginTop: '10px',
            borderColor: variables.purple1,
          }}
          title={accountButtonText}
          type="stripe"
        />
      </RowContainer>
    </div>
  );
  const _confirmReceiptAll = () => {
    let arr = [];
    teanats.forEach((data) => {
      arr.push({
        ...data,
        movein_money_status: 3,
        movein_money_received_date: data.movein_money_received_date ? data.movein_money_received_date : new Date(),
      });
    });
    _confirmReceipt({ tenants: arr });
  };
  const _confirmReceipt = (data) => {
    // dispatch(tenantActions.confirmMovin(data, flowId));
  };
  const _onMovinData = (backNavigation, isRequest, modalData) => {
    let data = {
      inventory_check_date: fieldsValue?.inventory_check_in_date,
      checkin_date: fieldsValue?.check_in_date,
      inventory_id: null,
      deposit_schema: [selectedTds],
      payee_ref: payeeRef,
      account: {
        account_id: account_id,
        sort_code: sortCode,
        account_name: accountName,
      },
      inventory: {
        inventory_documents: inventoryDoc,
      },
      collectDeposit: collectDeposit,
      ...modalData,
    };
    // dispatch(tenantActions.saveMoveinData(data, flowId, backNavigation, isRequest));
    setAccountModal(false);
  };
  // const _onAddRentalData = (url) => {
  //   dispatch(
  //     tenantActions.addRentalOffer(
  //       {
  //         account: {
  //           account_id: account_id,
  //           sort_code: sortCode,
  //           account_name: accountName,
  //         },
  //         payee_ref: payeeRef,
  //       },
  //       flowId,
  //       url,
  //       true
  //     )
  //   );
  // };
  const _onArrangeCheckIn = () => {
    let data = {
      check_in_date: fieldsValue.check_in_date,
      inventory_check_in_date: fieldsValue.inventory_check_in_date,
      additional_areas: counterValue,
      key_drop_off: fieldsValue.key_drop_off,
      key_pick_up: fieldsValue.key_pick_up,
      notes: fieldsValue.notes,
      notifyDateCheckbox: notifyDateCheckbox,
      confirmDateCheckbox: confirmDateCheckbox,
    };
    // dispatch(tenantActions.arrangeCheckIn(data, flowId));
    setShowModal(false);
  };

  const addDoc = (data) => {
    let teampArr = [
      ...inventoryDoc,
      {
        document_url: data[0],
      },
    ];

    setInventoryDoc(teampArr);
  };

  const removeDoc = (docIndex) => {
    let teampArr = [...inventoryDoc];
    teampArr.splice(docIndex, 1);
    setInventoryDoc(teampArr);
  };
  const renderModal = () => (
    <CommanModal
      onSubmitted={() => setShowModal(false)}
      type={'inventory'}
      initialCost={65}
      minRange={1}
      flowID={flowId}
    />
  );
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const [collectDeposit, setcollectDeposit] = React.useState(false);
  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Moving In'}
          description={`A breakdown of all "Moving in" Monies, including the prescribed information and relevant requirements of the  tenant's deposit.`}
          showPropertyCard
          // icon={Movein}
        />

        <BackComponent
          id={flowId}
          isComplete={move_in_data?.flow?.move_in_status === 1 ? true : false}
          flowtype={'move_in_status'}
        />

        <React.Fragment>
          <SubHeading>Deposit & Rent</SubHeading>
          <CardBox hasBorderRadius={true} backColor={variables.powderBlue}>
            <SubHeading>Deposit & upfront rental payment</SubHeading>
            <SubHeading color={variables.scooter}>
              The Deposit demand is auto-calculated to a sum equal to 5 weeks rent. Demand for this payment together
              with the "Initial" first rental payment will be sent to the Applicant Tenant.
            </SubHeading>
          </CardBox>
          <LineSeperator />
          <SubHeading>Move in Monies</SubHeading>
          <CardBox hasShadow hasBorderRadius style={{ position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Move in Monies Collection'}
              ContentData={[
                `TIP 1`,
                `Move in Monies is usually collected from only the Lead tenant`,
                'TIP 2',
                'For AST tenancies, the Tenant Fee Act stipulates that you may take a MAXIMUM of one weeks rent for Move in Monies',
              ]}
            />
            <SubHeading>
              There are two methods to collect the Move in Monies. <br />
              <br />
              You can either collect it:
              <br />
              1) ONE single amount from the lead tenant <br />
              2) Equal amounts, split between all tenants
              <br /> <br />
              How would you like to collect the Move in Monies?
            </SubHeading>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="isHoldingDepositSelected"
                name="isHoldingDepositSelected"
                value={collectDeposit}
                onChange={(e) => {
                  if (e?.target?.value === 'true') {
                    setcollectDeposit(true);
                  } else {
                    setcollectDeposit(false);
                  }
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="All from lead tenant"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="Equally between all tenants"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </CardBox>
          <CardBox style={{ position: 'relative', marginTop: 20, width: '100%' }} hasBorderRadius hasShadow>
            <ToolTip
              style={{ top: '20%', right: '-40%' }}
              heading={'Tooltip'}
              ContentData={[
                `Check that the "Move-In Monies" are correct and send a breakdown and demand to the Applicant Tenant.`,
                `Sometimes an "initial 1st rental payment" may be larger or smaller than the agreed monthly rent.`,
              ]}
            />
            <TextInput
              title={'Total Amount - Movie In Monies'}
              inputProps={{ readOnly: true }}
              value={move_in_data?.total_amount ? move_in_data?.total_amount.toFixed(2) : 0}
              wrapperstyle={{ width: '100%' }}
            />

            <TextInput
              title={'Holding Deposit Paid'}
              inputProps={{ readOnly: true }}
              value={
                move_in_data?.holdingDeposit?.is_holding_deposit_selected
                  ? '-£' + move_in_data?.holdingDeposit?.holding_desposit_amount?.toFixed(2)
                  : '0'
              }
              wrapperstyle={{
                display: 'flex',
                width: layoutType != 'phone' ? '70%' : '100%',
                alignItems: 'center',
                marginTop: '50px',
                justifyContent: 'space-between',
              }}
            />
            <TextInput
              title={'Initial Rental Payment'}
              value={fieldsValue.initial_rental_payment ? '£' + fieldsValue.initial_rental_payment : 'N/A'}
              inputProps={{ readOnly: true }}
              wrapperstyle={{
                display: 'flex',
                marginTop: '15px',
                width: layoutType != 'phone' ? '70%' : '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            />

            <TextInput
              title={'Tenancy Deposit Paid'}
              inputProps={{ readOnly: true }}
              value={move_in_data?.tenancy_deposit_amount ? '£' + move_in_data.tenancy_deposit_amount : 'N/A'}
              wrapperstyle={{
                display: 'flex',
                marginTop: '15px',
                width: layoutType != 'phone' ? '70%' : '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            />
          </CardBox>

          <>
            <LineSeperator />
          </>
          <SubHeading>Account Details</SubHeading>

          <CardBox style={{ position: 'relative', width: '100%' }} hasShadow hasBorderRadius>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Tooltip'}
              ContentData={[
                `If you would like the "Move in monies" to be paid into a different account, you can add a new account using (+) button`,
              ]}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5%',
              }}
            >
              <Select
                value={selectedAccount}
                style={{ width: '50%', height: '40px', borderWidth: 0 }}
                onChange={(e) => {
                  const selectedAc = e.target.value;
                  setselectedAccount(selectedAc);
                  setAccountName(selectedAc.account_name);
                  setAccount_id(selectedAc.account_id);
                  setsortCode(selectedAc.sort_code);
                  setAccountButtonText('Update Account');
                }}
                variant="outlined"
                name="selectedAccount"
                placeholder="duration"
              >
                {Accounts.map((data, i) => {
                  return (
                    <MenuItem value={data} key={'mi' + i}>
                      {data?.account_name}
                    </MenuItem>
                  );
                })}
              </Select>

              <AddCircleOutlineOutlinedIcon
                onClick={() => {
                  setselectedAccount(null);
                  setAccountButtonText('Add Account');
                  setNewAccAdded(true);
                  setAccount_id('');
                  setPayeeRef('');
                  setAccountName('');
                  setsortCode('');
                  setAccountModal(true);
                }}
                style={{
                  marginLeft: '10px',
                  fontSize: '30px',
                  color: variables.purple1,
                }}
              />
            </div>

            <>
              <RowContainer>
                <FormContainer>
                  <TextInput
                    title={'Account Name'}
                    // disabled={selectedAccount?.account_name}
                    value={accountName}
                    containerstyles={{ width: '100%' }}
                    onChange={(e) => setAccountName(e.target.value)}
                  />
                </FormContainer>
              </RowContainer>
              <RowContainer>
                <FormContainer>
                  <Otp
                    title={'Sort Code'}
                    no={6}
                    // disabled={selectedAccount?.sort_code}
                    value={sortCode}
                    containerstyles={{ width: '100%' }}
                    onChange={(e) => setsortCode(e.target.value)}
                  />
                </FormContainer>
                <FormContainer>
                  <Otp
                    value={account_id}
                    title={'AccountNo'}
                    no={8}
                    // disabled={selectedAccount?.account_id}
                    containerstyles={{ width: '100%' }}
                    onChange={(e) => setAccount_id(e.target.value)}
                  />
                </FormContainer>
              </RowContainer>
            </>
            {accountButtonText == 'Update Account' && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => _onMovinData(false, false)}
                  style={{
                    marginTop: '10px',
                    borderColor: variables.purple1,
                    // color: "#FC4850",
                  }}
                  title={accountButtonText}
                  type="stripe"
                />
              </div>
            )}
          </CardBox>
          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <CardBox hasShadow hasBorderRadius style={{ width: layoutType == 'phone' ? '100%' : '50%' }}>
              <FormContainer>
                <TextInput
                  value={payeeRef}
                  title={'Payee Ref'}
                  isfullwidth
                  containerstyles={{ width: '100%' }}
                  onChange={(e) => setPayeeRef(e.target.value)}
                  // error={!payeeRef}
                />
              </FormContainer>
            </CardBox>
          </div>

          {offerStatus === 0 ? null : (
            <>
              <LineSeperator />

              {teanats && teanats.length ? (
                <>
                  <TlyfeSelector style={{ marginTop: 0, justifyContent: 'space-between' }}>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Heading>Receiving Move in Monies</Heading>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FormControl component="fieldset">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={getCheckedStatus()}
                              style={{ color: variables.heliotrope }}
                              onChange={() => _confirmReceiptAll()}
                              name="isReferencingComplete"
                            />
                          }
                          label="Confirm All"
                          style={{ color: '#000', fontSize: 10 }}
                        />
                      </FormControl>
                    </div>
                  </TlyfeSelector>

                  {teanats.map((data, index) => {
                    return (
                      <>
                        <CardBox style={{ marginBottom: '5%' }} hasShadow hasBorderRadius>
                          <RowContainer>
                            <div
                              style={{
                                flex: 0.7,
                                paddingRight: '10%',
                                paddingLeft: '4%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <CardBox
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                                hasBorderRadius
                                backColor={getHoldingDepositStatusData(data?.movein_money_status).backgroundColor}
                                borderColor={getHoldingDepositStatusData(data?.movein_money_status).borderColor}
                              >
                                <SubHeading style={{ margin: 0 }}>
                                  {data?.app_user?.user?.first_name + ' ' + data?.app_user?.user?.last_name}
                                </SubHeading>

                                <SubHeading
                                  style={{
                                    margin: 0,
                                    color: getHoldingDepositStatusData(data?.movein_money_status).color,
                                  }}
                                >
                                  {getHoldingDepositStatusData(data?.movein_money_status).DateText}{' '}
                                  {moment(getHoldingDepositStatusData(data?.movein_money_status, data).Date).format(
                                    'YYYY/MM/DD'
                                  )}
                                </SubHeading>

                                <SubHeading
                                  style={{
                                    margin: 0,
                                    color: getHoldingDepositStatusData(data?.movein_money_status).color,
                                  }}
                                >
                                  {getHoldingDepositStatusData(data?.movein_money_status).Text}
                                </SubHeading>
                              </CardBox>
                            </div>
                            <div style={{ flex: 0.3 }}></div>
                          </RowContainer>
                          <RowContainer>
                            <div
                              style={{
                                flex: 0.7,
                                paddingRight: '10%',
                                paddingLeft: '4%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <TextInput
                                disabled={true}
                                value={
                                  collectDeposit
                                    ? data?.is_lead
                                      ? fieldsValue.total_amount.toFixed(2)
                                      : 0
                                    : (fieldsValue.total_amount / teanats.length).toFixed(2)
                                }
                                title={'Amount (£)'}
                                containerstyles={{ width: '100%' }}
                              />
                            </div>
                            <div
                              style={{
                                flex: 0.7,
                                paddingRight: '10%',
                                paddingLeft: '4%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            ></div>
                            <div
                              style={{
                                flex: 0.3,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <TlyfeButtonWrapper>
                                <Button
                                  onClick={() =>
                                    _confirmReceipt({
                                      tenants: [
                                        {
                                          ...data,

                                          movein_money_status: data.movein_money_status === 3 ? 2 : 3,
                                          date_received_deposit: data.movein_money_received_date
                                            ? data.movein_money_received_date
                                            : new Date(),
                                        },
                                      ],
                                    })
                                  }
                                  style={{
                                    marginTop: '15px',
                                    backgroundColor:
                                      data.movein_money_status != 3 ? 'transparent' : variables.puregreen,
                                  }}
                                  title={data.movein_money_status != 3 ? 'Confirm Receipt' : 'Revoke Confirmation'}
                                  color={data.movein_money_status != 3 ? variables.puregreen : variables.peachgreen}
                                  textColor={'#000'}
                                  type={data.movein_money_status != 3 ? 'custom' : 'solid'}
                                />
                              </TlyfeButtonWrapper>
                            </div>
                            {/*
                             */}
                          </RowContainer>
                        </CardBox>
                      </>
                    );
                  })}
                </>
              ) : null}
            </>
          )}
          <LineSeperator />

          <CardBox hasBorderRadius hasShadow style={{ marginTop: '30px', position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Tooltip'}
              ContentData={[
                'You will need to have an inventory as evidence in the event that your client wishes to make a claim against the deposit for damage.',
              ]}
            />
            <SubHeading style={{ fontSize: '13px' }}>
              <strong>Inventory & Check in</strong>
              <br />
              <br />
              A national inventory service has integrated with OpenFlow to simplify the process by pre-populating all
              required details. You can either purchase this service here on a tenancy by tenancy basis or upload your
              own version.
              <br />
              <br />
              <br />
            </SubHeading>

            <TlyfeButtonWrapper>
              <Button
                onClick={() => setShowModal(true)}
                style={{ marginTop: '40px', backgroundColor: variables.navyblue }}
                title={'Arrange Check In'}
                type="solid"
              />
            </TlyfeButtonWrapper>
            {inventoryReq && <strong>Inventory Details</strong>}
            {inventoryReq && (
              <div
                style={{
                  margin: '10px',
                  padding: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  borderRadius: 10,
                  width: '70%',
                  backgroundColor: variables.lightyellow,
                  border: '1px solid ' + variables.yellow,
                }}
              >
                <SubHeading>
                  {' '}
                  Appointment Date :{' '}
                  {inventoryReq.ammendDate ? moment(inventoryReq?.ammendDate).format('YYYY/MM/DD') : 'N/A'}
                </SubHeading>
                <SubHeading> Status : {inventoryReq.status} </SubHeading>
              </div>
            )}
            {checkinReq && <SubHeading>Check In details</SubHeading>}

            {checkinReq && (
              <div
                style={{
                  display: 'flex',
                  margin: '10px',
                  padding: '10px',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  borderRadius: 10,
                  width: '70%',
                  backgroundColor: variables.peachgreen,
                  border: '1px solid ' + variables.puregreen,
                }}
              >
                <SubHeading> CheckIn </SubHeading>
                <SubHeading>
                  {' '}
                  Appointment Date :{' '}
                  {checkinReq.ammendDate ? moment(checkinReq?.ammendDate).format('YYYY/MM/DD') : 'N/A'}
                </SubHeading>
                <SubHeading> Status : {checkinReq.status} </SubHeading>
              </div>
            )}
            {checkinReq?.management_link ? (
              <Button
                type="solid"
                title="Management"
                onClick={(data) => {
                  openInNewTab(checkinReq?.management_link);
                }}
                style={{ marginLeft: '4%' }}
              />
            ) : null}
          </CardBox>
          <CardBox hasBorderRadius hasShadow style={{ marginTop: '30px', width: '100%' }}>
            <DocumentWithStatusCard
              flowID={flowId}
              showFileIcon
              isSeen={move_in_data?.monies?.inventory_is_seen}
              docValue={inventoryDoc}
              onClick={(data) => addDoc(data)}
              onRemove={(docIndex) => removeDoc(docIndex)}
              name={'Uploaded Inventory Documents'}
            />
          </CardBox>
          <LineSeperator />
          <CardBox hasBorderRadius hasShadow style={{ width: '100%' }}>
            <SubHeading style={{ fontSize: '13px' }}>
              <strong>Tenancy Deposit Scheme Information</strong>
              <br />
              <br />
              Please select the TDS scheme you will be using to protect this deposit and upload any additional
              information document you wish to share with your tenant(s).
              <br />
              <br />
              Your tenants will be sent this document for their information.
              <br />
              <br />
              Please select the deposit scheme you will use to protect this deposit
              <br />
              <br />
            </SubHeading>
            <div
              style={{
                display: 'flex',
                marginTop: '20px',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '80%',
              }}
            >
              <SubHeading style={{ fontSize: '14px' }}>Your TDS Scheme-</SubHeading>
              <Select
                value={selectedTds ? selectedTds : ''}
                style={{ width: '80%', borderWidth: 0, height: '42px' }}
                onChange={(e) => setSelectedTds(e.target.value)}
                variant="outlined"
                name="rental_type"
                placeholder="duration"
              >
                {tdsArry.map((data, i) => (
                  <MenuItem value={data?.file} key={'tdsmi' + i}>
                    {data.name}
                  </MenuItem>
                ))}
                {/* <MenuItem value={"20"}>annually</MenuItem>
                <MenuItem value={"30"}>quarterly</MenuItem> */}
              </Select>
            </div>
            {selectedTds ? (
              <CardBox
                hasShadow={true}
                hasBorderRadius={true}
                style={{
                  background: variables.lightGreen,
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 15,
                  paddingLeft: 15,
                  marginBottom: '5%',
                  width: '50%',
                  marginTop: '5%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ fontSize: '14px', width: 200 }}>{GetFilename(selectedTds) || 'file'}</div>
                  <a target="_blank" href={selectedTds}>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </span>
                  </a>
                </div>
              </CardBox>
            ) : null}
          </CardBox>
          <BottomButton
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            onRightClick={() =>
              modalState?.send_to_tenant
                ? _onMovinData(true, true, { send_to_tenant: true, requestAgain: true })
                : setSuccessModal(true)
            }
            onContinue={() => _onMovinData(true, true, { send_to_tenant: false, requestAgain: false })}
          />
        </React.Fragment>
      </CardWrapper>
      {showModal && (
        <OpenFlowModal
          showModal={showModal}
          setShowModal={setShowModal}
          modalBody={renderModal()}
          showFooter={false}
          modalHeading={'Inventory & Check in'}
          modalWidth={600}
        />
      )}
      {openAccountModal && (
        <OpenFlowModal
          showModal={openAccountModal}
          setShowModal={setAccountModal}
          modalBody={accountDetails()}
          showFooter={false}
          modalHeading=""
          modalWidth={600}
        />
      )}
      <SaveandContinueModal
        modalState={modalState}
        visible={showSuccessModal}
        heading={'Move in Monies'}
        subHeading={[
          `Do you wish to send the "move in monies " request to your tenant(s)?`,
          `Remember,  if you don't toggle to "send", your tenant(s) will not receive the "Move in Monies" request.`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        showNote
        onOkPress={(data) => {
          data.requestAgain = true;
          _onMovinData(true, true, data);
        }}
      />
    </Container>
  );
}
export default drawerHoc({
  Component: MovingIn,
  showBackButton: true,
});
