import React, { useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import Text from '../../components/text';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import { Header } from './header';
import Modal from 'modal-enhanced-react-native-web';
import MainDrawerMenu from './MainDrawer';
import getPlatformType from '../../helpers/helpers';
import { ImageWrapper2, ImageWrapper3, TopLastLeft, TopLastRight } from './hello.style';
import Footer from '../../components/footerComponent/customFooter';
import TypeWriter from 'react-native-typewriter';
import { navigate } from '../../navigation/RootNavigation';
import { useNavigation } from '@react-navigation/native';


export const Hello = (props) => {
    const [openDrawer, setDrawer] = useState(false);
    const layoutType = getPlatformType();
    const [visibleButton,setVisible]=useState(false);
    const navigation = useNavigation();

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <Header setDrawer={() => setDrawer(true)} />
            <View style={{ width: '100%', height: layoutType == "phone" ? 130 : 170, alignItems: layoutType == "phone" ? 'center' : 'flex-end', justifyContent: 'center' }}>
                <Image
                    source={require('../../assets/images/top_left.png.png')}
                    style={{ height: '100%', width: layoutType == "phone" ? 200 : 310 }}
                    resizeMode={'contain'}
                />
            </View>
            <TypeWriter fixed={true} style={{ fontFamily: 'poppins-light',textAlign:'center',fontSize:layoutType == "phone" ? 32 : 55 }}typing={1}>Manage your property</TypeWriter>
            <TypeWriter fixed={true} onTypingEnd={()=>setVisible(true)} initialDelay={1800} style={{ fontFamily: 'poppins-medium',textAlign:'center',fontSize:layoutType == "phone" ? 32 : 55 }}typing={1}>Yourself</TypeWriter>
            <Text fontSize={18} color={'#6D6D6D'} marginTop={15} style={{ fontFamily: 'roboto-light' }} align={'center'}>UK’s first FREE property management site{'\n'}
                for self managing landlords</Text>
            {layoutType == 'phone' && visibleButton && <TouchableOpacity style={{ marginVertical: 30, alignSelf: 'center', width: 150, height: 45, borderRadius: 100, backgroundColor: '#00BCD4', justifyContent: 'center', alignItems: 'center' }}
            onPress={() => navigate('RegisterScreen')}>
                <Text color={'white'} style={{ fontFamily: 'poppins-medium' }}>Register NOW</Text>
            </TouchableOpacity>}
            <View style={{ width: '100%', height: layoutType == "phone" ? 170 : 270, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <TopLastLeft source={require('../../assets/images/Group4.png')} />
                {layoutType != 'phone'&& visibleButton && <TouchableOpacity style={{ width: 150, height: 45, borderRadius: 100, backgroundColor: '#00BCD4', justifyContent: 'center', alignItems: 'center' }}
                onPress={() => navigate('RegisterScreen')}>
                    <Text color={'white'} style={{ fontFamily: 'poppins-medium' }}>Register NOW</Text>
                </TouchableOpacity>}
                <TopLastRight source={require('../../assets/images/Group5.png')} />
            </View>
            <View style={{ width: '100%', height: layoutType == "phone" ? 630 : 800, flexDirection: 'row', backgroundColor: '#E0E0E0' }}>
                {layoutType != 'phone' && <ImageWrapper2 source={require('../../assets/images/pixel.png')} />}
                <View style={{ width: layoutType == "phone" ? '100%' : '50%', height: '100%', padding: layoutType == "phone" ? 30 : 80 }}>
                    <Text fontSize={layoutType == "phone" ? 32 : 42} style={{ fontFamily: 'poppins' }} align={'left'}>Manage your {`\n`}property Yourself</Text>
                    {/* <TypeWriter style={{ fontFamily: 'poppins',textAlign:'left',fontSize:layoutType == "phone" ? 32 : 42 }}typing={1}>Manage your {`\n`}property Yourself</TypeWriter> */}

                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Image
                            source={require('../../assets/images/Logo/HubitaMobile.png')}
                            style={{ height: 96, width: 87, marginTop: 20, marginBottom: 20 }}
                            resizeMode={'contain'}
                        />
                        <Text marginTop={75} marginBottom={20} style={{ fontFamily: 'poppins' }} marginLeft={12} fontSize={20}>
                            {' '}
                            with MyHub™{' '}
                        </Text>
                    </View>
                    <Text fontSize={15} color={'#6D6D6D'} lineHeight={26} marginTop={layoutType == "phone" ? 15 : 30} style={{ fontFamily: 'roboto-light', width: layoutType == "phone" ? '100%' : '70%',letterSpacing:0.5 }}>{`Hubita is the free online property management portal, that  lets manage your property anywhere, anytime.
            
            
            
Manage your property portfolio compliantly using our FREE, award winning MyHub™ tool. Track your property price, buy gas and electricity certificates... and much much more.`}</Text>
                    <TouchableOpacity style={{ marginTop: layoutType != "web" ? 50 : 100, alignSelf: 'center', width: 150, height: 45, borderRadius: 100, backgroundColor: '#00BCD4', justifyContent: 'center', alignItems: 'center' }}
                    onPress={() => navigate('RegisterScreen')}>
                        <Text color={'white'} style={{ fontFamily: 'poppins-medium' }}>Register NOW</Text>
                    </TouchableOpacity>

                </View>

            </View>
            <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: layoutType == "phone" ? 30 : 120 }}>
                <Text style={{ fontFamily: 'poppins' }} align={'center'} fontSize={40}>MyHub™</Text>
                <Text fontSize={15} color={'#6D6D6D'} marginTop={layoutType == "phone" ? 15 : 30} lineHeight={26} style={{ fontFamily: 'roboto-light', width: layoutType == "phone" ? '85%' : '50%',letterSpacing:0.5 }}>Manage your property portfolio using MyHub™ and take control of your property.{`\n`}
                    Track your property value, get certificate expiry notifications, buy gas and electricity certificates, arrange
                    maintenance jobs and store all your documents in one place.
                </Text>
            </View>
            <View style={{width:'100%',flexDirection:layoutType=="phone"?'column':'row',alignItems:layoutType=="phone"?'center':'flex-start',justifyContent:'space-evenly',marginTop:layoutType=="phone"?30:120,marginBottom:50 }}>
                <View style={{width:layoutType=="phone"?'80%':'25%'}}>
                    <View style={{width:'100%',justifyContent:'center',alignItems:'center'}}>
                    <Image
                source={require('../../assets/images/notification.png.png')}
                style={{ height: 40, width: 40 }}
                resizeMode={'contain'}
              />
                        <Text marginTop={8} marginBottom={8} fontSize={24} style={{fontFamily:'poppins'}}>Notifications</Text>
                        <Text  lineHeight={26} fontSize={15} align={'left'} color={'#6D6D6D'} style={{fontFamily:'roboto-light',letterSpacing:0.5}}>Get auto notification of expiring certificates, end of your mortgage discount period and many other compliance issues. Never get caught out on compliance issues again.</Text>

                    </View>
                    <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:layoutType=="phone"?30:120}}>
                    <Image
                source={require('../../assets/images/document.png.png')}
                style={{ height: 40, width: 40 }}
                resizeMode={'contain'}
              />
                        <Text marginTop={8} marginBottom={8} fontSize={24} style={{fontFamily:'poppins'}}>Documents</Text>
                        <Text  lineHeight={26} fontSize={15} align={'left'} color={'#6D6D6D'} style={{fontFamily:'roboto-light',letterSpacing:0.5}}>MyHub’s smart document storage facility makes it easy for landlords to digitise all their documents, from compliance certificates and tenancy agreements to invoices and purchase orders. All conveniently located in one place and easily retrievable wherever and whenever needed.</Text>

                    </View>
                </View>
                <View style={{width:layoutType=="phone"?'80%':'25%'}}>
                {layoutType != 'phone' ? <ImageWrapper3 source={require('../../assets/images/phoneimg.png')} /> : null}
                </View>
                <View style={{width:layoutType=="phone"?'80%':'25%'}}>
                    <View style={{width:'100%',justifyContent:'center',alignItems:'center',marginTop:30}}>
                    <Image
                source={require('../../assets/images/network.png.png')}
                style={{ height: 40, width: 40 }}
                resizeMode={'contain'}
              />
                        <Text marginTop={8} marginBottom={8} fontSize={24} align={'center'} style={{fontFamily:'poppins'}}>Payment and Reminder Tracking</Text>
                        <Text marginTop={10}  lineHeight={26} fontSize={15} align={'left'} color={'#6D6D6D'} style={{fontFamily:'roboto-light',letterSpacing:0.5}}>MyHub™ allows our clients to create bespoke reminders and update their payments to keep in top of their property management duties.</Text>

                    </View>
                  
                </View>
                {/* <a href="tlyfe://">open app</a> */}
            </View>
            <Footer navigation = {navigation}/>
            <Modal
                isVisible={openDrawer}
                animationIn="slideInRight"
                animationOut="slideOutRight"
                animationInTiming={300}
                animationOutTiming={300}
                coverScreen={false}
                hasBackdrop={false}
                backdropOpacity={0}
                onBackdropPress={() => setDrawer(false)}
                style={{ margin: 0, width: '100%', right: 0 }}
            >
                <View style={{ width: '50%', height: '100%', position: 'absolute', right: 0, top: 0 }}>
                    <MainDrawerMenu setDrawer={setDrawer} />
                </View>
            </Modal>
  
        </View>
    )
}