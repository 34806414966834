import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Typography;
Typography = styled.Text`
  font-family: 'Lato';
  font-style: normal;
  font-weight: ${(props) => (props.bold ? props.bold : 800)};
  font-size: ${(props) =>
    props.size
      ? layoutType == 'phone'
        ? parseInt(props.size) - 5 + 'px'
        : layoutType == 'tab'
        ? parseInt(props.size) - 1 + 'px'
        : parseInt(props.size) - 1 + 'px'
      : '19px'};
  text-align: ${(props) => (props.align ? props.align : 'center')};
  color: ${(props) => (props.color ? props.color : '#FAFAFA')};
  margin-top: ${(props) => (props.top ? props.top : '0px')};
  margin-bottom: ${(props) => (props.bottom ? props.bottom : '0px')};
  text-decoration-line:${(props) => (props.underline ? 'underline':"none")}; ;
`;
export {
  Typography,
};
