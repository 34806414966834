import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, TableTools , HeaderView ,ContainerList;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 65%;
    // margin-left: 320px;
    padding: 10px;
    margin-top: 20px;
  `;
  ContainerList = styled.View`
  width: 100%;
  padding-top: 25px;
  padding-horizontal: 20px;
  margin-vertical: 15px;
  padding-bottom: 10px;
  border-radius: ${(props) => props.borderRadius || 20}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
`;
  TableTools = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    z-index: 10000;
  `;
  HeaderView = styled.View`
flex-direction: row;
justify-content: space-between;
margin-bottom: 15px;
`;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  ContainerList = styled.View`
  width: 100%;
  padding-top: 10px;
  padding-horizontal: 20px;
  margin-vertical: 10px;
  border-radius: ${(props) => props.borderRadius || 20}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
`;
  TableTools = styled.View`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    z-index: 10000;
  `;
  HeaderView = styled.View`
flex-direction: row;
justify-content: space-between;
margin-bottom: 15px;
`;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 90%;
    padding: 10px;
    // margin-left: 10%;
  `;
  ContainerList = styled.View`
  width: 100%;
  padding-top: 25px;
  padding-horizontal: 20px;
  margin-vertical: 15px;
  padding-bottom: 10px;
  border-radius: ${(props) => props.borderRadius || 20}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: 0 6px 4px rgba(50, 99, 244, 0.05);
`;
  TableTools = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 10000;
  `;
  HeaderView = styled.View`
flex-direction: row;
justify-content: space-between;
margin-bottom: 15px;
`;
}
export { ContainerScrollView, Container, TableTools ,HeaderView ,ContainerList};
