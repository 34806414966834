import React, { useState } from 'react';
import { View, SafeAreaView, Platform, ScrollView, Linking } from 'react-native';
import { Container, MediaWrapper, TitleText, FooterBox, MediaVideoBox, MediaVideoDesc } from './styles';
import MediaDetails from './mediaDetails';

const layoutType = getPlatformType();
// import WideButton from '../../commonComponents/wideButton';
import MediaNewsHub from './mediaNewsHub';
import MediaBlogHub from './mediaBlogHub';
import MediaVideoHub from './mediaVideoHub';
import getPlatformType from '../../helpers/helpers';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';

const MediaHubDetails = (props) => {
  const [selectedMedia, setSelectedMedia] = useState(props?.route?.params?.data);

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const tabList = [
    {
      label: 'News',
      target: <MediaNewsHub />,
      targetfooter: null,
    },
    {
      label: 'Blog',
      targetfooter: null,
      target: <MediaBlogHub />,
    },
    {
      label: 'Video',
      target: <MediaVideoHub />,
      targetfooter: null,
    },
  ];

  const setCurrentTab = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <SafeAreaView style={{ backgroundColor: '#FAFAFA' }}>
      <ScrollView>
        <Container style={{ paddingBottom: 100 }}>
          <View style={{}}>
            <MediaDetails selectedPost={selectedMedia} />
            <FooterBox
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '100%',
                width: '100%',
              }}
            >
              <Button
                onPress={() => {
                  navigate('MediaHub Details', {
                    data: data.info_id,
                  });
                }}
                onPress={() => navigate('MediaHub')}
                borderRadius={15}
                type={'stripe'}
                childText="Back"
                height={layoutType == 'phone' ? 40 : 30}
                width={300}
              />
            </FooterBox>
          </View>
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
};

export default MediaHubDetails;
