import React, { useEffect } from 'react';
import { ListItem } from '../atoms/listitem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import variables from '../atoms/variables.json';
import { TextInput } from '../atoms/textinput';
import PersonIcon from '@material-ui/icons/Person';
import BottomButton from '../atoms/bottomButton';
import ContactCard from '../atoms/contactCard';
import ToolTip from '../atoms/ToolTip';
import Checkbox from '@material-ui/core/Checkbox';
import PropertyCard from '../atoms/PropertyCard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import DOC_ICON from './../../../assets/svgIcons/OpenFlo/Documentation.svg';

import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  TlyfeButtonWrapper,
  FormContainer,
  SubHeading,
  RowContainer,
  TextBox,
  ButtonRowContainer,
} from './style';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import { DocumentCard } from '../atoms/documentCard';
import BackComponent from '../atoms/backComponent';
import { useDispatch, useSelector } from 'react-redux';
// import { tenantActions, alertActions } from '_actions';
import ToggleComplete from '../atoms/toggleComplete';
// import Constant from 'config/Constant';
import CommanModal from './commanModal';
import OpenFlowModal from '../atoms/modal';
// import StringConstatnt from '../../../utils/constants';
// import { useHistory } from 'react-router-dom';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import Container from '../atoms/Container/Container';
import DrawerHoc from '../../../../components/drawerHOC';
import getPlatformType from '../../../../helpers/helpers';
let landlordModal = {
  title: '',
  name: '',
  first_name: '',
  last_name: '',
  is_lead: false,
  correspondence_address: '',
  post_code: '',
  email: '',
  number: '',
  is_england_address: true,
  number_england: '',
  correspondence_address_england: '',
  post_code_england: '',
  email_england: '',
  showForm: false,
};
const layoutType = getPlatformType();
const PropertyDocumentation = (props) => {
  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const [price, setPrice] = React.useState({
    gas_safety_certificate: 0,
    epc: 0,
    eicr: 0,
    pat: 0,
  });

  const [showModal, setModal] = React.useState(null);
  const [showNoticeModal, setShowNoticeModal] = React.useState(false);
  const [gasSafteyCer, setGasSafetyCer] = React.useState(null);
  const [gasSafteyCerReq, setGasSafetyCerReq] = React.useState(false);
  const [epcCerReq, setEpcCerReq] = React.useState(false);
  const [eicCerReq, setEicCerReq] = React.useState(false);
  const [patCerReq, setPatCerReq] = React.useState(false);
  const [showAgain, setShowAgain] = React.useState(false);
  const [epcCer, setEpcCer] = React.useState(null);
  const [eicCer, setEicCer] = React.useState(null);
  const [patCer, setPatCer] = React.useState(null);
  const [lisc, setLiscCer] = React.useState(null);
  const [gasSafteyCerIsSeen, setGasSafetyCerIsSeen] = React.useState(false);
  const [epcCerIsSeen, setEpcCerIsSeen] = React.useState(false);
  const [eicCerIsSeen, setEicCerIsSeen] = React.useState(false);
  const [patCerIsSeen, setPatCerIsSeen] = React.useState(false);
  const [liscIsSeen, setLiscCerIsSeen] = React.useState(false);
  const [showAddLandLord, setShowAddLandLord] = React.useState(false);
  const [fieldsValue, setFieldValues] = React.useState(landlordModal);
  const [landlords, setLandlords] = React.useState([]);
  const [closePopup, setClosePopup] = React.useState(false);
  const [userSelection, setUserSelection] = React.useState(null);
  const [is_show_document_popup, setIs_show_document_popup] = React.useState(false);
  const [mailToUpdateAsLead, setMailToUpdateAsLead] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [showSuccessModal, setSuccessModal] = React.useState(false);

  const globalState = useSelector((state) => state?.tenant?.tenantDocs?.data);
  const lisenceData = useSelector((state) => state?.tenant?.lisenceData);
  const priceData = useSelector((state) => state?.tenant?.flowIdInfo?.product_prices);
  const modalGlobalObject = useSelector((state) => state?.tenant?.tenantDocs);
  const [modalState, setModalState] = React.useState({ send_to_tenant: true });
  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject?.data?.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);

  const _save = (back, sendDocToTeanant, data = null) => {
    let obj = {
      certificates_gas: [
        {
          certificate_url: gasSafteyCer,
          is_seen: gasSafteyCerIsSeen,
        },
      ],
      certificates_epc: [
        {
          certificate_url: epcCer,
          is_seen: epcCerIsSeen,
        },
      ],
      certificates_eicr: [
        {
          certificate_url: eicCer,
          is_seen: eicCerIsSeen,
        },
      ],
      certificates_pat: [
        {
          certificate_url: patCer,
          is_seen: patCerIsSeen,
        },
      ],
      licensing: [
        {
          certificate_url: lisc,
          is_seen: liscIsSeen,
        },
      ],
      sendDocToTeanant: sendDocToTeanant,
      is_show_document_popup: is_show_document_popup,
      ...data,
    };
    // dispatch(tenantActions.saveCer(obj, flowId, back));
  };
  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
      // dispatch(tenantActions.getDocuments(props?.match?.params?.id));
      // dispatch(tenantActions.getFlowInfo(props?.match?.params?.id));
    }
  }, [props?.match?.params?.id]);

  useEffect(() => {
    setIs_show_document_popup(globalState?.is_show_document_popup);
  }, [globalState?.is_show_document_popup]);
  useEffect(() => {
    if (globalState?.landlords && globalState?.landlords?.length) {
      setLandlords(globalState?.landlords);
    }
  }, [globalState]);
  useEffect(() => {
    let newData = price;
    if (priceData && priceData.length) {
      priceData.map((data) => {
        newData[data?.product_description] = data.product_price;
      });
    }
    setPrice(newData);
  }, [priceData]);

  useEffect(() => {
    if (globalState?.certificates) {
      let { certificates_eicr, certificates_epc, certificates_gas, certificates_pat, licensing } =
        globalState?.certificates;
      if (certificates_eicr && certificates_eicr.length) {
        setEicCer(certificates_eicr[0].certificate_url);
        setEicCerIsSeen(certificates_eicr[0].is_seen);
      } else {
        setEicCer(null);
      }
      if (certificates_epc && certificates_epc.length) {
        setEpcCer(certificates_epc[0].certificate_url);
        setEpcCerIsSeen(certificates_epc[0].is_seen);
      } else {
        setEpcCer(null);
      }

      if (certificates_gas && certificates_gas.length) {
        setGasSafetyCer(certificates_gas[0].certificate_url);
        setGasSafetyCerIsSeen(certificates_gas[0].is_seen);
      } else {
        setGasSafetyCer(null);
      }
      if (certificates_pat && certificates_pat.length) {
        setPatCer(certificates_pat[0].certificate_url);
        setPatCerIsSeen(certificates_pat[0].is_seen);
      } else {
        setPatCer(null);
      }
      if (licensing && licensing.length) {
        setLiscCer(licensing[0].certificate_url);
        setLiscCerIsSeen(licensing[0].is_seen);
      } else {
        setLiscCer(null);
      }
    }
  }, [
    globalState?.certificates?.certificates_pat,
    globalState?.certificates?.certificates_eicr,
    globalState?.certificates?.licensing,
    globalState?.certificates?.certificates_gas,
    globalState?.certificates?.certificates_epc,
  ]);

  useEffect(() => {
    if (globalState?.certicicateRequests) {
      let { eicr, epc, gas, pat } = globalState?.certicicateRequests;
      if (eicr && eicr.length) {
        setEicCerReq(eicr[0]);
      }

      if (epc && epc.length) {
        setEpcCerReq(epc[0]);
      }

      if (gas && gas.length) {
        setGasSafetyCerReq(gas[0]);
      }

      if (pat && pat.length) {
        setPatCerReq(pat[0]);
      }
    }
  }, [globalState?.certicicateRequests]);

  const renderModal = (
    <>
      <h4 style={{ fontWeight: 'bold', marginTop: 20 }}>Have you clicked "save and send documents"?</h4>
      <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>
        Remember, if you don't do this, your tenants do not receive the documents to thier tlyfe app.
      </h4>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <Button
          type={userSelection === 'sentDocumnets' ? 'solid' : 'stripe'}
          title={"I've sent it"}
          type={'stripe'}
          style={{ borderColor: '#90C418', color: '#90C418' }}
          onClick={() => {
            setUserSelection('sentDocumnets');
            _save('backNavigation', true);
            setShowNoticeModal(false);
          }}
        />
        <Button
          type={userSelection === 'saveOnly' ? 'solid' : 'stripe'}
          title={"I'll send it later"}
          style={{ marginLeft: 30 }}
          type={'stripe'}
          style={{ borderColor: '#FC4850', color: '#FC4850' }}
          onClick={() => {
            setUserSelection('saveOnly');
            _save('backNavigation', false);
            setShowAgain(false);
          }}
        />
      </div>

      <RowContainer
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <div>
          <Checkbox
            name="showPopup"
            checked={!is_show_document_popup}
            onChange={() => setIs_show_document_popup(!is_show_document_popup)}
            value={1}
            color="primary"
          />
          <span style={{ color: 'black' }}>Do not show again</span>
        </div>

        {/* <Button
          type="solid"
          title={"save"}
          style={{ marginLeft: 30 }}
          onClick={() => {
            if (userSelection === "sentDocumnets") {
              _save("backNavigation", true);
              setShowNoticeModal(false);
            } else if (userSelection === "saveOnly") {
              _save("backNavigation", false);
            }
          }}
        /> */}
      </RowContainer>
      <span style={{ color: 'black' }}>Once you click this, you cannot undo this change.</span>
    </>
  );

  const renderModalBodyforGas = (
    <CommanModal
      onSubmitted={() => setModal(false)}
      type={'gas'}
      initialCost={price?.gas_safety_certificate}
      minRange={2}
      flowID={flowId}
    />
  );
  const renderModalBodyforEPC = (
    <CommanModal
      onSubmitted={() => setModal(false)}
      type={'epc'}
      initialCost={price?.epc}
      minRange={1}
      flowID={flowId}
    />
  );
  const renderModalBodyforEICR = (
    <CommanModal
      onSubmitted={() => setModal(false)}
      type={'eicr'}
      initialCost={price?.eicr}
      minRange={1}
      flowID={flowId}
    />
  );
  const renderModalBodyforPAT = (
    <CommanModal
      onSubmitted={() => setModal(false)}
      type={'pat'}
      initialCost={price?.pat}
      minRange={1}
      flowID={flowId}
    />
  );

  const renderModalBodyLandloard = (data) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <div style={{ width: '48%' }}>
        <TextInput
          title={
            <>
              <strong>Title: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '99%',
          }}
          containerStyles={{ width: '100%' }}
          value={data.title || ''}
        />
      </div>
      <div style={{ width: '48%' }}>
        <TextInput
          title={
            <>
              <strong>First Name: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '99%',
          }}
          containerStyles={{ width: '100%' }}
          value={data.first_name || ''}
        />
      </div>
      <div style={{ width: '48%', marginTop: 10 }}>
        <TextInput
          title={
            <>
              <strong>Last Name: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '19%',
            marginTop: 20,
          }}
          containerStyles={{ width: '100%' }}
          value={data.last_name || ''}
        />
      </div>
      <div style={{ width: '48%', marginTop: 10 }}>
        <TextInput
          title={
            <>
              <strong>Email: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerStyles={{ width: '100%' }}
          value={data.email?.toLowerCase() || ''}
        />
      </div>
      <div style={{ width: '48%', marginTop: 10 }}>
        <TextInput
          title={
            <>
              <strong>Contact Number: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerStyles={{ width: '100%' }}
          value={data.number || ''}
        />
      </div>
      <div style={{ width: '48%', marginTop: 10 }}>
        <TextInput
          title={
            <>
              <strong>Post Code: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '49%',
            marginTop: 20,
          }}
          containerStyles={{ width: '100%' }}
          value={data.post_code || ''}
        />
      </div>
      <div style={{ width: '100%', marginTop: 10 }}>
        <TextInput
          title={
            <>
              <strong>Correspondence Address: </strong>
            </>
          }
          type={'row'}
          wrapperStyle={{
            width: '100%',
            marginTop: 20,
          }}
          containerStyles={{ width: '100%' }}
          value={data.correspondece_address || ''}
        />
      </div>
    </div>
  );

  const renderRemoveCardBody = (item, index) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <RowContainer>
        <span>Do you wish to remove this ?</span>
      </RowContainer>
      <br />
      <br />
      <TlyfeButtonWrapper>
        <br />
        <Button
          onClick={() => {
            let tempLandLords = [...landlords];
            tempLandLords.splice(index, 1);
            setLandlords(tempLandLords);
            // dispatch(tenantActions.addNRemovedLandlord(props?.match?.params?.id, tempLandLords));

            setClosePopup(false);
          }}
          title={'Yes'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setClosePopup(false);
          }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
      <br /> <br />
      <RowContainer>
        <span>Please Note</span>
        <span> Once you remove this you cannot undo this change</span>
      </RowContainer>
    </div>
  );
  const _onSave = () => {};

  const handleChange = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };

  const renderModalBody = () => (
    <>
      <ButtonRowContainer style={{ marginTop: '5%', alignItems: 'baseline' }}>
        <Heading>Don't know if you need a licence or not, Check Here!</Heading>
        {lisenceData && lisenceData.length ? null : (
          <Button
            style={{ borderColor: '#90C418' }}
            // onClick={() => dispatch(tenantActions.CheckForLisence(flowId))}
            title={'Check'}
            type="stripe"
          />
        )}
      </ButtonRowContainer>

      {lisenceData && lisenceData.length ? (
        <>
          <ButtonRowContainer
            style={{
              marginTop: '2%',
              alignItems: 'flex-end',
              justifyContent: 'flex-start',
            }}
          >
            <Heading>Your Council: </Heading>
          </ButtonRowContainer>
          {lisenceData.map((data, index) => (
            <CardBox style={{ width: '70%', marginBottom: '2%' }} hasShadow={true} hasBorderRadius={true}>
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <p style={{ marginTop: '5px', fontSize: '14px' }}>{data?.name}</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <a target="_blank" href={data?.license_portal}>
                    <p
                      style={{
                        marginTop: '5px',
                        fontSize: '14px',
                        fontWeight: 500,
                        color: variables.purple1,
                      }}
                    >
                      View
                    </p>
                  </a>
                </div>
              </div>
            </CardBox>
          ))}
        </>
      ) : null}
    </>
  );
  const renderModalBodyEpc = () => (
    <>
      <ButtonRowContainer style={{ marginTop: '5%', alignItems: 'baseline' }}>
        <Heading>Don't know if this property already has an EPC? Check Here!</Heading>
      </ButtonRowContainer>
      <Button
        style={{ borderColor: '#90C418' }}
        onClick={() => {
          // const newWindow = window.open(Constant.EIC_URL, '_blank', 'noopener,noreferrer');
          if (newWindow) newWindow.opener = null;
        }}
        title={'Check'}
        type="stripe"
      />
    </>
  );
  const _addLandlord = () => {
    if (!fieldsValue.first_name) {
      setNameError(true);
      // dispatch(alertActions.error('Field Required'));
    } else if (landlords.length == 0 && !fieldsValue?.is_lead) {
      // dispatch(alertActions.error('Please add the lead landlord first!'));
    } else {
      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(fieldsValue.email)) {
        const updatedList = fieldsValue.is_lead
          ? MakeLeadFunct([...landlords, fieldsValue], fieldsValue?.email)
          : [...landlords, fieldsValue];

        setShowAddLandLord(false);
        setLandlords(updatedList);
        // dispatch(tenantActions.addNRemovedLandlord(props?.match?.params?.id, updatedList));
        setFieldValues(landlordModal);
      } else {
        // dispatch(alertActions.error('Invalid Email'));
      }
    }
  };
  const _closeForm = () => {
    setShowAddLandLord(false);
    setFieldValues(landlordModal);
  };

  const displayAddLandLord = () => (
    <>
      <CardBox style={{ position: 'relative' }} hasShadow={true} hasBorderRadius={true}>
        {/* <ToolTip
          style={{ top: '0%', right: '-40%' }}
          heading={'Tool Tips'}
          ContentData={[
            'Custom tenancy agreement?',
            'If you have a custom AST, please contact support@openbrix.co.uk and we will implement a bespoke AST for your branch',
          ]}
        /> */}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: 30,
            width: '100%',
          }}
        >
          <div style={{ width: layoutType == 'phone' ? '100%' : '20%' }}>
            <p style={{ fontSize: 14, width: '100%' }}>Title</p>
            <Select
              value={fieldsValue?.title}
              style={{
                width: '100%',
                height: '40px',
              }}
              onChange={handleChange}
              variant="outlined"
              name="title"
              placeholder="Title"
            >
              {/* {StringConstatnt.OPENFLOW_TITLES.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })} */}
            </Select>
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '38%' }}>
            <TextInput
              title={'First Name'}
              isfullwidth={true}
              type="text"
              error={nameError}
              wrapperStyle={{ width: '24%', marginBottom: 15 }}
              onChange={(e) => {
                handleChange(e);
                setNameError(false);
              }}
              name="first_name"
              value={fieldsValue.first_name}
            />
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '38%' }}>
            <TextInput
              title={'Last Name'}
              isfullwidth={true}
              type="text"
              wrapperStyle={{ width: '24%', marginBottom: 15 }}
              onChange={handleChange}
              name="last_name"
              value={fieldsValue.last_name}
            />
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInput
              title={'Correspondence Address'}
              isfullwidth={true}
              type="text"
              value={fieldsValue.correspondece_address}
              wrapperStyle={{ width: '49%', marginBottom: 15 }}
              onChange={handleChange}
              name="correspondece_address"
            />
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInput
              title={'Post Code'}
              isfullwidth={true}
              type="text"
              value={fieldsValue.post_code}
              wrapperStyle={{ width: '49%', marginBottom: 15 }}
              onChange={handleChange}
              name="post_code"
            />
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInput
              title={'Email Address'}
              isfullwidth={true}
              type="email"
              value={fieldsValue.email?.toLowerCase()}
              wrapperStyle={{ width: '49%', marginBottom: 15 }}
              onChange={handleChange}
              name="email"
            />
          </div>
          <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
            <TextInput
              title={'Contact Number'}
              isfullwidth={true}
              type="tel"
              value={fieldsValue.number}
              wrapperStyle={{ width: '49%', marginBottom: 15 }}
              onChange={handleChange}
              name="number"
            />
          </div>
        </div>
        <TlyfeSelector style={{ marginTop: 0 }}>
          <div
            style={{
              flex: 0.6,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <SubHeading>Is this lead landlord?</SubHeading>
          </div>
          <div
            style={{
              flex: 0.4,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="is_lead"
                name="is_lead"
                value={fieldsValue.is_lead}
                onChange={(e) => {
                  setFieldValues({
                    ...fieldsValue,
                    is_lead: e.target.value == 'true' ? true : false,
                  });
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="No"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </TlyfeSelector>
        <TlyfeSelector style={{ marginTop: 0 }}>
          <div
            style={{
              flex: 0.6,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <SubHeading style={{ color: 'red' }}>Is this address situated within England and Wales?</SubHeading>
          </div>
          <div
            style={{
              flex: 0.4,

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="isInEngland"
                name="is_england_address"
                value={fieldsValue.is_england_address}
                onChange={(e) => {
                  setFieldValues({
                    ...fieldsValue,
                    is_england_address: e.target.value == 'true' ? true : false,
                  });
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: variables.heliotrope }} />}
                    style={{ color: 'black' }}
                    label="No"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </TlyfeSelector>
        {!fieldsValue.is_england_address ? (
          <>
            <LineSeperator />
            <SubHeading>Please enter landlord Endland & Wales address</SubHeading>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: 30,
              }}
            >
              <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
                <TextInput
                  title={'Correspondence Address'}
                  isfullwidth={true}
                  type="text"
                  wrapperStyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  value={fieldsValue.correspondece_address_england}
                  name="correspondece_address_england"
                />
              </div>
              <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
                <TextInput
                  title={'Post Code'}
                  isfullwidth={true}
                  type="text"
                  wrapperStyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  value={fieldsValue.post_code_england}
                  name="post_code_england"
                />
              </div>
              <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
                <TextInput
                  title={'Email Address'}
                  isfullwidth={true}
                  type="email"
                  value={fieldsValue.email_england?.toLowerCase()}
                  wrapperStyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="email_england"
                />
              </div>
              <div style={{ width: layoutType == 'phone' ? '100%' : '49%' }}>
                <TextInput
                  title={'Contact Number'}
                  isfullwidth={true}
                  type="tel"
                  value={fieldsValue.number_england}
                  wrapperStyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                  onChange={handleChange}
                  name="number_england"
                />
              </div>
            </div>
          </>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="stripe"
            onClick={() => _closeForm()}
            title="Cancel"
            style={{
              marginTop: 30,
              marginBottom: 10,
              marginRight: 30,
              color: variables.red,
              borderColor: variables.red,
            }}
          />
          <Button
            type="stripe"
            onClick={() => _addLandlord()}
            title="Save Landlord"
            style={{ marginTop: 30, marginBottom: 10, borderColor: '#90C418' }}
          />
        </div>
      </CardBox>
    </>
  );
  const renderMakeLeadModalBody = () => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <span>Do you wish to make this landlord as Lead Landlord</span>
      <br />
      <br />
      <TlyfeButtonWrapper>
        <Button
          onClick={() => {
            setLandlords(MakeLeadFunct(landlords, mailToUpdateAsLead));
            setClosePopup(false);
            // setConfirm(true)
          }}
          title={'Yes'}
          type="stripe"
        />
        &nbsp;&nbsp;
        <Button
          onClick={() => {
            setClosePopup(false);
          }}
          title={'No'}
          type="stripe"
        />
      </TlyfeButtonWrapper>
      <br /> <br />
      <RowContainer>
        <span>Please Note</span>
        <span>
          If you make landlord the Lead landlord any other landlord has been assigned as lead landlord would no longer
          be the Lead landlord
        </span>
      </RowContainer>
    </div>
  );
  const MakeLeadFunct = (arrayData, emailTomakeLead) => {
    const data = arrayData.map((elem) => ({
      ...elem,
      is_lead: emailTomakeLead === elem?.email ? true : false,
    }));
    return data;
  };

  return (
    <Container>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Landlord & Property Documentation'}
          description={
            'Please ensure all details are correct so that all documentation, certificates and compliance requirements are correct. '
          }
          // icon={DOC_ICON}
          showPropertyCard
        />
        {/* <Button
          type="stripe"
          onClick={() => history.push("/dashboard/rent/edit/" + flowId)}
          title={`Edit`}
          style={{
            borderColor: "#90C418",
            alignSelf: "flex-end",
            marginTop: 20,
          }}
        /> */}
        <BackComponent
          id={flowId}
          isComplete={globalState?.flow?.doc_status === 1 ? true : false}
          flowtype="doc_status"
        />

        <SubHeading style={{ color: variables.purple }}>Landlord Information</SubHeading>

        <React.Fragment>
          <SubHeading style={{ marginTop: 0 }}>Please add landlord information below</SubHeading>
          <RowContainer style={{ marginBottom: 40, position: 'relative' }}>
            {landlords && landlords.length
              ? landlords.map((item, landlordIndex) => (
                  <ContactCard
                    key={item.email}
                    iconColor={variables.yellow}
                    data={item}
                    isPrimary={item.isLeadTeanant ? true : false}
                    name={`${item.first_name} ${item.last_name}`}
                    isLeadEditable={true}
                    selectedEmailForLead={(email) => setMailToUpdateAsLead(email)}
                    heading={'Landlord Details'}
                    is_lead={item.is_lead}
                    index={landlordIndex}
                    MakeLeadFunct={MakeLeadFunct}
                    mailToUpdateAsLead={mailToUpdateAsLead}
                    setAddedUsers={setLandlords}
                    setAddedUsers={landlords}
                    renderModal={renderModalBodyLandloard(item)}
                    renderShowHeadModal={renderMakeLeadModalBody(item)}
                    setClosePopup={setClosePopup}
                    closePopup={closePopup}
                    renderRemoveCardBody={renderRemoveCardBody(item, landlordIndex)}
                    onMakeLead={() => {
                      setLandlords(MakeLeadFunct(landlords, item.email));
                      // dispatch(
                      //   tenantActions.addNRemovedLandlord(
                      //     props?.match?.params?.id,
                      //     MakeLeadFunct(landlords, item.email)
                      //   )
                      // );
                    }}
                    onDelete={(data) => {
                      if (item.is_lead && landlords.length > 1) {
                        let newAddusers = landlords.filter((ele) => {
                          return ele.email != item.email;
                        });
                        setLandlords(MakeLeadFunct(newAddusers, newAddusers[0].email));
                        // dispatch(tenantActions.addNRemovedLandlord(props?.match?.params?.id, newAddusers));
                      } else {
                        let tempLandLords = [...landlords];
                        tempLandLords.splice(landlordIndex, 1);
                        setLandlords(tempLandLords);
                        // dispatch(tenantActions.addNRemovedLandlord(props?.match?.params?.id, tempLandLords));
                      }
                    }}
                  />
                ))
              : null}
            {/* <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Landlord Tip Box'}
              ContentData={[
                'Your landlord information is requested, in order to auto generate the tenancy agreement and prescribed information.',
              ]}
            /> */}
          </RowContainer>
        </React.Fragment>

        {showAddLandLord ? (
          displayAddLandLord()
        ) : (
          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              width: '100%',
            }}
          >
            <Button
              type="stripe"
              onClick={() => setShowAddLandLord(!showAddLandLord)}
              title={`${landlords?.length > 0 ? 'Add Another Landlord' : 'Add Landlord'}`}
              style={{
                marginTop: 0,
                marginBottom: 10,
                borderColor: '#90C418',
              }}
            />
          </div>
        )}

        <LineSeperator />
        <React.Fragment style={{}}>
          <SubHeading style={{ color: variables.purple }}>Certificate Requirements</SubHeading>

          <CardBox style={{ position: 'relative', width: '100%' }} hasShadow={true} hasBorderRadius={true}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'General Tip Box'}
              ContentData={[
                "If you don't have all your certificates, why not buy them from our best in class provider.",
              ]}
            />
            <ToolTip
              style={{ top: '100%', right: '-40%' }}
              heading={'Gas Cert Tip'}
              ContentData={[
                'Failure to provide valid certificate, could result in failure to gain possession, and or fine / imprisonment.',
              ]}
            />
            <DocumentCard
              flowID={flowId}
              title={'Gas Safety Certificate'}
              docValue={gasSafteyCer}
              isSeen={gasSafteyCerIsSeen}
              showAmmend={true}
              isButtonOptional={false}
              showModalProps={showModal}
              isBuyActive={gasSafteyCerReq}
              onClick={(data) => {
                setGasSafetyCer(data[0]);
                setGasSafetyCerIsSeen(false);
              }}
              onRemove={() => {
                setGasSafetyCer(null);
                setGasSafetyCerIsSeen(false);
              }}
              description={
                'If the property has a Gas supply, it is a legal requirement to ensure that all gas appliances (including installation pipework and meters) are checked for safety and a certificate must be issued to the tenant prior to occupation.'
              }
              modalBody={renderModalBodyforGas}
              footerButtonText="Submit"
              showFooter={false}
              onModalStatusChnage={(data) => setModal(data)}
              modalHeading="Gas Safety Certificate (CP12)"
              modalSubHeading="In order to faciliate the purchase of your Gas Certificate, please enter the details below"
            />
          </CardBox>
          <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px', position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '50%', right: '-40%' }}
              heading={'EPC Cert Tip'}
              ContentData={['Failure to provide valid certificate, could result in failure to gain possession. ']}
            />
            <DocumentCard
              flowID={flowId}
              isButtonOptional={false}
              showModalProps={showModal}
              isSeen={epcCerIsSeen}
              showAmmend={true}
              docValue={epcCer}
              isBuyActive={epcCerReq}
              onClick={(data) => {
                setEpcCer(data[0]);
                setEpcCerIsSeen(false);
              }}
              onRemove={() => {
                setEpcCer(null);
                setEpcCerIsSeen(false);
              }}
              title={'Energy Performance Certificate (EPC)'}
              description={`Please upload your EPC here. <br/>
                EPC must be rated E or better or you must hold an exemption.`}
              modalBody={renderModalBodyforEPC}
              footerButtonText="Submit"
              handleOnModalButtonClick={() => {}}
              showFooter={false}
              renderModal={renderModalBodyEpc()}
              onModalStatusChnage={(data) => setModal(data)}
              modalHeading="Energy Performance Certificate (EPC)"
              modalSubHeading="In order to faciliate the purchase of your EPC, please enter the details below"
            />
          </CardBox>
          <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px', position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'EICR Tip'}
              ContentData={[
                'Check your EICR for Code 1, Code 2 and FI (Further Investigation) all of which must be addressed with 28 days.',
              ]}
            />
            <DocumentCard
              flowID={flowId}
              isButtonOptional={false}
              isSeen={eicCerIsSeen}
              showAmmend={true}
              showModalProps={showModal}
              isBuyActive={eicCerReq}
              docValue={eicCer}
              onClick={(data) => {
                setEicCer(data[0]);
                setEicCerIsSeen(false);
              }}
              onRemove={() => {
                setEicCer(null);
                setEicCerIsSeen(false);
              }}
              onModalStatusChnage={(data) => setModal(data)}
              title={'Electrical Installation Condition Report (EICR)'}
              description={
                'It is a legal requirement to ensure that the property complies with electrical safety regulations. Either upload your EICR or purchase one from our preferred supplier.'
              }
              modalBody={renderModalBodyforEICR}
              footerButtonText="Submit"
              handleOnModalButtonClick={() => {}}
              showFooter={false}
              modalHeading={'Electrical Installation Condition Report (EICR)'}
              modalSubHeading="In order to faciliate the purchase of your EPC, please enter the details below"
            />
          </CardBox>
          <CardBox hasShadow hasBorderRadius style={{ marginTop: '25px', position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'PAT Tip'}
              ContentData={[
                "PAT testing is not a requirement for landlords but it's recommended to keep your home safe.",
                'Please check with your legal team for further information',
              ]}
            />
            <DocumentCard
              flowID={flowId}
              isButtonOptional={false}
              showModalProps={showModal}
              isSeen={patCerIsSeen}
              showAmmend={true}
              isBuyActive={patCerReq}
              docValue={patCer}
              onClick={(data) => {
                setPatCer(data[0]);
                setPatCerIsSeen(false);
              }}
              onModalStatusChnage={(data) => setModal(data)}
              onRemove={() => {
                setPatCer(null);
                setPatCerIsSeen(false);
              }}
              title={'Portable Appliance Testing (PAT) '}
              description={
                'Portable appliance testing (PAT) is the term used to describe the examination of electrical appliances and equipment to ensure they are safe to use.'
              }
              modalBody={renderModalBodyforPAT}
              footerButtonText="Submit"
              handleOnModalButtonClick={() => {}}
              showFooter={false}
              modalHeading="Portable Appliance Testing (PAT)"
              modalSubHeading="In order to faciliate the purchase of your EPC, please enter the details below"
            />
          </CardBox>
          <LineSeperator />
          <SubHeading style={{ color: variables.purple }}>Licensing</SubHeading>
          <CardBox hasShadow hasBorderRadius style={{ marginTop: '10px', position: 'relative', width: '100%' }}>
            <ToolTip
              style={{ top: '0%', right: '-40%' }}
              heading={'Tool Tips'}
              ContentData={[
                'Tip 1 - Why not visit the website of the local authority and check if you need a licence.',
                'Tip 2 - Failing to licence your property can incur significant fines.',
              ]}
            />
            <DocumentCard
              flowID={flowId}
              docValue={lisc}
              isSeen={liscIsSeen}
              isBuyActive={true}
              showAmmend={false}
              onClick={(data) => {
                setLiscCer(data[0]);
                setLiscCerIsSeen(false);
              }}
              onModalStatusChnage={(data) => setModal(data)}
              renderModal={renderModalBody()}
              onRemove={() => {
                setLiscCer(null);
                setLiscCerIsSeen(false);
              }}
              title={'License'}
              description={`Does your property require a licence? The main types of licence are:
                <br/>
                <br/>
                - Mandatory Licence - 5 or more people forming two or more households. 
                <br/>
                <br/>
               - Additional Licence - Requirement conditions will be dictated by the local authority.
               <br/>
                <br/>
               - Selective Licence - A geographic area agreed by the local authority.
                `}
            />
          </CardBox>
          <SubHeading>
            PLEASE NOTE: If your tenant(s) have signed up to the tlyfe app, these document will now appear on their
            property document page
          </SubHeading>
          <BottomButton
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            rightBtnTiltle={'Save & Continue'}
            onRightClick={() => {
              modalState?.send_to_tenant
                ? _save('backNavigation', false, { send_to_tenant: true })
                : setSuccessModal(true);
            }}
            onContinue={() => _save('backNavigation', false, { send_to_tenant: false })}
          />
        </React.Fragment>
      </CardWrapper>
      {showNoticeModal && (
        <OpenFlowModal
          showModal={showNoticeModal}
          setShowModal={setShowNoticeModal}
          modalBody={renderModal}
          showFooter={false}
          modalHeading={'Documents'}
          onModalStatusChnage={(data) => setModal(data)}
          modalSubHeading={``}
          modalWidth={600}
        />
      )}
      <SaveandContinueModal
        visible={showSuccessModal}
        heading={'Documents'}
        modalState={modalState}
        showNote
        subHeading={[
          `Have you clicked "save and send documents"?`,
          `Remember,  if you don't do this, your tenants do not receive the documents to thier tlyfe app.`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        onOkPress={(data) => {
          data.send_to_tenant = true;
          _save('backNavigation', true, data);
        }}
      />
    </Container>
  );
};

export default DrawerHoc({
  Component: PropertyDocumentation,
  showBackButton: true,
});
