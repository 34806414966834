import React, { useState, useRef } from 'react';
import { TextInput, View } from 'react-native';
import {
  Container,
  SolidButton,
  StatusButton,
  StripeButton,
  TextInputWrapper,
  TextWrapper,
  TopView,
} from './Button.style';
import PropTypes from 'prop-types';
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import { httpUpload } from './../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
const Button = (props) => {
  const { childText, fontSize, color, onPress, alignSelf, type, width, onDataSuccess, disabled } = props;
  const inputFile = useRef();
  const onButtonClick = () => {
    inputFile.current.click();
  };
  return (
    <>
      <If condition={type == 'solid'}>
        <SolidButton width={width} onPress={onPress} alignSelf={alignSelf} color={color} {...props}>
          <Text color={'white'} bold fontSize={fontSize}>
            {childText}
          </Text>
        </SolidButton>
      </If>
      <If condition={type == 'stripe'}>
        <StripeButton width={width} onPress={onPress} alignSelf={alignSelf} color={color} {...props}>
          <Text color={color ? color : Colors.light.purple} bold fontSize={fontSize}>
            {childText}
          </Text>
        </StripeButton>
      </If>
      <If condition={type == 'status'}>
        <StatusButton width={width} onPress={onPress} alignSelf={alignSelf} color={color} {...props}>
          <Text color={color ? color : Colors.light.purple} bold fontSize={fontSize}>
            {childText}
          </Text>
        </StatusButton>
      </If>
      <If condition={type == 'upload'}>
        <>
          <input
            onChange={(e) => {
              let data = e.target.files;
              if (data && data.length) {
                let arr = [...e.target.files];
                var newImages = arr.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
                console.log('newImagesnewImages', newImages);
                var formdata = new FormData();
                formdata.append('type', 'Document');
                newImages.forEach((item) => {
                  formdata.append('files', item);
                });
                httpUpload
                  .post(Urls.Upload, formdata)
                  .then(({ data }) => {
                    console.log('Data', data);
                    if (data.status) {
                      onDataSuccess(data?.data);
                    } else {
                    }
                  })
                  .catch((error) => {
                    console.log('error', error);
                  });
              }
            }}
            style={{ display: 'none' }}
            id="select-file"
            type="file"
            name="fileUpload"
            multiple
            ref={inputFile}
          />
          <StripeButton width={width} onPress={onButtonClick} alignSelf={alignSelf} color={color} {...props} 
          style={{ opacity: props.disabled ? 0.5 : 1 }}>
            <Text color={color ? color : Colors.light.purple} bold fontSize={fontSize}>
              {childText}
            </Text>
          </StripeButton>
        </>
      </If>
    </>
  );
};

Button.propTypes = {
  childText: PropTypes.string,
  onPress: PropTypes.func,
  onDataSuccess: PropTypes.func,
  color: PropTypes.bool,
  fontSize: PropTypes.string,
  alignSelf: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  disabled: PropTypes.bool
};

Button.defaultProps = {
  type: 'solid',
  onDataSuccess: () => {},
  disabled: false
};

export default Button;
//Eg.
{
  /* <Button title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
