import AUTH_TOKEN from '../ActionTypes';
import REMOVE_AUTH_TOKEN from '../ActionTypes';

export const saveAuthToken = (data, navigation) => {
  navigation.navigate('home');
  return { type: AUTH_TOKEN, payload: data };
};
export const saveUserProfile = (data) => {
  return { type: 'USERPROFILE', payload: data };
};

export const removeAuthToken = () => {
  return { type: REMOVE_AUTH_TOKEN, payload: null };
};
