import React from 'react';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';


const layoutType = getPlatformType();

const DocumentBox = styled.View`
  border: 1px dashed #d0d0d0;
  width: 100%;
  min-height: 100px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
`;

export { DocumentBox };
