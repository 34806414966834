import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, TextInputWrapper, Row, RowCol, Section, Left;
let HorizontalLine = styled.View`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-vertical: 30px;
`;
if (layoutType == 'web') {
  Container = styled.View`
    width: 60%;
    margin-left: 320px;
    padding: 10px;
    margin-top: 50px;
  `;
} else if (layoutType == 'phone') {
  Container = styled.View`
    width: 100%;
    padding: 40px 20px;
    align-items: 'center';
  `;
} else {
  Container = styled.View`
    width: 85%;
    margin-left: 70px;
    padding: 30px 20px;

    background-color: #f2f2f2;
    border-radius: 40px;
    height: 100vh;
  `;
}
export { ContainerScrollView, Container, HorizontalLine, Row, TextInputWrapper, RowCol, Section, Left };
