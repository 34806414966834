import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, WrapperBox, ListingBox;

export const HeaderText = styled.Text`
  font-weight: bold;
  font-size: 18px;
`;
export const SettingText = styled.Text`
  font-weight: 400;
`;
export const SettingList = styled.Text`
  padding: 10px 10px;
  padding-top: ${(props) => (props.count === 0 ? 0 : '15px')};
  padding-bottom: ${(props) => (props.length === props.count ? 0 : '15px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: ${(props) => (props.length === props.count ? 0 : '1px')};
  border-bottom-color: #ebebeb;
`;
export const MainText = styled.Text`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 15px;
`;

export const ProfileBox = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ProfileName = styled.Text`
  font-size: 14px;
`;
export const ProfileContact = styled.Text`
  font-size: 13px;
`;
export const ProfileEmail = styled.Text`
  font-size: 12px;
`;

if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 70%;
    padding: 10px;
    margin-left: 320px;
    margin-top: 20px;
  `;
  WrapperBox = styled(Box)`
    width: 460px;
  `;
  ListingBox = styled(Box)`
    width: 540px;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;

  WrapperBox = styled(Box)`
    width: 100%;
  `;
  ListingBox = styled(Box)`
    width: 100%;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 90%;
    padding: 10px;
    margin-left: 10%;
  `;
  WrapperBox = styled(Box)`
    width: 100%;
  `;
  ListingBox = styled(Box)`
    width: 100%;
  `;
}
export { ContainerScrollView, Container, WrapperBox, ListingBox };
