import React, { useState , useEffect} from 'react';
import { View , FlatList , TouchableOpacity} from 'react-native';
import getPlatformType from '../../helpers/helpers';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import { Container, ContainerScrollView, TableTools, HeaderView ,ContainerList } from './index.style';
import SortList from '../../components/sortList';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import TenantTable from '../../components/tenantTable';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import Text from '../../components/text';
import Delete from '@material-ui/icons/Delete';
import PopUp from '../../components/popUp/PopupView';

var layoutType = getPlatformType();

var colData = ['Tenant', 'Property', 'Tenancy End Date'];
var sortData = [
  {
    name: 'A-Z',
    value: 'A to Z',
  },
  {
    name: 'Z-A',
    value: 'Z to A',
  },
  // {
  //   name: 'ID',
  //   value: 'ID',
  // },
];

const TenantArchive = ({ navigation }) => {
  const [sort, setSort] = useState({ name: 'A-Z', value: 'A-Z' });

  const setSortData = (name, value) => {
    setSort({
      name,
      value,
    });
  };
  const [allArhcivedTenant, setAllArchivedTenant] = useState();
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [deteledIndex, setDeteledIndex] = useState(false);

  useEffect(() => {
    getAllPropertiesArchivedTenants();
  }, []);

  const onDeleteDocument = (document) => {
   console.log(document,"ddddddddddddddddd")
    ajax
      .delete(`${Urls.DELETE_TENANT}${document}`)
      .then(({ data }) => {
        if (data.status) {
          getAllPropertiesArchivedTenants();
          setDeteledIndex(null)
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };


  const getAllPropertiesArchivedTenants = () => {
    ajax
      .get(Urls.GET_ARCHIVE_TEANANT)
      .then(({ data }) => {
        console.log(data.data,"dataaaaaaaaaa")
        if (data.status) {
          data.payload && setAllArchivedTenant(data.payload);
          let temp = [];
          data.payload &&
            data.payload.map((e) => {
              temp.push({
                name: e?.tenant?.first_name,   
                location: e?.property?.name,  
                email: e?.tenant?.email, 
                id: e?.property_tenant_id,
                // date: moment(e?.rentalData?.tenancy_end_date).format('DD/MM/YYYY'),
              
              });
            });
          setData(temp);
          console.log(data,'kkkkkkkkkkkkkk')
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _ReminderItem = (item) => {
    return (
      <TouchableOpacity

        style={{
          justifyContent: 'space-between',
          marginBottom: 10,
          flexDirection: layoutType == 'phone' ? 'column' : 'row',
          borderBottomWidth: 1,
          borderColor: '#dcdcdc',
          paddingBottom: 10,
        }}
      >
<Text style={{ flex: 0.25 }}>
          {item?.name}
        </Text>
          <Text style={{ flex: 0.35 }}>
          {item?.email}
        
        </Text>
        <Text style={{ flex: 0.25 }}>
        {item?.location}
        </Text>
        <View style={{ flex: 0.15, flexDirection: "row", alignItems: "center", }} >
   
              <TouchableOpacity onPress={() => {
              setDeteledIndex(item?.id)
              setIsVisible(true)
              }}>

                <Delete
                  style={{
                    height: 24,
                    width: 24,
                    color: '#6D08C0',
                  }}
                />
              </TouchableOpacity> 

      
 </View>
      </TouchableOpacity>
    )
  }
  return (
    <>
      <Container>
        <TableTools>
          <View style={{ width: layoutType === 'web' ? '80%' : layoutType === 'tab' ? '70%' : '100%' }}>
            <SearchCardComponent
      placehorderText={'Search Tenants'}
            leftText={'Tenant archive'} />
          </View>
          <SortList renderTitle={sort.name} setSort={setSortData} data={sortData} width={200} />
        </TableTools>

        {/* <TenantTable
          data={data}
          colData={colData}
          rowIcon={<FontAwesome name="user" size={20} color={Colors.light.purple} />}
          isTeanat
        /> */}
        <ContainerList>
                <FlatList
          data={data}
          style={{ flexGrow: 0 }}
          ListHeaderComponent={() => (
            <HeaderView>
             <View style={{ flex: 0.25, }}>
                <Text fontWeight={'600'} fontSize={15}>
                  Name
                </Text>
              </View>

              <View style={{ flex: 0.35, }}>
                <Text fontWeight={'600'} fontSize={15}>
                  Email
                </Text>
              </View>

              <View style={{ flex: 0.25, }}>
                <Text fontWeight={'600'} fontSize={15}>
                  Property

                </Text>

              </View>

              <View style={{ flex: 0.15, }}>
          
              </View>

            
            </HeaderView>
          )}
          renderItem={({ item }) => _ReminderItem(item)}
        />
        </ContainerList>
      </Container>
      <PopUp
        modalVisible={isVisible}
        titleText={'Delete Tenant?'}
        titleColor={'#6D08C0'}
        subTitleColor={'black'}
        customSubtitle={
          <View>
            <Text>Are you sure you want to delete this tenant permananently?</Text>
          </View>
        }
        actionButtons={[
          {
            text: 'Delete',
            width: 100,
            onPress: () => {
              onDeleteDocument(deteledIndex);
              setIsVisible(false);
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisible(false);
            },
          },
        ]}
        closePopup={() => setIsVisible(false)}
      />
    </>
  );
};

export default drawerHoc({
  Component: TenantArchive,
  showBackButton: true,
});

