import React, { useState, useEffect } from 'react';
import { TouchableOpacity, View, Image } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import CheckBox from '../checkbox';
import { useNavigation, useRoute } from '@react-navigation/native';
import Box from '../box';
import {
  Row,
  Right,
  Left,
  Center,
  BoxContainer,
  ImageContainer,
  CircleIcon,
  InfoSection,
  RemainderSection,
  SectionLine,
  Iconcontain,
} from './pageHeader.style';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import Colors from '../../constants/Colors';
import Knob from '../knob';
import Button from '../button/Button';
import Text from '../text';
import getPlatformType from '../../helpers/helpers';
import PopUp from '../popUp/PopupView';
import { showSuccesToast } from '../../screens/tenants/tenantOnboarding/helpers';
var layoutType = getPlatformType();

const MainHeader = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const { property_name, property_worth, id, percentage, address1, city, postcode, onContinuePress, houseName, continueText } = props;
  const navigation = useNavigation();
  const { navigate } = navigation;
  const route = useRoute();

  const onArchiveProperty = () => {
    ajax
      .post(Urls.ARCHIVE_PROPERTY + route?.params?.id)
      .then(({ data }) => {
        if (data.status) {
          // alert(data?.data)
          showSuccesToast(data.data);
          navigation.navigate('Properties');
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };


  return (
    <Box style={{ marginTop: 10 }}>
      <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row' }}>
        <View style={{ flexDirection: 'coloumn', width: '80%' }}>
          <BoxContainer>
            <Left>
              <ImageContainer>
                <Entypo name="home" size={55} color={Colors.light.purple} />
              </ImageContainer>
            </Left>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
                <Text
                  fontSize={layoutType == 'phone' ? 16 : 18}
                  marginLeft={layoutType == 'phone' ? 0 : 20}
                  fontWeight={'600'}
                  marginBottom={5}
                  style={{width:'100%'}}
                 numberOfLines={2}
                >
                  {property_name.substring(0,20)}{' '}
                </Text>

                <Text
                  fontSize={13}
                  color={Colors.light.lightPurple}
                  style={{
                    padding: 5,
                    borderWidth: 1,
                    borderRadius: 5,
                    borderColor: Colors.light.lightPurple,
                    alignSelf: 'flex-start',
                    width:100
                  }}
                  fontWeight={'600'}
                  numberOfLines={1}
                >
                  £{property_worth}
                </Text>
              </View>
              <View style={{ alignSelf: 'flex-start', marginBottom: 10 }}>
                <Text  numberOfLines={2} marginTop={10} fontSize={15} marginLeft={layoutType == 'phone' ? 0 : 20}>
                  {houseName} {address1} {city} {postcode}
                </Text>
              </View>
            </View>

          </BoxContainer>
          <View style={{ flexDirection: 'row', marginBottom: 10 }}>
            <>
              <TouchableOpacity
                onPress={() => navigate('PropertyDetailsMore', { id: id })}
                style={{
                  position: 'relative',
                  right: 0,
                  top: 0,
                  alignSelf: 'flex-start',
                }}
              >
                <Text
                  fontWeight={'600'}
                  marginLeft={layoutType == 'phone' ? 0 : 20}
                  fontSize={14}
                  color={Colors.light.purple}
                  marginTop={5}
                >
                  More details
                </Text>
              </TouchableOpacity>
            </>
            <Iconcontain>
              <TouchableOpacity onPress={() => navigate('PropertiesInfoDetails', { id: route?.params?.id })}>
                <Image
                  source={require('../../assets/icons/view.png')}
                  style={{ height: 67, width: 53, marginRight: 5 }}
                  resizeMode={'contain'}
                />
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setIsVisible(true)}>
                <Image
                  source={require('../../assets/icons/Archive.png')}
                  style={{ height: 67, width: 50, marginLeft: 30 }}
                  resizeMode={'contain'}
                />
              </TouchableOpacity>
              <PopUp
                modalVisible={isVisible}
                titleText={'Archive Property?'}
                titleColor={'#6D08C0'}
                subTitleColor={'black'}
                customSubtitle={
                  <View>
                    <Text>
                      Are you sure you want to archive this Property? (Hint this property will be archived and can be viewed
                      <Text bold
                        onPress={() => { navigation.navigate('PropertyArchive'); }}
                        style={{ color: "black" }}> here
                      </Text> )
                    </Text>
                  </View>
                }
                actionButtons={[
                  {
                    text: 'Archive',
                    width: 100,
                    onPress: () => {
                      onArchiveProperty();
                      setIsVisible(false);
                    },
                  },
                  {
                    text: 'Cancel',
                    width: 100,
                    onPress: () => {
                      setIsVisible(false);
                    },
                  },
                ]}
                closePopup={() => setIsVisible(false)}
              />
            </Iconcontain>
          </View>
        </View>


        <SectionLine />
        <View style={{ flexDirection: layoutType == 'phone' ? 'row' : 'column', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
            <Knob
              size={90}
              strokeWidth={10}
              progressPercent={percentage ? Math.round(percentage) : 0}
              text={'Completed'}
              textColor={Colors.light.purple}
              pgColor={Colors.light.purple}
            />
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '50%' }}>
            <Button
              onPress={() => {
                onContinuePress ? onContinuePress() : navigate('PropertySection', { id: id });
              }}
              childText={continueText || 'Continue'}
              type="solid"
              width={100}
            />
          </View>
        </View>
      </View>
    </Box>
  );
};

export default MainHeader;
