import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storageLocal from './localStorage';
import rootReducer from '../reducers';
import whitelist from './whitelist';
import { createLogger } from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage: storageLocal,
  whitelist,
  timeout: 1000,
  /**
   * add keys here to forget state
   *  */
  // blacklist: [],
};
const middlewares = [];

if (__DEV__) {
  middlewares.push(createLogger());
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(...middlewares, ...[thunk]));

export const persistor = persistStore(store);

export default store;
