import React, { useState } from 'react';
import { View, SafeAreaView, Platform, ScrollView, TouchableOpacity, Text } from 'react-native';
import { Container } from './style2';
const layoutType = getPlatformType();
import MediaNewsHub from './mediaNewsHub';
import MediaBlogHub from './mediaBlogHub';
import MediaVideoHub from './mediaVideoHub';
import getPlatformType from '../../helpers/helpers';
import TripleTabs from '../../components/tripleTabs/tripleTabs';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';



const MediaHub = (props) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(1);


  const setCurrentTab = (index) => {
    setCurrentTabIndex(index);
  };

  return (
    <SafeAreaView style={{ }}>


        <Text style={{  color: '#6612B6', fontSize: 39, fontWeight: "bold", marginBottom: 20 }}>Media hub</Text>
        <Container>
          <View style={{ flexDirection: "row", width: "100%" }}>

            <View style={{ flex: 1, flexDirection: "row", }}>
            </View>
            <View style={{ flex: 1, flexDirection: "row", justifyContent: 'flex-end' }}>
              <TouchableOpacity 
                            onPress={()=>{setCurrentTab(1)}}

              style={{ marginRight: 20 }}>
                <Text style={{ color: 'rgba(76, 78, 100, 0.87)', fontSize: 16, fontWeight: "400", }}>Landlord Hub</Text>
                {currentTabIndex==1?<View style={{ width: "100%", height: 2, backgroundColor: "#6612B6", marginTop: 10 }} />:null}
              </TouchableOpacity>
              <View style={{ height: "100%", width: 1, backgroundColor: "rgba(76, 78, 100, 0.38)" }} />
              <TouchableOpacity 
              onPress={()=>{setCurrentTab(2)}}
              style={{ paddingLeft: 30 }}>
                <Text style={{ color: 'rgba(76, 78, 100, 0.87)', fontSize: 16, fontWeight: "400", }}>News</Text>
                {currentTabIndex==2?<View style={{ width: "100%", height: 2, backgroundColor: "#6612B6", marginTop: 10 }} />:null}
              </TouchableOpacity>
            </View>
          </View>
          <View style={{alignItems:'center',width:'100%',marginTop:30}}>
{currentTabIndex==2? <MediaNewsHub {...props} />:null}
{currentTabIndex==1? <MediaBlogHub {...props} />:null}


          </View>
        </Container>

    </SafeAreaView>
  );
};
export default drawerHoc({
  Component: MediaHub,
  showBackButton: true,
});
