import styled from 'styled-components/native';

export const TextWrapper = styled.Text`
  color: ${({ color }) => color || 'lightGray'};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  ${({ bold }) => bold && 'font-weight: bold'};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ lineHeight }) => lineHeight && `line-height:${20}px`};
  ${({ marginLeft }) => marginLeft && `margin-left:${marginLeft}px`};
  ${({ marginRight }) => marginRight && `margin-left:${marginRight}px`};
  ${({ marginBottom }) => marginBottom && `margin-bottom:${marginBottom}px`};
  ${({ marginTop }) => marginTop && `margin-top:${marginTop}px`};
  ${({ fontWeight }) => fontWeight && `font-weight:${fontWeight}`};
  ${({ width }) => width && `width:${width}`};


`;

export default TextWrapper;
