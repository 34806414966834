import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { CardBox } from '../card/index.js';
import { StatusButton } from '../statusbutton/index.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListAltIcon from '@material-ui/icons/ListAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Button } from '../button/index.js';
const Main = styled.div`
  width: 360px;
  margin-top: 10px;
`;

export const FileCard = (props) => {
  const [fileName, setFileName] = React.useState('FileName.pdf');
  return (
    <Main>
      <CardBox hasShadow={true} hasBorderRadius={true}>
        <div style={{ justifyContent: 'space-between', display: 'flex', paddingRight: 15 }}>
          <p style={{ marginTop: '5px', fontSize: '14px', width: '50%' }}>{fileName}</p>
          <a
            target="_blank"
            href="https://scholarshipfellow.com/wp-content/uploads/2021/02/illustration-document-icon.jpg"
          >
            <p style={{ marginTop: '5px', fontSize: '14px', fontWeight: 500, color: variables.purple1 }}>View</p>
          </a>
          {props.viewOnly ? null : (
            <p
              type="button"
              onClick={() => props.onRemove()}
              style={{ marginTop: '5px', fontSize: '14px', fontWeight: 500, color: variables.orange }}
            >
              Remove
            </p>
          )}
        </div>
      </CardBox>
    </Main>
  );
};

{
  /*
Example.(type=complete/incomplete)
 <ListItem type={"complete"} title={"Tenants Details"} description={"This is a new way of selling property and our UK MLS is tailored for the UK Market, which means you can achieve higher fees from your clients."}/>

*/
}
