import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';

export const BackBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
`;
