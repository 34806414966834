import React, { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import { MediaWrapper, TitleText, MediaVideoBox, MediaVideoDesc, MediaBox } from './styles';

const layoutType = getPlatformType();
import YoutubePlayer from '../../components/youtubePlayer/youtubePlayer';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { useIsFocused } from '@react-navigation/native';
import Button from '../../components/button/Button';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';

const MediaVideoDetails = (props) => {
    const [posts, setPost] = useState([]);

    useEffect(() => {
        ajax
            .get(Urls.GET_POST + 'VIDEO')
            .then(({ data }) => {
                if (data.status) {
                    console.log('ASDASDAS', data);

                    if (data?.payload) {
                        if (data?.payload && data?.payload?.length) {
                            setPost(data?.payload);
                        }
                    }
                } else {
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    }, [focus]);

    return (
        <>
            <View style={{}}>

                {posts != undefined &&
                    posts.map((data, index) => {
                        return (

                            <View style={{ justifyContent: 'flex-start' ,marginTop:20 , backgroundColor: 'white' }}>
                                <View style={{ height: 305, marginBottom: 20 }}>

                                    <YoutubePlayer url={data.video_id} height={305} width={'100%'} />
                                </View>
                                <View style={{ marginLeft: 30  , marginRight:30}}>

                                    <Text style={{
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: 26,
                                        lineHeight: 32,
                                    }}>
                                        {data.title}
                                    </Text>
                                    <MediaVideoDesc>{data.desc}</MediaVideoDesc>



                                </View>
                            </View>

                        );
                    })}

            </View>
        </>
    );
};

export default drawerHoc({
    Component: MediaVideoDetails,
    showBackButton: true,
});