import React, { useState } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import {
  ParentWrapper,
  HeaderBox,
  ListingBox,
  SettingText,
  SettingList,
  HeaderText,
  ContainerScrollView,
  Container,
} from './style';
import { ScrollView } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';

var layoutType = getPlatformType();

const DATA = [
  {
    id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
    name: 'My account',
    route: 'MyAccount',
  },
  {
    id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
    name: 'Change password',
    route: 'ChangePassword',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e2ed72',
    name: 'Property archive',
    route: 'PropertyArchive',
  },
  {
    id: '58694a0f-3da1-471f-bd96-145571e2esdd72',
    name: 'Tenant archive',
    route: 'TenantArchive',
  },
  {
    id: '58694a0f-3da1-4sd71f-bd96-145571e2ed72',
    name: 'Document archive',
    route: 'DocumentArchive',
  },
];

const Settings = ({ navigation }) => {
  return (
    <>
      <HeaderBox style={{ marginBottom: 25 }}>
        <Box>
          <HeaderText style={{ fontWeight: 'bold' }}>Settings</HeaderText>
        </Box>
      </HeaderBox>
      <ListingBox>
        {DATA &&
          DATA.map((item, index) => (
            <SettingList
              onPress={() => navigation.navigate(item.route)}
              count={index}
              length={DATA.length - 1}
              key={'setting' + index}
            >
              <SettingText>{item.name}</SettingText>
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate(item.route);
                }}
              >
                <AntDesign name="right" size={14} color="#9F78FF" />
              </TouchableOpacity>
            </SettingList>
          ))}
      </ListingBox>
      {/* onDelete={(data) => {
                      if (item.is_lead && addedUsers.length > 1) {
                        let newAddusers = addedUsers.filter((ele) => {
                          return ele.email != item.email;
                        });
                        setAddedUsers(MakeLeadFunct(newAddusers, newAddusers[0].email));
                        setClosePopup(false);
                      } else {
                        let newArray = [...addedUsers];
                        newArray.splice(index, 1);
                        setAddedUsers(newArray);
                        setClosePopup(false);
                      }
                    }} */}
    </>
  );
};

export default drawerHoc({
  Component: Settings,
});
