import React, { useState } from 'react';
import {
  Container,
  FormContainer,
  GradientOverlay,
  ImageWrapper,
  LeftSection,
  RightSection,
  TopSection,
  TouchableWrapper,
  ImageLogoWrapper
} from './forgotPassword.style';
import Text from '../../components/text';
import Colors from '../../constants/Colors';
import Urls from '../../services/Urls';
import { EmailValidation } from '../../helpers/validation';
import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import rightImg from '../../assets/images/forgotImage.png';
import ajax from '../../helpers/ajaxHelper';
import WebImage from '../../assets/images/Logo/HubitaWeb.png';
import { showSuccesToast } from '../tenants/tenantOnboarding/helpers';
let LogoImg= WebImage;

const ForgotPasswordScreen = ({ navigation }) => {
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');

  const forgotAPICall = () => {
    const requestBody = {
      email: email,
    };
    ajax
      .post(Urls.ForgotPassword, requestBody)
      .then(({ data }) => {
        if (data.status) {
          // alert(data?.data);
          showSuccesToast(data?.data);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const forgotPasswordValidation = () => {
    let emailValidate = EmailValidation(email);
    if (emailValidate.status) {
      forgotAPICall();
    } else {
      setEmailError(emailValidate?.status ? '' : emailValidate?.msg);
    }
  };

  return (
    <Container>
      <LeftSection>
        <FormContainer>
          <ImageLogoWrapper source={LogoImg} />
          <Text fontSize={30} marginBottom={30} marginTop={10} color={Colors.light.gradientColor1} fontWeight={450}>
            Forgot Password
          </Text>
          <Text fontSize={15} marginBottom={30} color={Colors.light.text} fontWeight={400}>
            Please enter your username or email to reset your password. If we have your email, we will send you a
            replacement password.
          </Text>
          <TextInputComponent
            error={emailError}
            title={'Email Address'}
            onChangeText={(e) => {
              setEmail(e), setEmailError('');
            }}
            colored
          />
          <TouchableWrapper onPress={() => navigation.navigate('login')}>
            <Text fontSize={20} color={Colors.light.purple} fontWeight={450}>
              {'<'} Back to Login
            </Text>
          </TouchableWrapper>
          <Button onPress={() => forgotPasswordValidation()} childText={'Submit'} alignSelf={'flex-end'} />
        </FormContainer>
      </LeftSection>
      <RightSection>
        <ImageWrapper source={rightImg} />
        <GradientOverlay colors={['#7002BC90', '#3364F590']} start={{ x: 1, y: 0 }} end={{ x: 1, y: 1 }} />
      </RightSection>
    </Container>
  );
};

export default ForgotPasswordScreen;
