import React, { useState, useEffect } from 'react';
import { View , Text} from 'react-native';
import { MediaBox, MediaWrapper, TitleText, MediaDesc, MediaDate, FooterBox } from './styles';

const layoutType = getPlatformType();
import MediaDetails from './mediaDetails';

import moment from 'moment';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { navigate } from '../../navigation/RootNavigation';
import Button from '../../components/button/Button';
import RenderHtml from 'react-native-render-html';
import MediaComponent from './mediacomponent';
import LandlordMediaDetails from './landlordDetails';

const MediaBlogHub = (props) => {
  const [showBlogDetail, setShowBlogDetail] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [posts, setPost] = useState([]);

  useEffect(() => {
    getPost();

  }, [focus]);
   const getPost = () =>{
    ajax
      .get(Urls.GET_POST + 'BLOG')
      .then(({ data }) => {
        if (data.status) {
          console.log('ASDASDAS', data);
          if (data?.payload && data?.payload?.length) {
            setPost(data?.payload);
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
   }
 
  
  const createMarkup = (data) => {
    return { __html: data.description.split('.')[0] };
  };

  return (
    <View style={{width:"100%",alignSelf:'center'}}>
      {showBlogDetail ? (
        <View style={{ overflow: 'visible' }}>
          <MediaDetails selectedPost={selectedMedia} />
          <FooterBox
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '100%',
              width: '100%',
            }}
          >
            <Button
              onPress={() => setShowBlogDetail(false)}
              borderRadius={15}
              type={'stripe'}
              childText={Back}
              height={layoutType == 'phone' ? 40 : 30}
              width={300}
            />
          </FooterBox>
        </View>
      ) : (
        <View style={{display:'flex',flexDirection:"row",flexWrap:"wrap",justifyContent: layoutType=='web'?'flex-start':'center',width:'100%',alignItems:'center' }}>
     
          
          {posts != undefined &&
              posts.map((data, index) => {
                return (
                  
                    <View >
                    <MediaComponent 
                    key={index}
                    image={data.file}
                    title= {data.title}
                    description= {data.description}
                    onPress={() => {
                      console.log("hiiijjsjkwmswsjsmwjmewjemememememememem")
                      navigate('LandlordMediaDetails', {
                        data: data.id,
                      });
                    }} 
                    />
                    </View>
                     
                );
              })}


        </View>
      )}
    </View>
  );
};

export default MediaBlogHub;
