import React from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';
import Box from '../box';
import Text from '../text';
import TickGreen from '../../assets/svgIcons/property/tickGreen.svg';
import TickRed from '../../assets/svgIcons/property/tickRed.svg';
import CrossRed from '../../assets/svgIcons/property/crossRed.svg';
import { HorizontalLine } from '../../screens/properties/index.style';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';

var layoutType = getPlatformType();

const item = [1, 2, 3, 4];
const getIcons = (val) => {
  if (val == 'GreenTick') {
    return TickGreen;
  } else if (val == 'OrangeTick') {
    return TickRed;
  } else {
    return CrossRed;
  }
};

const ListOverView = ({ list, header1, header2, header3, header4, onPress }) => {
  console.log("listlist",list)
  const _renderItem = ({ item }) => (
    <Choose>
      <When condition={layoutType == 'phone'}>
        <TouchableOpacity onPress={onPress}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: 10,
            }}
          >
            <View style={{ alignItems: 'center', flexDirection: 'row', flex: 1 }}>
              <MaterialIcons name="home" size={24} color="rgb(159, 120, 255)" />
              <Text marginLeft={10}>{item.name} </Text>
            </View>
            <MaterialIcons name="keyboard-arrow-right" size={24} color="#rgb(159, 120, 255)" />
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 20 }}>
            <Text fontWeight={'600'} fontSize={15} width="33%" align="center">
              {header2 || 'header2'}
            </Text>
            <Text fontWeight={'600'} fontSize={15} width="33%" align="center">
              {header3 || 'Header3'}
            </Text>{' '}
           {header4? <Text fontWeight={'600'} fontSize={15} width="33%" align="center">
              {header4 || 'header4'}
            </Text>:null}
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>
            <img src={getIcons(item.item_1)} style={{ height: 40, width: '33%' }} />
            <img src={getIcons(item.item_2)} style={{ height: 40, width: '33%' }} />
           {item?.item_3? <img src={getIcons(item.item_3)} style={{ height: 40, width: '33%' }} />:null}
          </View>
        </TouchableOpacity>
      </When>
      <Otherwise>
        <TouchableOpacity onPress={onPress}>
          <View
            style={{
              marginTop: 20,
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderColor: '#d8d8d8',
              alignItems: 'center',
              paddingBottom: 10,
            }}
          >
            <View style={{ alignItems: 'center', flexDirection: 'row', width: '40%' }}>
              <MaterialIcons name="home" size={24} color="rgb(159, 120, 255)" />
              <Text marginLeft={10}>{item.name} </Text>
            </View>
            <View style={{ flexDirection: 'row', width: '60%' }}>
              <img src={getIcons(item.item_1)} style={{ height: 40, width: '20%' }} />
              <img src={getIcons(item.item_2)} style={{ height: 40, width: '20%' }} />
             {item?.item_3? <img src={getIcons(item.item_3)} style={{ height: 40, width: '20%' }} />:null}
              <View style={{ alignItems: 'flex-end', width: '40%' }}>
                <MaterialIcons name="keyboard-arrow-right" size={24} color="#rgb(159, 120, 255)" />
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </Otherwise>
    </Choose>
  );

  return (
    <Box>
      <FlatList
        data={list}
        ListHeaderComponent={() => (
          <If condition={layoutType !== 'phone'}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <View style={{ width: '40%' }}>
                <Text fontWeight={'600'} fontSize={15}>
                  {header1 || 'header1'}
                </Text>
              </View>
              <View style={{ width: '60%', flexDirection: 'row' }}>
                <View style={{ width: '20%', alignItems: 'center' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    {header2 || 'header2'}
                  </Text>
                </View>
                <View style={{ width: '20%', alignItems: 'center' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    {header3 || 'header3'}
                  </Text>
                </View>
             {header4?   <View style={{ width: '20%', alignItems: 'center' }}>
                  <Text fontWeight={'600'} fontSize={15}>
                    {header4 || 'header4'}
                  </Text>
                </View>:null}
                <View style={{ width: '40%' }} />
              </View>
            </View>
          </If>
        )}
        renderItem={_renderItem}
      />
    </Box>
  );
};

export default ListOverView;
