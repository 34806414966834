import React, { useState, useEffect } from 'react';
import { View, Platform, Linking } from 'react-native';
import { MediaBox, MediaWrapper, TitleText, MediaLink, MediaDesc, FooterBox } from './styles';
const layoutType = getPlatformType();
import MediaDetails from './mediaDetails';
import { useIsFocused } from '@react-navigation/native';
import getPlatformType from '../../helpers/helpers';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';
import RenderHtml from 'react-native-render-html';
import MediaComponent from './mediacomponent';
import MediaVideoHub from './mediaVideoHub';

export const _redirectLink = (url) => {
  Linking.canOpenURL(url).then((supported) => {
    if (supported) {
      if (Platform.OS === 'web') {
        window.open(url);
      } else {
        Linking.openURL(url);
      }
    } else {
    }
  });
};

const MediaNewsHub = (props) => {
  const [showNewsDetail, setShowNewsDetail] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [posts, setPost] = useState([]);

  const focus = useIsFocused();

  useEffect(() => {
    ajax
      .get(Urls.GET_POST + 'NEWS')
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload && data?.payload?.length) {
            setPost(data?.payload);
          }
          if (data?.payload) {
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, [focus]);

  const createMarkup = (data) => {
    console.log('descriptiondescription', data.description);
    return { __html: data.description.split('.')[0] };
  };

  return (
    <>
     <View style={{width:"100%",alignSelf:'center'}}>
      {showNewsDetail ? (
        <View style={{ overflow: 'visible' }}>
          <MediaDetails selectedPost={selectedMedia} />
          <FooterBox
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              top: '100%',
              width: '100%',
            }}
          >
            <Button
              onPress={() => setShowNewsDetail(false)}
              borderRadius={15}
              type={'stripe'}
              childText="Back"
              height={layoutType == 'phone' ? 40 : 30}
              width={300}
            />
          </FooterBox>
        </View>
      ) : ( 
        <View style={{display:'flex',flexDirection:"row",flexWrap:"wrap",justifyContent: layoutType=='web'?'flex-start':'center',width:'100%',alignItems:'center' }}>
     
          
          <>
        {posts != undefined &&
            posts.map((data, index) => {
              return (
                
                <View key={index}>
                  <MediaComponent 
                
                  image={data.file}
                  title= {data.title}
                  description= {data.description}
                  onPress={() => {
                    navigate('LandlordMediaDetails', {
                      data: data.id,
                    });
                  }}
                  />
                  
                  </View>
              );
            })}
<MediaVideoHub/>
</>
      </View>



    )
      }
      </View>
    </>
  );
};
export default MediaNewsHub;
