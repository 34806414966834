import React from 'react';
import variables from '../variables.json';

/*
<Knob
  size={93}
  strokeWidth={10}
  progressPercent={60}
  text="10%"
/>
*/

const Knob = (props) => {
  const { size, strokeWidth, text, endCircleRadius } = props;
  const pointerRadius = props.pointerRadius ? props.pointerRadius : 5;
  const radius = (size - strokeWidth) / 2 - pointerRadius;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 100 - props.progressPercent;
  const angle = (props.progressPercent * 360) / 100;
  // const pointerX = size / 2 + radius * Math.sin((angle * Math.PI) / 180);
  // const pointerY = size / 2 - radius * Math.cos((angle * Math.PI) / 180);

  return (
    <div
      style={{
        borderWidth: 0,
        font: '14px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
      }}
    >
      <svg width={size} height={size}>
        {/* Background circle */}
        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={variables.blue}></stop>
          <stop offset="34%" stopColor={variables.blue}></stop>
          <stop offset="100%" stopColor={variables.purple2}></stop>
        </linearGradient>
        <circle
          stroke={props.bgColor ? props.bgColor : variables.concrete}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        <circle
          stroke={'url(#linearColors1)'}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
        {/* <circle
          fill={variables.blue}
          cx={pointerX}
          cy={pointerY}
          r={endCircleRadius || 5}
        /> */}
        <text
          fontSize={props.textSize ? props.textSize : '18'}
          x={size / 2}
          y={size / 2.4 + (props.textSize ? props.textSize / 2 - 1 : 5) + 5}
          textAnchor="middle"
          fill={variables.purple1}
          fontWeight={'bold'}
        >
          {text}
        </text>
        <text
          fontSize={props.textSize ? props.textSize : '9'}
          x={size / 2}
          y={size / 1.7 + (props.textSize ? props.textSize / 2 - 1 : 5)}
          textAnchor="middle"
          fill={variables.purple1}
          fontWeight={'500'}
        >
          complete
        </text>
      </svg>
    </div>
  );
};

export default Knob;
