import styled from 'styled-components/native';
import getPlatformType from '../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, Row, Col;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 55%;
    align-self: center;
    padding: 10px;
  `;
  Row = styled.View`
    flex-direction: row;
  `;
  Col = styled.View`
    flex-direction: column;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  Row = styled.View`
    flex-direction: row;
  `;
  Col = styled.View`
    flex-direction: column;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 85%;
    padding: 10px;
    margin-left: 10%;
  `;
  Row = styled.View`
    flex-direction: row;
  `;
  Col = styled.View`
    flex-direction: column;
  `;
}
export { ContainerScrollView, Container, Row, Col };
