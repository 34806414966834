import React, { useEffect, useState } from 'react';
import { ListItem } from '../atoms/listitem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import variables from '../atoms/variables.json';
import { TextInput } from '../atoms/textinput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  FormContainer,
  RowContainer,
} from './style';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import ContactCard from '../atoms/contactCard';
import { useDispatch, useSelector } from 'react-redux';
import BackComponent from '../atoms/backComponent';
import BottomButton from '../atoms/bottomButton';
import OpenFlowModal from '../atoms/modal';
import moment from 'moment';
import {  View } from 'react-native';
import Text from '../../../../components/text';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
import ajax from '../../../../helpers/ajaxHelper';
import Urls from '../../../../services/Urls';
import { showErrorToast, showSuccesToast } from '../helpers';
import { useFocusEffect } from '@react-navigation/native';
import { ListCard } from '../atoms/listCard';
import MarkAsComplete from '../../../../components/markAsComplete/MarkAsComplete';
import TextInputComponent from '../../../../components/textinput/TextInputComponent';
import { EmailValidation } from '../../../properties/validation';
import { parseISO } from 'date-fns';
const userModal = {
  first_name: '',
  last_name: '',
  email: '',
  contact_number: '',
  isLeadTeanant: null,
  opbrix_id: '',
  is_lead: false,
  tLyfeId: '',
  pincode: '',
  address: '',
};
const layoutType = getPlatformType();
const PropertyTenantDetails = (props) => {
  const {route} =props
  const dispatch = useDispatch();
  const [days, setDays] = useState([
   "", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]);
  const globalState = useSelector((state) => state?.tenant);
  const [value, setValue] = React.useState(null);
  const [teanentDetails, setTeanentDetails] = React.useState(userModal);
  const [notifyTeanant, setNotifyTeanants] = React.useState(false);
  const [addedUsers, setAddedUsers] = React.useState([]);
  const [flow, setFlow] = React.useState({});

  const [isValidate, setIsValidate] = React.useState(false);
  const [is_show_tenant_popup, setIs_show_tenant_popup] = React.useState(true);
  const [addUser, setAddUser] = React.useState(null);
  const [flowId, setFlowId] = React.useState(null);
  const [confirm, setConfirm] = React.useState(false);
  const [deleteCardconfirm, setDeleteCardconfirm] = React.useState(false);
  const [mailToUpdateAsLead, setMailToUpdateAsLead] = React.useState('');
  const [idToDeleteTenant, setIdToDeleteTenant] = React.useState('');
  const [isValidated, setValidation] = React.useState(false);
  const [isRentalValidated, setRentalValidation] = React.useState(false);
  const [rentalData, setRentalData] = React.useState({
    rental_frequency:"",
    rental_due_date:''
  });

  const [showSuccessModal, setSuccessModal] = React.useState(false);
  const [closePopup, setClosePopup] = React.useState(false); 


  const modalGlobalObject = useSelector((state) => state?.tenant?.tenantDetails);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });
  console.log('rentalData', rentalData);
  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);

  useEffect(() => {
    if (props?.route?.params?.id) {
      setFlowId(props?.route?.params?.id);
      _getDetails(props?.route?.params?.id);
    }
  }, [props?.route?.params?.id, useFocusEffect]);
  const _getDetails = (id) => {
    ajax
      .get(`${Urls.GET_TEANANT_DETAILS}/${id}`)
      .then(({ data }) => {
        if (data?.status) {
          if (data?.payload?.rentalData) {
            console.log('GET_TEANANT_DETAILS', data?.payload?.rentalData);
            setRentalData(data.payload.rentalData);
          }else{
            setRentalData({
              rental_frequency:"",
              rental_due_date:''
            })
          }
          if (data?.payload &&data?.payload?.tenants?.length ) {
            let newArr = [];
            data?.payload.tenants.map((item) => {
              newArr.push({...item.tenant,is_lead:item?.is_lead});
            });
            console.log('newArr',newArr)
            setAddedUsers(newArr);
           
          } else {
            setAddedUsers([]);
          }
         
        }else{
          setAddedUsers([])
          setRentalData({
            rental_frequency:"",
            rental_due_date:''
          })
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  console.log("added users",rentalData)
  const saveDetails = (id, payload) => {
    ajax
      .post(`${Urls.GET_TEANANT_DETAILS}/${id}`, payload)
      .then(({ data }) => {
        if (data.status) {
          if (flowId) {
            props?.navigation.navigate('PropertySection', { id: flowId });
          } else {
            props?.navigation.navigate('PropertySection', { id: flowId });
          }
          // showSuccesToast(data?.data);
        } else {
          if (data.message) {
            showErrorToast(data.message);
          } else {
            showErrorToast('Something went wrong. Please contact support@openbrix.co.uk');
          }
        }
      })
      .catch((error) => {
        console.log('errorerrorerror', error);
        showErrorToast('Something went wrong. Please contact support@openbrix.co.uk');
      });
  };
  const checkandSaveDetails = (id, payload) => {
    ajax
      .post(`${Urls.CHECK_TEANANT_EMAIL}${id}`, payload)
      .then(({ data }) => {
        console.log('datadatadata', data);
        if (data.status) {
          if (data?.data?.payload?.length) {
            showErrorToast('Email is alreay assoisted with Teanant. Please contact support@openbrix.co.uk');
          } else {
            setAddedUsers(
              teanentDetails?.is_lead
                ? MakeLeadFunct([...addedUsers, fieldsValue], fieldsValue?.email)
                : [...addedUsers, fieldsValue]
            );
            setAddUser(false);
            setValue(null);
            setFieldValues(userModal);
            setIsValidate(false);
            window.scrollTo(0, 0);
          }
        } else {
          if (data?.message) {
            showErrorToast(data?.message);
          } else {
            showErrorToast('Something went wrong. Please contact support@openbrix.co.uk');
          }
        }
      })
      .catch((error) => {
        showErrorToast('Something went wrong. Please contact support@openbrix.co.uk');
      });
  };
  useEffect(() => {
    if (globalState?.tenantDetails && globalState?.tenantDetails?.sub_tenants?.length) {
      setAddedUsers(globalState?.tenantDetails?.sub_tenants);
      setFlow(globalState?.tenantDetails?.flow);
      setIs_show_tenant_popup(globalState?.tenantDetails?.is_show_tenant_popup);
    } else {
      setAddedUsers([]);
    }
  }, [globalState?.tenantDetails, globalState?.tenantDetails?.sub_tenants]);

  useEffect(() => {
    setTeanentDetails({
      ...teanentDetails,
      is_lead: addedUsers.length > 0 ? false : true,
    });
  }, [addedUsers]);
  const [fieldsValue, setFieldValues] = useState({
    tLyfeId: '',
    //8MDZ62U55
  });
  const handleChangeForm = (e, name) => {
    setFieldValues({ ...fieldsValue, [e.target.name]: e.target.value });
  };
  const handleChangeRentalForm = (e, name) => {
    if ([e.target.name] == 'tenancy_start_date') {
      return setRentalData({
        ...rentalData,
        [e.target.name]: e.target.value,
        tenancy_end_date: moment(e.target.value)
          .add(1, 'years')
          .subtract(1, 'd')
          .format('YYYY-MM-DD'),
      });
    }else{
      setRentalData({ ...rentalData, [e.target.name]: e.target.value });
    }
  };
  

  const handleChangeLead = (event) => {
    setTeanentDetails({
      ...teanentDetails,
      is_lead: event.target.value == 'true' ? true : false,
    });
  };
  const MakeLeadFunct = (arrayData, emailTomakeLead) => {
    const data = arrayData.map((elem) => ({
      ...elem,
      is_lead: emailTomakeLead === elem?.email ? true : false,
    }));
    return data;
  };

 const CheckEmailValidation = (value) =>{
  let EmailValidate = EmailValidation(value);
  if (EmailValidate.status === false){
  return EmailValidate.msg;
  }
  else{

  }
 }
  const checkForError = (value) => {
    console.log('value', value);
    console.log('isValidated', isValidated);
    if (isValidated) {
      if (value) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const checkForRentalError = (value) => {
    if (isRentalValidated) {
      if (value) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  console.log('sssssss',addedUsers)
  return (
    <View style={{ flex: 1 }}>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Tenant'}
          description={`Please add the information of any tenants who are occupants of this property. You can leave this empty if the property is vacant.`}
          showPropertyCard={false}
          // icon={TEANANT_ICON}
        />
<MarkAsComplete

flowID={props?.route?.params?.id}
flowtype={route?.params?.key}
isComplete={route?.params?.status === 2 ? true : false}
/>
       
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SubHeading style={{ color: variables.purple }}> Your applicant tenant(s)</SubHeading>
          {globalState?.tenantDetails?.sub_tenants.length ? (
            <SubHeading style={{ color: variables.purple }}>
              {' '}
              Last sent on: {moment(flow?.updatedAt).format('DD/MM/YYYY')}
            </SubHeading>
          ) : null}
        </div>
        {addedUsers && addedUsers.length ? (
          <React.Fragment>
            <RowContainer style={{ marginBottom: 0, flexWrap: 'wrap' }}>
              {addedUsers.map((item, index) => {
                return (
                  <ContactCard
                    data={item}
                    key={item?.tenant_id}
                    isLeadEditable={true}
                    selectedEmailForLead={(email) => setMailToUpdateAsLead(email)}
                    leadModalConfirm={confirm}
                    deleteCardConfirm={deleteCardconfirm}
                    selectIdToDelete={(id) => setIdToDeleteTenant(id)}
                    row={item?.email}
                    rowIndex={index}
                    is_lead={item.is_lead ? true : false}
                    name={item.first_name + ' ' + item.last_name}
                    isArchive
                    onDelete={(data) => {
                      if (item.is_lead && addedUsers.length > 1) {
                        let newAddusers = addedUsers.filter((ele) => {
                          return ele.email != item.email;
                        });
                        setAddedUsers(MakeLeadFunct(newAddusers, newAddusers[0].email));
                        setClosePopup(false);
                      } else {
                        let newArray = [...addedUsers];
                        newArray.splice(index, 1);
                        setAddedUsers(newArray);
                        setClosePopup(false);
                      }
                    }}
                    nonRemove={item?.tenant_id ?  addedUsers.length===1 : true}
                    onEdit={(data)=>{
                
                      console.log("datadatadata pre",data)
                      let teampArr = [...addedUsers];
                      teampArr[index] = data;
                      console.log("datadatadata next",teampArr)
                      setAddedUsers(teampArr);
                    }}
                    onMakeLead={() => {
                      setClosePopup(false);
                      setAddedUsers(MakeLeadFunct(addedUsers, mailToUpdateAsLead));
                    }}
                    closePopup={closePopup}
                    setClosePopup={setClosePopup}
                  />
                );
              })}
            </RowContainer>
          </React.Fragment>
        ) : null}
        <TlyfeButtonWrapper>
          <Button
            onClick={() => {
              setAddUser(true);
            }}
            title={addedUsers && addedUsers.length ? 'Add another tenant' : 'Add tenant'}
            type="stripe"
            style={{ borderColor: '#90C418',marginTop:20 }}
          />
        </TlyfeButtonWrapper>
        {addUser ? (
          <React.Fragment>
            <LineSeperator />
            <Heading>Tenant Details</Heading>
            <TlyfeSelector style={{ marginBottom: '5%' }}>
              <div
                style={{
                  flex: 0.3,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SubHeading>Is this the lead tenant?</SubHeading>
              </div>
              <div
                style={{
                  flex: 0.7,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="is_lead"
                    value={teanentDetails.is_lead}
                    onChange={handleChangeLead}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        value={true}
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </TlyfeSelector>

            <CardBox style={{ position: 'relative', width: '100%' }} hasBorderRadius hasShadow>
              <RowContainer>
                <FormContainer style={{marginTop:10}}>
                  <p style={{ fontSize: 14, width: '100%', fontFamily: 'lato' }}>Title</p>
                  <Select
                    value={fieldsValue?.title}
                    style={{
                      width: '100%',
                      height: '40px',
                      fontFamily: 'lato',
                    }}
                    onChange={handleChangeForm}
                    variant="outlined"
                    name="title"
                    placeholder="Title"
                  >
                    {['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'N/A'].map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                  </Select>
                {  (!fieldsValue?.title) ? (
             <p style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
             *Required
             
           </p>
                  ) : null}
                </FormContainer>
                <FormContainer>
                  <TextInput
                    title={'First name'}
                    name={'first_name'}
                    // error={checkForError(fieldsValue?.first_name)}
                    onChange={(e) => {
                        handleChangeForm(e);
                    }}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.first_name}
                    disabled={fieldsValue.opbrix_id ? true : false}
                    error={fieldsValue?.first_name ? false : '*Required'}
                  />
                </FormContainer>
              </RowContainer>
              <RowContainer>
                <FormContainer>
                  <TextInput
                    title={'Last name'}
                    name={'last_name'}
                    onChange={(e) => {  
                        handleChangeForm(e);
                    }}
                    error={fieldsValue?.last_name ? false : '*Required'}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.last_name}
                    disabled={fieldsValue.opbrix_id ? true : false}
                  />
                </FormContainer>
                <FormContainer>
                  <TextInput
                    title={'Contact Number'}
                    name={'contact_number'}
                    type={'number'}
                    onChange={(e) => {
                      const re = /^[+\d](?:\d)*$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        handleChangeForm(e);
                      }
                    }}
                    error={checkForError(fieldsValue?.contact_number)}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.contact_number}
                  />
                </FormContainer>
              </RowContainer>
              <RowContainer>
                <FormContainer>
                  <TextInput
                    title={'Email Address'}
                    inputProps={{
                      readOnly: fieldsValue.tLyfeId ? true : false,
                    }}
                    name={'email'}
                    onChange={(e) => {
                      handleChangeForm(e);
                    }}
                    // error={checkForError(fieldsValue?.email)}
                    error = {fieldsValue?.email?CheckEmailValidation(fieldsValue?.email):'*Reuired'}
                    
                    containerstyles={{ width: '100%' }}
                    disabled={fieldsValue.opbrix_id ? true : false}
                    value={fieldsValue?.email?.toLowerCase()}
                  />
                </FormContainer>
                <FormContainer>
                  <TextInput
                    title={'Confirm Email'}
                    name={'confirm_email'}
                    onChange={handleChangeForm}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.confirm_email?.toLowerCase()}
                    error={fieldsValue?.confirm_email ? false : '*Required'}
                  />
                  {fieldsValue?.email && fieldsValue?.confirm_email ? (
                    fieldsValue?.email === fieldsValue?.confirm_email ? null : (
                      <Text
                        style={{
                          color: 'red',
                          textAlign: 'right',
                          marginTop: 10,
                        }}
                      >
                        Email Should be same!
                      </Text>
                    )
                  ) : null}
                </FormContainer>
              </RowContainer>
              <RowContainer>
                <FormContainer>
                  <TextInput
                    title={'Current Address'}
                    name={'address'}
                    onChange={handleChangeForm}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.address}
                  />
                </FormContainer>
                <FormContainer>
                  <TextInput
                    title={'Postcode'}
                    name={'postcode'}
                    onChange={handleChangeForm}
                    containerstyles={{ width: '100%' }}
                    value={fieldsValue?.postcode}
                  />
                </FormContainer>
              </RowContainer>
              <TlyfeButtonWrapper style={{ marginTop: '5%', justifyContent: 'space-between' }}>
                <Button
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    borderColor: '#FC4850',
                    color: '#FC4850',
                  }}
                  onClick={async () => {
                    setAddUser(false);
                    setValue(null);
                    setFieldValues(userModal);
                    window.scrollTo(0, 0);
                  }}
                  title={'Cancel'}
                  type="stripe"
                />
                <Button
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    borderColor: '#90C418',
                    color: '#90C418',
                  }}
                  onClick={async () => {
                    setValidation(true);
                    if (
                      fieldsValue.first_name &&
                      fieldsValue.last_name &&
                      fieldsValue.title &&
                      // fieldsValue.contact_number &&
                      fieldsValue.email &&
                      fieldsValue?.email === fieldsValue?.confirm_email
                    ) {
                      if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(fieldsValue.email)) {
                        setValidation(false)
                        checkandSaveDetails(flowId, {
                          email: fieldsValue.email,
                        });
                      } else {
                        showErrorToast('invalid eamil');
                      }
                    }
                    if (addedUsers.length == 0 && !teanentDetails?.is_lead) {
                      showErrorToast('Please add the lead tanant first!');
                    }
                  }}
                  title={'Add Tenant'}
                  type="stripe"
                />
              </TlyfeButtonWrapper>
            </CardBox>
          </React.Fragment>
        ) : null}
        <LineSeperator />
        <CardBox
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: 30,
          }}
          hasBorderRadius
          hasShadow
        >
          <Heading style={{ color: variables.purple, width: '100%' }}>General Rental Conditions</Heading>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop:20,
              width: layoutType == 'phone' ? '100%' : '49%',
            }}
          >
          <TextInputComponent
            onChangeText={(e) =>{
              handleChangeRentalForm({target:{
                name:"rental_amount",
                value:e
              }});
              // handleChangeRentalForm({ value: e, label: 'mortgage_variable_rate' })
            }}
            isAmount
            amountWidth={{width:"100%"}}
            // wrapperStyle={{width: 100}}
            value={rentalData?.rental_amount}
            allowDecimal
            name="tenancy_deposit_amount"
            title={'Rental amount (£)'}
            height={42.5}
          />
           {checkForRentalError(rentalData?.tenancy_deposit_amount) ? (
             <Text style={{ marginTop: '5px', color: 'red', textAlign: 'right', marginTop: 10, fontFamily: 'lato' }}>
             *Required
             
           </Text>
            ) : null}
            
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '49%',
              flexWrap: 'wrap',
              marginTop:20,
              alignItems: 'left'
              // justifyContent: 'flex-end',
            }}
          >
            {/* <SubHeading style={{ fontSize: 16, width: '100%' , marginBottom:10}}>Frequency</SubHeading> */}
            <Text fontWeight={'600'} marginBottom={10} lineHeight={22} fontSize={16}>
          Frequency
        </Text>

            <Select
              value={rentalData.rental_frequency}
              className="form-control"
              onChange={handleChangeRentalForm}
              style={{ width: '100%', height: 42 ,marginBottom: 10,}}
              variant="outlined"
              name="rental_frequency"
              placeholder="duration"
            >
              <MenuItem value={''}>Select</MenuItem>
              <MenuItem value={'week'}>Weekly</MenuItem>
              <MenuItem value={'monthly'}>Per Calender Month</MenuItem>
              <MenuItem value={'quarterly'}>Quarterly</MenuItem>
              <MenuItem value={'6 monthly'}>6 Monthly</MenuItem>
              <MenuItem value={'annually'}>Annually</MenuItem>
            </Select>
            {checkForRentalError(rentalData?.rental_frequency) ? (
              <Text
                style={{
                  color: 'red',
                  textAlign: 'right',
                  marginTop: 10,
                }}
              >
                *Required
              </Text>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: layoutType == 'phone' ? '100%' : '49%',
              flexWrap: 'wrap',
              marginTop: 15,
              alignItems: 'left',
              // justifyContent: 'flex-end',
            }}
          >
            {/* <SubHeading style={{ fontSize: 14, width: '100%' }}>Rent Due Date</SubHeading> */}
            <Text fontWeight={'600'} marginBottom={10} lineHeight={22} fontSize={16}>Rent Due Date</Text>
            <Select
              value={rentalData?.rental_due_date || ''}
              style={{ width: '100%', height: '42px', borderWidth: 0 ,marginBottom: 10, }}
              onChange={handleChangeRentalForm}
              variant="outlined"
              name="rental_due_date"
              placeholder="duration"
            >
              {days.map((ele, index) => {
                return (
                  <MenuItem key={index} value={ele}>
                    {ele}
                  </MenuItem>
                );
              })}
            </Select>
            {checkForRentalError(rentalData?.rental_due_date) ? (
              <Text
                style={{
                  color: 'red',
                  textAlign: 'right',
                  marginTop: 10,
                  alignSelf: 'flex-end',
                }}
              >
                *Required!
              </Text>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop:15,
              width: layoutType == 'phone' ? '100%' : '49%',
            }}
          >
          <TextInputComponent
            onChangeText={(e) =>{
              handleChangeRentalForm({target:{
                name:"tenancy_deposit_amount",
                value:e
              }});
              // handleChangeRentalForm({ value: e, label: 'mortgage_variable_rate' })
            }}
            amountWidth={{width:"100%"}}
            isAmount
            // wrapperStyle={{width: 100}}
            value={rentalData?.tenancy_deposit_amount}
            allowDecimal
            name="tenancy_deposit_amount"
            title={'Tenancy Deposit'}
            height={42.5}
          />
           {checkForRentalError(rentalData?.tenancy_deposit_amount) ? (
              <Text
                style={{
                  color: 'red',
                  textAlign: 'right',
                  marginTop: 10,
                  alignSelf: 'flex-end',
                }}
              >
                *Required
              </Text>
            ) : null}
          </div>
          {/* <TextInput
            title={''}
            isfullwidth={true}
            wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 0 }}
            value={rentalData?.tenancy_deposit_amount}
            onChange={(e) => {
              const re = /^[+\d](?:\d)*$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                handleChangeRentalForm(e);
              }
            }}
            error={checkForRentalError(rentalData?.tenancy_deposit_amount)}
            name="tenancy_deposit_amount"
          /> */}
          {/* <TextInput
            title={'Tenancy start date'}
            isfullwidth={true}
            type={'date'}
            value={moment(new Date(rentalData.tenancy_start_date )).format('YYYY-MM-DD')}
            // inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
            wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
            onChange={handleChangeRentalForm}
            error={checkForRentalError(rentalData?.tenancy_start_date)}
            name="tenancy_start_date"
            formatDate={(date) => {
              console.log('datedate', date);
              moment(date).format('DD-MM-YYYY');
            }}
          /> */}
              <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop:15,
              width: layoutType == 'phone' ? '100%' : '49%',
            }}
          >
                    <TextInputComponent
            onChangeText={(e) => {
              handleChangeRentalForm({
                target: {
                  name: "tenancy_start_date",
                  value: e
                }
              });
           
            }}
                  title={'Tenancy start date'}
                  isDate
                  date={rentalData?.tenancy_start_date}
                  amountWidth={{width:"100%"}}
                ></TextInputComponent>
                          {checkForRentalError(rentalData?.tenancy_start_date) ? (
              <Text
                style={{
                  color: 'red',
                  textAlign: 'right',
                  marginTop: 10,
                  alignSelf: 'flex-end',
                }}
              >
                *Required
              </Text>
            ) : null}
                </div>
          {/* <TextInput
            title={'Tenancy End date'}
            type={'date'}
            isfullwidth={true}
            value={ moment(new Date(rentalData.tenancy_end_date )).format('YYYY-MM-DD') }
            inputProps={{ min: moment(rentalData?.tenancy_start_date).format('YYYY-MM-DD') }}
            error={checkForRentalError(rentalData?.tenancy_end_date)}
            wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
            onChange={handleChangeRentalForm}
            name="tenancy_end_date"
          /> */}
                <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop:15,
              width: layoutType == 'phone' ? '100%' : '49%',
            }}
          >
          <TextInputComponent
            onChangeText={(e) => {
              handleChangeRentalForm({
                target: {
                  name: "tenancy_end_date",
                  value: e
                }
              });
           
            }}
                  title={'Tenancy End date'}
                  isDate
                  date={rentalData?.tenancy_end_date}
                  amountWidth={{width:"100%"}}
                  minDate={rentalData?.tenancy_start_date}

                ></TextInputComponent>
                           {checkForRentalError(rentalData?.tenancy_start_date) ? (
              <Text
                style={{
                  color: 'red',
                  textAlign: 'right',
                  marginTop: 10,
                  alignSelf: 'flex-end',
                }}
              >
                *Required
              </Text>
            ) : null}
                </div>
        </CardBox>
        {addedUsers && addedUsers.length ? (
          <BottomButton
            showSendbutton={false}
            showContinueModal={false}
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            onContinue={() => {
              setRentalValidation(true);
              if (
                rentalData.rental_amount &&
                rentalData.tenancy_end_date &&
                rentalData.tenancy_start_date &&
                rentalData.tenancy_deposit_amount &&
                rentalData.rental_due_date &&
                rentalData.rental_frequency
              ) {
                saveDetails(flowId, { tenants: [...addedUsers], rentalData: { ...rentalData } });
              } else {
                showErrorToast('Please fill the rental information!');
              }
            }}
          />
        ) : null}
      </CardWrapper>
    </View>
  );
};
export default drawerHoc({
  Component: PropertyTenantDetails,
  showBackButton: true,
});
