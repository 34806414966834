import React, { useEffect, useState } from 'react';
import { FlatList, TextInput, TouchableOpacity, Image } from 'react-native';
import {
  Container,
  Row,
  HorizontalLine,
  LeftContainer,
  ListItem,
  Col,
  Left,
  Right,
  RightContainer,
  RightBox,
} from './index.style';
import PropTypes from 'prop-types';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';

import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import { _redirectLink } from '../../screens/mediaHub/mediaNewsHub';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';

const removedHtmlTags = (strInputCode) => strInputCode.replace(/<\/?[^>]+(>|$)/g, '');

const NewsTool = (props) => {
  let navigation = useNavigation();
  // const { onChangeText, tooltipData, newsData, blogData } = props;
  const [blogData, setBlogData] = useState([]);
  const [newsData, setNewsData] = useState([]);

  const getBlog = (endpoint) => {
    ajax
      .get(Urls.GET_POST + endpoint)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload && data?.payload?.length) {
            setBlogData(data?.payload);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getNews = (endpoint) => {
    ajax
      .get(Urls.GET_POST + endpoint)
      .then(({ data }) => {
        if (data.status) {
          if (data?.payload && data?.payload?.length) {
            setNewsData(data?.payload);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    getBlog('BLOG');
    getNews('NEWS');
  }, []);

  const _render = (item, index) => {
    return (
      <ListItem
        style={{ borderColor: '#ebebeb', borderBottomWidth: newsData?.length || blogData?.length == index + 1 ? 0 : 1 }}
      >
        <Col width={'80%'} onPress={() => (item?.link ? _redirectLink(item.link) : null)}>
          <Text fontSize={15} fontWeight={500} marginBottom={5}>
            {item?.title}
          </Text>
          <Text fontSize={14} numberOfLines={1}>
            {removedHtmlTags(item?.description)}
          </Text>
        </Col>
        <Col width={'10%'}>
          <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />
        </Col>
      </ListItem>
    );
  };
  // const _renderTools = () => {
  //   return (
  //     <RightBox>
  //       <Image
  //         source={require('../../assets/icons/clock.png')}
  //         style={{ width: 40, height: 43, resizeMode: 'contain' }}
  //       />
  //       <Text align={'center'} fontSize={13} fontWeight={500} marginTop={20}>
  //         Title {'\n'} Description
  //       </Text>
  //     </RightBox>
  //   );
  // };
  return (
    <Container>
      <Left>
        <If condition={newsData && newsData.length > 0}>
          <Row style={{ padding: 10, marginBottom: 10 }}>
            <Text fontSize={16} fontWeight={500}>
              News & Updates
            </Text>
            <Text
              fontSize={12}
              fontWeight={500}
              color={Colors.light.purple}
              onPress={() => {
                navigation.navigate('MediaHub');
              }}
            >
              View all
            </Text>
          </Row>

          <LeftContainer>
            <FlatList data={newsData} renderItem={({ item, index }) => _render(item, index)} />
          </LeftContainer>
        </If>
      </Left>
      <Right>
        <If condition={blogData && blogData.length > 0}>
          <Row style={{ padding: 10, marginBottom: 10 }}>
            <Text fontSize={16} fontWeight={500}>
              Blogs
            </Text>
            <Text
              fontSize={12}
              fontWeight={500}
              color={Colors.light.purple}
              onPress={() => {
                navigation.navigate('MediaHub');
              }}
            >
              View all
            </Text>
          </Row>

          <LeftContainer>
            {console.log('blogData', blogData)}
            <FlatList data={blogData} renderItem={({ item, index }) => _render(item, index)} />
          </LeftContainer>
        </If>
      </Right>
    </Container>
  );
};
NewsTool.propTypes = {
  leftText: PropTypes.string,
  onChangeText: PropTypes.func,
};

NewsTool.defaultProps = {};

export default NewsTool;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
