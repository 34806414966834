import React, { useState } from 'react';
import { View, FlatList } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { FontAwesome } from '@expo/vector-icons';
import Text from '../../components/text';
// import DrawerMenu from '../drawerMenu/DrawerMenu';
import { MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
import { Container, ContainerScrollView } from './index.style';
import CalenderComponent from '../../components/calendar';
import CalendarList from '../../components/calendarList';
import { HOME } from './../../constants/svg';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';

import DrawerMenu from '../drawerMenu/DrawerMenu';
import { Row } from '../commonStyles';
import TenantTable from '../../components/tenantTable';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
var layoutType = getPlatformType();
var DATA = [
  {
    name: 'Ahmed Almutereb',
    location: 'Acorn Place',
    date: '01/07/2021',
  },
  {
    name: 'Ahmed Almutereb',
    location: 'Acorn Place',
    date: '01/07/2021',
  },
];
var colData = ['Tenant', 'Property', 'Tenancy End Date'];

const Tenants = ({ navigation }) => {
  return (
    <>
      <SearchCardComponent placehorderText={'Search Tenants'} leftText={'My Tenants'} />
      <Text marginTop={10} fontSize={16} fontWeight="600">
        Live Tenancies
      </Text>
      <TenantTable
      isTeanat
      data={DATA} colData={colData} />
      <Text marginTop={10} fontSize={16} fontWeight="600">
        Currently Onboarding
      </Text>
      <TenantTable 
      isTeanat
      data={DATA} colData={colData} />
      <Row>
        <Button type={'solid'} width={170} childText={'Add new tenant'} />
        <Button type={'stripe'} width={170} childText={'Tenant archive'} style={{ marginLeft: 10 }} />
      </Row>
    </>
  );
};
export default drawerHoc({
  Component: Tenants,
});
