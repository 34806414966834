import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, HeaderBox, ListingBox, ContainerWrapper, Section, SectionRow;

export const HeaderText = styled.Text`
  font-weight: bold;
  font-size: 18px;
`;
Section = styled.View`
  flex-direction: ${layoutType == 'phone' ? 'colume' : 'row'};
  margin-top: 4%;
`;

SectionRow = styled.View`
  flex: 0.5;
  margin-horizontal: 2%;
`;
export const SettingText = styled.Text`
  font-weight: bold;
`;
export const SettingList = styled.TouchableOpacity`
  padding: 10px 10px;
  padding-top: ${(props) => (props.count === 0 ? 0 : '15px')};
  padding-bottom: ${(props) => (props.length === props.count ? 0 : '15px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: ${(props) => (props.length === props.count ? 0 : '1px')};
  border-bottom-color: #ebebeb;
`;

export const ViewFlex = styled.View`
  flex: ${(props) => props.flex || 1};
`;

if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 65%;
    margin-left: 320px;
    padding: 10px;
    margin-top: 20px;
  `;

  HeaderBox = styled.View`
    width: 300px;
  `;
  ListingBox = styled(Box)`
    width: 80%;
  `;
  ContainerWrapper = styled.View`
    margin-top: 20px;
    margin-left: 50px;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  HeaderBox = styled.View`
    width: 100%;
  `;
  ListingBox = styled(Box)`
    width: 100%;
  `;
  ContainerWrapper = styled.View`
    margin-top: 20px;
    margin-left: 30px;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 90%;
    padding: 10px;
    margin-left: 10%;
  `;
  HeaderBox = styled.View`
    width: 100%;
  `;
  ListingBox = styled(Box)`
    width: 100%;
  `;
  ContainerWrapper = styled.View`
    margin-top: 10px;
    margin-left: 10px;
  `;
  Section;
}
export { ContainerScrollView, Container, HeaderBox, ListingBox, ContainerWrapper, Section, SectionRow };
