import styled from 'styled-components/native';
import { Text, TextInput, TouchableOpacity } from 'react-native';
import getPlatformType from '../../helpers/helpers';
import Colors from '../../constants/Colors';
const layoutType = getPlatformType();
let Container, Row, Left;
let Radio = styled.TouchableOpacity`
  width: 20px;
  height: 20px;
  border-radius: 1000px;
  border-width: 2px;
  border-color: ${(props) => (props?.checked ? Colors.light.purple : '#D8D8D8')};
  justify-content: center;
  align-items: center;
`;
let InsideRadio = styled.View`
  width: 12px;
  height: 12px;
  border-radius: 1000px;
  background-color: ${(props) => (props?.checked ? Colors.light.purple : 'white')};
`;
if (layoutType == 'web') {
  Container = styled.View`
    margin-top: 10px;
  `;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
} else if (layoutType == 'phone') {
  Container = styled.View`
    margin-top: 10px;
  `;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
} else {
  Container = styled.View`
    margin-top: 10px;
  `;
  Left = styled.View`
    flex-direction: row;
    align-items: center;
  `;
  Row = styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `;
}
export { Container, Row, Left, Radio, InsideRadio };
