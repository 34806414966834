import React from 'react';
import { Text } from 'react-native';
import './styles.scss';

const CounterInput = (props) => {
  const { value, label, handleCounterChange, isRounder = false, minValue } = props;

  return (
    <>
      {label && <label>{label}</label>}
      <div className={`counter ${isRounder ? 'rounded-counter' : ''}`}>
        {value > minValue ? (
          <button className="counter-control" onClick={() => handleCounterChange(value - 1, 'sub')} type="button">
            -
          </button>
        ) : null}
        <Text style={{marginHorizontal:10,fontSize:18}}>{value}</Text>
        <button
          className="counter-control"
          onClick={() => {
            handleCounterChange(value + 1, 'add');
          }}
          type="button"
        >
          +
        </button>
      </div>
    </>
  );
};

export default CounterInput;
