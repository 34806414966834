import React from 'react';
import {
    View,
    Text,
    SafeAreaView,
    Image,
    TouchableOpacity,
    Platform,
    ScrollView,
    Button,
    WebView
} from "react-native";
import styled from "styled-components";
import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader,
    SectionPageTitle,
    SectionTitleTop,
    BulletText,
    Bullet,
    SectionInnerTitle,
    PrivacyGrid,
    PrivacyGridHeader,
    PrivacyGridBody,
    Asterick,
    PrivacyLeftBox,
    PrivacyRightBox,
    CookieTable,
    CookieCells,
    BulletInlineText,
} from "./styles";

const rowText = (text1, text2) => {
    return (
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 10 }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{text1}<Text style={{ fontSize: 14, marginLeft: 5, fontWeight: '400' }}>{text2}</Text> </Text>

        </View>
    )
}
const headingText = (text1) => {
    return (
        <View style={{ flexDirection: 'row', width: '100%', marginTop: 30 }}>
            <Text style={{ fontSize: 16, fontWeight: 'bold' }}>{text1} </Text>

        </View>
    )
}

const Terms = () => {
    return (
        <View style={{ flex: 1, padding: 20, width: '100%', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <Text style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'center', marginVertical: 20 }}>Hubita Ltd – Business Terms and Conditions  </Text>
            {headingText("1 About Us")}
            <Text style={{ fontSize: 14, marginTop: 20 }}>
  A   HUBITA LIMITED (Companies House number 12517876) (the Company, the Supplier, we and us) is a company registered in England and Wales and our registered office is: 5, Indescon Square, South Quay, Canary Wharf, London, England, E14 9DQ. We operate the website{' '}
  <Text style={{ color: "#6D08C0", textDecorationLine: 'underline' }}>
    <a href="http://www.hubita.co.uk" target="_blank" rel="noopener noreferrer">
      www.hubita.co.uk
    </a>
  </Text>
  {' (the Site).'}
</Text>
<Text style={{ fontSize: 14, marginTop: 20 }}>
  B.{' '}
  The Company is authorised by the Financial Conduct Authority and our FCA Firm Reference Number is 990767. Hubita Ltd is an Introducer Appointed Representative of Paragon Advance Limited, whose FCA Firm Reference Number is 304595.
</Text>
<Text style={{ fontSize: 14, marginTop: 20 }}>
  C.{' '}
  To contact us, you should email our customer service team at{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="mailto:info@hubita.co.uk">info@hubita.co.uk</a>
  </Text>
  {'.'}
</Text>

            {headingText("2 Interpretation")}
            {rowText("", "The following definitions and rules of interpretation apply in these Business Terms and Conditions (Conditions).")}
            {rowText("", "")}
            {/* {rowText("Definitions", "")}
            {rowText("Business Day:", "A day other than a Saturday, Sunday or public holiday in England when banks in London are open for business.")}
            {rowText("Charges:", "means Hubita Ltd registered in England and Wales with company number 11305881.")}
            {rowText("Identity Service:", "the service referred to in clause 1.1.1 ")}
            {rowText("Platform:", "the Hubita Platform found at www.hubita.co.uk.")}
            {rowText("Rent Record Service:", "the service referred to in clause 1.1.2 ")}
            {rowText("Rent Reference Service:", " the service referred to in clause 1.1.3. ")}
            {rowText("Services:", "the various services set out at clause 1. ")}
            {rowText("Tenant:", "a private tenant who uses the Services. ")}
            {rowText("Tenant Guide Service:", "the service referred to in clause 1.1.4 ")}
            {rowText("Terms and Conditions:", "the general terms and conditions of service of Hubita which can be found here.  ")} */}
            {rowText("2.1   Definitions", "")}
            {rowText("Business Day:", "A day other than a Saturday, Sunday or public holiday in England when banks in London are open for business.")}
            {rowText("Charges", "the charges payable by the Customer for the supply of the Services in accordance with clause 5 (Charges and payment).")}
            {rowText("Commencement Date:", "means the date of accepting these terms and conditions electronically via the Site.")}
            {rowText("Complaints Procedure:", "The Supplier’s complaints procedure as in place from time to time and available on the Site.")}
            {rowText("Conditions:", "These terms and conditions as amended from time to time.  ")}
            {rowText("Contract:", "The contract between the Supplier and the Customer for the supply of Services in accordance with these Conditions")}
            {rowText("Control:", "Has the meaning given in section 1124 of the Corporation Tax Act 2010, and the expression change of control shall be construed accordingly.")}
            {rowText("Controller, processor, data subject, personal data, personal data breach, processing and appropriate technical measures:", "As defined in the Data Protection Legislation.")}
            {rowText("Customer (or You):", "The person or firm who purchases or uses Services from the Supplier.")}
            {rowText("Customer Default:", "Has the meaning set out in clause 5.2.  ")}
            {rowText("Data Controller:", "has the meaning set out in section 1(1) of the Data Protection Act 1998.")}
            {rowText("Data Protection Legislation:", "the UK Data Protection Legislation relating to personal data and all other legislation and regulatory requirements in force from time to time which apply to a party relating to the use of personal data (including, without limitation, the privacy of electronic communications).")}
            {rowText("Intellectual Property Rights:", "Patents, rights to inventions, copyright and related rights, trade marks, business names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information (including know-how and trade secrets), and all other intellectual property rights, in each case whether registered or unregistered and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future in any part of the world.  ")}
            {/* {rowText("Portal:", "the Hubita portal found at www.hubita.co.uk (the Site) ")} */}
            <Text style={{ fontSize: 14, marginTop: 20 }}>
  <Text style={{ fontWeight: 'bold', color: 'black' }}>Portal:</Text> the Hubita portal found at{' '}
  <Text style={{ color: '#6D08C0', textDecorationLine: 'underline' }}>
    <a href="http://www.hubita.co.uk" target="_blank" rel="noopener noreferrer">
      www.hubita.co.uk (the Site)
    </a>
  </Text>
</Text>

            {rowText("Services:", "the online services supplied by the Supplier to the Customer as detailed in the Schedule.  ")}
            {rowText("Supplier:", "HUBITA LTD    ")}
            {rowText("2.2   Interpretation ", "")}
            {[`A    A reference to a statute or statutory provision is a reference to it as amended or re-enacted. A reference to a statute or statutory provision includes all subordinate legislation made under that statute or statutory provision. `,

                `B  Any words following the terms including, include, in particular, for example or any similar expression, shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms. `,

                `C  A reference to writing or written includes faxes and emails.  `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>)}

            {headingText("3 Basis of Contract")}
            {[`A    A Contract will come into existence between the parties once the Customer has confirmed acceptance of these Conditions via the Site (Commencement Date).   

B   These Conditions apply to the Contract to the exclusion of any other terms that the Customer seeks to impose or incorporate, or which are implied by law, trade custom, practice or course of dealing.  The Supplier reserves the right to amend these Conditions as it deems necessary from time to time.  

C   These Conditions apply to the order by the Customer and supply of Services by the Supplier.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>)}


            {headingText("4 Supply of Services")}
            {[`A    The Supplier shall supply to the Customer such of the Services listed in the Schedule as requested by the Customer.  

B   Time shall not be of the essence for performance of the Services.  

C   The Supplier warrants to the Customer that the Services will be provided using reasonable care and skill.  

D   The Supplier makes no promise that the Site is appropriate or available for use in locations outside England. If the Site is accessed from locations outside England the Customer acknowledges that it is responsible for compliance with local laws where they apply.  

E   The Supplier tries to make sure that the Site is accurate, up-to-date and free from bugs, but is unable to promise that it will be. Furthermore, the Supplier cannot promise that the Site will be fit or suitable for any purpose. Any reliance that the Customer may place on the information on the Site is at the Customer’s own risk.  

F   The Supplier may suspend or terminate access or operation of the Site at any time.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>)}



            {headingText("5 Customer’s Obligations")}
            {[`The Customer shall:  

A   ensure that any information it provides necessary for the provision of the Services is complete and accurate;  

B   co-operate with the Supplier in all matters relating to the Services;  

C   obtain and maintain all necessary licences, permissions and consents which may be required for the Services before the date on which the Services are to start;  

D   comply with all applicable laws and regulations and fully indemnify the Supplier for any cost or losses sustained or incurred by the Supplier arising directly or indirectly from the Customer’s failure to comply with all applicable laws and regulations;   

E   comply with the terms of the Complaints Procedure for any complaints against the Supplier;     

F   at all times, act responsibly and professionally  

G   be solely responsible for all costs and expenses that may be incurred in relation to use of the Site.`].map((e) => <Text style={{ fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20 }}>{e}</Text>)}


            {headingText("5.1   Acceptable Use Policy ")}
            {[`The Customer shall also comply with the Supplier’s Acceptable Use Policy (the or this Policy) as follows: 

A   By using the Site, the Customer confirms acceptance of the terms of this Policy and agrees to comply with them. If the Customer does not agree to this Policy, the Site must not be used. Any contravention by the Customer of the Policy will result in the Supplier's termination of the Services with the Customer  

B   Prohibited Uses – the Customer may use the Site for lawful purposes only and may not use the Site: 

        a   In any way that breaches any applicable local, national or international law or regulation.   

        b   In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.   

        c   For the purpose of harming or attempting to harm minors in any way.   

        d   To bully, insult, intimidate or humiliate any person.   

        e   To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards.   

        f   To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).   

        g   To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.   

C   The Customer also agrees:   

        a   Not to reproduce, duplicate, copy or re-sell any part of the Site  

        b   Not to access without authority, interfere with, damage or disrupt:   

                    1-  any part of the Site;   

                    2-  any equipment or network on which the Site is stored;   

                    3-  any software used in the provision of the Site; or   

                    4-  any equipment or network or software owned or used by any third party.  

        C   That any material the Customer contributes to the Site must:  

                    1-  be accurate   

                    2-  comply with all laws and regulations applicable in England and Wales and in any country from which it is posted  

                    3-  Not be defamatory of any person.   

                    4-  Not be obscene, offensive, hateful or inflammatory.   

                    5-  Not bully, insult, intimidate or humiliate.   

                    6-  Not promote or include sexually explicit material.   

                    7-  Not include child sexual abuse material.   

                    8-  Not promote violence.   

                    9-  Not promote discrimination based on race, sex, gender identity, religion, nationality, disability, sexual orientation or age.   

                    10- Not infringe any copyright, database right or trademark of any other person.   

                    11- Not be likely to deceive any person.   

                    12- Not breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.   

                    13- Not promote any illegal activity.   

                    14- Not be in contempt of court.   

                    15- Not be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.   

                    16- Not be likely to harass, upset, embarrass, alarm or annoy any other person.   

                    17- Not impersonate any person or misrepresent your identity or affiliation with any person.   

                    18- Not give the impression that the Contribution emanates from Hubita, if this is not the case.   

                    19- Not advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.  

                    20- Not contain a statement which the Customer knows or believes, or has reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.   

                    21- Not contain any advertising or promote any services or web links to other sites.  

        D   That failure to comply with this Policy constitutes a material breach of the Conditions and may result in the Supplier taking all or any of the following actions:  

                    1-  Immediate, temporary or permanent withdrawal of the Customer’s right to use the Site.   

                    2-  Immediate, temporary or permanent removal of any Contribution uploaded by the Customer to the Site.   

                    3-  Issue of a warning to the Customer  

                    4-  Legal proceedings against the Customer for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.   

                    5-  Further legal action against the Customer  

                    6-  Disclosure of such information to law enforcement authorities as the Supplier reasonably feels is necessary or as required by law. `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("5.2   Customer Default")}
{[`If the Supplier’s performance of any of its obligations under the Contract is prevented or delayed by any act or omission by the Customer or failure by the Customer to perform any relevant obligation (Customer Default):  

A   Without limiting or affecting any other right or remedy available to it, the Supplier shall have the right to suspend performance of the Services until the Customer remedies the Customer Default, and to rely on the Customer Default to relieve it from the performance of any of its obligations in each case to the extent the Customer Default prevents or delays the Supplier’s performance of any of its obligations;  

B   The Supplier shall not be liable for any costs or losses sustained or incurred by the Customer arising directly or indirectly from the Supplier’s failure or delay to perform any of its obligations as set out in this clause 5.2; and  

C   The Customer shall reimburse the Supplier on written demand for any costs or losses sustained or incurred by the Supplier arising directly or indirectly from the Customer Default.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("6 Charges and Payment  ")}
{[`The Customer shall pay for the Services in full (including any Value Added Tax as appropriate) and in cleared funds by debit or credit card at the time of ordering either directly via the Portal or third-party suppliers’ webistes. `].map((e)=><Text style={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("7 Intellectual Property Rights")}
{[`All Intellectual Property Rights in or arising out of or in connection with the Services (other than Intellectual Property Rights in any materials provided by the Customer) shall be owned by the Supplier.`].map((e)=><Text style={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("8 Data Protection ")}
{[`A    Both parties will comply with all applicable requirements of the Data Protection Legislation. This Clause 7 is in addition to, and does not relieve, remove or replace, a party’s obligations or rights under the Data Protection Legislation. In this Clause 8, Applicable Laws means (for so long as and to the extent that they apply to the Supplier) the law of the European Union, the law of any member state of the European Union and/or Domestic UK Law; and Domestic UK Law means the UK Data Protection Legislation and any other law that applies in the UK.  

B   The parties acknowledge that for the purposes of the Data Protection Legislation, the Customer is the controller and the Supplier is the processor.  

C   Without prejudice to the generality of Clause 8.17.1, the Customer will ensure that it has all necessary appropriate consents and notices in place to enable lawful transfer of the personal data to the Supplier for the duration and purposes of the Contract.  

D   Any personal information that the Customer provides to the Supplier will be dealt with in line with the Supplier’s Privacy Policy  `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400' ,marginTop: 20}}>{e}</Text>)}
  
  {headingText("9   Limitation of Liability ")}
  {[`A  The Supplier has obtained insurance cover in respect of its own legal liability for individual claims. The limits and exclusions in this clause reflect the insurance cover the Supplier has been able to arrange and the Customer is responsible for making its own arrangements for the insurance of any excess loss.  

B   The restrictions on liability in this Clause 9 apply to every liability arising under or in connection with the Contract including liability in contract, tort (including negligence), misrepresentation, restitution or otherwise.  

C   Neither party may benefit from the limitations and exclusions set out in this clause in respect of any liability arising from its deliberate default.  

D   Nothing in the Contract limits any liability which cannot legally be limited, but to the greatest extent permitted, the Supplier accepts no liability for any losses of the Customer.  The following types of loss are wholly excluded by the parties: 

        a   loss of profits; 

        b   loss of sales or business;  

        c   loss of agreements or contracts;  

        d   loss of anticipated savings;  

        e   loss of use or corruption of software, data, information or IT systems;  

        f   loss of or damage to goodwill; and  

        g   Indirect or consequential loss.  

E   Parties other than the Supplier provide services or sell product lines on or via the Portal. In addition, the Supplier provides links to the sites of affiliated companies and certain other businesses. Please note:  

        a   The Supplier is not responsible for examining or evaluating and does not warrant the offerings of any of these businesses or individuals or the content of their websites. The Supplier does not assume any responsibility or liability for the actions, services, products, and content of all of these or any other third parties; and  

        b   The Supplier is neither the buyer, the seller nor provider of these other parties' items or services. Accordingly, the contract formed at the completion of a sale for these third-party products or services is solely between the buyer and the seller/service provider. The Supplier is not a party to these contracts nor assumes any responsibility arising out of or in connection with them nor is it the seller's/supplier’s agent. The seller/supplier is responsible for the sale of the products/supply of services and for dealing with any buyer claims or any other issues arising out of or in connection with the contract between the buyer and seller/supplier.  

F   This Clause 9 shall survive termination of the Contract.  `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400', marginTop: 20}}>{e}</Text>)}
  
    
  {headingText("10  Termination  ")}
  {[`Without affecting any other right or remedy available to it, the Supplier reserves the right to immediately suspend access to the Portal and/or the applicable Services where a Customer commits a material breach of these Terms and Conditions.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
    

  {headingText("10.1    RIGHT TO TERMINATE")}
  {[`A. The Supplier may terminate the Contract or any other contract which it has with the Customer at any time by giving notice in writing to the Customer if: 

            1   the Customer commits a material breach of Contract and such breach is not remediable; 

            2   the Customer commits a material breach of the Contract which is capable of being remedied and such breach is not remedied within 7 days of receiving written notice of such breach; 

            3   the Customer has failed to pay any amount due under the Contract on the due date and such amount remains unpaid within 14 days after the Supplier has given notification that the payment is overdue; or 

            4   any consent, licence or authorisation held by the Customer is revoked or modified such that the Customer is no longer able to comply with its obligations under the Contract or receive any benefit to which it is entitled. 

B. The Supplier may terminate the Contract immediately at any time by giving notice in writing to the Customer if the Customer: 

            1   stops carrying on all or a significant part of its business, or indicates in any way that it intends to do so; 

            2   is unable to pay its debts either within the meaning of section 123 of the Insolvency Act 1986 or if the Supplier reasonably believes that to be the case;  

            3   becomes the subject of a company voluntary arrangement under the Insolvency Act 1986; 

            4   becomes subject to a moratorium under Part A1 of the Insolvency Act 1986; 

            5   becomes subject to a restructuring plan under Part 26A of the Companies Act 2006; 

            6   becomes subject to a scheme of arrangement under Part 26 of the Companies Act 2006; 

            7   has a receiver, manager, administrator or administrative receiver appointed over all or any part of its undertaking, assets or income; 

            8   has a resolution passed for its winding up; 

            9   has a petition presented to any court for its winding up or an application is made for an administration order, or any winding-up or administration order is made against it; 

            10  suspends or ceases, or threatens to suspend or cease, to carry on all or a substantial part of its business; 

            11  is subject to any procedure for the taking control of its goods that is not withdrawn or discharged within seven days of that procedure being commenced; 

            12  has a freezing order made against it; 

            13  is subject to any recovery or attempted recovery of items supplied to it by a supplier retaining title to those items; 

            14  is subject to any events or circumstances analogous to those in clauses B.1 to B.13 in any jurisdiction; 

            15  takes any steps in anticipation of, or has no realistic prospect of avoiding, any of the events or procedures described in clauses B.1 to B.14 including giving notice for the convening of any meeting of creditors, issuing an application at court or filing any notice at court, receiving any demand for repayment of lending facilities, or passing any board resolution authorising any steps to be taken to enter into an insolvency process. 

C. The Supplier can terminate the Contract for any reason by giving four weeks’ notice to the Customer. 

D. If the Customer becomes aware that any event has occurred, or circumstances exist, which may entitle the Supplier to terminate the Contract under this clause 10.1, it shall immediately notify the Supplier in writing. 

E. Termination or expiry of the Contract shall not affect any accrued rights and liabilities of the Supplier at any time up to the date of termination. `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20,marginTop: 20}}>{e}</Text>)}
  
  
  {headingText("10.2    Consequences of termination")}
  {[`A  On termination of the Services the Customer shall immediately pay to the Supplier any outstanding unpaid charges and interest which shall be payable by the Customer immediately on receipt.  

B   Termination or expiry of the Contract shall not affect any rights, remedies, obligations or liabilities of the parties that have accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of the Contract which existed at or before the date of termination or expiry.  

C   Any provision of the Contract that expressly or by implication is intended to come into or continue in force on or after termination or expiry of the Contract shall remain in full force and effect.  

D   Termination of this Contract will automatically terminate access to the Portal and/or the applicable Services. `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
  
  {headingText("11  General")}
  {headingText("11.1    Force Majeure ")}
  {[`Neither party shall be in breach of the Contract nor liable for delay in performing, or failure to perform, any of its obligations under the Contract if such delay or failure result from events, circumstances or causes beyond its reasonable control.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400'}}>{e}</Text>)}


  {headingText("11.2    Assignment and other dealings ")}
  {[`A  The Supplier may at any time assign, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any or all of its rights and obligations under the Contract.  

B   The Customer shall not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or deal in any other manner with any of its rights and obligations under the Contract without the prior written consent of the Supplier.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
 
 {headingText("11.3 Confidentiality ")}
 {[`A   Each party undertakes that it shall not at any time disclose to any person any confidential information concerning the business, affairs, customers, clients or suppliers of the other party, except as permitted by clause (b).  

B   Each party may disclose the other party’s confidential information:  

        a   to its employees, officers, representatives, subcontractors or advisers who need to know such information for the purposes of carrying out the party’s obligations under the Contract. Each party shall ensure that its employees, officers, representatives, subcontractors or advisers to whom it discloses the other party’s confidential information comply with this clause 11.3; and  

        b   as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.  

C   Neither party shall use the other party’s confidential information for any purpose other than to perform its obligations under the Contract.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

{headingText("11.4  Entire agreement ")}
{[`A    The Contract constitutes the entire agreement between the parties and supersedes and extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether written or oral, relating to its subject matter.  

B   Each party acknowledges that in entering into the Contract it does not rely on, and shall have no remedies in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in the Contract. Each party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in the Contract.  

C   Nothing in this clause shall limit or exclude any liability for fraud.  `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400' ,marginTop: 20}}>{e}</Text>)}

{headingText("11.5  Variation")}
{[`These terms may be varied by the Supplier by giving seven days' notice of the variation, to be posted on the Site. `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
 
{headingText("11.6  Waiver ")}
{[`A waiver of any right or remedy under the Contract or by law is only effective if given in writing and shall not be deemed a waiver of any subsequent right or default. A failure or delay by a party to exercise any right or remedy provided under the Contract or by law shall not constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict any further exercise of that or any other right or remedy. No single or partial exercise of any right or remedy provided under the Contract or by law shall prevent or restrict the further exercise of that or any other right or remedy.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("11.7  Severance ")}
{[`If any provision or part-provision of the Contract is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and enforceable. If such modification is not possible, the relevant provision or part-provision shall be deemed deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect the validity and enforceability of the rest of the Contract.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
           
{headingText("11.8  Notices ")}         
{[`A    Any notice or other communication given to a party under or in connection with the Contract shall be in writing and shall be delivered by hand or by pre-paid first-class post or other next working day delivery service at its registered office (if a company) or its principal place of business (in any other case); or sent by fax to its main fax number or sent by email to the address notified. `,
`B   Any notice shall be deemed to have been received:   

        a   if delivered by hand, on signature of a delivery receipt or at the time the notice is left at the proper address;   

        b   if sent by pre-paid first-class post or other next working day delivery service, at 9.00 am on the second Business Day after posting or at the time recorded by the delivery service; or   

        c   if sent by fax or email, at the time of transmission, or, if this time falls outside business hours in the place of receipt, when business hours resume. In this Clause (iii), business hours mean 9.00am to 5.00pm Monday to Friday on a day that is not a public holiday in the place of receipt.   

C   This clause does not apply to the service of any proceedings or other documents in any legal action or, where applicable, any other method of dispute resolution.  `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop:30}}>{e}</Text>)
}
           
{headingText("11.9  Third party rights ")} 
{[`A    Unless it expressly states otherwise, the Contract does not give rise to any rights under the Contracts (Rights of Third Parties) Act 1999 to enforce any term of the Contract.  

B   The rights of the parties to rescind or vary the Contract are not subject to the consent of any other person.  `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

{headingText("11.10 Governing law ")} 
{[`The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by, and construed in accordance with the law of England and Wales.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}


{headingText("11.11 Jurisdiction ")} 
{[`Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.`].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}

{headingText("Schedule")} 
{[`Subject to the Conditions above, the Company offers the following Services to its Customers either directly or via third party suppliers (subject to their separate terms and conditions):   

A   Landlord’s insurance; 

B   Compliance certificates (e.g. energy performance, gas and electrical safety certificates); 

C   Inventories; and 

D   ‘Know your customer’ (KYC) checks to ensure identity verification. `].map((e)=><Text style ={{fontSize: 14, marginLeft: 5, fontWeight: '400',marginTop: 20}}>{e}</Text>)}
            {/* <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>1.{`       `}SERVICES</Text>
            <View style={{ paddingHorizontal: 30 }}>
                <Text style={{ fontSize: 14, marginTop: 20 }}>1.1{`        `}Subject to these terms, and the Terms and Conditions, such of the following services as the Tenant requests when registering with Hubita:</Text>
                <View style={{ paddingHorizontal: 30 }}>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.1{`        `}access to the Platform for the purposes of uploading property details and  documents relating facilitating the process of the Agent letting a property to a tenant, such as template tenancy agreements and energy performance certificates; </Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.2{`        `}access to a third party provided rent record service, subject to the third party’s own terms and conditions;</Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.3{`        `}access to a third party tenant reference agency, subject to the third party’s own terms and conditions; and </Text>
                    <Text style={{ fontSize: 14, marginTop: 20 }}>1.1.4{`        `}access to the Tenant’s Guide to Letting available on the Platform.</Text>

                </View>
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>2.{`       `}RESPONSIBILITIES OF TENANTS </Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`2.1        Will at all times comply with the Terms and Conditions;`, `2.2       Ensure any information or documents they upload to the Platform are full, accurate and not misleading in any respect; `, `2.3        Ensure any documents they upload to the Platform are free from viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. `, `2.4        Will enter in to and comply with terms of service supplied to them by any third party provider of Services. `, `2.5        Will address any complaints relating to a third party provider in relation to the Services directly to that third party provider and copy in Hubita to all complaints and related correspondence. `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}

            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>3.{`       `}CHARGES AND PAYMENT</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`3.1        Details available here`, `3.2       All amounts due to Hubita shall be paid in full without any set-off, counterclaim, deduction or withholding (other than any deduction or withholding of tax as required by law).  `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>4.{`       `}TERMINATION</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`4.1        Without affecting any other right or remedy available to it, Hubita reserves the right to immediately suspend or terminate access to the Platform and/or the applicable Services where a Tenant commits either a material breach of these terms or the Terms and Conditions.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>5.{`       `}Limitation of liability: ATTENTION IS PARTICULARLY DRAWN TO THIS CLAUSE </Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`5.1        To the fullest extent permitted by law, Hubita accepts no liability whatsoever to a Tenant relating to either the Contract and/or the Services or for the acts or omissions of any third party provider in relation to the Services. `, `5.2       A Tenant agrees to fully indemnify Hubita for any costs or losses sustained or incurred arising directly or indirectly from that Tenant’s failure to comply with these terms, failure to comply with all applicable laws and regulations and in relation to any third party claims`, `5.3        Without prejudice to the generality of clauses 5.1 and 5.2: 

        5.3.1 A Tenant acknowledges that an identity produced by the  Identity Service may not be accepted as adequate proof of the Tenant’s identity, and that other forms of identity may be required from the Tenant; 

        5.3.2 A Tenant acknowledges that a rental payment may not be validated under the Rent Record Service; and 

        5.3.3 A Tenant acknowledges that a rental payment may not be uploaded to their credit record under the Rent Reference Service. `].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}
            </View>
            <Text style={{ fontWeight: 'bold', fontSize: 14, marginTop: 20 }}>6.{`       `}GENERAL</Text>
            <View style={{ paddingHorizontal: 30 }}>
                {[`6.1        Mediation 

Subject to clause 1.6, If any dispute arises in connection with this agreement, the parties agree to enter into mediation in good faith to settle such a dispute and will do so in accordance with the Centre for Effective Dispute Resolution (CEDR) Model Mediation Procedure. Unless otherwise agreed between the parties within 10 Business Days of notice of the dispute, the mediator will be nominated by CEDR. `, `6.2       Governing law. 

The Contract, and any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with it or its subject matter or formation shall be governed by, and construed in accordance with the law of England and Wales. `, `6.3        Jurisdiction. 

Each party irrevocably agrees that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with the Contract or its subject matter or formation.`].map((e) => <Text style={{ fontSize: 14, marginTop: 20 }}>{e}</Text>
                )}

            </View> */}
        </View>
    )
}
export default Terms;