import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView, Container, FormContainer, FormBox, MainText;
if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 70%;
    padding: 10px;
    margin-left: 320px;
    margin-top: 20px;
  `;
  FormContainer = styled.View`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: transparent;
  `;
  FormBox = styled.View`
    width: 70%;
    background-color: transparent;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
    padding: 10px;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  FormContainer = styled.View`
    display: flex;
    flex-direction: column;
    background-color: transparent;
  `;
  FormBox = styled.View`
    width: 100%;
    background-color: transparent;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 90%;
    padding: 10px;
    margin-left: 10%;
  `;
  FormContainer = styled.View`
    display: flex;
    flex-direction: column;
    background-color: transparent;
  `;
  FormBox = styled.View`
    width: 100%;
    background-color: transparent;
  `;
  MainText = styled.Text`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 10px;
  `;
}
export { ContainerScrollView, Container, FormContainer, FormBox, MainText };
