import React, { useState, useEffect } from 'react';
import { ListItem } from '../atoms/listitem';
import { View } from 'react-native';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import PersonIcon from '@material-ui/icons/Person';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import variables from '../atoms/variables.json';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HighlightOff from '@material-ui/icons/HighlightOff';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ToolTip from '../atoms/ToolTip';
import PropertyCard from '../atoms/PropertyCard';
//import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
// import RENTAL_ICON from './../../../assets/svgIcons/OpenFlo/RentalOffer.svg';
// import { alertActions } from '_actions';

import { TextInput } from './../atoms/textinput';
import {
  CardWrapper,
  TlyfeSelector,
  LineSeperator,
  Heading,
  SubHeading,
  TlyfeButtonWrapper,
  ProfilesList,
  ProfilesButtonBox,
  RemoveButton,
  ViewButton,
  PersonName,
  CardFooter,
  RowContainer,
} from './style';
import { CardBox } from '../atoms/card';
import { Button } from '../atoms/button';
import { useDispatch, useSelector } from 'react-redux';
// import { tenantActions } from '_actions';
import moment from 'moment';
import BackComponent from '../atoms/backComponent';
import ToggleComplete from '../atoms/toggleComplete';
import BottomButton from '../atoms/bottomButton';
import { GetFilename, getRentalOfferStatus, GetTenancyDeposit } from '../helpers';
import ContactCard from '../atoms/contactCard';
import { SpecialCondition } from '../molecules/specialCondition';
import SaveandContinueModal from '../atoms/SaveandContinueModal';
import Container from '../atoms/Container/Container';
import drawerHoc from '../../../../components/drawerHOC/DrawerHOC';
import getPlatformType from '../../../../helpers/helpers';
const layoutType = getPlatformType();

const RentalOffers = (props) => {
  const dispatch = useDispatch();
  const [days, setDays] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]);
  const rental_offers_data = useSelector((state) => state?.tenant?.rentalOffersData);

  const tdsData = useSelector((state) => state?.tenant?.tdsData);
  const [isValidated, setIsValidated] = React.useState(false);

  const [selectedTds, setSelectedTds] = React.useState();

  useEffect(() => {
    // dispatch(tenantActions.getTdsData());
  }, []);

  const [tdsArry, setTdsArray] = React.useState([]);
  const loading = useSelector((state) => state?.tenant?.tenantLoading);
  const caclDateDiff = (date1, date2) => {
    if (date1 && date2) {
      if (moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD')) {
        return 'No';
      } else {
        return 'Yes';
      }
    } else {
      return 'No';
    }
  };
  var intialValues = {
    rental_amount: rental_offers_data?.conditions ? rental_offers_data?.conditions?.rental_amount : '',

    isComplete: '',
    is_Initial_Rental_Payment:
      rental_offers_data?.conditions?.is_irp_required === true
        ? 'Yes'
        : rental_offers_data?.conditions?.is_irp_required === false
        ? 'No'
        : 'Yes',
    rental_due_date: rental_offers_data?.conditions ? rental_offers_data?.conditions?.rental_due_date : '',
    furnished_type: rental_offers_data?.conditions?.furnished ? rental_offers_data?.conditions?.furnished : 'Furnished',
    rental_payments_recipient: rental_offers_data?.conditions?.rental_payments_recipient
      ? rental_offers_data?.conditions?.rental_payments_recipient
      : '',
    rental_frequency: rental_offers_data?.conditions?.rental_frequency
      ? rental_offers_data?.conditions?.rental_frequency
      : 'monthly',
    initial_rental_amount: rental_offers_data?.conditions?.initial_rental_amount
      ? rental_offers_data?.conditions?.initial_rental_amount
      : rental_offers_data?.conditions?.rental_amount
      ? rental_offers_data?.conditions?.rental_amount
      : '',
    deposit: rental_offers_data?.conditions ? rental_offers_data?.conditions?.deposit : '',
    move_in_date: rental_offers_data?.conditions
      ? moment(rental_offers_data?.conditions?.move_in_date).format('YYYY-MM-DD')
      : '',
    tenancy_start_date: rental_offers_data?.conditions
      ? moment(rental_offers_data?.conditions?.tenancy_start_date).format('YYYY-MM-DD')
      : '',
    tenancy_end_date: rental_offers_data?.conditions
      ? moment(rental_offers_data?.conditions?.tenancy_end_date).format('YYYY-MM-DD')
      : '',

    irp_start_date: rental_offers_data?.conditions
      ? moment(rental_offers_data.conditions?.irp_start_date).format('YYYY-MM-DD')
      : '',

    irp_end_date: rental_offers_data?.conditions?.irp_end_date
      ? moment(rental_offers_data?.conditions?.irp_end_date).format('YYYY-MM-DD')
      : moment(rental_offers_data?.conditions?.irp_start_date).add(1, 'M').subtract(1, 'days').format('YYYY-MM-DD'),

    is_date_different: caclDateDiff(
      rental_offers_data?.conditions?.tenancy_start_date,
      rental_offers_data?.conditions?.move_in_date
    ),
    broadband: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.broadband : false,
    tv_license: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.tv : false,
    council_tax: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.council_tax : false,
    water: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.water : false,
    electricity: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.electrical : false,
    gas: rental_offers_data?.responsabilities ? rental_offers_data?.responsabilities?.gas : false,
    tenants: rental_offers_data?.tenants ? rental_offers_data?.tenants : [],
    tenancy_deposit: rental_offers_data?.conditions?.tenancy_deposit,
  };
  const [flowId, setFlowId] = React.useState(null);
  const [offerStatus, setOfferStatus] = React.useState(0);
  const [teanats, setTeanats] = React.useState([]);
  const [commonCondition, setCommonCondition] = useState([]);
  const [commonConditionObj, setCommonConditionObj] = useState({});

  const [isModified, setIsModified] = useState(false);
  const [commonConditionText, setCommonConditionText] = useState('');
  const [additionalCondition, setAdditionalCondition] = useState([]);
  const [additionalConditionText, setAdditionalConditionText] = useState('');
  const [break_clause, setBreakClause] = useState('');
  const [additionalError, setAdditionalError] = React.useState(false);
  const [commonConditionError, setCommonConditionError] = React.useState(false);
  const [dayStart, setDayStart] = useState(1);
  const [fieldsValue, setFieldValues] = useState(intialValues);
  const [showSuccessModal, setSuccessModal] = React.useState(false);
  const modalGlobalObject = useSelector((state) => state?.tenant?.rentalOffersData);
  const [modalState, setModalState] = React.useState({
    send_to_tenant: true,
  });
  useEffect(() => {
    if (modalGlobalObject) {
      setModalState({
        send_to_tenant: modalGlobalObject.send_to_tenant,
      });
    }
  }, [modalGlobalObject]);

  useEffect(() => {
    if (tdsData?.schemes) {
      setTdsArray(tdsData?.schemes);
    }
  }, [tdsData?.schemes]);
  useEffect(() => {
    if (fieldsValue?.tenancy_end_date === 'Invalid date' && fieldsValue?.tenancy_start_date !== 'YYYY-MM-DD') {
      setFieldValues({
        ...fieldsValue,
        irp_start_date: moment(fieldsValue.move_in_date).add(1, 'month').format('YYYY-MM-DD'),
        irp_end_date: moment(fieldsValue.tenancy_start_date).add(1, 'M').subtract(1, 'd').format('YYYY-MM-DD'),
        tenancy_end_date: moment(fieldsValue.tenancy_start_date).add(1, 'years').subtract(1, 'd').format('YYYY-MM-DD'),
      });
    }
    // if(fieldsValue?.irp_end_date==""){
    //   setFieldValues({
    //     ...fieldsValue,
    //     irp_end_date: moment(fieldsValue.irp_start_date)
    //       .add(1, 'M')
    //       .format('YYYY-MM-DD'),
    //   });
    // }
  }, [fieldsValue?.tenancy_start_date]);

  useEffect(() => {
    if (props?.match?.params?.id) {
      setFlowId(props?.match?.params?.id);
      // dispatch(tenantActions.getRentalOffers(props?.match?.params?.id));
    }
  }, [props?.match?.params?.id]);

  useEffect(() => {
    if (rental_offers_data) {
      setFieldValues(intialValues);
    }
  }, [
    rental_offers_data,
    rental_offers_data?.responsabilities,
    rental_offers_data?.responsabilities,
    rental_offers_data?.conditions?.tenancy_start_date,
    rental_offers_data?.conditions?.rental_due_date,
    rental_offers_data?.conditions?.rental_frequency,
    rental_offers_data?.conditions?.furnished,
  ]);

  useEffect(() => {
    if (rental_offers_data) {
      setSelectedTds(rental_offers_data?.monies?.tds_pdf);
    }
  }, [rental_offers_data?.monies]);

  useEffect(() => {
    let rental_offer_status = 0;
    if (rental_offers_data?.tenants?.length) {
      setTeanats(rental_offers_data.tenants);

      let ar = rental_offers_data?.tenants;
      ar.some(function (item, index) {
        if (item?.rental_offer_status > 0) {
          rental_offer_status = item?.rental_offer_status;
          return true;
        }
      });
      setOfferStatus(rental_offer_status);
    }
  }, [rental_offers_data, rental_offers_data?.tenants]);

  useEffect(() => {
    if (rental_offers_data?.flow && rental_offers_data?.flow?.break_clause) {
      setBreakClause(rental_offers_data?.flow?.break_clause);
    }
    if (rental_offers_data?.flow && rental_offers_data?.flow?.common_conditions) {
      setCommonCondition(rental_offers_data?.flow?.common_conditions);
    }
    if (rental_offers_data?.flow && rental_offers_data?.flow?.additional_conditions) {
      setAdditionalCondition(rental_offers_data?.flow?.additional_conditions.split(','));
    }
  }, [rental_offers_data]);

  const handleChange = (e, name) => {
    if (!isModified) {
      setIsModified(true);
    }

    if ([e.target.name] == 'initial_rental_amount') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value.replace(/[^0-9.]/g, ''),
      });
    }
    if ([e.target.name] == 'rental_amount') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value.replace(/[^0-9.]/g, ''),
        initial_rental_amount: e.target.value.replace(/[^0-9.]/g, ''),
        tenancy_deposit: GetTenancyDeposit(e.target.value.replace(/[^0-9.]/g, ''), fieldsValue?.rental_frequency),
      });
    }
    if ([e.target.name] == 'move_in_date') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value,
        irp_start_date: moment(e.target.value).add(1, 'month').format('YYYY-MM-DD'),
      });
    }

    if ([e.target.name] == 'rental_frequency') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value,
        tenancy_deposit: GetTenancyDeposit(fieldsValue.rental_amount, e.target.value),
      });
    }
    if ([e.target.name] == 'tenancy_start_date') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value,
        irp_start_date: e.target.value,
        irp_end_date: moment(e.target.value).add(1, 'M').subtract(1, 'days').format('YYYY-MM-DD'),
        tenancy_end_date: moment(e.target.value).add(1, 'years').subtract(1, 'd').format('YYYY-MM-DD'),
      });
    }
    if ([e.target.name] == 'is_Initial_Rental_Payment') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value,
        initial_rental_amount: fieldsValue?.initial_rental_amount
          ? fieldsValue?.initial_rental_amount
          : rental_offers_data?.conditions?.rental_amount,
      });
    }
    if ([e.target.name] == 'irp_start_date') {
      return setFieldValues({
        ...fieldsValue,
        [e.target.name]: e.target.value,
        irp_start_date: e.target.value,
        irp_end_date: moment(e.target.value).add(1, 'M').subtract(1, 'days').format('YYYY-MM-DD'),
      });
    }

    setFieldValues({
      ...fieldsValue,
      [e.target.name]: e.target.value ? e.target.value : name,
    });
  };
  // useEffect(() => {
  //   if(fieldsValue?.rental_amount,fieldsValue?.rental_frequency){
  //     setFieldValues({
  //       ...fieldsValue,
  //       tenancy_deposit: GetTenancyDeposit(fieldsValue?.rental_amount, fieldsValue?.rental_frequency)
  //     });
  //   }
  // }, [fieldsValue?.rental_amount,fieldsValue?.rental_frequency]);
  console.log('fieldsValuefieldsValue', fieldsValue);
  const _onAddRentalData = (url, data) => {
    setIsValidated(true);

    let payload = {
      selectedTds,
      conditions: {
        rental_payments_recipient: fieldsValue?.rental_payments_recipient,
        paid: false,
        deposit: fieldsValue?.deposit,
        furnished: fieldsValue?.furnished_type,
        irp_end_date: fieldsValue?.irp_end_date,
        rental_amount: fieldsValue?.rental_amount,
        irp_start_date: fieldsValue?.irp_start_date,
        rental_due_date: fieldsValue?.rental_due_date,
        tenancy_end_date: fieldsValue?.tenancy_end_date,
        rental_frequency: fieldsValue?.rental_frequency,
        tenancy_start_date: fieldsValue?.tenancy_start_date,
        initial_rental_amount: fieldsValue?.initial_rental_amount,
        is_irp_required: fieldsValue?.is_Initial_Rental_Payment == 'Yes',
        tenancy_deposit: fieldsValue?.tenancy_deposit,
        move_in_date:
          fieldsValue.is_date_different == 'Yes' ? fieldsValue?.move_in_date : fieldsValue?.tenancy_start_date,
      },
      responsabilities: {
        gas: fieldsValue?.gas,
        electrical: fieldsValue?.electricity,
        water: fieldsValue?.water,
        council_tax: fieldsValue?.council_tax,
        tv: fieldsValue?.tv_license,
        broadband: fieldsValue?.broadband,
      },
      tenants: fieldsValue?.tenants,
      flow: {
        ...rental_offers_data?.flow,
        break_clause: break_clause,
        additional_conditions: additionalCondition.join(','),
        common_conditions: commonCondition,
      },
      ...data,
    };

    if (
      (fieldsValue?.rental_amount &&
        fieldsValue?.is_Initial_Rental_Payment == 'Yes' &&
        fieldsValue?.initial_rental_amount) ||
      (fieldsValue?.rental_amount && fieldsValue?.is_Initial_Rental_Payment == 'No')
    ) {
      // dispatch(tenantActions.addRentalOffer(payload, flowId, url));
    } else {
      window.scrollTo({ top: 400, behavior: 'smooth' });
      // dispatch(alertActions.error('Please select all mandatory fields'));
    }
  };

  const _addCommonCondition = (obj) => {
    if (obj) {
      let _array = [...commonCondition];
      setCommonCondition([..._array, obj]);
      setCommonConditionObj({});
    }
  };

  const _subCommonCondition = (value) => {
    let _array = [...commonCondition];
    const finalArray = _array.filter((val) => {
      if (val !== value) return val;
    });
    setCommonCondition(finalArray);
  };

  const _subAdditionCondition = (value) => {
    let _array = [...additionalCondition];
    const finalArray = _array.filter((val) => {
      if (val !== value) return val;
    });
    setAdditionalCondition(finalArray);
  };
  const renderModalBody = (data) => (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      {data?.opbrix_id && (
        <TextInput
          title={
            <>
              <strong>TLyfeId: </strong>
            </>
          }
          type={'row'}
          wrapperstyle={{
            width: '99%',
            marginTop: 20,
          }}
          containerstyles={{ width: '100%' }}
          value={data?.opbrix_id || ''}
        />
      )}
      <TextInput
        title={
          <>
            <strong>Title: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '10%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.title || ''}
      />
      <TextInput
        title={
          <>
            <strong>First Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '38%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.first_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Last Name: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.last_name || ''}
      />
      <TextInput
        title={
          <>
            <strong>Email: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.email || ''}
      />
      <TextInput
        title={
          <>
            <strong>Contact Number: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.contact_number || ''}
      />
      <TextInput
        title={
          <>
            <strong>Post Code: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.postcode || ''}
      />
      <TextInput
        title={
          <>
            <strong>Address: </strong>
          </>
        }
        type={'row'}
        wrapperstyle={{
          width: '49%',
          marginTop: 20,
        }}
        containerstyles={{ width: '100%' }}
        value={data.address || ''}
      />
    </div>
  );
  const _addAdditionCondition = (text) => {
    if (text) {
      let _array = [...additionalCondition];
      setAdditionalCondition([..._array, text]);
      setAdditionalConditionText('');
    } else {
      setAdditionalError(true);
    }
  };
  return (
    <Container style={{ fontWeight: 400 }}>
      <CardWrapper>
        <ListItem
          type={'complete'}
          title={'Rental Offer'}
          listIcon={<ListAltTwoToneIcon style={{ fontSize: '25px', color: variables.purple1 }} />}
          // icon={RENTAL_ICON}
          showPropertyCard
          description={`The following information contains the details of the offer agreed on the proposed property, subject to contract.`}
        />

        <BackComponent
          id={flowId}
          isComplete={rental_offers_data?.flow?.rental_offer_status === 1 ? true : false}
          flowtype={'rental_offer_status'}
        />
        <ProfilesList>
          {fieldsValue.tenants &&
            fieldsValue.tenants.map((item) => (
              <ContactCard
                key={item.email + new Date()}
                nonRemove
                key={item.opbrix_id}
                row={item.email}
                is_lead={item.is_lead ? true : false}
                name={item.first_name + ' ' + item.last_name}
                renderModal={renderModalBody(item)}
              />
            ))}
        </ProfilesList>
        <TlyfeSelector style={{ marginTop: 30, justifyContent: 'space-between' }}>
          <div
            style={{
              flex: 0.5,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Heading style={{ color: variables.purple }}>Condition of Offer</Heading>
          </div>
        </TlyfeSelector>
        <View style={{ position: 'relative' }}>
          <ToolTip
            style={{ bottom: '40%', right: '-40%' }}
            heading={'Move in Date'}
            ContentData={[`Under some circumstances the "move-in" date may be after the actual tenancy start date.`]}
          />

          <ToolTip
            style={{ bottom: '0%', right: '-40%' }}
            heading={'Initial Rental Period = UPFRONT PAYMENT'}
            ContentData={[
              'This is the period that covers the upfront rental amount.',
              'I.e 1 months rent in advance etc',
            ]}
          />
          <CardBox
            hasShadow={true}
            hasBorderRadius={true}
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              marginBottom: 30,
            }}
          >
            <TextInput
              title={'Rental amount (£)'}
              isfullwidth={true}
              error={isModified && !fieldsValue.rental_amount ? true : false}
              value={fieldsValue.rental_amount || ''}
              wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
              onChange={handleChange}
              name="rental_amount"
              error={fieldsValue.rental_amount ? false : isValidated ? true : false}
            />
            <div
              style={{
                display: 'flex',
                width: layoutType == 'phone' ? '100%' : '49%',
                alignItems: 'center',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
              }}
            >
              <SubHeading style={{ fontSize: 14, width: '100%' }}>Frequency</SubHeading>

              <Select
                value={fieldsValue?.rental_frequency}
                className="form-control"
                onChange={handleChange}
                style={{ width: '100%', height: 42, marginTop: 7 }}
                variant="outlined"
                name="rental_frequency"
                placeholder="duration"
              >
                <MenuItem value={'week'}>Weekly</MenuItem>
                <MenuItem value={'monthly'}>Per Calender Month</MenuItem>
                <MenuItem value={'quarterly'}>Quarterly</MenuItem>
                <MenuItem value={'6 monthly'}>6 Monthly</MenuItem>
                <MenuItem value={'annually'}>Annually</MenuItem>
              </Select>
            </div>

            <div
              style={{
                display: 'flex',
                width: layoutType == 'phone' ? '100%' : '49%',
                alignItems: 'center',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
              }}
            >
              <SubHeading style={{ fontSize: 14, width: '100%' }}>Rent Due Date</SubHeading>
              <Select
                value={fieldsValue?.rental_due_date || ''}
                style={{ width: '100%', height: '42px', marginTop: 8, borderWidth: 0 }}
                onChange={handleChange}
                variant="outlined"
                name="rental_due_date"
                placeholder="duration"
              >
                {days.map((ele, index) => {
                  return (
                    <MenuItem key={index} value={ele}>
                      {ele}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <TextInput
              title={'Tenancy Deposit'}
              isfullwidth={true}
              wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 0 }}
              value={fieldsValue?.tenancy_deposit}
              // value={fieldsValue?.rental_amount ? GetTenancyDeposit(fieldsValue?.rental_amount, fieldsValue?.rental_frequency): 0}
              onChange={handleChange}
              name="tenancy_deposit"
            />

            <TlyfeSelector style={{ width: '100%', marginTop: 10 }}>
              <div
                style={{
                  flex: 0.7,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingRight: 10,
                }}
              >
                <SubHeading style={{ fontWeight: '900' }}>
                  Is the "Move in" date different from "Tenancy Start" date?
                </SubHeading>
              </div>
              <div
                style={{
                  flex: 0.3,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="is_date_different"
                    name="is_date_different"
                    value={fieldsValue.is_date_different || false}
                    onChange={handleChange}
                    name="is_date_different"
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </TlyfeSelector>
            <React.Fragment>
              <TextInput
                title={'Move in Date'}
                isfullwidth={false}
                type={'date'}
                disabled={fieldsValue.is_date_different !== 'Yes'}
                wrapperstyle={{ width: '100%', marginBottom: 15 }}
                containerstyles={{ width: layoutType == 'phone' ? '100%' : '49%' }}
                onChange={handleChange}
                inputProps={{ min: fieldsValue.tenancy_start_date }}
                error={isModified && !fieldsValue.move_in_date ? true : false}
                value={
                  fieldsValue.is_date_different === 'Yes' ? fieldsValue.move_in_date : fieldsValue.tenancy_start_date
                }
                name="move_in_date"
              />

              <TextInput
                title={'Tenancy start date'}
                isfullwidth={true}
                type={'date'}
                value={fieldsValue.tenancy_start_date || ''}
                inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
                wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                onChange={handleChange}
                error={isModified && !fieldsValue.tenancy_start_date ? true : false}
                name="tenancy_start_date"
                formatDate={(date) => {
                  console.log('datedate', date);
                  moment(date).format('DD-MM-YYYY');
                }}
              />
              <TextInput
                title={'Tenancy End date'}
                type={'date'}
                isfullwidth={true}
                value={fieldsValue.tenancy_end_date || ''}
                inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
                error={isModified && !fieldsValue.tenancy_end_date ? true : false}
                wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                onChange={handleChange}
                name="tenancy_end_date"
              />
            </React.Fragment>
            <TlyfeSelector style={{ width: '100%', marginTop: 10 }}>
              <div
                style={{
                  flex: 0.7,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SubHeading>Is an Initial Rental Payment (IRP) (£) required?</SubHeading>
              </div>
              <div
                style={{
                  flex: 0.3,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="is_Initial_Rental_Payment"
                    name="is_Initial_Rental_Payment"
                    value={fieldsValue?.is_Initial_Rental_Payment || false}
                    onChange={handleChange}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <FormControlLabel
                        value="Yes"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={<Radio style={{ color: variables.heliotrope }} />}
                        style={{ color: 'black' }}
                        label="No"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </TlyfeSelector>
            {fieldsValue?.is_Initial_Rental_Payment == 'Yes' && (
              <>
                <TextInput
                  title={'Initial Rental Payment (IRP) (£)'}
                  isfullwidth={true}
                  error={isModified && !fieldsValue.initial_rental_amount ? true : false}
                  value={fieldsValue.initial_rental_amount || ''}
                  wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%' }}
                  onChange={handleChange}
                  name="initial_rental_amount"
                  error={fieldsValue.initial_rental_amount ? false : isValidated ? true : false}
                />
                <SubHeading style={{ fontSize: 14, width: '100%', marginTop: 20 }}>
                  Initial Rental Period (Covered by IRP)
                </SubHeading>

                <React.Fragment>
                  <TextInput
                    title={'Start Date'}
                    isfullwidth={true}
                    type={'date'}
                    inputProps={{ min: moment(new Date()).format('YYYY-MM-DD') }}
                    error={isModified && !fieldsValue.irp_start_date ? true : false}
                    wrapperstyle={{ width: layoutType == 'phone' ? '100%' : '49%', marginBottom: 15 }}
                    onChange={handleChange}
                    value={fieldsValue.irp_start_date || ''}
                    name="irp_start_date"
                  />
                  <TextInput
                    title={'End Date'}
                    type={'date'}
                    isfullwidth={true}
                    wrapperstyle={{
                      width: layoutType == 'phone' ? '100%' : '49%',
                      marginBottom: 15,
                      marginLeft: '2%',
                    }}
                    inputProps={{
                      min: moment(fieldsValue?.irp_start_date).add(1, 'M').format('YYYY-MM-DD'),
                    }}
                    error={isModified && !fieldsValue.irp_end_date ? true : false}
                    onChange={handleChange}
                    value={fieldsValue.irp_end_date || ''}
                    name="irp_end_date"
                  />
                </React.Fragment>
              </>
            )}
          </CardBox>
        </View>
        <SpecialCondition
          commonCondition={commonCondition}
          additionalCondition={additionalCondition}
          break_clause={break_clause}
          setBreakClause={setBreakClause}
          _subAdditionCondition={_subAdditionCondition}
          _subCommonCondition={_subCommonCondition}
          commonConditionText={commonConditionText}
          setCommonConditionText={setCommonConditionText}
          _addCommonCondition={_addCommonCondition}
          additionalConditionText={additionalConditionText}
          setAdditionalConditionText={setAdditionalConditionText}
          _addAdditionCondition={_addAdditionCondition}
          _subAdditionCondition={_subAdditionCondition}
        />
        <Heading style={{ color: variables.purple, marginTop: '4%' }}>Tenant Responsibilities</Heading>

        <CardBox hasShadow={true} hasBorderRadius={true} style={{ position: 'relative', width: '100%' }}>
          <SubHeading style={{ marginTop: 0 }}>Select items are the tenant’s responsibility</SubHeading>
          <FormControl
            component="fieldset"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              width: '70%',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="gas"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.gas || false}
                  onChange={(e) => handleChange(e, !fieldsValue.gas)}
                />
              }
              label="Gas"
              style={{ width: '32%', color: '#000', fontSize: 10 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="electricity"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.electricity || false}
                  onChange={(e) => handleChange(e, !fieldsValue.electricity)}
                />
              }
              style={{ width: '32%', color: '#000', fontSize: 10 }}
              label="Electricity"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="water"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.water || false}
                  onChange={(e) => handleChange(e, !fieldsValue.water)}
                />
              }
              label="Water"
              style={{ width: '32%', color: '#000', fontSize: 10 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="council_tax"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.council_tax || false}
                  onChange={(e) => handleChange(e, !fieldsValue.council_tax)}
                />
              }
              label="Council Tax"
              style={{ width: '32%', color: '#000', fontSize: 10 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="tv_license"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.tv_license || false}
                  onChange={(e) => handleChange(e, !fieldsValue.tv_license)}
                />
              }
              style={{ width: '32%', color: '#000', fontSize: 10 }}
              label="TV License"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="broadband"
                  style={{ color: variables.heliotrope }}
                  checked={fieldsValue.broadband || false}
                  onChange={(e) => handleChange(e, !fieldsValue.broadband)}
                />
              }
              label="Broadband/phone"
              style={{ width: '32%', color: '#000', fontSize: 10 }}
            />
          </FormControl>
          <ToolTip
            style={{ bottom: '-234%', right: '-40%' }}
            heading={'Special Conditions'}
            ContentData={[
              'Common clauses are clauses that are often used and have been pre-drafted for ease of use.',
              'Select as many as you require.',
              'Additional clauses agreed between both parties may be selected; this is for you to manually add. Please speak to your legal team to ensure the wording is correct',
            ]}
          />{' '}
        </CardBox>
        <Heading style={{ color: variables.purple, marginTop: '4%' }}>Future Rental Payments</Heading>

        <CardBox hasShadow={true} hasBorderRadius={true} style={{ width: '100%' }}>
          <SubHeading style={{ marginTop: 0 }}>Who will collect future rental Payment</SubHeading>
          <Select
            value={fieldsValue?.rental_payments_recipient}
            style={{ width: '50%', height: '40px', borderWidth: 0 }}
            onChange={handleChange}
            variant="outlined"
            name="rental_payments_recipient"
            placeholder="rental_payments_recipient"
          >
            <MenuItem value={'Agent'}>Agent</MenuItem>
            <MenuItem value={'Landlord'}>Landlord</MenuItem>
          </Select>
        </CardBox>
        <Heading style={{ color: variables.purple, marginTop: '4%' }}>Furnishing</Heading>

        <CardBox hasShadow={true} hasBorderRadius={true} style={{ width: '100%' }}>
          <SubHeading style={{ marginTop: 0 }}>Furnishing</SubHeading>

          <SubHeading style={{ marginTop: 15 }}>Is this property furnished?</SubHeading>

          <Select
            value={fieldsValue?.furnished_type}
            style={{ width: '50%', height: '40px', borderWidth: 0 }}
            onChange={handleChange}
            variant="outlined"
            name="furnished_type"
            placeholder="Furnishing"
          >
            <MenuItem value={'Furnished'}>Furnished</MenuItem>
            <MenuItem value={'Part furnished'}>Part Furnished</MenuItem>
            <MenuItem value={'UnFurnished'}>UnFurnished</MenuItem>
          </Select>
        </CardBox>

        <Heading style={{ color: variables.purple, marginTop: '4%' }}>Tenancy Deposit Scheme</Heading>

        <CardBox hasShadow={true} hasBorderRadius={true} style={{ width: '100%', fontFamily: 'lato' }}>
          <p style={{ fontSize: '13px', fontFamily: 'lato' }}>
            <br />
            <br />
            Please select the tenancy deposit scheme (TDS) scheme you will be using to protect this deposit and upload
            any additional information document you wish to share with your tenant(s).
            <br />
            <br />
            Your tenants will be sent this document for their information.
            <br />
            <br />
            Please select the deposit scheme you will use to protect this deposit.
            <br />
            <br />
          </p>
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '80%',
            }}
          >
            <p style={{ fontSize: '14px' }}>Your TDS Scheme?</p>
            <Select
              value={selectedTds ? selectedTds : ''}
              style={{ width: '80%', borderWidth: 0, height: '42px' }}
              onChange={(e) => setSelectedTds(e.target.value)}
              variant="outlined"
              name="tds"
              placeholder="TDS"
            >
              {tdsArry.map((data, index) => (
                <MenuItem value={data.file} key={'mmi' + index}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          {selectedTds ? (
            <CardBox
              hasShadow={true}
              hasBorderRadius={true}
              style={{
                background: variables.lightGreen,
                paddingTop: 5,
                paddingBottom: 5,
                paddingRight: 15,
                paddingLeft: 15,
                marginBottom: '5%',
                width: '50%',
                marginTop: '5%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ fontSize: '14px', width: 200 }}>{GetFilename(selectedTds) || 'file'}</div>
                <a target="_blank" href={selectedTds}>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      color: variables.purple1,
                    }}
                  >
                    View
                  </span>
                </a>
              </div>
            </CardBox>
          ) : null}
        </CardBox>

        <CardFooter>
          {offerStatus !== 0 ? (
            <>
              <CardBox style={{ marginBottom: '5%', width: '100%' }} hasShadow hasBorderRadius>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Heading>Offer Status</Heading>
                </div>
                {teanats.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <RowContainer>
                        <div
                          style={{
                            flex: 1,
                            paddingRight: '10%',
                            paddingLeft: '4%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CardBox
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                            hasBorderRadius
                            backColor={getRentalOfferStatus(data?.rental_offer_status).backgroundColor}
                          >
                            <SubHeading style={{ margin: 0 }}>{data?.first_name + ' ' + data?.last_name}</SubHeading>
                            <SubHeading
                              style={{
                                margin: 0,
                                color: getRentalOfferStatus(data?.rental_offer_status).color,
                              }}
                            >
                              {getRentalOfferStatus(data?.rental_offer_status).Text}
                            </SubHeading>
                            <SubHeading
                              style={{
                                margin: 0,
                                color: getRentalOfferStatus(data?.rental_offer_status).color,
                              }}
                            >
                              {getRentalOfferStatus(data?.rental_offer_status).Text} on:{' '}
                              {data?.rental_offer_accepted_at
                                ? moment(data?.rental_offer_accepted_at).format('YYYY-MM-DD')
                                : data?.rental_offer_sent_at
                                ? moment(data?.rental_offer_sent_at).format('YYYY-MM-DD')
                                : 'N/A'}
                            </SubHeading>
                          </CardBox>
                        </div>
                      </RowContainer>
                    </React.Fragment>
                  );
                })}
              </CardBox>
            </>
          ) : null}
          <BottomButton
            buttonText={modalState?.send_to_tenant ? 'Update and Send' : 'Save and Send'}
            onRightClick={() =>
              modalState?.send_to_tenant
                ? _onAddRentalData(null, { send_to_tenant: true, resetRequest: true })
                : setSuccessModal(true)
            }
            onContinue={() => _onAddRentalData(null, { send_to_tenant: false, resetRequest: false })}
          />
        </CardFooter>
      </CardWrapper>
      <SaveandContinueModal
        visible={showSuccessModal}
        heading={'Rental Offer'}
        modalState={modalState}
        subHeading={[
          'Do you wish to send this offer to your tenant(s)?',
          `Remember,  if you don't toggle to "send", your tenant(s) will not receive the rental offer.`,
        ]}
        onCancelPress={() => setSuccessModal(false)}
        showNote
        onOkPress={(data) => {
          data.resetRequest = true;
          _onAddRentalData(null, data);
        }}
      />
    </Container>
  );
};
export default drawerHoc({
  Component: RentalOffers,
  showBackButton: true,
});
