import React, { Component, useState, useEffect } from 'react';
import { Text, Image,View } from 'react-native';
import { DocumentBox } from './styles';
import Dropzone from 'react-dropzone';
import { httpUpload } from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { MaterialIcons } from '@expo/vector-icons';

const FilePicker = (props) => {
  const { loading, files, onFileSelect, Idindex, onDataSuccess,accept ,...attributes } = props;
  const onDropImages = (data) => {
    if (data && data.length) {
      var newImages = data.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }));
      var formdata = new FormData();
      formdata.append('type', 'Document');
      newImages.forEach((item) => {
        formdata.append('files', item);
      });
      httpUpload
        .post(Urls.Upload, formdata)
        .then(({ data }) => {
          if (data.status) {
            onDataSuccess(data?.data);
          } else {
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  // useEffect(() => {
  //   let { uploadedFiles} =props
  //  if(uploadedFiles&& uploadedFiles?.data && uploadedFiles?.data?.length ){
  //
  //    onFileSelect(uploadedFiles?.data[0],Idindex)
  //   props.clearuploadedFiles()
  //  }
  // },[props?.uploadedFiles]);

  return (
    <>
      <DocumentBox>
        <Dropzone
          accept={accept?accept:"image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf"}
          onDrop={(acceptedFiles) => {
            onDropImages(acceptedFiles);
          }}
          onDropRejected={()=>alert('Invalid File')}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              style={{
                width: '100%',
                minHeight: '100px',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexWrap: 'wrap',
              }}
              {...getRootProps()}
            >
              <input {...getInputProps()} />

              {files && files.length ? (
                files.map((data, index) => (
                  <View style={{ justifyContent: 'center', alignItems: 'center',flexDirection: "row" }}>
                    <Text key={index} style={{ textAlign: 'center', padding: 10 }}>
                      {data?.name}
                    </Text>
                    <MaterialIcons onClick={(e) => {props?.onDelete(index)
                    e?.preventDefault()
                    }} name="delete-forever" size={18} color="red" />
                  </View>
                ))
              ) : (
                <Text style={{ textAlign: 'center', padding: 10 }}>
                  "Drag 'n' drop some files here, or click to select files"
                </Text>
              )}
            </div>
          )}
        </Dropzone>
      </DocumentBox>
    </>
  );
};

export default FilePicker;
