import React, { useState } from "react";
import {
  View,
  Text,
  SafeAreaView,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  Button,
  WebView
} from "react-native";
import styled from "styled-components";
import Terms from "./terms";
import Cookies from "./cookies";

import Hyperlink from 'react-native-hyperlink'
  import {
    Container,
    SectionWrapper,
    DualView,
    TextBox,
    SectionTitle,
    SectionText,
    ImageBox,
    TeamsSection,
    MemberBoxWrapper,
    MemberBox,
    MemberImage,
    MemberName,
    MemberNameText,
    MemberDetails,
    MemberDetailsText,
    MemberHeader,
    TeamHeader,
    SectionPageTitle,
    SectionTitleTop,
    BulletText,
    Bullet,
    SectionInnerTitle,
    PrivacyGrid,
    PrivacyGridHeader,
    PrivacyGridBody,
    Asterick,
    PrivacyLeftBox,
    PrivacyRightBox,
    CookieTable,
    CookieCells,
    BulletInlineText,
  } from "./styles";
const Content=styled.div`

`;


import Privacy from "./privacy";

import getPlatformType from "../../helpers/helpers";
import drawerHoc from "../../components/drawerHOC/DrawerHOC";
import TripleTabs from "../../components/tripleTabs/tripleTabs";
import RenderHtml from 'react-native-render-html';
import { HTML } from "./policy";

var template = { html: HTML };

const LegalScreen = (props) => {
  const linkStyle = { color: '#2980b9', fontSize: 16 }
  const layoutType = getPlatformType();

  const openLink = (url) => {
    window.open(url);
  }
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const rowText=(text1,text2)=>{
   return(
     <View style={{flexDirection:'row',width:'100%',marginTop:10}}>
       <Text style={{fontSize:14,fontWeight:'bold'}}>{text1}</Text>
       <Text style={{fontSize:14,marginLeft:5}}>{text2}</Text>
     </View>
   )
  }
  const tab1 = (
     <Terms></Terms>
     );
   


  const tab2 = (
    <Privacy></Privacy>
  );

  const tab3 = (
    <Cookies></Cookies>
  );
  const tabList = [
    {
      label: "Terms and Conditions",
      target: tab1,
      targetfooter: null,
    },
    {
      label: "Privacy Policy",
      targetfooter: null,
      target: tab2,
    },
    {
      label: "Cookies",
      target: tab3,
      targetfooter: null,
    },
  ];
  const setCurrentTab = (index) => {
    
    setCurrentTabIndex(index);
  };
  return (
    <>

        <Container style={{marginTop: 30}}>
          <TripleTabs
            isFullWidth={false}
            tabList={tabList}
            title="Legal Bit"
            setCurrentTabIndex={setCurrentTab}
          />
        </Container>
        {currentTabIndex == 0 ? (


          <TeamsSection>
            {/*

            <MemberHeader>
              <TeamHeader>Meet our team</TeamHeader>
            </MemberHeader>
            <Container>
              <MemberBoxWrapper>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Adam.jpg")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>Adam Pigott</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Co-Founder / Chief Commercial Officer
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Shahad.jpg")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>Shahad Choudhury</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Founder /  Chief Innovation Officer
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>
                <MemberBox>
                  <MemberImage
                    style={{
                      borderTopRightRadius: 100,
                      borderTopLeftRadius: 100,
                      borderBottomRightRadius: 100,
                      borderBottomLeftRadius: 100,
                    }}
                  >
                    <Image
                      source={require("../../assets/images/Jhon.png")}
                      style={{
                        width: "100%",
                        height: "100%",
                        resizeMode: "cover",
                      }}
                    ></Image>
                  </MemberImage>
                  <MemberName>
                    <MemberNameText>John Hards</MemberNameText>
                  </MemberName>
                  <MemberDetails>
                    <MemberDetailsText>
                      Senior Strategic Advisor and Chair of Governance
                    </MemberDetailsText>
                  </MemberDetails>
                </MemberBox>

                {layoutType == "phone" ? (





                    <MemberBox>
                    <MemberImage
                      style={{
                        borderTopRightRadius: 100,
                        borderTopLeftRadius: 100,
                        borderBottomRightRadius: 100,
                        borderBottomLeftRadius: 100,
                      }}
                    >
                      <Image
                        source={{
                          uri:
                            "https://www.jagranimages.com/images/newimg/09122019/09_12_2019-house_pexels_19829618.jpg",
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          resizeMode: "cover",
                        }}
                      ></Image>
                    </MemberImage>


                    <MemberName>
                      <MemberNameText>Adam Pigott</MemberNameText>
                    </MemberName>
                    <MemberDetails>
                      <MemberDetailsText>
                        beatae vitae dicta sunt explicabo. Nemo enim ipsam
                        voluptatem quia voluptas sit aspernatur aut odit aut
                        fugit, sed quia consequuntur magni dolores eos qui
                        ratione voluptatem sequi
                      </MemberDetailsText>
                    </MemberDetails>



                  </MemberBox>

                ) : null}


              </MemberBoxWrapper>
            </Container>  */}
          </TeamsSection>


        ) : null}
  </>
  );
};

export default drawerHoc({
  Component: LegalScreen,
  showBackButton: true,
});
