import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { CardBox } from '../card/index.js';
import OpenFlowModal from '../modal';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { Button } from '../button/index.js';
import { useDispatch } from 'react-redux';
// import { tenantActions } from '_actions';
const layoutType = getPlatformType();
import moment from 'moment';
import getPlatformType from '../../../../../helpers/helpers';

const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const SubHeading = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => (props.color ? `${props.color}` : `${variables.black}`)};
  line-height: 20px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 15)}px;
`;

export const DocumentCard = (props) => {
  const dispatch = useDispatch();
  const {
    modalBody,
    footerButtonText,
    handleOnModalButtonClick,
    showFooter,
    modalHeading,
    modalSubHeading,
    isButtonOptional = true,
    renderModal,
    isSeen,
    showModalProps,
    showAmmend = false,
    isBuyActive,
    name,
    noIcon,
    onModalStatusChnage,
  } = props;
  const [showModal, setShowModal] = React.useState(false);

  console.log('showModal inside document card', showModal);

  useEffect(() => {
    console.log('showModalProps inside document card', showModalProps);
    if (showModalProps === false) {
      setShowModal(showModalProps);
    }
  }, [showModalProps]);

  const [fileName, setFileName] = React.useState(null);
  useEffect(() => {
    setFileName(props.docValue);
  }, [props.docValue]);

  const getDocumentColor = () => {
    if (fileName) {
      if (isSeen) {
        //     return <img src={require('../../../../assets/img/doc_seen.png')} height="45px" width="45px" alt="Property" />;
      }
      //   return <img src={require('../../../../assets/img/doc_red.png')} height="45px" width="45px" alt="Property" />;
    } else {
      // return <img src={require('../../../../assets/img/doc.png')} height="45px" width="45px" alt="Property" />;
    }
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div>
      <Container>
        <div style={{ flex: 0.95 }}>
          <p
            style={{
              fontSize: '14px',
              marginTop: 10,
              fontWeight: 400,
              color: props.type == 'complete' ? variables.purple1 : 'black',
              fontFamily: 'lato',
            }}
          >
            {props.title}
          </p>
          <p
            style={{ marginTop: '5px', fontSize: '13px', fontFamily: 'lato' }}
            dangerouslySetInnerHTML={{ __html: props.description }}
          />
        </div>
        {noIcon ? null : getDocumentColor()}
      </Container>
      {(!isButtonOptional || !fileName) && (
        <div
          style={{
            display: layoutType == 'phone' ? 'block' : 'flex',
            justifyContent: 'space-between',
            marginTop: '15px',
          }}
        >
          <Button
            type="Upload"
            title="Upload"
            style={{ marginBottom: 10 }}
            onClick={(data) => {
              if (data && data?.data?.length) {
                props.onClick(data.data);
              }
            }}
          />
          {isBuyActive ? null : (
            <Button
              type="solid"
              onClick={() => {
                onModalStatusChnage(null);
                setShowModal(true);
              }}
              title="BUY"
              color={variables.navyblue}
            />
          )}
        </div>
      )}

      {isBuyActive && showAmmend && (
        <div style={{ width: '70%', marginTop: '15px' }}>
          <CardBox hasShadow={false} hasBorderRadius={false}>
            <Container style={{ justifyContent: 'space-between' }}>
              <div
                style={{
                  margin: '10px',
                  padding: '12px',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  borderRadius: 10,
                  width: '70%',
                  borderWidth: 2,
                  fontFamily: 'lato',
                  borderColor:
                    isBuyActive.status === 'Active'
                      ? '#F8C822'
                      : isBuyActive.status === 'Cancelled'
                      ? '#FC4850'
                      : isBuyActive.status === 'Complete'
                      ? '90C418'
                      : '#F8C822',
                  backgroundColor:
                    isBuyActive.status === 'Active'
                      ? '#F4F0BD'
                      : isBuyActive.status === 'Cancelled'
                      ? '#F8E4E4'
                      : isBuyActive.status === 'Complete'
                      ? 'E1EDC5'
                      : '#F4F0BD',
                }}
              >
                <SubHeading>
                  {' '}
                  Appointment Date :{' '}
                  {isBuyActive?.ammendDate ? moment(isBuyActive?.ammendDate).format('YYYY/MM/DD') : 'N/A'}{' '}
                </SubHeading>
                <SubHeading>Status : {isBuyActive.status} </SubHeading>
                {isBuyActive?.management_link ? (
                  <Button
                    type="solid"
                    title="Management"
                    onClick={(data) => {
                      openInNewTab(isBuyActive?.management_link);
                    }}
                    style={{ marginLeft: '4%' }}
                  />
                ) : null}
              </div>
            </Container>
          </CardBox>
        </div>
      )}

      {fileName && (
        <div style={{ width: '70%', marginTop: '15px' }}>
          <CardBox
            hasShadow={true}
            hasBorderRadius={true}
            style={{ backgroundColor: variables.peachgreen, border: '1px solid ' + variables.puregreen }}
          >
            <Container style={{ justifyContent: 'space-between' }}>
              <p style={{ marginTop: '5px', fontSize: '14px', width: 230 }}>{name ? name : props.title}</p>
              <a target="_blank" href={fileName}>
                <p
                  style={{
                    marginTop: '5px',
                    fontSize: '14px',
                    fontWeight: 500,
                    color: variables.purple1,
                  }}
                >
                  View
                </p>
              </a>
              <p
                type="button"
                onClick={() => {
                  dispatch();
                  // tenantActions.delCer({ url: fileName }, props.flowID, (isDeleted) => {
                  //   if (isDeleted) {
                  //     props.onRemove();
                  //   }
                  // })
                }}
                style={{
                  marginTop: '5px',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: variables.orange,
                }}
              >
                Remove
              </p>
            </Container>
          </CardBox>
        </div>
      )}
      {renderModal}
      <OpenFlowModal
        modalBody={modalBody}
        footerButtonText={footerButtonText}
        handleOnModalButtonClick={handleOnModalButtonClick}
        showFooter={showFooter}
        showModal={showModal}
        setShowModal={setShowModal}
        modalHeading={modalHeading}
        modalSubHeading={modalSubHeading}
      />
    </div>
  );
};

{
  /*
Example.(type=complete/incomplete)
 <ListItem type={"complete"} title={"Tenants Details"} description={"This is a new way of selling property and our UK MLS is tailored for the UK Market, which means you can achieve higher fees from your clients."}/>

*/
}
