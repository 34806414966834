import React, { useState } from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { CardBox } from '../card/index.js';
import { StatusButton } from '../statusbutton/index.js';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PropertyCard from '../PropertyCard';
import { TouchableOpacity, View } from 'react-native';
import Text from '../../../../../components/text';
import Colors from '../../../../../constants/Colors';
import { AntDesign } from '@expo/vector-icons';
import { SubHeading } from '../../screens/style';

const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.type == 'complete' ? variables.purple1 : variables.heliotrope)};
  font-size: 10px;
`;

export const ListItem = (props) => {
  const { title, marginTop, listIcon, showButton, type, description, showArrowIcon, showPropertyCard, icon } = props;
  const [showTooltip, setTooltip] = useState(false);
  return (
    <>
      <CardBox
        style={{
          marginTop: props.marginTop ? props.marginTop : 0,
          width: props.width ? props.width : '100%',
          position: 'relative',
          marginBottom: showPropertyCard ? 80 : 0,
        }}
        hasShadow={true}
        hasBorderRadius={true}
      >
        {showPropertyCard && <PropertyCard style={{ right: 0, bottom: -80 }} />}
        <Container type={props.type}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon ? (
              <img style={{ height: 30, width: 30 }} src={icon} />
            ) : (
              <ListAltIcon data-testid="listAltIcon" style={{ fontSize: '25px' }} />
            )}
            <SubHeading
              style={{
                fontSize: '14px',
                marginTop: 10,
                marginLeft: 15,
                color: variables.purple1,
                fontWeight: 'bold',
                fontFamily: 'lato',
              }}
              data-testid="title"
            >
              {title}
            </SubHeading>
          </div>
          {showArrowIcon ? (
            <ArrowForwardIosIcon style={{ fontSize: '14px', marginLeft: 20 }} data-testid="icon" />
          ) : null}
        </Container>
        <SubHeading style={{ marginTop: '15px', fontSize: '13px', fontWeight: 'bold' }}>{description}</SubHeading>
        <View style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* {showTooltip ? (
            <TouchableOpacity
              onPress={() => setTooltip(false)}
              style={{
                width: 110,
                height: 26,
                borderRadius: 6,
                backgroundColor: '#9F78FF60',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <AntDesign name="close" size={14} color={Colors.light.purple} />
              <Text color={Colors.light.purple} marginLeft={6} bold>
                Close
              </Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              onPress={() => setTooltip(true)}
              style={{
                width: 110,
                height: 26,
                borderRadius: 6,
                backgroundColor: '#9F78FF60',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <AntDesign name="exclamationcircleo" size={14} color={Colors.light.purple} />
              <Text color={Colors.light.purple} marginLeft={6} bold>
                Tool tip title
              </Text>
            </TouchableOpacity>
          )} */}
          {showButton && <StatusButton type={type} />}
        </View>
      </CardBox>
    </>
  );
};

{
  /*
Example.(type=complete/incomplete)
 <ListItem type={"complete"} title={"Tenants Details"} description={"This is a new way of selling property and our UK MLS is tailored for the UK Market, which means you can achieve higher fees from your clients."}/>

*/
}
