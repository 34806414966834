import styled from 'styled-components/native';
import Box from '../box';

export const BoxWrapper = styled(Box)`
  flex-direction: row;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;

export const BorderColor = styled.View`
  border-width: 0.5px;
  border-color: #c7c4bf;
  height: 40px;
`;

export const HeaderWrapper = styled.View`
  flex-direction: row;
  align-items: flex-start;
  margin-left: 100px;
  justify-content: flex-start;
`;

export const ToolTipsWrapper = styled(Box)`
  background-color: #ceeaf2;
  padding: 10px;
  align-self: flex-start;
  flex-direction: row;
`;

export const RowWrapper = styled.View`
  flex-direction: row;
  align-items: ${(props) => props.align || 'flex-start'};
  width: ${(props) => props.width || '100%'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
  
`;

export const ParentWrapper = styled.View`
  margin-left: 310px;
  width: 60%;
  height: 100%;
  align-self: flex-start;
`;

export const ViewFlex = styled.View`
  flex: ${(props) => props.flex || 1};
`;
