import { ButtonWrapper, CloseText, Container, Discription, Title } from './index.style';

import Button from '../button/Button';
import Modal from 'modal-enhanced-react-native-web';
import React from 'react';

const PopUp = (props) => {
  const { modalVisible, titleText,customSubtitle, titleColor, subTitleText, subTitleColor, actionButtons, closePopup, hideClose } =
    props;

  const onPress = (button) => {
    button.onPress && button.onPress();
    closePopup && closePopup();
  };

  return (
    <Modal
      isVisible={modalVisible}
      backdropTransitionInTiming={2000}
      backdropTransitionOutTiming={2000}
      fullScreen={true}
      backdropColor={'transparent'}
    >
      <Container>
        <Title color={titleColor}>{titleText}</Title>
        {hideClose ? null : (
          <CloseText onPress={() => closePopup && closePopup()} color={titleColor}>
            {'Close'}
          </CloseText>
        )}
        <Discription color={subTitleColor}>{subTitleText}</Discription>
        {customSubtitle?customSubtitle:null}
        <ButtonWrapper>
          {actionButtons &&
            actionButtons.map((button, i) => (
              <Button
                onPress={() => button.onPress()}
                color={button?.color || titleColor}
                type={'stripe'}
                childText={button?.text}
                width={button?.width || 105}
              />
            ))}
        </ButtonWrapper>
      </Container>
    </Modal>
  );
};
PopUp.defaultProps = {
  titleColor: '#7C3CC4',
  subTitleColor: '#000000',
  hideClose: false,
};
export default PopUp;
