import React, { useEffect, useState } from 'react';
import { TextInput, View } from 'react-native';
import { Container, TextInputWrapper, TextWrapper, TopView, Radio, InsideRadio } from './index.style';
import PropTypes from 'prop-types';
import Text from '../text/TextComponent';
import { Row } from '../../screens/commonStyles';

const RadioButton = (props) => {
  const { title, onChange, options, value, disable, ...restProps } = props;
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  return (
    <Container>
      <Text bold lineHeight={22} fontSize={15}>
        {title}
      </Text>
      <Row style={{ marginTop: 10, flexWrap: 'wrap' }} {...restProps}>
        {options.map((e) => {
          return (
            <Row style={{ marginRight: 30, marginVertical: 10 }}>
              <Radio
                checked={checked == e ? true : false}
                disabled={disable}
                onPress={() => {
                  setChecked(e);
                  onChange(e);
                }}
              >
                <InsideRadio checked={checked == e ? true : false} />
              </Radio>
              <Text bold lineHeight={22} marginLeft={15} fontSize={15}>
                {e}
              </Text>
            </Row>
          );
        })}
      </Row>
    </Container>
  );
};

RadioButton.propTypes = {
  title: PropTypes.string,
  onChangeText: PropTypes.func,
  error: PropTypes.string,
  colored: PropTypes.bool,
  fontSize: PropTypes.string,
  placeholder: PropTypes.string,
  height: PropTypes.number,
  password: PropTypes.bool,
  options: PropTypes.array,
};

RadioButton.defaultProps = {
  options: ['Yes', 'No'],
  disable: false
};

export default RadioButton;
//Eg.
{
  /* <RadioButton title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
