import React, { useState, useEffect, useCallback } from 'react';
import Button from '../../components/button/Button';
import SearchCardComponent from '../../components/searchCard';
import PropertyTable from '../../components/propertyTable';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import debounce from 'lodash.debounce';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import PropertyArchiveTable from '../../components/propertyArchiveTable';
import PopUp from '../../components/popUp/PopupView';
import { View } from 'react-native';
import Text from '../../components/text';

var colData = ['Property', 'Location', 'Status'];

const PropertyArchive = ({}) => {
  let navigation = useNavigation();
  const [properties, setProperties] = useState([]);
  const [isVisibleForDelete, setIsVisibleForDelete] = useState(false);
  const [isVisibleForUnarchive, setIsVisibleForUnArchive] = useState(false);
  const [itemId, setItemId] = useState('');
  const focused = useIsFocused();

  const fetchArchivedProperty = () => {
    ajax
      .get(Urls.ArchiveProperties)
      .then(({ data }) => {
        console.log('data?.data', data?.data);
        if (data.status) {
          setProperties(data?.payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  useEffect(() => {
    fetchArchivedProperty();
  }, [focused]);

  const _onSearch = (text) => {
    ajax
      .post(Urls.SearchProperty, { searchText: text })
      .then(({ data }) => {
        if (data.status) {
          console.log('data?.data', data?.data);
          setProperties(data?.data);
        } else {
          // alert(data.message);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const _onChange = debounce((text) => {
    _onSearch(text);
  }, 1000);

  const onDelete = (id) => {
    setItemId(id);
    setIsVisibleForDelete(true);
  };

  const onArchived = (id) => {
    setItemId(id);
    setIsVisibleForUnArchive(true);
  };

  const deleteProperty = () => {
    if (isVisibleForDelete && itemId) {
      ajax
        .delete(`${Urls.Properties}/${itemId}`)
        .then(({ data }) => {
          console.log('data?.data', data?.data);
          if (data.status) {
            fetchArchivedProperty();
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  const unArchiveProperty = () => {
    if (isVisibleForUnarchive && itemId) {
      ajax
        .post(`${Urls.UnarchiveProperties}/${itemId}`)
        .then(({ data }) => {
          console.log('data?.data', data?.data);
          if (data.status) {
            fetchArchivedProperty();
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
    }
  };

  return (
    <>
      <SearchCardComponent
        onChangeText={(e) => {
          _onChange(e);
        }}
        leftText={'Archive Properties'}
      />
      <PropertyArchiveTable data={properties.reverse()} colData={colData} onDelete={onDelete} onArchived={onArchived} />

      <PopUp
        modalVisible={isVisibleForUnarchive}
        titleText={'Unarchive Property ?'}
        titleColor={'#6D08C0'}
        subTitleColor={'black'}
        customSubtitle={
          <View>
            <Text>Are you sure you want to Unarchive this Property?</Text>
          </View>
        }
        actionButtons={[
          {
            text: 'Un-Archive',
            width: 100,
            onPress: () => {
              setIsVisibleForUnArchive(false);
              unArchiveProperty();
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisibleForUnArchive(false);
            },
          },
        ]}
        closePopup={() => setIsVisibleForUnArchive(false)}
      />

      <PopUp
        modalVisible={isVisibleForDelete}
        titleText={'Property Delete ?'}
        titleColor={'#6D08C0'}
        subTitleColor={'black'}
        customSubtitle={
          <View>
            <Text>Are you sure you want to delete this Property? (You cannot undo this operation.)</Text>
          </View>
        }
        actionButtons={[
          {
            text: 'Delete',
            width: 100,
            onPress: () => {
              setIsVisibleForDelete(false);
              deleteProperty();
            },
          },
          {
            text: 'Cancel',
            width: 100,
            onPress: () => {
              setIsVisibleForDelete(false);
            },
          },
        ]}
        closePopup={() => setIsVisibleForDelete(false)}
      />

      {/* <Button
          type={'solid'}
          width={170}
          onPress={() => navigation.navigate('PropertyAddress')}
          childText={'Add New Property'}
        />
        <NewsTool newsData={[1, 2]} tooltipData={[1, 2, 3]} /> */}
    </>
  );
};
export default drawerHoc({
  Component: PropertyArchive,
  showBackButton: true,
});
