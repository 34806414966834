import styled from 'styled-components/native';
import Box from '../../components/box';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import Select from 'react-select';
let layoutType = getPlatformType();
let ContainerScrollView,
  Container,
  TextInputWrapper,
  Row,
  RowCol,
  Left,
  Center,
  Right,
  BoxContainer,
  ImageContainer,
  InfoSection,
  RemainderSection,
  RowContainer,
  RightContainer,
  MiddleContainer,
  LeftContainer,
  AddressSelect,
  CircleIcon;

let HorizontalLine = styled.View`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-vertical: 30px;
`;

RowContainer = styled.View`
  flex-direction: row;

  margin-top: ${(props) => (props.isMarginTop ? '20px' : '20px')};
  flex-wrap: wrap;
`;

LeftContainer = styled.View`
  flex: 1;
  min-width: 128px;
  align-items: center;
`;

MiddleContainer = styled.View`
  flex: 1;
  min-width: 128px;
  align-items: center;
`;

RightContainer = styled.View`
  flex: 1;
  min-width: 128px;
  align-items: center;
`;

AddressSelect = styled(Select)`
  font-family: 'lato';
  font-size: 14px
`;

if (layoutType == 'web') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  RemainderSection = styled.View`
    width: 30%;
    margin-left: 20px;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 65%;
  `;
  CircleIcon = styled.TouchableOpacity`
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    width: 50px;
    height: 50px;
    border-color: ${Colors.light.purple};
    border-width: 2px;
  `;
  BoxContainer = styled.View`
    flex-direction: row;
  `;
  ImageContainer = styled.View`
    width: 90px;
    height: 90px;
    background-color: #9f78ff30;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  `;
  Left = styled.View`
    justify-content: center;
    width: 15%;
    align-items: center;
    height: 90px;
  `;
  Right = styled.View`
    justify-content: center;
    width: 40%;
    align-items: center;
    padding: 10px;
    border-left-width: 1px;
    border-color: #cdcdcd;
  `;

  Center = styled.View`
    justify-content: center;
    width: 45%;
    height: 90px;
  `;

  Container = styled.View`
    width: 60%;
    margin-left: 320px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
  `;
} else if (layoutType == 'phone') {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  RemainderSection = styled.View`
    width: 100%;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 100%;
  `;
  ImageContainer = styled.View`
    width: 90px;
    height: 90px;
    background-color: #9f78ff30;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  `;
  BoxContainer = styled.View`
    flex: 1;
    height: 110px;
    flex-direction: row;
  `;
  Left = styled.View`
    justify-content: center;
    flex: 0.2;
    align-items: center;
  `;
  Right = styled.View`
    justify-content: center;
    flex: 0.5;
    align-items: center;
  `;
  Center = styled.View`
    justify-content: center;
    align-items: center;
    flex: 0.3;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '100%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    justify-content: space-between;
    align-items: flex-end;
  `;
} else {
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 65%;
  `;
  RemainderSection = styled.View`
    width: 30%;
    margin-left: 20px;
  `;
  ImageContainer = styled.View`
    width: 90px;
    height: 90px;
    background-color: #9f78ff30;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
  `;
  BoxContainer = styled.View`
    flex: 1;
    height: 110px;
    flex-direction: row;
  `;
  Left = styled.View`
    justify-content: center;
    flex: 0.2;
    align-items: center;
  `;
  Right = styled.View`
    justify-content: center;
    flex: 0.5;
    align-items: center;
  `;
  Center = styled.View`
    justify-content: center;
    align-items: center;
    flex: 0.3;
  `;
  Container = styled.View`
    width: 85%;
    margin-left: 90px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
}
export {
  ContainerScrollView,
  Container,
  HorizontalLine,
  Row,
  TextInputWrapper,
  RowCol,
  Left,
  Center,
  Right,
  BoxContainer,
  CircleIcon,
  ImageContainer,
  InfoSection,
  RemainderSection,
  LeftContainer,
  MiddleContainer,
  RightContainer,
  RowContainer,
  AddressSelect
};
