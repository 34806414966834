import React, { useState } from 'react';
import { FlatList, TextInput, TouchableOpacity, View } from 'react-native';

import PropTypes from 'prop-types';
import { MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import Box from '../box';
import StatusButton from '../statusButton/statusButton';
import { Col, Row } from '../../screens/commonStyles';
import Button from '../button/Button';
import moment from 'moment';
const PaymentList = (props) => {
  let { data } = props;
  console.log('data inside', data);
  return (
    <Col style={{ borderColor: '#d3d3d3', borderBottomWidth: 1, marginTop: 15 }}>
      <Row style={{ justifyContent: 'space-between' }}>
        {/* <Button type={'status'} color={Colors.light.lightPurple} childText={'Overdue'} /> */}
      </Row>
      <Row style={{ justifyContent: 'space-between', width: '80%', paddingBottom: 15, marginTop: 5 }}>
        <Col>
          <Text fontSize={14} fontWeight={'600'}>
            Amount
          </Text>
          <Text fontSize={15} marginTop={10}>
            £ {data?.amount}
          </Text>
        </Col>
        <Col>
          <Text fontSize={14} fontWeight={'600'}>
            Data
          </Text>
          <Text fontSize={15} fontWeight={'500'} marginTop={10}>
            {moment(data?.date).format('MM/DD/YYYY')}
          </Text>
        </Col>
        <Col>
          <Text fontSize={14} fontWeight={'600'}>
            Paid
          </Text>
          <MaterialIcons
            name={data.is_received ? 'check-circle' : 'close'}
            style={{ marginTop: 10 }}
            size={20}
            color={data.is_received ? Colors.light.lightPurple : 'red'}
          />
        </Col>
      </Row>
    </Col>
  );
};
PaymentList.propTypes = {};

PaymentList.defaultProps = {};

export default PaymentList;
//Eg.
{
  /* <TextInputComponent title={"Email Address"} onChangeText={(e)=>console.log(e)} error={"This Field is required"}/> */
}
