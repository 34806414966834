import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
let layoutType = getPlatformType();
let ContainerScrollView,
  Container,
  TenanaceyWrapper,
  TenancyDetails,
  ContractDetails,
  TextInputWrapper,
  Row,
  RowCol,
  InfoSection,
  RemainderSection,
  LabelText,
  ValueText,
  ArchiveWrapper;

let HorizontalLine = styled.View`
  width: 100%;
  height: 1px;
  background-color: #d8d8d8;
  margin-vertical: 30px;
`;
if (layoutType == 'web') {
  TenanaceyWrapper = styled.View`

  width: 100%;
  background-color:"red",
  flex-direction: row;
  `;
  TenancyDetails = styled.View`
    flex: 0.7;
  `;
  ContractDetails = styled.View`
    flex: 0.3;
  `;
  ArchiveWrapper = styled.View`
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 65%;
  `;
  RemainderSection = styled.View`
    width: 30%;
    margin-left: 20px;
  `;
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 65%;
    margin-left: 320px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
} else if (layoutType == 'phone') {
  TenanaceyWrapper = styled.View``;
  TenancyDetails = styled.View``;
  ContractDetails = styled.View``;
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  ArchiveWrapper = styled.View`
    flex-direction: column;
    margin-top: 10px;
    justify-content: 'center';
    align-items: center;
  `;
  RemainderSection = styled.View`
    width: 100%;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 100%;
  `;
  Container = styled.View`
    width: 100%;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '100%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    justify-content: space-between;
    align-items: flex-end;
  `;
} else {
  TenanaceyWrapper = styled.View``;
  TenancyDetails = styled.View``;
  ContractDetails = styled.View``;
  ArchiveWrapper = styled.View`
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
  `;
  RemainderSection = styled.View`
    width: 30%;
    margin-left: 20px;
  `;
  InfoSection = styled.ScrollView`
    margin-top: 20px;
    width: 65%;
  `;
  ContainerScrollView = styled.ScrollView`
    flex: 1;
  `;
  Container = styled.View`
    width: 85%;
    margin-left: 90px;
    padding: 10px;
  `;
  TextInputWrapper = styled.View`
    width: ${(props) => props.width || '83%'};
  `;
  Row = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
  RowCol = styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  `;
}
export {
  ArchiveWrapper,
  RemainderSection,
  ContainerScrollView,
  Container,
  HorizontalLine,
  Row,
  TextInputWrapper,
  RowCol,
  InfoSection,
  TenanaceyWrapper,
  TenancyDetails,
  ContractDetails,
};
