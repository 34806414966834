import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import Box from '../../components/box';
import getPlatformType from '../../helpers/helpers';
var layoutType = getPlatformType();

let BoxWrapper, ParentWrapper, ViewFlex,RowCardBlock;
if (layoutType === 'web') {
    BoxWrapper = styled(Box)`
  flex-direction: column;
  margin-right: 150px;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  margin-top: 20px
`;
  ParentWrapper = styled.View`
  margin-left: 360px;
  margin-top: 40px
  width: 60%;
  height: 100%;
  align-self: flex-start;

`;

  ViewFlex = styled.View`
  flex: ${(props) => props.flex || 1};
`;
RowCardBlock = styled.View`
  width:860px;
  padding:5px;
  flex-direction: row;
`
} else if (layoutType === 'tab') {
  BoxWrapper = styled(Box)`
  flex-direction: row;
  margin-right: 150px;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
  ParentWrapper = styled.View`
  margin-left: 360px;
  margin-top: 40px
  width: 60%;
  height: 100%;
  align-self: flex-start;

`;

  ViewFlex = styled.View`
  flex: ${(props) => props.flex || 1};
`;
RowCardBlock = styled.View`
  width:860px;
  padding:10px;
  flex-direction: row;
`

}else{
  BoxWrapper = styled(Box)`
  flex-direction: row;
  margin-right: 150px;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
  ParentWrapper = styled.View`
  margin-left: 360px;
  margin-top: 40px
  width: 60%;
  height: 100%;
  align-self: flex-start;

`;

  ViewFlex = styled.View`
  flex: ${(props) => props.flex || 1};
`;
RowCardBlock = styled.View`
  width:860px;
  padding:10px;
  flex-direction: row;
`

}

export {BoxWrapper, ParentWrapper, ViewFlex,RowCardBlock};