import React from 'react';
import { View } from 'react-native';
import { Svg, Circle, Text as SVGText } from 'react-native-svg';
import Text from '../text';

const Knob = (props) => {
  const { size, strokeWidth, text } = props;
  const pointerRadius = props.pointerRadius ? props.pointerRadius : 5;
  const radius = (size - strokeWidth) / 2 - pointerRadius;
  const circum = radius * 2 * Math.PI;
  const svgProgress = 100 - props.progressPercent;
  const angle = (props.progressPercent * 360) / 100;
  const pointerX = size / 2 + radius * Math.sin((angle * Math.PI) / 180);
  const pointerY = size / 2 - radius * Math.cos((angle * Math.PI) / 180);

  return (
    <View style={{ margin: 10, borderWidth: 0 , alignItems:'center'}}>
     <Text fontWeight={'600'} fontSize={15}>{text ? text:null}</Text> 
      <Svg width={size} height={size}>
        {/* Background Circle */}
        <Circle
          stroke={props.bgColor ? props.bgColor : '#f2f2f2'}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          {...{ strokeWidth }}
        />
        <Circle
          stroke={props.pgColor ? props.pgColor : '#3b5998'}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={radius * Math.PI * 2 * (svgProgress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          {...{ strokeWidth }}
        />
        <SVGText
          fontSize={18}
          fontFamily='system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif'
          x={size / 2}
          y={size / 1.8}
          textAnchor="middle"
          fill={props.textColor ? props.textColor : '#ffffff'}
          fontWeight={'bold'}
        >
          {props.progressPercent + '%'}
        </SVGText>
      </Svg>
    </View>
  );
};

export default Knob;
{
  /* 
  example...
  <Knob
size={100}
strokeWidth={10}
progressPercent={50}
text={"Complete"}
textColor={variables.primary}
pgColor={variables.primary}
></Knob> */
}
