import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

/*
Example
<CardBox hasShadow={true} hasBorderRadius={true}>
    Some data
</CardBox>

*/

const Wrapper = styled.div`
  min-height: 67px;
  width: 314px;
  background-color: #fff;
  box-shadow: 0 2px 11px 1px rgba(0, 0, 0, 0.09);
  position: absolute;
  border: 1px solid #ececec;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const HeadingWrapper = styled.div`
  color: #6d08c0;
  font-size: 13px;
  line-height: 16px;
`;
const SubheadingWrapper = styled.div`
  color: #b0b0b0;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
`;
const PropertyCard = (props) => {
  const { heading, ContentData, style, Id } = props;
  const dispatch = useDispatch();
  const [flowId, setFlowId] = React.useState(null);
  const propertyData = useSelector((state) => state?.tenant?.flowIdInfo?.property);

  useEffect(() => {
    if (Id && !propertyData) {
      setFlowId(Id);
    }
  }, []);

  let source = 'https://assets.openbrix.co.uk/nophoto.jpg';
  if (propertyData?.property_images && propertyData?.property_images?.length) {
    source = propertyData?.property_images[0];
  }
  return (
    <Wrapper style={{ ...style }}>
      <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 0.3 }}>
          <img
            onError={(e) => {
              e.target.src = 'https://assets.openbrix.co.uk/nophoto.jpg'; //replacement image imported above
              e.target.style = 'height: 67px; width: 100%'; // inline styles in html format
            }}
            src={source}
            style={{ height: 67, width: '100%' }}
            alt="Property"
          />
        </div>
        <div
          style={{
            flex: 0.7,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '4%',
          }}
        >
          {propertyData?.name && <HeadingWrapper style={{ textAlign: 'center' }}>{propertyData?.name}</HeadingWrapper>}
          {propertyData?.display_address && (
            <SubheadingWrapper style={{ textAlign: 'center' }}>{propertyData?.display_address}</SubheadingWrapper>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default PropertyCard;
