import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import variables from '../variables.json';
import { CardBox } from '../card/index.js';
import PersonIcon from '@material-ui/icons/Person';
import { Button } from '../button/index.js';
import { Status } from './style';
import { useDispatch } from 'react-redux';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
// import { generalService } from '_services';
// import { alertActions } from '_actions';
import moment from 'moment';
// import { saveAs } from 'file-saver';
import Loader from '../../../../../components/loader';
import { GetFilename } from '../../helpers';
import { SubHeading } from '../../screens/style';
// import Loader from 'components/Loader/Loader';
// import { getDigitalVerificationStatus } from 'components/tenantOnboarding/helpers';
const Container = styled.div`
  flex: 1;
  display: flex;
`;

export const DocumentWithStatusCard = (props) => {
  const {
    hideUpload,
    renderCustomBody,
    isSeen,
    topAlignment = 'normal',
    appUserID,
    dateValue,
    dateText,
    showDate,
    customButton,
    isCustomTextSent,
  } = props;
  const [fileName, setFileName] = useState([]);
  const [loader, setLoader] = useState(false);

  const { onMiddleButtonClick } = props;

  // const downloadVerifiedDocuments = async () => {
  //   setLoader(true);
  //   generalService
  //     .downloadVerifiedDocuments({
  //       user_id: appUserID,
  //     })
  //     .then((response) => {
  //       setLoader(false);

  //       if (!response.ok) {
  //         // alertActions.error('This user does not have verified Documents to download');
  //         var err = new Error('HTTP status code: ' + response.status);
  //         err.response = response;
  //         err.status = response.status;
  //         throw err;
  //       } else {
  //         return response.blob();
  //       }
  //     })
  //     .then((blob) => {
  //       setLoader(false);

  //       // alertActions.success('Your document are being downloaded, check your download folder');
  //       saveAs(blob, appUserID + '.zip');
  //     })
  //     .catch((err) => {
  //       setLoader(false);
  //     });
  // };
  useEffect(() => {
    if (props?.docValue && props?.docValue?.length) {
      setFileName(props.docValue);
    } else {
      setFileName([]);
    }
  }, [props.docValue]);

  const getDocumentColor = () => {
    if (fileName) {
      if (isSeen) {
        return 'green';
      }
      return '#FC4850';
    } else {
      return 'grey';
    }
  };
  return (
    <div>
      {loader ? <Loader /> : null}
      <Container style={{ justifyContent: topAlignment, alignItems: 'center' }}>
        <div style={{ flex: 0.95, display: 'flex', alignItems: 'center' }}>
          {props.hideIcon ? null : (
            <PersonIcon
              style={{
                color: props.isPrimary ? variables.tint : variables.heliotrope,
              }}
            />
          )}
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
              <SubHeading
                style={{
                  fontSize: '14px',
                  marginTop: 10,
                  fontWeight: 400,
                  marginLeft: 5,
                  color: props.type == 'complete' ? variables.purple1 : 'black',
                }}
              >
                {props.name}
              </SubHeading>

              {showDate && (
                <SubHeading
                  style={{
                    color: variables.purple1,
                    fontSize: '14px',
                    fontWeight: 400,
                    marginTop: 10,
                    marginLeft: 50,
                  }}
                >
                  {dateText ? dateText : 'Started on'}: {dateValue ? moment(dateValue).format('DD-MM-YYYY') : 'N/A'}
                </SubHeading>
              )}
            </div>
            {props.subName ? (
              <SubHeading
                style={{
                  fontSize: '14px',
                  marginTop: 10,
                  fontWeight: 400,
                  marginLeft: 5,
                  width: '60%',
                  color: props.type == 'complete' ? variables.purple1 : 'black',
                }}
              >
                {props.subName}
              </SubHeading>
            ) : null}
          </div>
        </div>
        {props.middleButtonText ? (
          <Button
            type="custom"
            textColor={variables.purple1}
            color={variables.purple1}
            onClick={() => onMiddleButtonClick()}
            title={props.middleButtonText}
          />
        ) : null}
        {customButton && (
          <div
            style={{
              width: 120,
              height: 32,
              borderRadius: 10,
              backgroundColor: isCustomTextSent ? '#90C418' : '#938E8E',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginRight: 10,
            }}
          >
            <SubHeading style={{ color: isCustomTextSent ? 'black' : 'white' }}>
              {' '}
              {isCustomTextSent ? 'Sent' : 'Not Sent'}
            </SubHeading>
          </div>
        )}

        {appUserID && props.status == 'Verified' ? (
          <Button
            type="stripe"
            title="Download verified docs"
            onClick={(data) => {
              // downloadVerifiedDocuments();
            }}
          />
        ) : null}
        {props.showFileIcon ? (
          <InsertDriveFileIcon
            style={{
              color: getDocumentColor(),
              fontSize: '35px',
              marginLeft: 15,
            }}
          />
        ) : null}
      </Container>
      {hideUpload ? null : (
        <Button
          type="Upload"
          title="Upload"
          style={{ color: variables?.purple1 }}
          style={{ marginTop: 20, marginLeft: 'auto' }}
          onClick={(data) => {
            if (data && data?.data?.length) {
              props.onClick(data.data);
            }
          }}
        />
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '65%', marginTop: '15px' }}>
          {Array.isArray(fileName) && fileName?.length
            ? fileName.map((item, docIndex) => {
                if (item.document_url)
                  return (
                    <CardBox
                      key={item?.document_url}
                      hasShadow={true}
                      hasBorderRadius={true}
                      style={{
                        background: props.status === 'verified' ? variables.lightyellow : variables.peachgreen,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 15,
                        paddingLeft: 15,
                        marginBottom: '5%',
                        border: 'solid 1px ' + variables.puregreen,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <SubHeading style={{ fontSize: '14px', width: 200 }}>
                          {GetFilename(item?.document_url) || 'file'}
                        </SubHeading>
                        <a target="_blank" href={item?.document_url}>
                          <SubHeading
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: variables.purple1,
                            }}
                          >
                            View
                          </SubHeading>
                        </a>
                        {props?.onRemove && (
                          <SubHeading
                            type="button"
                            style={{
                              fontSize: '14px',
                              fontWeight: 500,
                              color: variables.orange,
                            }}
                            onClick={() => {
                              props.onRemove(docIndex);
                            }}
                          >
                            Remove
                          </SubHeading>
                        )}
                      </div>
                    </CardBox>
                  );
              })
            : null}
        </div>

        {props.status ? (
          <div style={{ maxWidth: '30%', marginTop: '15px' }}>
            <Button
              style={{ borderRadius: 5 }}
              // color={getDigitalVerificationStatus(props?.status).color}
              // textColor={getDigitalVerificationStatus(props?.status).textColor}
              // title={getDigitalVerificationStatus(props?.status).Text}
              // type={getDigitalVerificationStatus(props?.status).type}
            />
          </div>
        ) : null}
      </div>

      {renderCustomBody ? renderCustomBody() : null}
    </div>
  );
};
