import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FlatList } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';
import getPlatformType from '../../helpers/helpers';
import { navigate } from '../../navigation/RootNavigation';
import { Container, HeaderWrapper, ColoumnWrapper, ListItem, ListSection } from './index.style';
import Delete from '@material-ui/icons/Delete';
import { TouchableOpacity } from 'react-native';
const layoutType = getPlatformType();

const TenantTable = (props) => {
  const { colData, data, rowIcon: RowIcon, onPress, rightIcon, deleteFile ,isTeanat} = props;

  const _renderItem = (item, index) => {
    return (
      <ListItem
        onPress={() => (onPress ? onPress(data, index) :isTeanat? navigate('TeanantInfo', { id: item?.id }):null)}
        key={index}
        style={{ borderBottomWidth: data.length == index + 1 ? 0 : 1, paddingVertical: 10, borderColor: '#ebebeb' }}

      >
        <ListSection style={{ flexDirection: 'row' }} width={layoutType == 'phone' ? '90%' : '30%'}>
          {RowIcon ? RowIcon : <MaterialIcons name="home" color={Colors.light.purple} size={20} />}

          <Text fontSize={15} marginLeft={10}>
            {item.name}
          </Text>
        </ListSection>
        {layoutType == 'phone' ? (
          <ListSection width={'10%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity
              onPress={() => isTeanat?null:window.open(item.url) }>
            {rightIcon ? rightIcon : <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />}
            </TouchableOpacity>
          </ListSection>
        ) : null}
        <ListSection width={layoutType == 'phone' ? '45%' : '30%'}>
          <Text fontSize={15} marginLeft={0}>
            {item.location}
          </Text>
        </ListSection>
        <ListSection width={layoutType == 'phone' ? '45%' : '24%'}>
          <Text fontSize={15} marginLeft={0}>
            {item?.date ? item?.date : ''}
          </Text>
        </ListSection>
        {layoutType != 'phone' ? (
          <ListSection width={'8%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity style={{ marginHorizontal: 30 }} onPress={() =>isTeanat?null: deleteFile(item)}>
              <Delete
                style={{
                  height: 24,
                  width: 24,
                  color: '#6D08C0',
                }}
              />
            </TouchableOpacity>
          </ListSection>
        ) : null}
        {layoutType != 'phone' ? (
          <ListSection width={'8%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity
            onPress={() => isTeanat?navigate('TeanantInfo', { id: item?.id }):window.open(item.url) }>
            {rightIcon ? rightIcon : <MaterialIcons name={'chevron-right'} color={Colors.light.purple} size={20} />}
            </TouchableOpacity>
          </ListSection>
        ) : null}
         {layoutType == 'phone' ? (
          <ListSection width={'10%'} style={{ justifyContent: 'center', alignItems: 'center' }}>
                     <TouchableOpacity onPress={() =>isTeanat?null: deleteFile(item)}>
              <Delete
                style={{
                  height: 24,
                  width: 24,
                  color: '#6D08C0',
                }}
              />
            </TouchableOpacity>
          </ListSection>
        ) : null}
      </ListItem>

    );
  };

  const _renderHeader = () => {
    return (
      <HeaderWrapper>
        {colData.map((e) => (
          <ColoumnWrapper key={e}>
            <Text fontWeight={500} fontSize={15}>
              {e}
            </Text>
          </ColoumnWrapper>
        ))}
      </HeaderWrapper>
    );
  };

  return (
    <Container>
      {layoutType != 'phone' ? _renderHeader() : null}
      {data.map((item,index)=>{
        return(
          _renderItem(item, index)
        )
      })}
    </Container>
  );
};

TenantTable.propTypes = {
  leftText: PropTypes.string,
  onChangeText: PropTypes.func,
  onItemClick: PropTypes.func,
};

TenantTable.defaultProps = {};

export default TenantTable;