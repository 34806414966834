import React, { useState, useEffect } from 'react';
import { FlatList, View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { Container, TextWrapper, TopView, SortBox, RenderTitle } from './sortListComponent.style';
import { MaterialIcons } from '@expo/vector-icons';
import Box from '../box';
import Colors from '../../constants/Colors';
import Text from '../text';

const SortList = (props) => {
  const {
    data,
    height = 40,
    width,
    title,
    titleStyle,
    setSort,
    renderTitle = 'Sort',
    accordionOpenVal,
    background = 'transparent',
  } = props;

  const [accordionToggle, setAccordionToggle] = useState(false);

  useEffect(() => {
    setAccordionToggle(accordionOpenVal);
  }, [accordionOpenVal]);

  const _renderList = (item, index) => {
    return (
      <TouchableOpacity
        key={'sort' + index}
        style={{ padding: 10, borderBottomWidth: 1, borderColor: '#cdcdcd' }}
        onPress={() => {
          setSort(item.name, item.value);
          setAccordionToggle(false);
        }}
      >
        <Text fontSize={15} color={Colors.light.lightPurple}>
          {item.name}
        </Text>
      </TouchableOpacity>
    );
  };
  return (
    <SortBox
      style={{
        height: height,
        width: width,
      }}
    >
      <Container>
        <View style={{ backgroundColor: 'background' }}>
          <TopView>
            <If condition={title}>
              <TextWrapper marginRight={0} style={titleStyle}>
                {title}
              </TextWrapper>
            </If>
            <RenderTitle>{renderTitle ? 'Sort by: ' + renderTitle : 'Sort'}</RenderTitle>
            <Choose>
              <When condition={accordionToggle}>
                <MaterialIcons
                  name="keyboard-arrow-up"
                  size={24}
                  color={Colors.light.lightPurple}
                  onPress={() => setAccordionToggle(!accordionToggle)}
                />
              </When>
              <Otherwise>
                <MaterialIcons
                  name="keyboard-arrow-down"
                  size={24}
                  color={Colors.light.lightPurple}
                  onPress={() => setAccordionToggle(!accordionToggle)}
                />
              </Otherwise>
            </Choose>
          </TopView>
          <View style={{ backgroundColor: '#f2f2f2', width: 150, alignSelf: 'flex-end', marginTop: 10 }}>
            <If condition={accordionToggle}>
              <FlatList data={data} renderItem={({ item, index }) => _renderList(item, index)} />
            </If>
          </View>
        </View>
      </Container>
    </SortBox>
  );
};

SortList.propTypes = {
  renderChildren: PropTypes.node,
  renderTitle: PropTypes.node,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
};

SortList.defaultProps = {
  renderChildren: <Text>Sort Data</Text>,
};

export default SortList;
