import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let  FormContainer, TouchableWrapper, ImageWrapper, TopSection ,GradientOverlay,RightSection;
if (layoutType == 'web') {
 
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 60%;
    margin-right: 10%;
  `;
  ImageWrapper = styled.Image`
    width: 100%;
    height: 100%;
    // margin-left: 5%;
    // margin-top: 20%;
    // resize-mode: contain;
  `;
  TopSection = styled.View`
    position: absolute;
    top: 5%;
    flex-direction: row;
    right: 30%;
    align-items: center;
    z-index:10000;
  `;
  GradientOverlay = styled(LinearGradient)`
    position: absolute;
    width:100%;
    height:100%;
    `;
  RightSection = styled.View`
    width: 50%;
    background-color: rgb(242, 245, 250);
    justify-content: center;
    align-items: flex-start;
    `;
} else if (layoutType == 'phone') {
 
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 80%;
  `;
  ImageWrapper = styled.Image`
    width: 100%;
    height: 60%;
    resize-mode: contain;
  `;
  TopSection = styled.View`
    flex-direction: row;
    align-items: center;
    margin-top: 56px;
  `;
  RightSection = styled.View`
  background-color: rgb(242, 245, 250);
  justify-content: center;
  align-items: center;
`;
} else {
 
  TouchableWrapper = styled.TouchableOpacity``;
  FormContainer = styled.View`
    width: 85%;
  `;
 
  ImageWrapper = styled.Image`
    width: 100%;
    height: 100%;
    resize-mode: cover;
  `;
  TopSection = styled.View`
    position: absolute;
    top: 5%;
    flex-direction: row;
    right: 10%;
    align-items: center;
    z-index:10000;
  `;
  GradientOverlay = styled(LinearGradient)`
  position: absolute;
  width:100%;
  height:100%;
`;
  RightSection = styled.View`
    width: 50%;
    background-color: rgb(242, 245, 250);
    justify-content: center;
    align-items: center;
    `;
}
export { FormContainer, TouchableWrapper, ImageWrapper, TopSection, GradientOverlay, RightSection };
