import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import CheckBox from '../checkbox';
import { MainText } from './style';
import { useRoute } from '@react-navigation/native';
import { showErrorToast } from '../../screens/tenants/tenantOnboarding/helpers';
const MarkAsComplete = ({ navigation, label, isComplete, flowtype, flowID }) => {
  const route = useRoute();

  const [complete, setComplete] = useState(isComplete);

  useEffect(() => {
    setComplete(isComplete);
  }, [isComplete]);
  const callApi = (requestBody) => {
    ajax
      .post(Urls.COMPLETE + '/' + flowID, requestBody)
      .then(({ data }) => {
        if (data.status) {
          if (route?.params?.id) {
            navigation.navigate('PropertySection');
          } else {
            navigation.navigate('Properties', { id: route?.params?.id });
          }
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  const handleChange = (event) => {
    if(flowID){

   
    setComplete(!complete);
    if (!complete) {
      callApi({
        field: flowtype,
        isComplete: 2,
      });
    } else {
      callApi({
        field: flowtype,
        isComplete: 1,
      });
    }
  }else{
    showErrorToast('Please save property details before clicking "mark as complete".')
  }        
  };
  return (
    <>
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 15,alignItems:'center',marginBottom:15 }}>
        <MainText>{label}</MainText>
        <CheckBox 
        width="170px" checked={complete} label="Mark as Complete" onPress={handleChange} />
      </View>
    </>
  );
};

export default MarkAsComplete;
