import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native';
import styled from 'styled-components/native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let ImageWrapper,
  ImageWrapper2,
  Container,
  Midtext,
  Midtext1,
  ImageWrapper3,
  TopLastRight,
  TopLastLeft,
  TopSection,
  TopView,
  TopLeft,
  TouchableWrapper,
  BottomSection1,
  BottomSection2,
  MidRight,
  HeaderContainer,
  Logo
  ;
if (layoutType == 'web') {
  HeaderContainer = styled.View`
  width: 100%;
  height: 100px;
  justify-content:space-between;
  align-items:center;
  flex-direction:row;
  padding-horizontal:60px;
`;
Logo = styled.Image`
width: 300px;
height: 95px;
resize-mode:contain;

`;
  Container = styled.View`
    width: 100%;
    height: 100%;
  `;
 
  TopView = styled.View`
  
  width: 100%;
    height: auto;
    background-color: white;
    // margin-left: 5%;
    // margin-top: 20%;
  `;

  TopLeft = styled.Image`
    width: 30%;
    height: 30%;
    resize-mode: contain;
    flex-direction: row;
    align-items:flex-end;
    justify-content: flex-end;
    // position: absolute;
  `;
  TopLastLeft = styled.Image`
    width: 300;
    height: 300;
    // margin-left: 2%;
    // left: 10%;
    // margin-top: 20%;
    resize-mode: contain;
    // position: relative;
  `;
  TopLastRight = styled.Image`
    width: 300;
    height: 300;
    // margin-right: 2%;
    // right: 10%;
    // margin-top: 2%;
    resize-mode: contain;
    // position: absolute;
  `;

  ImageWrapper = styled.Image`
    width: 100%;
    height: 982px;
    // margin-left: 5%;
    // margin-top: 20%;
    resize-mode: contain;
  `;
  ImageWrapper2 = styled.Image`
    width: 50%;
    height: 100%;
    // margin-top: 1%;
    resize-mode: cover;
  `;
  ImageWrapper3 = styled.Image`
    width: 100%;
    height: 553px;
    //   margin-top: 106px;
    resize-mode: contain;
  `;
  TopSection = styled.View`
    // position: absolute;
    flex:1;
    top: 2%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 10000;
  `;
  BottomSection1 = styled.View`
    margin-top: 5%;
    flex-direction: row;
    align-item: center;
    justify-content: space-around;
  `;
  BottomSection2 = styled.View`

    margin-top: 15;
    flex-direction: column;
    width:30%
    align-item: center;
    justify-content: space-between;
    height:660;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  Midtext = styled.View`
    align-items: center;
    justify-content: center;
    widhth: 50%;
  `;
  MidRight = styled.View`
    // margin-top: 1%;
    flex-direction: row;
    // align-item:center;
    // justify-content: space-between;
  `;
  Midtext1 = styled.View`
  flex:1;
  align-items: left;
  // width:50%;
// justify-content: center;
margin-left:40;
  `;
} else if (layoutType == 'phone') {
  HeaderContainer = styled.View`
  width: 100%;
  height: 100px;
  justify-content:space-between;
  align-items:center;
  flex-direction:row;
  padding-horizontal:30px;
`;
Logo = styled.Image`
width: 200px;
height: 100px;
resize-mode:contain;

`;
  Container = styled.View`
    width: 100%;
    height: 100%;
  `;

  ImageWrapper = styled.Image`
    width: 100%;
    height: 444px;
    // margin-left: 5%;
    // margin-top: 20%;
    resize-mode: stretch;
  `;
  ImageWrapper2 = styled.Image`
    width: 50%;
    height: 486.56;
    margin-top: 5%;
  `;
  ImageWrapper3 = styled.Image`
    width: 60%;
    height: 336px;
    //   margin-top: 106px;
    resize-mode: stretch;
    align-items: center;
    justify-content: center;
  `;
  TopSection = styled.View`
    top: 2%;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    width: 100%;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  Midtext = styled.View`
    align-items: center;
    justify-content: center;
  `;
  BottomSection1 = styled.View`
    margin-top: 15;
    flex-direction: column;
    align-item: center;
    justify-content: space-between;
  `;
  BottomSection2 = styled.View`
    margin-top: 15;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    
  `;
  MidRight = styled.View`
  
    margin-top: 5%;
    flex-direction: row;
    // align-item:center;
    // justify-content: space-between;
  `;
  Midtext1 = styled.View`
  flex:1;
align-items: center;
width:100%
justify-content: center;
margin-left:20;

  `;
  TopView = styled.View`
  width: 100%;
  height: auto;
  background-color: white;
  padding:2px;
  // margin-left: 5%;
  // margin-top: 20%;
`;

TopLeft = styled.Image`
  width: 30%;
  height: 30%;
  resize-mode: contain;
  flex-direction: row;
  align-items:flex-end;
  justify-content: flex-end;
  // position: absolute;
`;
TopLastLeft = styled.Image`
  width: 165;
  height: 199;
  // margin-left: 2%;
  // left: 10%;
  // margin-top: 20%;
  resize-mode: contain;
  // position: relative;
`;
TopLastRight = styled.Image`
  width: 165;
  height: 199;
  // margin-right: 2%;
  // right: 10%;
  // margin-top: 2%;
  resize-mode: contain;
  // position: absolute;
`;
} else {
  HeaderContainer = styled.View`
  width: 100%;
  height: 100px;
  justify-content:space-between;
  align-items:center;
  flex-direction:row;
  padding-horizontal:40px;
`;
Logo = styled.Image`
width: 300px;
height: 95px;
resize-mode:contain;

`;
  Container = styled.View`
    width: 100%;
    height: 100%;
  `;

  ImageWrapper = styled.Image`
    width: 100%;
    height: 1024px;
    // margin-left: 5%;
    // margin-top: 20%;
    resize-mode: stretch;
  `;
  ImageWrapper2 = styled.Image`
    width: 50%;
    height: 100%;
    // margin-top: 5%;
    resize-mode:cover;
  `;
  ImageWrapper3 = styled.Image`
    width: 280px;
    height: 553px;
    margin-top: 5%;
    resize-mode:contain;
  `;
  TopSection = styled.View`
    top: 2%;
    flex-direction: row;
    z-index: 10000;
    justify-content: space-between;
    align-items: center;
  `;
  TouchableWrapper = styled.TouchableOpacity``;
  Midtext = styled.View`
    align-items: center;
    justify-content: center;
  `;
  BottomSection1 = styled.View`
    
    margin-top: 15;
    flex-direction: row;
    align-item: center;
    justify-content: space-between;
    width:100%;
  `;
  BottomSection2 = styled.View`
    margin-top: 15;
    flex-direction: column;
    align-item: space-between;
    justify-content: space-between;
    width:33%;
    height: 800;
  `;
  MidRight = styled.View`
    // margin-top: 5%;
    flex-direction: row;
    // align-item:center;
    // justify-content: space-between;
  `;
  Midtext1 = styled.View`
flex:1;
align-items: left;
width:50%;
justify-content: center;
margin-left:20;
  `;
  TopView = styled.View`
  width: 100%;
  height: auto;
  background-color: white;
  // margin-left: 5%;
  // margin-top: 20%;
`;

TopLeft = styled.Image`
  width: 30%;
  height: 30%;
  resize-mode: contain;
  flex-direction: row;
  align-items:flex-end;
  justify-content: flex-end;
  // position: absolute;
`;
TopLastLeft = styled.Image`
  width: 300;
  height: 300;
  // margin-left: 2%;
  // left: 10%;
  // margin-top: 20%;
  resize-mode: contain;
  // position: relative;
`;
TopLastRight = styled.Image`
  width: 300;
  height: 300;
  // margin-right: 2%;
  // right: 10%;
  // margin-top: 2%;
  resize-mode: contain;
  // position: absolute;
`;
TopView = styled.View`
width: 100%;
height: auto;
background-color: white;
// margin-left: 5%;
// margin-top: 20%;
`;

TopLeft = styled.Image`
width: 30%;
height: 30%;
resize-mode: contain;
flex-direction: row;
align-items:flex-end;
justify-content: flex-end;
// position: absolute;
`;
TopLastRight = styled.Image`
width: 300;
height: 300;
// margin-right: 2%;
// right: 10%;
// margin-top: 2%;
resize-mode: contain;
// position: absolute;
`;
}

export {
  ImageWrapper,
  ImageWrapper2,
  Container,
  Midtext,
  Midtext1,
  TopView,
  TopLastLeft,
  TopLastRight,
  TopLeft,
  ImageWrapper3,
  TopSection,
  TouchableWrapper,
  BottomSection1,
  BottomSection2,
  MidRight,
  HeaderContainer,
  Logo
};
