import { useState, useEffect } from 'react';
import ajax from '../helpers/ajaxHelper';

const useUtilsProvider = (arrUrl) => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState('');

  const fetchData = async () => {
    ajax
      .get(arrUrl)
      .then(({ data }) => {
        if (data.status && data?.payload) {
          setResponse(data?.payload);
        } else {
          setResponse(data?.payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return { response, error };
};

export default useUtilsProvider;
