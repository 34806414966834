import React from 'react';
import PropTypes from 'prop-types';
import { TextWrapper } from './TextComponent.style';

const Text = ({
  color,
  fontSize,
  children,
  align,
  lineHeight,
  bold,
  marginLeft,
  marginBottom,
  marginTop,
  marginRight,
  fontWeight,
  width,
  ...restProps
}) => (
  <TextWrapper
    color={color}
    fontSize={fontSize}
    align={align}
    lineHeight={lineHeight}
    bold={bold}
    marginLeft={marginLeft}
    marginRight={marginRight}
    marginBottom={marginBottom}
    marginTop={marginTop}
    fontWeight={fontWeight}
    width={width}
    {...restProps}
  >
    {children}
  </TextWrapper>
);

Text.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.number,
  align: PropTypes.string,
  lineHeight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  bold: PropTypes.bool,
  width:PropTypes.string
};

Text.defaultProps = {
  children: null,
  color: 'lightGray',
  fontSize: 12,
  align: 'left',
  marginLeft: 0,
  marginBottom: 0,
  marginRight: 0,
  marginTop: 0,
  bold: false,
};

export default Text;
