import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import StatusBar from '../../components/statusBar';
import DrawerMenu from '../drawerMenu/DrawerMenu';
import getPlatformType from '../../helpers/helpers';
var layoutType = getPlatformType();

const Dashboard = ({ navigation }) => {
  return (
    <>
      {layoutType === 'tab' ? null : <StatusBar />}
      <DrawerMenu navigation={navigation} />
    </>
  );
};

export default Dashboard;
