import styled from 'styled-components/native';

export const Container = styled.View`

  padding: 20px;
  border-radius: ${(props) => props.borderRadius || 10}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: ${(props) => (props.noShadow ? '0px 0px 0px' : '0 6px 4px rgba(50,99,244,0.05)')};

`;

export const DashedContainer = styled.View`
  padding: 10px;
  border: 3px dashed #B4BED5;
  border-radius: 16px;

`;
