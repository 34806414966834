import React, { useState, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text } from 'react-native';
import StatusBar from '../../components/statusBar';
import Box from '../../components/box';
import { Feather } from '@expo/vector-icons';

import getPlatformType from '../../helpers/helpers';
import { ContainerWrapper, Section, SectionRow } from './style';
import { ScrollView } from 'react-native-gesture-handler';
import { AntDesign } from '@expo/vector-icons';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';

import TextInputComponent from '../../components/textinput/TextInputComponent';
import Button from '../../components/button/Button';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { navigate } from '../../navigation/RootNavigation';

var layoutType = getPlatformType();
const AccountEdit = ({ navigation }) => {
  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    contact_number: '',
    email: '',
    address: '',
    postcode: '',
  });

  const _handleChange = (e) => {
    setState({ ...state, [e.label]: e.value });
  };
  console.log('statestatestatestate', state);

  useEffect(() => {
    ajax
      .get(Urls.PROFILE)
      .then(({ data }) => {
        console.log('datadatadata', data);
        if (data?.data?.userDetails) {
          let payload = data?.data?.userDetails;
          setState({
            first_name: payload?.first_name,
            last_name: payload?.last_name,
            contact_number: payload?.contact_number,
            email: payload?.email,
            address: payload?.address,
            postcode: payload?.postcode,
            id: payload?.id,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);
  const _onUpdate = () => {
    ajax
      .put(Urls.PROFILE, state)
      .then(({ data }) => {
        if (data.status) {
          navigate('MyAccount');
        } else {
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  if (layoutType == 'phone') {
    return (
      <>
        <View style={{ alignItems: 'center' }}>
          <View
            style={{
              height: 154,
              width: 154,
              borderRadius: 80,
              backgroundColor: '#6D08C0',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <Feather name="user" size={90} color="white" />
          </View>
          <TextInputComponent value={state?.id} height={40} title={'Hubita ID'} colored={true} disabled={true} />
        </View>
        <Box style={{ width: '100%', marginTop: 40, padding: 20 }}>
          <Section>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'first_name' })}
              value={state?.first_name}
              height={40}
              style={{ width: '100%' }}
              title={'First name'}
            />

            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'last_name' })}
              value={state?.last_name}
              height={40}
              title={'Last name'}
            />
          </Section>
          <Section>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'address' })}
              value={state?.address}
              height={40}
              title={'Current Address'}
            />

            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'postcode' })}
              value={state?.postcode}
              height={40}
              title={'Postcode'}
            />
          </Section>
          <Section>
            <TextInputComponent
              onChangeText={(e) => _handleChange({ value: e, label: 'contact_number' })}
              value={state?.contact_number}
              height={40}
              title={'Contact number'}
            />

            <TextInputComponent
              disabled={true}
              onChangeText={(e) => console.log()}
              value={state?.email}
              height={40}
              title={'Email address'}
            />
          </Section>
        </Box>
        <View style={{ width: '100%', alignItems: 'center', marginTop: 20 }}>
          <Button type={'solid'} childText={'Save'} onPress={_onUpdate} width={150} />
        </View>
      </>
    );
  }

  return (
    <>
      <ContainerWrapper>
        <View style={{ flexDirection: layoutType == 'phone' ? 'column' : 'row', alignItems: 'center' }}>
          <View
            style={{
              height: 154,
              width: 154,
              borderRadius: 154,
              backgroundColor: '#6D08C0',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 50,
            }}
          >
            <Feather name="user" size={90} color="white" />
          </View>
          <TextInputComponent value={state?.id} height={40} title={'Hubita ID'} colored={true} disabled={true} />
        </View>
        <Box style={{ width: 641, marginTop: 40, padding: 20 }}>
          <Section>
            <SectionRow>
              <TextInputComponent
                onChangeText={(e) => _handleChange({ value: e, label: 'first_name' })}
                value={state?.first_name}
                height={40}
                title={'First name'}
              />
            </SectionRow>
            <SectionRow>
              <TextInputComponent
                onChangeText={(e) => _handleChange({ value: e, label: 'last_name' })}
                value={state?.last_name}
                height={40}
                title={'Last name'}
              />
            </SectionRow>
          </Section>
          <Section>
            <SectionRow>
              <TextInputComponent
                onChangeText={(e) => _handleChange({ value: e, label: 'address' })}
                value={state?.address}
                height={40}
                title={'Current Address'}
              />
            </SectionRow>
            <SectionRow>
              <TextInputComponent
                onChangeText={(e) => _handleChange({ value: e, label: 'postcode' })}
                value={state?.postcode}
                height={40}
                title={'Postcode'}
              />
            </SectionRow>
          </Section>
          <Section>
            <SectionRow>
              <TextInputComponent
                onChangeText={(e) => _handleChange({ value: e, label: 'contact_number' })}
                value={state?.contact_number}
                height={40}
                title={'Contact number'}
              />
            </SectionRow>
            <SectionRow>
              <TextInputComponent disabled={true} value={state?.email} height={40} title={'Email address'} />
            </SectionRow>
          </Section>
        </Box>
        <View style={{ width: 641, alignItems: 'flex-end', marginTop: '4%' }}>
          <Button type={'solid'} childText={'Save'} onPress={_onUpdate} width={150} />
        </View>
      </ContainerWrapper>
    </>
  );
};

export default drawerHoc({
  Component: AccountEdit,
  showBackButton: true,
});
