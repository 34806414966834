export const colorsTheme = {
  pink: '#E71E82',
  yellow: '#FFC163',
  red: '#FC4850',
  green: '#06A006',
  gray: '#646566',
  blue: '#438DAC',
  white: '#fff',
  purple:'#9F78FF'
};
