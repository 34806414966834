import React, { Component, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import Modal from 'modal-enhanced-react-native-web';
import Colors from '../../constants/Colors';
import Text from '../text';

const Loader = (props) => {
  const { loading } = props;
  return (
    <Modal isVisible={loading} backdropTransitionInTiming={2000} backdropTransitionOutTiming={2000} fullScreen={true}>
      <View style={styles.modalBackground}>
        <View style={styles.activityIndicatorWrapper}>
          <View style={{ padding: 30, backgroundColor: 'white', marginBottom: 20, borderRadius: 10 }}>
            <Text fontSize={35} color={Colors.light.purple} bold>
              HUBITA
            </Text>
          </View>
          <Text fontSize={18} color={'white'} fontWeight={'600'}>
            Please Wait..
          </Text>
        </View>
      </View>
    </Modal>
  );
};
const styles = StyleSheet.create({
  modalBackground: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  activityIndicatorWrapper: {
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

export default Loader;
