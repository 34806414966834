import { LinearGradient } from 'expo-linear-gradient';
import styled from 'styled-components/native';

export const Container = styled.View`
  height: 15px;
`;

export const GradientView = styled(LinearGradient)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 15;
`;
export const WhiteView = styled.View`
  height: 45;
  width: 100%;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.TouchableOpacity`
  padding: 10px;
  flex-direction: row;
`;
export const TextWrapper = styled.Text`
  color: #4258db;
  font-size: 20;
  font-weight: bold;
`;
export const LogoutContainer = styled.Text`
  color: black;
  font-size: 14;
  font-weight: bold;
`;
