import styled from 'styled-components/native';
import { Text, TextInput } from 'react-native';
import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();
let Container,Row,TextInputWrapper,LeftContainer,ListItem,Col,Left,Right,RightContainer,RightBox;
if(layoutType=="web"){
Container = styled.View`
flex-direction:row;
justify-content:space-between;
`;
Left=styled.View`
width:51%;
margin-vertical:15px;
`;
Right=styled.View`
width:48%;
margin-vertical:15px;
`;
LeftContainer=styled.View`
width:100%;
border-radius: ${(props) => props.borderRadius || 16}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
padding-horizontal:20px;
padding-vertical:10px;
height:135px;
`;
RightBox=styled.View`
width:28%;
height:135px;
border-radius: ${(props) => props.borderRadius || 16}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
margin-bottom:5px;
padding-vertical:28px;
justify-content:center;
align-items:center;
margin-horizontal:10px;
`;
ListItem=styled.TouchableOpacity`
flex-direction:row;
justify-content:space-between;
`;
TextInputWrapper = styled(TextInput)`
  width:100%;
  height:${props=>props?.height?props.height+'px':'30px'};
  padding:10px;
  font-size:18px;
  border-color:white;
`;
Row=styled.TouchableOpacity`
flex-direction:row;
justify-content:space-between;
align-items:center;
`;
Col=styled.TouchableOpacity`
width:${props=>props.width || "40%"};
padding:10px;
justify-content:center;
`;
RightContainer=styled.View`
flex-direction:row;
justify-content:space-evenly;
`;
} 
else if(layoutType=="phone"){
  Container = styled.View`
flex-direction:column;
justify-content:space-between;
`;
Left=styled.View`
width:100%;
margin-vertical:15px;
`;
Right=styled.View`
width:100%;
margin-vertical:0px;
`;
LeftContainer=styled.View`
width:100%;
border-radius: ${(props) => props.borderRadius || 16}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
padding-horizontal:20px;
padding-vertical:10px;
height:135px;
`;
RightBox=styled.View`
width:28%;
height:135px;
border-radius: ${(props) => props.borderRadius || 16}px;
background-color: ${(props) => props.backgroundColor || 'white'};
box-shadow: 0 6px 4px rgba(50,99,244,0.05);
margin-bottom:5px;
padding-vertical:28px;
justify-content:center;
align-items:center;
margin-horizontal:10px;
`;
ListItem=styled.TouchableOpacity`
flex-direction:row;
justify-content:space-between;
`;
TextInputWrapper = styled(TextInput)`
  width:100%;
  height:${props=>props?.height?props.height+'px':'30px'};
  padding:10px;
  font-size:18px;
  border-color:white;
`;
Row=styled.TouchableOpacity`
flex-direction:row;
justify-content:space-between;
align-items:center;
`;
Col=styled.TouchableOpacity`
width:${props=>props.width || "40%"};
border-color:#d8d8d8;
padding:10px;
justify-content:center;
`;
RightContainer=styled.View`
flex-direction:row;
justify-content:space-evenly;
`;

}
else {
  Container = styled.View`
  flex-direction:row;
  justify-content:space-between;
  `;
  Left=styled.View`
  width:51%;
  margin-vertical:15px;
  `;
  Right=styled.View`
  width:48%;
  margin-vertical:15px;
  `;
  LeftContainer=styled.View`
  width:100%;
  border-radius: ${(props) => props.borderRadius || 16}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: 0 6px 4px rgba(50,99,244,0.05);
  padding-horizontal:20px;
  padding-vertical:10px;
  height:135px;
  `;
  RightBox=styled.View`
  width:28%;
  height:135px;
  border-radius: ${(props) => props.borderRadius || 16}px;
  background-color: ${(props) => props.backgroundColor || 'white'};
  box-shadow: 0 6px 4px rgba(50,99,244,0.05);
  margin-bottom:5px;
  padding-vertical:28px;
  justify-content:center;
  align-items:center;
  margin-horizontal:10px;
  `;
  ListItem=styled.TouchableOpacity`
  flex-direction:row;
  justify-content:space-between;
  `;
  TextInputWrapper = styled(TextInput)`
    width:100%;
    height:${props=>props?.height?props.height+'px':'30px'};
    padding:10px;
    font-size:18px;
    border-color:white;
  `;
  Row=styled.TouchableOpacity`
  flex-direction:row;
  justify-content:space-between;
  align-items:center;
  `;
  Col=styled.TouchableOpacity`
  width:${props=>props.width || "40%"};
  padding:10px;
  justify-content:center;
  `;
  RightContainer=styled.View`
  flex-direction:row;
  justify-content:space-evenly;
  `;
}
export {Container,Row,TextInputWrapper,LeftContainer,ListItem,Col,Left,Right,RightContainer,RightBox};



