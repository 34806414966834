export const getHelperKet = (key, appliance, BaseValue) => {
  switch (key) {
    case key == 'gas':
      return getApplicantVluesforGas(appliance, BaseValue);
      break;
    case key == 'pat':
      return getApplicantVluesforPat(appliance, BaseValue);
      break;
    case key == 'epc':
      return getApplicantVluesforEPC(appliance, BaseValue);
      break;
    case key == 'eicr':
      return getApplicantVluesforEICR(appliance, BaseValue);
      break;
    default:
      break;
  }
};
export const getApplicantVluesforGas = (appliance, BaseValue, type) => {
  if (appliance > 5) {
    return 'POA';
  } else if (appliance >= 3 || appliance < 6) {
    if (type == 'add') {
      return BaseValue + 20;
    } else if (type == 'sub') {
      if (appliance == 5) {
        return BaseValue;
      }
      return BaseValue - 20;
    }
  }
};
export const getApplicantVluesforEPC = (appliance, BaseValue, type) => {
  if (appliance > 6) {
    return 'POA';
  } else if (appliance < 5) {
    return 65;
  } else if (appliance < 7) {
    return 75;
  }
};
export const getApplicantVluesforPat = (appliance, BaseValue, type) => {
  if (appliance > 30) {
    return 'POA';
  } else if (appliance < 15) {
    return 65;
  } else if (appliance < 31) {
    return 85;
  }
};

export const getApplicantVluesforEICR = (appliance, BaseValue, type) => {
  if (appliance > 5) {
    return 'POA';
  } else if (appliance >= 2 || appliance < 6) {
    if (type == 'add') {
      return BaseValue + 10;
    } else if (type == 'sub') {
      if (appliance == 5) {
        return BaseValue;
      }
      return BaseValue - 20;
    }
  }
};
