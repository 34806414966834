import React, { useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import COLORS from '../../constants/Colors';
import { Row } from '../../screens/commonStyles';
import { InsideRadio, Radio } from './index.style';
import Text from '../text/TextComponent';
import Colors from '../../constants/Colors';

import getPlatformType from '../../helpers/helpers';
const layoutType = getPlatformType();

const ItemCounter = (props) => {
  const { items, onChange, values } = props;
  const [state, setState] = useState({});
  const [stateCounter, setStateCounter] = useState({});
  useEffect(() => {
    if (values) {
      setStateCounter(values);
    }
  }, [values]);
  const _renderItem = (item) => {
    return (
      <Row
        style={{
          paddingVertical: 10,
          alignItems: 'center',
          marginRight: '5%',
          width: layoutType == 'phone' ? '100%' : '28%',
        }}
      >
        <Radio
          onPress={() => {
            // setState({...state,[item]:!state[item]})
          }}
          checked={stateCounter[item] > 0 ? true : false}
        >
          <InsideRadio checked={stateCounter[item] > 0 ? true : false} />
        </Radio>
        <Text bold fontSize={16} style={{ width: '50%' }} marginLeft={10}>
          {item}
        </Text>
        <Row style={{ alignItems: 'center', marginLeft: 20, width: '20%' }}>
          <MaterialCommunityIcons
            onPress={() => {
              setStateCounter({ ...stateCounter, [item]: stateCounter[item] ? stateCounter[item] + 1 : 1 });
              onChange({ ...stateCounter, [item]: stateCounter[item] ? stateCounter[item] + 1 : 1 });
            }}
            name={'plus-circle-outline'}
            style={{ marginLeft: 5, fontSize: 20, color: Colors.light.purple }}
          />
          <Text marginLeft={5} fontSize={20} color={Colors.light.purple}>
            {stateCounter[item] ? stateCounter[item] : 0}
          </Text>
          <MaterialCommunityIcons
            onPress={() => {
              setStateCounter({ ...stateCounter, [item]: stateCounter[item] ? stateCounter[item] - 1 : 0 });
              onChange({ ...stateCounter, [item]: stateCounter[item] ? stateCounter[item] - 1 : 0 });
            }}
            name={'minus-circle-outline'}
            style={{ marginLeft: 5, fontSize: 20, color: Colors.light.purple }}
          />
        </Row>
      </Row>
    );
  };
  return (
    <FlatList
      data={items}
      renderItem={({ item }) => _renderItem(item)}
      numColumns={layoutType == 'phone' ? 1 : 3}
      ListHeaderComponent={() => (
        <Text fontSize={15} bold marginBottom={10}>
          Select the types of rooms and enter the quantity
        </Text>
      )}
    />
  );
};

export default ItemCounter;
