import React from 'react';
import { View, Text, Image } from 'react-native';
import { MediaBox, MediaWrapper } from './styles';
import Button from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';
import RenderHtml from 'react-native-render-html';

const MAX_DESCRIPTION_LENGTH = 60;

const MediaComponent = ({ image, title, description, onPress }) => {
    const truncatedDescription = description
        ? description.slice(0, MAX_DESCRIPTION_LENGTH)
        : '';

    return (

        <MediaBox>
            
            {image && (
                <Image source={{ uri: image }} style={{ width: "100%", height: 100, aspectRatio: 1, borderBottomWidth: 0.5, borderTopLeftRadius: 8, borderTopRightRadius: 8 , resizeMode: "cover"}} />
            )}
        
            <View style={{ paddingHorizontal: 20,paddingTop:20, width: "100%",flex:1 }}>
                <View style={{flex:0.9}}>
                <Text style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: 18,
                    lineHeight: 24,
                }}>{title}</Text>
           
                  {/* <RenderHtml    
                  renderersProps={{
    text: {
      style: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: 14,
        lineHeight: 19,
        color: 'rgba(76, 78, 100, 0.87)',
        marginTop: 6,
        height: '20%',
        color: '#4C4E64'

      }
    }
  }}
  source={{ html: `${truncatedDescription}` }} /> */}
                

                </View>
                <View style={{ alignSelf: 'flex-end', width: 169,flex:0.1,justifyContent:"flex-end",}}>
                    <Button
                        onPress={onPress ? onPress : null}
                        color={"#00BCD4"}
                        type={'solid'}
                        childText="Read More"
                        width={"100%"}
                    />
                </View>
          
            </View>
        </MediaBox>

    );
};

export default MediaComponent;


