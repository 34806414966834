import React from 'react';
import styled from 'styled-components/native';
import variables from '../variables.json';

/*
Example
<CardBox hasShadow={true} hasBorderRadius={true}>
    Some data
</CardBox>

*/

let CardBox;

CardBox = styled.View`
  border-radius: ${(props) => (props.hasBorderRadius ? `16px` : `0`)};
  padding: ${(props) => (props.padding ? props.padding : `16px`)};
  background-color: ${(props) => (props.backColor ? `${props.backColor}` : `${variables.white}`)};
  border: ${(props) => (props?.borderColor ? '1px solid ' + props?.borderColor : '0px')};
`;

export { CardBox };
