import React, { useState, useEffect } from 'react';
import { View, Text, Image } from 'react-native';
import ajax from '../../helpers/ajaxHelper';
import Urls from '../../services/Urls';
import { BackButton } from '../../components/button/Button';
import { navigate } from '../../navigation/RootNavigation';
import MediaComponent from './mediacomponent';
import drawerHoc from '../../components/drawerHOC/DrawerHOC';
import RenderHtml from 'react-native-render-html';
import { MediaLink } from './styles';

const LandlordMediaDetails = ({ route }) => {
  const [mediaItem, setMediaItem] = useState(null);

  useEffect(() => {
    const { data } = route.params;

    ajax
      .get(Urls.GET_POST_DETAILS + data)
      .then(({ data }) => {
        if (data.status) {
          setMediaItem(data.payload);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);


  if (!mediaItem) {
    return <Text>Loading...</Text>;
  }

  return (
    <View style={{ justifyContent: 'flex-start' , backgroundColor: 'white' ,marginTop:20}}>
      <View style={{ marginLeft: 30  , marginRight:30}}>
    {mediaItem.file ?  <Image source={{ uri: mediaItem.file }} style={{ width: "100%", height: 248 ,resizeMode: "contain" ,marginBottom:30 }} />:null}

      {/* <BackButton onPress={() => navigate('MediaHub')} /> */}
      <Text style={{
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 26,

        lineHeight: 32,
      }}>{mediaItem.title}</Text>
 
       <RenderHtml contentWidth={'70%'} source={{ html: `${mediaItem?.description}` }} />
      {mediaItem.link? <MediaLink>{mediaItem.link}</MediaLink>

      :null}

    </View>
    </View>
  );
};

export default drawerHoc({
  Component: LandlordMediaDetails,
  showBackButton: true,
});
