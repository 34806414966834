import React from 'react';
import styled from 'styled-components';
import variables from '../variables.json';

export const BackBox = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
`;

export const BackButton = styled.button`
  width: fit-content;
  border: none;
  background: none;
  font-weight: bold;
  overflow: hidden;
  outline: 0;
`;

export const LineSeperator = styled.hr`
  margin: 5px;
  border: 0;
  border-bottom: 1px solid ${variables.lineGrey};
`;
