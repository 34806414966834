import React from 'react';
import { Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import COLORS from '../../constants/Colors';

const CheckBox = (props) => {
  const { checked, label, onPress, fontSize, width, fontWeight } = props;
  return (
    <View
      style={{
        width: width ? width : 'auto',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Ionicons
        onPress={onPress}
        name={!checked ? 'ios-square-outline' : 'ios-checkbox-outline'}
        size={!checked ? 22 : 24}
        color={COLORS.light.lightPurple}
      />
      <Text
        style={{
          paddingLeft: '1%',
          alignSelf: 'center',
          fontSize: fontSize ? fontSize : 14,
          fontWeight: fontWeight ? fontWeight : '600',
        }}
      >
        {label}
      </Text>
    </View>
  );
};

export default CheckBox;
